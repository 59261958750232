import { defaultErrorResponse } from "../../defaults";
import {
    IGuestTenant,
    IGuestTenantDooapAppRegistration,
    IGuestTenantInput,
    IUpdateConsentStatus,
} from "store/environments/tenants/tenant-guest-tenants/tenant-guest-tenants-types";
import { IEmailDetails } from "store/customers/customer-details/customer-details-types";
import { API } from "../../../utils/api-config";
import Axios, { CancelTokenSource } from "axios";

export const loadGuestTenants = async (
    environmentMoniker: string,
    tenantMoniker: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[IGuestTenant[] | null, number]> => {
    if (!environmentMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }
    const response = await API.get(
        `environments/${environmentMoniker}/tenants/${tenantMoniker}/guest-tenants`,
        { cancelToken: cancelTokenSource?.token }
    );

    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};

export const sendEmailForGuestTenant = async (
    environmentMoniker: string,
    emailDetails: IEmailDetails
): Promise<[any | null, number]> => {
    const response = await API.post(
        `environments/${environmentMoniker}/tenant/guest-tenant/send-email`,
        emailDetails
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const activateGuestTenant = async (
    guestTenantId: string,
    environmentMoniker: string,
    tenantMoniker: string
): Promise<[any | null, number]> => {
    const response = await API.post(
        `environments/${environmentMoniker}/tenants/${tenantMoniker}/guest-tenants/${guestTenantId}/activate`,
        {}
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const loadGuestTenantConsents = async (
    environmentMoniker: string,
    tenantMoniker: string
): Promise<[IGuestTenantDooapAppRegistration[] | null, number]> => {
    const response = await API.get(
        `environments/${environmentMoniker}/tenants/${tenantMoniker}/guest-tenants/app-registrations`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const isDooapGuestTenantAllowed = async (
    environmentMoniker: string,
    tenantMoniker: string
): Promise<[boolean | null, number]> => {
    const response = await API.get(
        `environments/${environmentMoniker}/tenants/${tenantMoniker}/guest-tenants/is-dooap-allowed`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const createGuestTenant = async (
    environmentMoniker: string,
    tenantMoniker: string,
    guestTenant: IGuestTenantInput
): Promise<[any | null, number]> => {
    const response = await API.post(
        `environments/${environmentMoniker}/tenants/${tenantMoniker}/guest-tenants`,
        guestTenant
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const updateGuestTenantConsentStatus = async (
    environmentMoniker: string,
    tenantMoniker: string,
    guestTenantId: string,
    consentId: string,
    consentStatus: IUpdateConsentStatus
): Promise<[any | null, number]> => {
    const response = await API.put(
        `environments/${environmentMoniker}/tenants/${tenantMoniker}/guest-tenants/${guestTenantId}/consents/${consentId}/status`,
        consentStatus
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const updateGuestTenant = async (
    environmentMoniker: string,
    tenantMoniker: string,
    guestTenantId: string,
    guestTenantUpdate: IGuestTenantInput
): Promise<[any, number]> => {
    const response = await API.put(
        `environments/${environmentMoniker}/tenants/${tenantMoniker}/guest-tenants/${guestTenantId}`,
        guestTenantUpdate
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};
