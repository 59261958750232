import React, { MutableRefObject, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, FormInstance, InputGroup, Schema } from "rsuite";
import {
    IAxIntegrationApproveData,
    IntegrationFormType,
} from "store/environments/tenants/enroll-tenant/enroll-tenant-types";
import { setAutofillFormValues } from "utils/enroll-integration-helper";
import cn from "classnames";
import constants from "utils/constants";
import { validate } from "uuid";
import css from "./IntegrationInformation.module.css";
import EyeRoundIcon from "@rsuite/icons/EyeRound";

interface IComponentProps {
    d365IntegrationInfo: IAxIntegrationApproveData;
    isFormDisabled: boolean;
    passwordFormRef: MutableRefObject<FormInstance<Record<string, any>> | null>;
    onPasswordFormChange: (data: Record<string, string>) => void;
}

const PasswordIntegrationForm: React.FC<IComponentProps> = ({
    d365IntegrationInfo,
    passwordFormRef,
    isFormDisabled,
    onPasswordFormChange,
}: IComponentProps) => {
    const { t } = useTranslation();

    const [isPasswordShown, setIsPasswordShown] = useState(false);

    const onTogglePasswordHandler = () =>
        setIsPasswordShown((isPasswordShown) => !isPasswordShown);

    const onMouseDownPasswordHandler = () => {
        setIsPasswordShown(true);
    };

    const onChangeFormHandler = (data: any) => {
        setAutofillFormValues(
            IntegrationFormType.Password,
            d365IntegrationInfo,
            data,
            onPasswordFormChange
        );
    };

    const model = Schema.Model({
        username: Schema.Types.StringType()
            .isRequired(
                t("enrollTenant.stepFormCommon.validationMessages.isRequired")
            )
            .isEmail(
                t(
                    "enrollTenant.stepFormCommon.validationMessages.isCorrectEmail"
                )
            ),
        password: Schema.Types.StringType().isRequired(
            t("enrollTenant.stepFormCommon.validationMessages.isRequired")
        ),
        axClientId: Schema.Types.StringType()
            .isRequired(
                t("enrollTenant.stepFormCommon.validationMessages.isRequired")
            )
            .addRule(
                (enteredValue: string) => validate(enteredValue),
                t(
                    "enrollTenant.enrollForm.secretIntegrationForm.appRegistrationIdValidation"
                )
            ),
        axUrl: Schema.Types.StringType()
            .isRequired(
                t("enrollTenant.stepFormCommon.validationMessages.isRequired")
            )
            .pattern(
                constants.urlRegex,
                t("enrollTenant.stepFormCommon.validationMessages.isCorrectUrl")
            ),
        axAuthority: Schema.Types.StringType()
            .isRequired(
                t("enrollTenant.stepFormCommon.validationMessages.isRequired")
            )
            .addRule((d365AuthorityValue) => {
                return d365AuthorityValue.includes(".");
            }, t("enrollTenant.stepFormCommon.validationMessages.doesContainDot")),
    });

    return (
        <Form
            ref={passwordFormRef}
            model={model}
            className={css.formContainer}
            formValue={d365IntegrationInfo}
            onChange={(data) => {
                onChangeFormHandler(data);
            }}
        >
            <Form.Group className={css.formFieldSection}>
                <Form.ControlLabel className={css.label}>
                    {t("enrollTenant.enrollForm.usernameTitle")}
                </Form.ControlLabel>
                <Form.Control
                    disabled={isFormDisabled}
                    name={"username"}
                    placeholder={t(
                        "enrollTenant.enrollForm.usernamePlaceholder"
                    )}
                    className={css.formControl}
                />
            </Form.Group>
            <Form.Group className={css.formFieldSection}>
                <Form.ControlLabel className={css.label}>
                    {t("enrollTenant.enrollForm.passwordTitle")}
                </Form.ControlLabel>
                <InputGroup inside>
                    <Form.Control
                        disabled={isFormDisabled}
                        name={"password"}
                        type={isPasswordShown ? "text" : "password"}
                        placeholder={t(
                            "enrollTenant.enrollForm.passwordPlaceholder"
                        )}
                        className={css.formControl}
                    />
                    <InputGroup.Addon
                        onClick={onTogglePasswordHandler}
                        onMouseDown={onMouseDownPasswordHandler}
                        className={css.imageSection}
                    >
                        <EyeRoundIcon />
                    </InputGroup.Addon>
                </InputGroup>
            </Form.Group>
            <Form.Group className={css.formFieldSection}>
                <Form.ControlLabel className={css.label}>
                    {t(
                        "enrollTenant.enrollForm.passwordIntegrationForm.clientIdTitle"
                    )}
                </Form.ControlLabel>
                <Form.Control
                    disabled={isFormDisabled}
                    name={"axClientId"}
                    placeholder={t(
                        "enrollTenant.enrollForm.secretIntegrationForm.appRegistrationIdPlaceholder"
                    )}
                    className={cn(
                        css.formControl,
                        css.formFieldDisabled,
                        css.formFieldWider
                    )}
                />
            </Form.Group>
            <Form.Group className={css.formFieldSection}>
                <Form.ControlLabel className={css.label}>
                    {t("enrollTenant.enrollForm.d365UrlTitle")}
                </Form.ControlLabel>
                <Form.Control
                    disabled={isFormDisabled}
                    name={"axUrl"}
                    placeholder={t("enrollTenant.enrollForm.d365Placeholder")}
                    className={cn(css.formControl, css.formFieldWider)}
                />
            </Form.Group>
            <Form.Group className={css.formFieldSection}>
                <Form.ControlLabel className={css.label}>
                    {t("enrollTenant.enrollForm.tokenUrlTitle")}
                </Form.ControlLabel>
                <Form.Control
                    readOnly
                    name={"tokenUrl"}
                    className={cn(css.formControl, css.formFieldDisabled)}
                />
            </Form.Group>
            <Form.Group className={css.formFieldSection}>
                <Form.ControlLabel className={css.label}>
                    {t("enrollTenant.enrollForm.d365AuthorityTitle")}
                </Form.ControlLabel>
                <Form.Control
                    disabled={isFormDisabled}
                    name={"axAuthority"}
                    placeholder={t(
                        "enrollTenant.enrollForm.d365AuthorityPlaceholder"
                    )}
                    className={cn(css.formControl)}
                />
            </Form.Group>
        </Form>
    );
};

export default PasswordIntegrationForm;
