import React, { RefObject, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    IErpConnectionInfo,
    AuthenticationMethod,
    AuthenticationType,
} from "store/environments/tenants/tenant-configurations/tenant-configurations-types";
import {
    DatePicker,
    Form,
    FormInstance,
    IconButton,
    InputGroup,
    InputPicker,
    Tooltip,
    Whisper,
} from "rsuite";
import { isPreviousDate } from "utils/date-helper";
import cn from "classnames";
import {
    Area,
    IDooapAppRegistration,
} from "store/environments/environment-types";
import { CustomerType } from "store/customers/add-new-customer/add-new-customer-types";
import EditIcon from "@rsuite/icons/Edit";
import CopyIcon from "@rsuite/icons/Copy";
import EyeRoundIcon from "@rsuite/icons/EyeRound";

interface IComponentProps {
    envMoniker: string;
    authMethods: AuthenticationMethod[];
    erpConnectionInfo: IErpConnectionInfo | null;
    defaultAuthenticationMethod: AuthenticationMethod;
    authenticationMethod: AuthenticationMethod;
    appRegistrations: IDooapAppRegistration[];
    isConnectionInfoFetched: boolean;
    isPasswordAuthentication: boolean;
    customerType: CustomerType | null;
    formRef: RefObject<FormInstance> | null;
    setConnectionInfoChanged: (isChanged: boolean) => void;
    setErpConnectionInfo: (connectionInfo: IErpConnectionInfo | null) => void;
    setAuthenticationMethod: (authMethod: AuthenticationMethod) => void;
    setIsPasswordAuthentication: (isPasswordAuth: boolean) => void;
    fetchDooapAppRegistrations: (envMoniker: string) => void;
    setIsUnsavedChangeAvailable: (isChangesAvailable: boolean) => void;
}

const initialEditableFields = {
    url: false,
    clientId: false,
    username: false,
    authField: false,
    expDate: false,
};

const ErpConnectionInfo: React.FC<IComponentProps> = ({
    envMoniker,
    authMethods,
    erpConnectionInfo,
    defaultAuthenticationMethod,
    authenticationMethod,
    appRegistrations,
    isPasswordAuthentication,
    isConnectionInfoFetched,
    customerType,
    formRef,
    setConnectionInfoChanged,
    setErpConnectionInfo,
    setAuthenticationMethod,
    setIsPasswordAuthentication,
    fetchDooapAppRegistrations,
    setIsUnsavedChangeAvailable,
}: IComponentProps) => {
    const { t } = useTranslation();
    const [editableFields, setEditableFields] = useState(initialEditableFields);
    const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);
    const [isAuthMethodChanged, setIsAuthMethodChanged] =
        useState<boolean>(false);
    const [d365AppRegistration, setD365AppRegistration] =
        useState<IDooapAppRegistration | null>(null);
    const [isCopyErpUrlButtonShown, setIsCopyErpUrlButtonShown] =
        useState(false);
    const [copyFieldValueMessage, setCopyFiledValueMessage] = useState(
        t(
            "tenantConfig.generalConfigurationPage.copyFieldValue.copyToClipboard"
        )
    );
    const copyFieldValueTooltip = <Tooltip>{copyFieldValueMessage}</Tooltip>;

    useEffect(() => {
        if (isConnectionInfoFetched && erpConnectionInfo) {
            if (erpConnectionInfo.clientSecret) {
                setAuthenticationMethod(
                    authMethods.find(
                        (m) =>
                            m.authenticationType ===
                            AuthenticationType.ClientSecret
                    ) || defaultAuthenticationMethod
                );
            } else {
                setAuthenticationMethod(
                    authMethods.find(
                        (m) =>
                            m.authenticationType ===
                            AuthenticationType.UsernamePassword
                    ) || defaultAuthenticationMethod
                );
            }
            setEditableFields(initialEditableFields);
        }
    }, [isConnectionInfoFetched]);

    useEffect(() => {
        if (!appRegistrations.length) {
            fetchDooapAppRegistrations(envMoniker);
        }
    }, [appRegistrations, envMoniker, fetchDooapAppRegistrations]);

    useEffect(() => {
        const appRegistration = appRegistrations.find(
            (registration) => registration.area === Area.D365
        );
        setD365AppRegistration(appRegistration as IDooapAppRegistration);
    }, [appRegistrations]);

    useEffect(() => {
        if (
            d365AppRegistration &&
            authenticationMethod &&
            authenticationMethod.authenticationType ===
                AuthenticationType.UsernamePassword &&
            !erpConnectionInfo?.clientId
        ) {
            updateConnectionInfo((connectionInfo: IErpConnectionInfo) => ({
                ...connectionInfo,
                clientId: d365AppRegistration.clientId,
            }));
        }
    }, [d365AppRegistration, authenticationMethod]);

    useEffect(() => {
        const isUsernamePassword =
            authenticationMethod.authenticationType ===
            AuthenticationType.UsernamePassword;

        setEditableFields({
            ...editableFields,
            username: !erpConnectionInfo?.username.length,
            authField: isUsernamePassword
                ? !erpConnectionInfo?.password.length
                : !erpConnectionInfo?.clientSecret.length,
            clientId: isUsernamePassword
                ? false
                : !erpConnectionInfo?.clientId.length,
            expDate: isUsernamePassword
                ? editableFields.expDate
                : erpConnectionInfo?.secretExpirationDate === null,
        });
    }, [authenticationMethod]);

    useEffect(() => {
        if (isConnectionInfoFetched) {
            if (
                authenticationMethod &&
                authenticationMethod.authenticationType ===
                    AuthenticationType.UsernamePassword
            ) {
                setIsPasswordAuthentication(true);
            } else {
                setIsPasswordAuthentication(false);
            }
        }
    }, [authenticationMethod.authenticationType, isConnectionInfoFetched]);

    useEffect(() => {
        if (isPasswordAuthentication && isAuthMethodChanged) {
            updateConnectionInfo((connectionInfo: IErpConnectionInfo) => ({
                ...connectionInfo,
                clientSecret: "",
                secretExpirationDate: null,
            }));
            setIsAuthMethodChanged(false);
        }
    }, [isPasswordAuthentication, isAuthMethodChanged]);

    useEffect(() => {
        formRef?.current?.check();
    }, [erpConnectionInfo]);

    const updateConnectionInfo = (
        update: (connectionInfo: IErpConnectionInfo) => IErpConnectionInfo
    ) => {
        if (erpConnectionInfo) {
            setErpConnectionInfo(update(erpConnectionInfo));
        }
    };

    const handlePasswordToggle = () =>
        setIsPasswordShown((isPasswordShown) => !isPasswordShown);

    const handlePasswordMouseDown = () => {
        setIsPasswordShown(true);
    };

    const handleCopyButtonClick = (value: string) => {
        navigator.clipboard.writeText(value);
        setCopyFiledValueMessage(
            t("tenantConfig.generalConfigurationPage.copyFieldValue.copied")
        );
    };

    const handleChange = (
        name: keyof IErpConnectionInfo,
        value: string | Date
    ) => {
        updateConnectionInfo((connectionInfo: IErpConnectionInfo) => ({
            ...connectionInfo,
            [name]: value,
        }));
        setConnectionInfoChanged(true);
        setIsUnsavedChangeAvailable(true);
    };

    const handleAuthFieldChange = (value: string) => {
        if (isPasswordAuthentication) {
            updateConnectionInfo((connectionInfo: IErpConnectionInfo) => ({
                ...connectionInfo,
                password: value,
            }));
        } else {
            updateConnectionInfo((connectionInfo: IErpConnectionInfo) => ({
                ...connectionInfo,
                clientSecret: value,
                secretExpirationDate: null,
            }));

            setEditableFields({
                ...editableFields,
                expDate: true,
            });
        }
        setConnectionInfoChanged(true);
        setIsUnsavedChangeAvailable(true);
    };

    const handleChangeAuthMethod = (value: AuthenticationType) => {
        setAuthenticationMethod(
            authMethods.find((t) => t.authenticationType === value) ||
                defaultAuthenticationMethod
        );
        setIsAuthMethodChanged(true);
        updateConnectionInfo((connectionInfo: IErpConnectionInfo) => ({
            ...connectionInfo,
            clientId: "",
        }));
        setConnectionInfoChanged(true);
        setIsUnsavedChangeAvailable(true);
    };

    const formKey: Record<keyof IErpConnectionInfo, keyof IErpConnectionInfo> =
        {
            url: "url",
            username: "username",
            clientId: "clientId",
            secretExpirationDate: "secretExpirationDate",
            clientSecret: "clientSecret",
            password: "password",
        };
    return (
        <>
            <Form.Group
                className={"general-configurations__grid-item"}
                onMouseEnter={() => setIsCopyErpUrlButtonShown(true)}
                onMouseLeave={() => setIsCopyErpUrlButtonShown(false)}
            >
                <Form.ControlLabel className={"general-configurations__label"}>
                    {t("tenantConfig.d365InformationPage.erpUrlTitle")}
                </Form.ControlLabel>
                <div className={"general-configurations__auth-field"}>
                    <Form.Control
                        readOnly={!editableFields.url}
                        className={cn(
                            "general-configurations__form-control",
                            !editableFields.url
                                ? "general-configurations__disabled"
                                : ""
                        )}
                        name={formKey.url}
                        onChange={(value: string) => {
                            handleChange(formKey.url, value);
                        }}
                    />
                    <IconButton
                        disabled={editableFields.url}
                        appearance="subtle"
                        size="sm"
                        onClick={() => {
                            setEditableFields({
                                ...editableFields,
                                url: true,
                            });
                        }}
                        icon={<EditIcon />}
                        className={"general-configurations__pencil-icon"}
                    />
                    {isCopyErpUrlButtonShown && !editableFields.url && (
                        <div>
                            <Whisper
                                placement="right"
                                trigger="hover"
                                speaker={copyFieldValueTooltip}
                            >
                                <IconButton
                                    icon={<CopyIcon />}
                                    className={
                                        "general-configurations__copy-icon"
                                    }
                                    onClick={() => {
                                        handleCopyButtonClick(
                                            erpConnectionInfo?.url ?? ""
                                        );
                                    }}
                                    onMouseLeave={() =>
                                        setCopyFiledValueMessage(
                                            t(
                                                "tenantConfig.generalConfigurationPage.copyFieldValue.copyToClipboard"
                                            )
                                        )
                                    }
                                />
                            </Whisper>
                        </div>
                    )}
                </div>
            </Form.Group>
            <Form.Group className={"general-configurations__grid-item"}>
                <Form.ControlLabel className={"general-configurations__label"}>
                    {t("tenantConfig.d365InformationPage.authMethodTitle")}
                </Form.ControlLabel>
                <Form.Control
                    accepter={InputPicker}
                    data={authMethods
                        .filter((authMethod) =>
                            customerType === CustomerType.AX2012
                                ? authMethod.authenticationType ===
                                  AuthenticationType.UsernamePassword
                                : true
                        )
                        .map((m: AuthenticationMethod) => ({
                            label: m.authenticationDescription,
                            value: m.authenticationType,
                        }))}
                    name="authenticationMethod"
                    cleanable={false}
                    searchable={false}
                    onChange={handleChangeAuthMethod}
                    className={cn(
                        "general-configurations__form-control",
                        "general-configurations__company-input-picker"
                    )}
                />
            </Form.Group>
            <Form.Group className={"general-configurations__grid-item"}>
                <Form.ControlLabel className={"general-configurations__label"}>
                    {t("tenantConfig.d365InformationPage.clientIdTitle")}
                </Form.ControlLabel>
                <div className={"general-configurations__auth-field"}>
                    <Form.Control
                        readOnly={!editableFields.clientId}
                        className={cn(
                            "general-configurations__form-control",
                            !editableFields.clientId
                                ? "general-configurations__disabled"
                                : ""
                        )}
                        name={formKey.clientId}
                        onChange={(value: string) => {
                            handleChange(formKey.clientId, value);
                        }}
                    />
                    <IconButton
                        disabled={editableFields.clientId}
                        appearance="subtle"
                        size="sm"
                        onClick={() => {
                            setEditableFields({
                                ...editableFields,
                                clientId: true,
                            });
                        }}
                        icon={<EditIcon />}
                        className={"general-configurations__pencil-icon"}
                    />
                </div>
            </Form.Group>
            <Form.Group className={"general-configurations__grid-item"}>
                <Form.ControlLabel className={"general-configurations__label"}>
                    {t("tenantConfig.d365InformationPage.usernameTitle")}
                </Form.ControlLabel>
                <div className={"general-configurations__auth-field"}>
                    <Form.Control
                        readOnly={!editableFields.username}
                        className={cn(
                            "general-configurations__form-control",
                            !editableFields.username
                                ? "general-configurations__disabled"
                                : ""
                        )}
                        name={formKey.username}
                        onChange={(value: string) => {
                            handleChange(formKey.username, value);
                        }}
                    />
                    <IconButton
                        disabled={editableFields.username}
                        appearance="subtle"
                        size="sm"
                        onClick={() => {
                            setEditableFields({
                                ...editableFields,
                                username: true,
                            });
                        }}
                        icon={<EditIcon />}
                        className={"general-configurations__pencil-icon"}
                    />
                </div>
            </Form.Group>
            <Form.Group className={"general-configurations__grid-item"}>
                <Form.ControlLabel className={"general-configurations__label"}>
                    {isPasswordAuthentication
                        ? t("tenantConfig.d365InformationPage.passwordTitle")
                        : t(
                              "tenantConfig.d365InformationPage.clientSecretTitle"
                          )}
                </Form.ControlLabel>
                <div className={"general-configurations__auth-field"}>
                    <InputGroup
                        inside
                        className={"general-configurations__input-group"}
                    >
                        <Form.Control
                            readOnly={!editableFields.authField}
                            className={cn(
                                "general-configurations__form-control",
                                !editableFields.authField
                                    ? "general-configurations__disabled"
                                    : ""
                            )}
                            type={isPasswordShown ? "text" : "password"}
                            name={
                                isPasswordAuthentication
                                    ? formKey.password
                                    : formKey.clientSecret
                            }
                            onChange={handleAuthFieldChange}
                        />
                        <InputGroup.Addon
                            onClick={handlePasswordToggle}
                            onMouseDown={handlePasswordMouseDown}
                            className={"general-configurations__image-section"}
                        >
                            <EyeRoundIcon />
                        </InputGroup.Addon>
                    </InputGroup>
                    <IconButton
                        disabled={editableFields.authField}
                        appearance="subtle"
                        size="sm"
                        onClick={() => {
                            setEditableFields({
                                ...editableFields,
                                authField: true,
                            });
                        }}
                        icon={<EditIcon />}
                        className={"general-configurations__pencil-icon"}
                    />
                </div>
            </Form.Group>
            {!isPasswordAuthentication && (
                <Form.Group className={"general-configurations__grid-item"}>
                    <Form.ControlLabel
                        className={"general-configurations__label"}
                    >
                        {t(
                            "tenantConfig.d365InformationPage.clientSecretExpDateTitle"
                        )}
                    </Form.ControlLabel>
                    <div className={"general-configurations__auth-field"}>
                        <Form.Control
                            editable={false}
                            accepter={DatePicker}
                            readOnly={!editableFields.expDate}
                            name={formKey.secretExpirationDate}
                            value={
                                erpConnectionInfo?.secretExpirationDate
                                    ? new Date(
                                          erpConnectionInfo?.secretExpirationDate
                                      )
                                    : null
                            }
                            className={cn(
                                "general-configurations__form-control",
                                !editableFields.expDate
                                    ? "general-configurations__disabled"
                                    : ""
                            )}
                            cleanable={false}
                            ranges={[]}
                            placement={"topStart"}
                            onChange={(value: any) => {
                                handleChange(
                                    formKey.secretExpirationDate,
                                    (value as Date).toDateString()
                                );
                            }}
                            disabledDate={(date: Date | undefined) =>
                                date ? isPreviousDate(date) : false
                            }
                        />
                    </div>
                </Form.Group>
            )}
        </>
    );
};

export default ErpConnectionInfo;
