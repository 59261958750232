import AppHeader from "./AppHeader";
import { connect } from "react-redux";
import { RootState } from "store/store";
import { setModalConfirmCallback } from "store/common/unsaved-data/unsaved-data-actions";
import { setRefreshActivated } from "store/common/page-refresh/page-refresh-actions";

const mapStateToProps = (state: RootState) => {
    return {
        userName: state.authenticationReducer.userName,
        modalTitle: state.modalWindowReducer.modalTitle,
        modalMessage: state.modalWindowReducer.modalMessage,
    };
};
const AppHeaderContainer = connect(mapStateToProps, {
    setModalConfirmCallback,
    setRefreshActivated,
})(AppHeader);

export default AppHeaderContainer;
