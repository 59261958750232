import { RootState } from "store/store";
import { connect } from "react-redux";
import ErrorSection from "./ErrorSection";

const mapStateToProps = (state: RootState) => {
    return {
        errorMessage:
            state.initializationReducer.errorResponseMessage ||
            state.initializationReducer.errorMessageInactiveEnvironment ||
            state.initializationReducer.errorMessageInactiveTenant ||
            state.initializationReducer.errorResponseMessageForModal ||
            state.initializationReducer.errorMessageEnrollStep ||
            state.initializationReducer.errorEnrollManualStepResponseMessage ||
            state.initializationReducer.errorTenantDeleteMessage,
    };
};

const ErrorSectionContainer = connect(mapStateToProps)(ErrorSection);

export default ErrorSectionContainer;
