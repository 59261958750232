import { RootState } from "store/store";
import { connect } from "react-redux";
import ErpPackages from "./ErpPackages";
import { setErrorResponseMessage } from "store/common/initialization/initialization-actions";
import withLoader from "hocs/withLoader/withLoader";
import { compose } from "redux";

const mapStateToProps = (state: RootState) => {
    return {
        errorMessage: state.initializationReducer.errorResponseMessage,
    };
};
const ErpPackagesContainer = compose(
    connect(mapStateToProps, {
        setErrorResponseMessage,
    }),
    withLoader
)(ErpPackages);

export default ErpPackagesContainer;
