import { RootState } from "store/store";
import { connect } from "react-redux";
import { compose } from "redux";
import DopeSubscriptions from "./Subscriptions";
import withLoader from "hocs/withLoader/withLoader";
import { getActiveEnvironmentMoniker } from "store/environments/environment-selectors";
import { setErrorResponseMessage } from "store/common/initialization/initialization-actions";
import { setIsUnsavedChangeAvailable } from "store/common/unsaved-data/unsaved-data-actions";

const mapStateToProps = (state: RootState) => {
    return {
        envMoniker: getActiveEnvironmentMoniker(state),
        tenantMoniker: state.tenantsReducer.activeTenant.moniker,
        errorMessage:
            state.initializationReducer.errorResponseMessage ||
            state.initializationReducer.errorMessageInactiveEnvironment ||
            state.initializationReducer.errorMessageInactiveTenant,
    };
};
const DopeSubscriptionsContainer = compose(
    connect(mapStateToProps, {
        setIsUnsavedChangeAvailable,
        setErrorResponseMessage,
    }),
    withLoader
)(DopeSubscriptions);

export default DopeSubscriptionsContainer;
