import { IDooapUser } from "store/environments/tenants/tenant-users/tenant-users-types";
import { defaultErrorResponse } from "../../defaults";
import { API } from "../../../utils/api-config";
import Axios, { CancelTokenSource } from "axios";

export const loadUsers = async (
    environmentMoniker: string,
    tenantMoniker: string
): Promise<[IDooapUser[] | null, number]> => {
    if (!environmentMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.get(
        `/environments/${environmentMoniker}/tenants/${tenantMoniker}/users`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const loadErpUsers = async (
    environmentMoniker: string,
    tenantMoniker: string
): Promise<[IDooapUser[] | null, number]> => {
    if (!environmentMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.get(
        `/environments/${environmentMoniker}/tenants/${tenantMoniker}/erp/users`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const checkIntegrationUserErpValidity = async (
    environmentMoniker: string,
    tenantMoniker: string
): Promise<[boolean | null, number]> => {
    if (!environmentMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.get(
        `/environments/${environmentMoniker}/tenants/${tenantMoniker}/erp/users/validate-domain`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const checkIntegrationUserValidity = async (
    environmentMoniker: string,
    tenantMoniker: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[boolean | null, number]> => {
    if (!environmentMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.get(
        `/environments/${environmentMoniker}/tenants/${tenantMoniker}/users/validate-domain`,
        { cancelToken: cancelTokenSource?.token }
    );

    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};

export const dismissDomainCheck = async (
    environmentMoniker: string,
    tenantMoniker: string
): Promise<[null, number]> => {
    if (!environmentMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.post(
        `/environments/${environmentMoniker}/tenants/${tenantMoniker}/users/dismiss-domain-check`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};
