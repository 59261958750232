import { RootState } from "store/store";
import FeatureConfigurations from "./FeatureConfigurations";
import { connect } from "react-redux";
import { setIsUnsavedChangeAvailable } from "store/common/unsaved-data/unsaved-data-actions";
import { getAppSettings } from "store/app-settings/app-settings-selectors";
import { getActiveEnvironmentMoniker } from "store/environments/environment-selectors";
import {
    getActiveTenantMoniker,
    getTenantCustomerType,
    getTenantDisplayName,
} from "store/environments/tenants/tenants-selectors";
import { getIsPowerBiWorkspaceDeploymentAllowed } from "store/app-global/app-global-selectors";
import withLoader from "hocs/withLoader/withLoader";
import { compose } from "redux";

const mapStateToProps = (state: RootState) => ({
    envMoniker: getActiveEnvironmentMoniker(state),
    tenantMoniker: getActiveTenantMoniker(state),
    tenantName: getTenantDisplayName(state),
    customerType: getTenantCustomerType(state),
    isPowerBiWorkspaceDeploymentAllowed:
        getIsPowerBiWorkspaceDeploymentAllowed(state),
    appSettings: getAppSettings(state),
});
const FeatureConfigurationsContainer = compose(
    connect(mapStateToProps, {
        setIsUnsavedChangeAvailable,
    }),
    withLoader
)(FeatureConfigurations);

export default FeatureConfigurationsContainer;
