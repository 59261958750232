import { connect } from "react-redux";
import { RootState } from "store/store";
import EmailsSending from "./EmailsSending";
import { setModalLoaderVisibility } from "store/common/modal-window/modal-window-actions";
import { setEmailsSendingOpened } from "store/deliverables/erp-packages/erp-packages-actions";
import { setErrorResponseMessageForModal } from "store/common/initialization/initialization-actions";

const mapStateToProps = (state: RootState) => {
    return {
        isOpened: state.packagesReducer.isEmailsSendingOpened,
        isModalBusy: state.modalWindowReducer.isModalLoaderVisible,
    };
};

const EmailsSendingContainer = connect(mapStateToProps, {
    setModalLoaderVisibility,
    setEmailsSendingOpened,
    setErrorResponseMessageForModal,
})(EmailsSending);

export default EmailsSendingContainer;
