import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Updater, useImmer } from "use-immer";
import { Input, Popover, Whisper } from "rsuite";
import { TableInstance } from "rsuite/esm/Table";

import constants from "utils/constants";

import {
    IMailboxDefault,
    ValidationErrors,
    mailboxFields,
} from "store/environments/tenants/tenant-capture-mailboxes/tenant-capture-mailboxes-types";

import PopoverCell from "components/Common/PopoverCell/PopoverCell";

type MailboxFieldType = keyof typeof mailboxFields;

interface EditableCellProps {
    mailboxes: IMailboxDefault[];
    dataKey: MailboxFieldType;
    data: IMailboxDefault;
    tableBodyRef: React.MutableRefObject<TableInstance<any, any> | null>;
    validationErrors: ValidationErrors;
    setMailboxes: Updater<IMailboxDefault[]>;
    setValidationErrors: Updater<ValidationErrors>;
}

const EditableCell: React.FC<EditableCellProps> = ({
    mailboxes,
    dataKey,
    data,
    validationErrors,
    setMailboxes,
    setValidationErrors,
}: EditableCellProps) => {
    const { t } = useTranslation();
    const [value, setValue] = useImmer<string>(data[dataKey]);

    const validateField = (value: string) => {
        if (dataKey === mailboxFields.name && !value.trim()) {
            return t("generalValidationMessages.isRequired");
        }
        if (dataKey === mailboxFields.address) {
            if (!value.trim()) {
                return t("generalValidationMessages.isRequired");
            } else if (!constants.emailRegex.test(value)) {
                return t("generalValidationMessages.isEmail");
            } else if (
                mailboxes.some(
                    (mailbox) =>
                        mailbox.address === value && mailbox.id !== mailbox.id
                )
            ) {
                return t("generalValidationMessages.isUnique");
            }
        }
        return "";
    };

    useEffect(() => {
        setValue(data[dataKey]);
    }, [data, dataKey]);

    const handleInputChange = (value: string) => {
        setValue(value);

        setMailboxes((draft) => {
            const mailbox = draft.find((mb) => mb.id == data.id);
            if (mailbox) {
                mailbox[dataKey] = value;
            }
        });

        const errorMessage = validateField(value);
        setValidationErrors((draft) => ({
            ...draft,
            [data.id]: {
                ...draft[data.id],
                [dataKey]: errorMessage,
            },
        }));
    };

    const isLastElement = mailboxes[mailboxes.length - 1] === data;

    return (
        <>
            {data.editing ? (
                <Whisper
                    trigger="none"
                    open={!!validationErrors[data.id]?.[dataKey]}
                    placement={isLastElement ? "topEnd" : "bottomStart"}
                    speaker={
                        <Popover className="mailboxes-creation__error-popover">
                            {validationErrors[data.id]?.[dataKey]}
                        </Popover>
                    }
                >
                    <Input
                        type="text"
                        value={value}
                        onChange={(value: string) => handleInputChange(value)}
                    />
                </Whisper>
            ) : (
                <PopoverCell dataKey={dataKey} rowData={data} />
            )}
        </>
    );
};

export default EditableCell;
