import { RootState } from "store/store";
import { connect } from "react-redux";
import SearchBar from "./SearchBar";
import { setPartnerFilterSearch } from "store/filters/filters-actions";
import { getPartnerFilterSearchText } from "store/filters/filters-selectors";

const mapStateToProps = (state: RootState) => {
    return {
        activeEnvironmentId: "",
        searchText: getPartnerFilterSearchText(state) || "",
    };
};
const SearchBarPartnersContainer = connect(mapStateToProps, {
    setSearchText: setPartnerFilterSearch,
})(SearchBar);

export default SearchBarPartnersContainer;
