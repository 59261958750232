import { AppThunk } from "store/store";
import {
    setErrorResponseMessage,
    setLoaderVisibility,
} from "../common/initialization/initialization-actions";
import { loadPartners } from "api/partners/partners-api";
import { setPartners } from "./partners-actions";
import constants from "utils/constants";
import { getErrorMessage } from "api/defaults";
import { IPartner } from "./partners-types";

export const fetchPartners =
    (): AppThunk<Promise<IPartner[] | null>> => async (dispatch) => {
        dispatch(setLoaderVisibility(true));
        dispatch(setErrorResponseMessage(""));

        const [payload, status] = await loadPartners();
        const isStatusOk = status === constants.statusCode.OK;
        if (payload && isStatusOk) {
            dispatch(setPartners(payload));
        } else {
            const errorMessage = getErrorMessage(String(payload), status);
            dispatch(setErrorResponseMessage(errorMessage));
        }

        dispatch(setLoaderVisibility(false));
        return isStatusOk ? payload : null;
    };
