import React, { useEffect } from "react";
import { ReactComponent as Refresh } from "../../../assets/images/refresh.svg";
import css from "../AppHeader.module.css";
import useUrlParams from "../../../hooks/useUrlParams";
import cn from "classnames";
interface ComponentProps {
    setActiveEnvironmentByName: (name: string) => void;
    setRefreshActivated: (isRefreshActivated: boolean) => void;
}
const RefreshButton: React.FC<ComponentProps> = ({
    setActiveEnvironmentByName,
    setRefreshActivated,
}: ComponentProps) => {
    const { environmentName } = useUrlParams();

    useEffect(() => {
        setActiveEnvironmentByName(decodeURIComponent(environmentName));
    }, [environmentName, setActiveEnvironmentByName]);

    return (
        <div
            className={cn(css.btn, css.btnRefresh)}
            onClick={() => setRefreshActivated(true)}
        >
            <Refresh />
        </div>
    );
};

export default RefreshButton;
