import { getErrorMessage } from "api/defaults";
import ModalContainer from "components/Common/Modal/ModalContainer";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    ButtonToolbar,
    DatePicker,
    Form,
    FormInstance,
    InputPicker,
    Message,
    Schema,
    SelectPicker,
    toaster,
} from "rsuite";
import constants from "utils/constants";
import {
    IErpPackage,
    IErpPackageDetails,
    IErpPackageTarget,
    IErpPackageType,
    IManageErpPackage,
    PackageType,
} from "store/deliverables/erp-packages/erp-packages-types";
import { compareDatesWithoutTime, isPreviousDate } from "utils/date-helper";
import { cloneDeep } from "lodash";
import { v4 as uuid } from "uuid";
import css from "./ManageErpPackage.module.css";
import "themes/components/custom-modal.less";

interface ComponentProps {
    isOpened: boolean;
    packageDetails: IErpPackageDetails;
    existingPackages: IErpPackage[];
    packageTypes: IErpPackageType[];
    packageTargets: IErpPackageTarget[];
    setManagePackageOpened: (isOpened: boolean) => void;
    refreshPackages: () => void;
    setModalLoaderVisibility: (visible: boolean) => void;
}

const ManageErpPackage: React.FC<ComponentProps> = ({
    isOpened,
    packageDetails,
    existingPackages,
    packageTypes,
    packageTargets,
    setManagePackageOpened,
    refreshPackages,
    setModalLoaderVisibility,
}: ComponentProps) => {
    const { t } = useTranslation();
    const formRef = useRef<FormInstance<Record<string, any>> | null>(null);
    const [erpPackage, setErpPackage] = useState<IManageErpPackage>(
        packageDetails.erpPackage
    );

    const defaultDateValidation = Schema.Types.DateType();

    const defaultUrlValidation = Schema.Types.StringType().pattern(
        constants.urlRegex,
        t("generalValidationMessages.isUrl")
    );
    const [dateValidation, setDateValidation] = useState(defaultDateValidation);
    const [urlValidation, setUrlValidation] = useState(defaultUrlValidation);
    const [targetsData, setTargetsData] = useState<
        { label: string; value: string }[]
    >([]);
    const isReleasePackage =
        erpPackage.packageTypeId ===
        packageTypes.find((p) => p.type === PackageType.Release)?.id;

    useEffect(() => {
        setErpPackage(packageDetails.erpPackage);
    }, [packageDetails.erpPackage]);

    useEffect(() => {
        if (packageTargets) {
            setTargetsData(
                packageTargets.map((value) => {
                    return {
                        label: value.target,
                        value: value.id,
                    };
                })
            );
        }
    }, [packageTargets]);

    useEffect(() => {
        setDateValidation(
            isReleasePackage
                ? requiredValidation(defaultDateValidation)
                : defaultDateValidation
        );
        setUrlValidation(
            isReleasePackage
                ? requiredValidation(defaultUrlValidation)
                : defaultUrlValidation
        );
    }, [erpPackage.packageTypeId]);

    const requiredValidation = (validation: any) => {
        const updatedValidation = cloneDeep(validation);
        return updatedValidation.isRequired(
            t("generalValidationMessages.isRequired")
        );
    };

    const goLiveDateValidation = (validation: any) => {
        const updatedValidation = cloneDeep(validation);
        return updatedValidation.addRule((value: string | Date, data: any) => {
            if (data.uatStartDate && value) {
                return (
                    compareDatesWithoutTime(
                        new Date(value),
                        data.uatStartDate
                    ) < 0
                );
            }
        }, t("deliverables.packages.validation.goLiveDate"));
    };

    const handleModalClose = () => {
        setErpPackage(packageDetails.erpPackage);
        setManagePackageOpened(false);
    };

    const handleFormChange = (formValue: IManageErpPackage) => {
        setErpPackage({
            ...formValue,
            statusPageLink: formValue.statusPageLink || null,
            releaseNotesLink: formValue.releaseNotesLink || null,
            packageTarget: {
                id: formValue.packageTargetId,
                target:
                    targetsData.find(
                        (t) => t.value === formValue.packageTargetId
                    )?.label || "",
            },
        });
    };

    const handleManagePackage = () => {
        if (!formRef.current?.check()) {
            return;
        }
        setModalLoaderVisibility(true);
        packageDetails.apiMethod(erpPackage).then(([result, status]) => {
            if (status === constants.statusCode.NoContent) {
                handleModalClose();
                refreshPackages();
            } else {
                const errorMessage = getErrorMessage(String(result), status);
                toaster.push(
                    <Message type="error" showIcon>
                        {errorMessage}
                    </Message>,
                    {
                        duration: constants.errorAlertDurationMsec,
                    }
                );
            }

            setModalLoaderVisibility(false);
        });
    };

    const handleTargetChange = (value: any) => {
        if (value && !targetsData.map((t) => t.value).includes(value)) {
            setTargetsData((targetsData) => [
                ...targetsData,
                {
                    label: value,
                    value: uuid(),
                },
            ]);
        }
    };

    const header = <h1>{packageDetails.title}</h1>;
    const footer = (
        <ButtonToolbar className="custom-modal__button-toolbar">
            <Button
                type="submit"
                appearance="primary"
                onClick={handleManagePackage}
            >
                {packageDetails.confirmButtonName}
            </Button>
            <Button appearance="ghost" onClick={handleModalClose}>
                {t("deliverables.packages.add.cancel")}
            </Button>
            <div className={css.anotation}>
                <sup className="Required__sup">*</sup>
                {t("addNewCustomer.requiredFields")}
            </div>
        </ButtonToolbar>
    );

    const erpPackageModel = Schema.Model({
        packageTypeId: Schema.Types.StringType().isRequired(
            t("generalValidationMessages.isRequired")
        ),
        packageTargetId: Schema.Types.StringType().isRequired(
            t("generalValidationMessages.isRequired")
        ),
        version: Schema.Types.StringType()
            .isRequired(t("generalValidationMessages.isRequired"))
            .pattern(
                constants.versionRegex,
                t("deliverables.packages.validation.versionFormat")
            )
            .addRule((value) => {
                return existingPackages
                    .filter(
                        (p) =>
                            p.id !== erpPackage.id &&
                            p.packageTargetId === erpPackage.packageTargetId
                    )
                    .every((p) => p.version !== value);
            }, t("generalValidationMessages.isUnique")),
        description: Schema.Types.StringType()
            .isRequired(t("generalValidationMessages.isRequired"))
            .minLength(
                constants.minNameLengthLimit,
                t("generalValidationMessages.minLength")
            )
            .maxLength(
                constants.maxLengthLimit,
                t("generalValidationMessages.maxLength5000")
            ),
        creationDate: requiredValidation(defaultDateValidation),
        blobLink: requiredValidation(defaultUrlValidation),
        expirationDate: requiredValidation(defaultDateValidation),
        // conditional validation (if package type is Release then all the fields are valid)
        uatStartDate: dateValidation,
        goLiveDate: goLiveDateValidation(dateValidation),
        statusPageLink: urlValidation,
        releaseNotesLink: urlValidation,
    });

    return (
        <ModalContainer
            className="custom-modal__container"
            backdrop="static"
            open={isOpened}
            onClose={handleModalClose}
            header={header}
            footer={footer}
        >
            <>
                <Form
                    className={css.form}
                    ref={formRef}
                    model={erpPackageModel}
                    formValue={erpPackage}
                    onChange={(data: any) => {
                        handleFormChange(data);
                    }}
                >
                    <Form.Group className={css.typeGroup}>
                        <Form.ControlLabel>
                            {t("deliverables.packages.add.typeTitle")}
                            <sup className="Required__sup">*</sup>
                            <Form.HelpText className={css.help} tooltip>
                                {t("deliverables.packages.tooltips.type")}
                            </Form.HelpText>
                        </Form.ControlLabel>
                        <Form.Control
                            accepter={SelectPicker}
                            placeholder={t(
                                "deliverables.packages.add.selectPlaceholder"
                            )}
                            block
                            searchable={false}
                            data={packageTypes.map((value) => {
                                return {
                                    label: value.type,
                                    value: value.id,
                                };
                            })}
                            name={"packageTypeId"}
                        />
                    </Form.Group>
                    <Form.Group className={css.targetGroup}>
                        <Form.ControlLabel>
                            {t("deliverables.packages.targetTitle")}
                            <sup className="Required__sup">*</sup>
                            <Form.HelpText className={css.help} tooltip>
                                {t("deliverables.packages.tooltips.target")}
                            </Form.HelpText>
                        </Form.ControlLabel>
                        <Form.Control
                            accepter={InputPicker}
                            placeholder={t(
                                "deliverables.packages.add.selectPlaceholder"
                            )}
                            creatable
                            block
                            data={targetsData}
                            name={"packageTargetId"}
                            onChange={handleTargetChange}
                        />
                    </Form.Group>
                    <Form.Group className={css.versionGroup}>
                        <Form.ControlLabel>
                            {t("deliverables.packages.versionTitle")}
                            <sup className="Required__sup">*</sup>
                            <Form.HelpText className={css.help} tooltip>
                                {t("deliverables.packages.tooltips.version")}
                            </Form.HelpText>
                        </Form.ControlLabel>
                        <Form.Control
                            placeholder={t(
                                "deliverables.packages.add.versionPlaceholder"
                            )}
                            name={"version"}
                        />
                    </Form.Group>
                    <Form.Group className={css.blobLinkGroup}>
                        <Form.ControlLabel>
                            {t("deliverables.packages.add.blobLinkTitle")}
                            <sup className="Required__sup">*</sup>
                            <Form.HelpText className={css.help} tooltip>
                                {t(
                                    "deliverables.packages.tooltips.packageLink"
                                )}
                            </Form.HelpText>
                        </Form.ControlLabel>
                        <Form.Control
                            placeholder={t(
                                "deliverables.packages.add.blobLinkPlaceholder"
                            )}
                            name={"blobLink"}
                        />
                    </Form.Group>
                    <Form.Group className={css.descriptionGroup}>
                        <Form.ControlLabel>
                            {t("deliverables.packages.add.descriptionTitle")}
                            <sup className="Required__sup">*</sup>
                            <Form.HelpText className={css.help} tooltip>
                                {t(
                                    "deliverables.packages.tooltips.description"
                                )}
                            </Form.HelpText>
                        </Form.ControlLabel>
                        <Form.Control
                            placeholder={t(
                                "deliverables.packages.add.descriptionPlaceholder"
                            )}
                            name={"description"}
                        />
                    </Form.Group>
                    <Form.Group className={css.statusLinkGroup}>
                        <Form.ControlLabel>
                            {t("deliverables.packages.statusPageTitle")}
                            {isReleasePackage && (
                                <sup className="Required__sup">*</sup>
                            )}
                            <Form.HelpText className={css.help} tooltip>
                                {t("deliverables.packages.tooltips.statusPage")}
                            </Form.HelpText>
                        </Form.ControlLabel>
                        <Form.Control
                            placeholder={t(
                                "deliverables.packages.add.statusPagePlaceholder"
                            )}
                            name={"statusPageLink"}
                        />
                    </Form.Group>
                    <Form.Group className={css.releaseLinkGroup}>
                        <Form.ControlLabel>
                            {t("deliverables.packages.releaseNotesTitle")}
                            {isReleasePackage && (
                                <sup className="Required__sup">*</sup>
                            )}
                            <Form.HelpText className={css.help} tooltip>
                                {t(
                                    "deliverables.packages.tooltips.releaseNotes"
                                )}
                            </Form.HelpText>
                        </Form.ControlLabel>
                        <Form.Control
                            placeholder={t(
                                "deliverables.packages.add.releaseNotesPlaceholder"
                            )}
                            name={"releaseNotesLink"}
                        />
                    </Form.Group>
                    <Form.Group className={css.uatDateGroup}>
                        <Form.ControlLabel>
                            {t("deliverables.packages.uatStartTitle")}
                            {isReleasePackage && (
                                <sup className="Required__sup">*</sup>
                            )}
                            <Form.HelpText className={css.help} tooltip>
                                {t(
                                    "deliverables.packages.tooltips.uatStartDate"
                                )}
                            </Form.HelpText>
                        </Form.ControlLabel>
                        <Form.Control
                            accepter={DatePicker}
                            ranges={[]}
                            placement={"topStart"}
                            editable={false}
                            disabledDate={(date: Date | undefined) =>
                                date ? isPreviousDate(date) : false
                            }
                            name={"uatStartDate"}
                        />
                    </Form.Group>
                    <Form.Group className={css.prodDateGroup}>
                        <Form.ControlLabel>
                            {t("deliverables.packages.goLiveTitle")}
                            {isReleasePackage && (
                                <sup className="Required__sup">*</sup>
                            )}
                            <Form.HelpText className={css.help} tooltip>
                                {t("deliverables.packages.tooltips.goLiveDate")}
                            </Form.HelpText>
                        </Form.ControlLabel>
                        <Form.Control
                            editable={false}
                            accepter={DatePicker}
                            ranges={[]}
                            placement={"topStart"}
                            disabledDate={(date: Date | undefined) =>
                                date ? isPreviousDate(date) : false
                            }
                            name={"goLiveDate"}
                        />
                    </Form.Group>
                    <Form.Group className={css.creationDateGroup}>
                        <Form.ControlLabel>
                            {t("deliverables.packages.createdTitle")}
                            <Form.HelpText className={css.help} tooltip>
                                {t(
                                    "deliverables.packages.tooltips.creationDate"
                                )}
                            </Form.HelpText>
                        </Form.ControlLabel>
                        <Form.Control
                            disabled
                            accepter={DatePicker}
                            value={
                                packageDetails.erpPackage.creationDate ??
                                new Date()
                            }
                            name={"creationDate"}
                        />
                    </Form.Group>
                    <Form.Group className={css.expirationDateGroup}>
                        <Form.ControlLabel>
                            {t("deliverables.packages.expirationTitle")}
                            <sup className="Required__sup">*</sup>
                            <Form.HelpText className={css.help} tooltip>
                                {t(
                                    "deliverables.packages.tooltips.expirationDate"
                                )}
                            </Form.HelpText>
                        </Form.ControlLabel>
                        <Form.Control
                            accepter={DatePicker}
                            cleanable={false}
                            editable={false}
                            ranges={[]}
                            placement={"topStart"}
                            disabledDate={(date: Date | undefined) =>
                                date ? isPreviousDate(date) : false
                            }
                            name={"expirationDate"}
                        />
                    </Form.Group>
                </Form>
            </>
        </ModalContainer>
    );
};

export default ManageErpPackage;
