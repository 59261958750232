import { RootState } from "store/store";
import { connect } from "react-redux";
import ErpConfigurations from "./ErpConfigurations";
import { setIsTenantFetched } from "store/environments/tenants/tenants-actions";
import { setIsUnsavedChangeAvailable } from "store/common/unsaved-data/unsaved-data-actions";
import { getActiveEnvironmentMoniker } from "store/environments/environment-selectors";
import {
    getActiveTenantMoniker,
    getAuthenticationMethod,
    getIsTenantFetched,
} from "store/environments/tenants/tenants-selectors";
import withLoader from "hocs/withLoader/withLoader";
import { getTenantCustomerType } from "store/environments/tenants/tenants-selectors";
import { flowRight } from "lodash";
import withError from "hocs/withError/withError";

const mapStateToProps = (state: RootState) => {
    return {
        envMoniker: getActiveEnvironmentMoniker(state),
        tenantMoniker: getActiveTenantMoniker(state),
        isFetched: getIsTenantFetched(state),
        authenticationMethodDescription: getAuthenticationMethod(state),
        customerType: getTenantCustomerType(state),
    };
};
const Container = connect(mapStateToProps, {
    setIsUnsavedChangeAvailable,
    setIsTenantFetched,
})(ErpConfigurations);

const ErpConfigurationsContainer = flowRight(withLoader, withError)(Container);

export default ErpConfigurationsContainer;
