import { connect } from "react-redux";

import { RootState } from "store/store";
import { getActiveEnvironmentMoniker } from "store/environments/environment-selectors";
import { setModalLoaderVisibility } from "store/common/modal-window/modal-window-actions";
import { setCreateMailboxesOpened } from "store/environments/tenants/tenant-capture-mailboxes/tenant-capture-mailboxes-actions";

import MailboxesCreation from "./MailboxesCreation";

const mapStateToProps = (state: RootState) => {
    return {
        envMoniker: getActiveEnvironmentMoniker(state),
        tenantMoniker: state.tenantsReducer.activeTenant.moniker,
        isOpened: state.captureMailboxesReducer.isMailboxesCreationOpened,
    };
};

const MailboxesCreationContainer = connect(mapStateToProps, {
    setModalLoaderVisibility,
    setIsOpened: setCreateMailboxesOpened,
})(MailboxesCreation);

export default MailboxesCreationContainer;
