import { RootState } from "store/store";
import { connect } from "react-redux";
import DeletedTenantMessage from "./DeletedTenantMessage";
import { getActiveTenantDeleteWorkflow } from "store/environments/tenants/tenant-delete/tenant-delete-selectors";
import { setTenantDeleteWorkflow } from "store/environments/tenants/tenant-delete/tenant-delete-actions";

const mapStateToProps = (state: RootState) => ({
    activeTenantDeleteWorkflow: getActiveTenantDeleteWorkflow(state),
});

const DeletedTenantMessageContainer = connect(mapStateToProps, {
    setTenantDeleteWorkflow,
})(DeletedTenantMessage);

export default DeletedTenantMessageContainer;
