export const SET_SUPPORT_CONTACT = "contacts/SET_SUPPORT_CONTACT";

export interface IContact {
    email: string;
}

export interface State {
    supportContact: IContact;
}

export interface SetSupportContact {
    type: typeof SET_SUPPORT_CONTACT;
    contact: IContact;
}

export type Actions = SetSupportContact;
