import {
    HubConnection,
    HubConnectionBuilder,
    HubConnectionState,
} from "@microsoft/signalr";
import React, { useEffect, useState } from "react";
import { IEnrollStep } from "store/environments/tenants/enroll-tenant/enroll-tenant-types";
import EnrollTenantContainer from "../../EnrollTenant/EnrollTenantContainer";
import constants from "utils/constants";
import { IHubConnection } from "utils/types";
import { getEnrollHubConnection } from "api/environments/tenants/tenant-enrollments-api";

interface IComponentProps {
    orchestratorInstanceId: string | null;
    updateStep: (stepStatus: IEnrollStep) => void;
}

const SignalREnrollTenant: React.FC<IComponentProps> = ({
    orchestratorInstanceId,
    updateStep,
}: IComponentProps) => {
    const [hubConnection, setHubConnection] = useState<HubConnection | null>(
        null
    );
    const [hubInfo, setHubInfo] = useState<IHubConnection | null>(null);
    const [isConnectionInProgress, setIsConnectionInProgress] =
        useState<boolean>(false);
    useEffect(() => {
        if (
            orchestratorInstanceId !== null &&
            hubInfo === null &&
            !isConnectionInProgress
        ) {
            setIsConnectionInProgress(true);
            getEnrollHubConnection(orchestratorInstanceId).then(([payload]) => {
                setHubInfo(payload);
                setIsConnectionInProgress(false);
            });
        }
    }, [orchestratorInstanceId, hubInfo, getEnrollHubConnection]);
    useEffect(() => {
        if (hubInfo !== null) {
            if (hubConnection === null) {
                setHubConnection(
                    new HubConnectionBuilder()
                        .withAutomaticReconnect()
                        .withUrl(hubInfo.url, {
                            accessTokenFactory: () => hubInfo.accessToken,
                        })
                        .build()
                );
            } else {
                hubConnection.on(
                    constants.enrollNewSignalRMessageMethodName,
                    (step: string) => {
                        updateStep(JSON.parse(step) as IEnrollStep);
                    }
                );
                if (hubConnection.state === HubConnectionState.Disconnected) {
                    hubConnection.start();
                }
            }
        }
        return () => {
            if (
                hubConnection !== null &&
                hubConnection.state === HubConnectionState.Connected
            ) {
                hubConnection.stop();
            }
        };
    }, [hubInfo, hubConnection, updateStep, setHubConnection]);

    return <EnrollTenantContainer />;
};

export default SignalREnrollTenant;
