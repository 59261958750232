import { defaultErrorResponse } from "api/defaults";
import Axios, { CancelTokenSource } from "axios";
import { ITenantMaintenanceMode } from "store/environments/tenants/tenant-configurations/tenant-configurations-types";
import { API } from "utils/api-config";

export const loadTenantMaintenanceModeConfigurations = async (
    envMoniker: string,
    tenantMoniker: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[ITenantMaintenanceMode | null, number]> => {
    if (!envMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }
    const response = await API.get(
        `environments/${envMoniker}/tenants/${tenantMoniker}/maintenance-mode-configs`,
        { cancelToken: cancelTokenSource?.token }
    );

    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};

export const updateTenantMaintenanceModeConfigurations = async (
    envMoniker: string,
    tenantMoniker: string,
    featureConfigurations: ITenantMaintenanceMode
): Promise<[string | null, number]> => {
    if (!envMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }
    const response = await API.put(
        `environments/${envMoniker}/tenants/${tenantMoniker}/maintenance-mode-configs`,
        featureConfigurations
    );

    return [null, response.status];
};
