import { RootState } from "store/store";
import { connect } from "react-redux";
import { flowRight } from "lodash";
import { getActiveEnvironmentMoniker } from "store/environments/environment-selectors";
import { setIsUnsavedChangeAvailable } from "store/common/unsaved-data/unsaved-data-actions";
import withLoader from "hocs/withLoader/withLoader";
import withError from "hocs/withError/withError";
import DataRefreshHistory from "./DataRefreshHistory";

const mapStateToProps = (state: RootState) => {
    return {
        envMoniker: getActiveEnvironmentMoniker(state),
        tenantId: state.tenantsReducer.activeTenant.versionInfo.id,
    };
};

const Container = connect(mapStateToProps, {
    setIsUnsavedChangeAvailable,
})(DataRefreshHistory);

const DataRefreshHistoryContainer = flowRight(withLoader, withError)(Container);

export default DataRefreshHistoryContainer;
