import {
    IAxImport,
    IAxImportEntity,
} from "store/environments/tenants/data-import/data-import-types";
import { defaultErrorResponse } from "../../defaults";
import { API } from "../../../utils/api-config";
import Axios, { CancelTokenSource } from "axios";

export const loadAxImportEntities = async (
    environmentMoniker: string,
    tenantMoniker: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[IAxImport | null, number]> => {
    if (!environmentMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.get(
        `/environments/${environmentMoniker}/tenants/${tenantMoniker}/ax-import`,
        { cancelToken: cancelTokenSource?.token }
    );

    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};

export const updateAxImportEntities = async (
    environmentMoniker: string,
    tenantMoniker: string,
    axImportEntities: IAxImportEntity[]
): Promise<[string | null, number]> => {
    if (!environmentMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.put(
        `/environments/${environmentMoniker}/tenants/${tenantMoniker}/ax-import`,
        axImportEntities
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const transferAllCefData = async (
    environmentMoniker: string,
    tenantMoniker: string,
    entityName: string
): Promise<[string | null, number]> => {
    if (!environmentMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.post(
        `/environments/${environmentMoniker}/tenants/${tenantMoniker}/ax-import/${entityName}/transfer-cef-data`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const runAllAxImports = async (
    environmentMoniker: string,
    tenantMoniker: string,
    axImportEntities: IAxImportEntity[]
): Promise<[string | null, number]> => {
    if (!environmentMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }
    const response = await API.post(
        `/environments/${environmentMoniker}/tenants/${tenantMoniker}/ax-import/run-all/ax`,
        axImportEntities
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const runAllCefEntities = async (
    environmentMoniker: string,
    tenantMoniker: string,
    axImportEntities: IAxImportEntity[]
): Promise<[string | null, number]> => {
    if (!environmentMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }
    const response = await API.post(
        `/environments/${environmentMoniker}/tenants/${tenantMoniker}/ax-import/run-all/cef`,
        axImportEntities
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};
