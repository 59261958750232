import { RootState } from "store/store";
import { connect } from "react-redux";
import SearchBar from "./SearchBar";
import { setCustomerFilterSearch } from "store/filters/filters-actions";
import { getCustomerFilterSearchText } from "store/filters/filters-selectors";

const mapStateToProps = (state: RootState) => {
    return {
        activeEnvironmentId: "",
        searchText: getCustomerFilterSearchText(state) || "",
    };
};
const SearchBarCustomersContainer = connect(mapStateToProps, {
    setSearchText: setCustomerFilterSearch,
})(SearchBar);

export default SearchBarCustomersContainer;
