import {
    IAxIntegrationApproveData,
    IntegrationFormType,
} from "store/environments/tenants/enroll-tenant/enroll-tenant-types";

const getAxAuthorityFromUsername = (formValue: IAxIntegrationApproveData) => {
    return formValue.username.includes("@")
        ? (formValue.username.split("@")[1] as string)
        : "";
};

export const setAutofillFormValues = (
    integrationFormType: IntegrationFormType,
    d365IntegrationInfo: IAxIntegrationApproveData,
    data: any,
    onFormChange: (data: Record<any, any>) => void
) => {
    const newFormValues = data as IAxIntegrationApproveData;
    if (newFormValues) {
        let axAuthorityResult = d365IntegrationInfo.axAuthority;
        if (d365IntegrationInfo.username !== newFormValues.username) {
            axAuthorityResult = getAxAuthorityFromUsername(newFormValues);
        } else if (
            d365IntegrationInfo.axAuthority !== newFormValues.axAuthority
        ) {
            axAuthorityResult = newFormValues.axAuthority;
        }

        let azureAdResource = d365IntegrationInfo.azureAdResource;
        if (integrationFormType === IntegrationFormType.Secret) {
            if (d365IntegrationInfo.axUrl !== newFormValues.axUrl) {
                azureAdResource = newFormValues.axUrl;
            } else if (
                d365IntegrationInfo.azureAdResource !==
                newFormValues.azureAdResource
            ) {
                azureAdResource = newFormValues.azureAdResource;
            }
        }

        onFormChange({
            ...newFormValues,
            azureAdResource: azureAdResource,
            axAuthority: axAuthorityResult,
        });
    }
};
