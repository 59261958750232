import Axios, { CancelTokenSource } from "axios";
import { API } from "utils/api-config";
import { defaultErrorResponse } from "api/defaults";
import { IVersionInfo } from "store/environments/tenants/tenants-types";

export const pingTenant = async (
    envMoniker: string,
    tenantId: string,
    cancelTokenSource: CancelTokenSource
): Promise<[boolean | string | null, number]> => {
    if (!envMoniker || !envMoniker.length || !tenantId || !tenantId.length) {
        return defaultErrorResponse;
    }

    const response = await API.get(
        `/environments/${envMoniker}/tenants/${tenantId}/erp/ping`,
        { cancelToken: cancelTokenSource.token }
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const fetchTenantInfo = async (
    envMoniker: string,
    tenantId: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[IVersionInfo | null, number]> => {
    if (!envMoniker || !tenantId) {
        return defaultErrorResponse;
    }

    const response = await API.get(
        `/environments/${envMoniker}/tenants/${tenantId}/erp/info`,
        { cancelToken: cancelTokenSource?.token }
    );

    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};
