import React, {
    ReactNode,
    RefObject,
    useEffect,
    useRef,
    useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Checkbox,
    CheckboxGroup,
    CheckPicker,
    Form,
    FormInstance,
    InputPicker,
    Schema,
    Table,
} from "rsuite";
import {
    ConsentStatusType,
    IConsent,
    ICustomerDetails,
    ICustomerPartner,
    IRegionConsent,
} from "store/customers/customer-details/customer-details-types";
import RefreshButton from "components/Common/RefreshButton/RefreshButton";
import { regionNames } from "store/customers/add-new-customer/add-new-customer-types";
import { ICustomerNameInfo } from "store/customers/customers-types";
import { loadPartners } from "api/partners/partners-api";
import constants from "utils/constants";
import cn from "classnames";
import { template } from "lodash";
import useRefreshPage from "hooks/useRefreshPage";
import css from "../CustomerDetails.module.css";
import SettingsFooterContainer from "components/Common/SettingsFooter/SettingsFooterContainer";

interface ComponentProps {
    containerRef: RefObject<HTMLDivElement>;
    customerNames: ICustomerNameInfo[];
    customerDetails: ICustomerDetails;
    allConsentsPending: boolean;
    handleFormSubmit: (isValid: boolean) => void;
    setCustomerName: (customerName: string) => void;
    setIsSendByEmailClicked: (isSendByEmailClicked: boolean) => void;
    setCustomerPartners: (partners: ICustomerPartner[]) => void;
    setIsDataLoading: (isDataLoading: boolean) => void;
    setConsentStatus: (
        instanceId: string,
        consentId: string,
        consentStatus: ConsentStatusType
    ) => void;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
    showLoader: (isVisible: boolean) => void;
}

const GeneralDetails: React.FC<ComponentProps> = ({
    containerRef,
    customerNames,
    customerDetails,
    allConsentsPending,
    handleFormSubmit,
    setCustomerName,
    setIsSendByEmailClicked,
    setCustomerPartners,
    setIsDataLoading,
    setConsentStatus,
    setIsUnsavedChangeAvailable,
    showLoader,
}: ComponentProps) => {
    const { t } = useTranslation();
    const formRef = useRef<FormInstance<Record<string, any>> | null>(null);
    const [partners, setPartners] = useState<Record<string, any>[]>([]);
    const [isPartnersLoaded, setIsPartnersLoaded] = useState<boolean>(false);
    const [isPartnersDropdownDisabled, setPartnersDropdownDisabled] =
        useState<boolean>(false);

    const regions =
        customerDetails?.regionConsents.map(
            (regionConsent) => regionConsent.region
        ) || [];

    const getConsentStatusText = (status: ConsentStatusType) => {
        switch (status) {
            case ConsentStatusType.Pending:
                return t("customerDetails.consentStatus.pendingText");
            case ConsentStatusType.Success:
                return t("customerDetails.consentStatus.successText");
            case ConsentStatusType.Failed:
                return t("customerDetails.consentStatus.failedText");
            default:
                return "";
        }
    };

    const renderPartnerMenuItem = (label: ReactNode) => {
        return <div className={css.partnersSelector}>{label}</div>;
    };

    const isRegionConsentLastInList = (
        regionConsent: IRegionConsent,
        regionConsents: IRegionConsent[]
    ) => {
        return regionConsent === regionConsents[regionConsents.length - 1];
    };

    const fetchPartners = () => {
        showLoader(true);
        setIsDataLoading(true);
        loadPartners().then(([payload, status]) => {
            showLoader(false);
            setIsDataLoading(false);
            if (payload?.length && status === constants.statusCode.OK) {
                setPartners(
                    payload.map((partner) => ({
                        value: {
                            id: partner.id,
                            name: partner.name,
                        },
                        label: partner.name,
                    }))
                );
            }
            setIsPartnersLoaded(true);
        });
    };

    useEffect(() => {
        if (!isPartnersLoaded) {
            fetchPartners();
        }
    }, [isPartnersLoaded]);

    useEffect(() => {
        if (
            isPartnersLoaded &&
            !partners.length &&
            customerDetails?.partners.length
        ) {
            setPartnersDropdownDisabled(true);
            setPartners(
                customerDetails?.partners.map((partner) => ({
                    value: {
                        id: partner.id,
                        name: partner.name,
                    },
                    label: partner.name,
                }))
            );
        }
    }, [partners, customerDetails, isPartnersLoaded]);

    useRefreshPage(() => {
        fetchPartners();
        if (formRef && formRef.current) {
            containerRef.current?.scrollTo(0, 0);
            formRef.current.cleanErrors();
        }
    });

    const formValue = {
        name: customerDetails?.name,
        id: customerDetails?.id,
        partners: customerDetails?.partners,
        regions: regions,
        type: customerDetails?.type ?? "",
        consentWizardAppLink: customerDetails?.consentWizardAppLink ?? "",
        adminUserName: customerDetails?.adminUserName
            ? customerDetails?.adminUserName
            : allConsentsPending &&
                !customerDetails?.adminUserName &&
                !customerDetails?.adminUserEmail
              ? t("consentWizardDetails.waitingForConsents")
              : t("consentWizardDetails.noAdminDataAvailable"),
        adminUserEmail: customerDetails?.adminUserEmail
            ? customerDetails?.adminUserEmail
            : allConsentsPending &&
                !customerDetails?.adminUserName &&
                !customerDetails?.adminUserEmail
              ? t("consentWizardDetails.waitingForConsents")
              : t("consentWizardDetails.noAdminDataAvailable"),
    };

    const validationModel = Schema.Model({
        name: Schema.Types.StringType()
            .isRequired(t("generalValidationMessages.isRequired"))
            .addRule((value) => {
                return customerNames?.every(
                    (customer) => customer.name !== value
                );
            }, t("generalValidationMessages.isUnique"))
            .maxLength(
                constants.maxLengthLimit,
                t("generalValidationMessages.maxLength5000")
            )
            .minLength(
                constants.minNameLengthLimit,
                t("generalValidationMessages.minLength")
            ),
    });

    const handleChange = (value: ICustomerPartner[]) => {
        setCustomerPartners(value);
        setIsUnsavedChangeAvailable(true);
    };

    const handleSubmit = () => {
        if (formRef.current) {
            handleFormSubmit(formRef.current?.check());
        }
    };

    return (
        <Form
            ref={formRef}
            className={css.formContainer}
            model={validationModel}
            formValue={formValue}
            onSubmit={() => {
                handleSubmit();
            }}
        >
            <Form.Group>
                <Form.ControlLabel className={css.label}>
                    {t("customerDetails.customerNameInputLabel")}
                    <sup className="Required__sup">*</sup>
                </Form.ControlLabel>
                <Form.Control
                    name={"name"}
                    onChange={(value: string) => {
                        setCustomerName(value);
                        setIsUnsavedChangeAvailable(true);
                    }}
                    className={cn(css.formControl, css.input, css.lg)}
                />
            </Form.Group>
            <Form.Group>
                <Form.ControlLabel className={css.label}>
                    {t("customerDetails.customerIdInputLabel")}
                </Form.ControlLabel>
                <Form.Control
                    name={"id"}
                    className={cn(css.formControl, css.input, css.lg)}
                    disabled
                />
            </Form.Group>
            <div className={css.formRow}>
                <Form.Group>
                    <Form.ControlLabel className={css.label}>
                        {t("customerDetails.consentLinkInputLabel")}
                    </Form.ControlLabel>
                    <Form.Control
                        name={"consentWizardAppLink"}
                        className={cn(css.formControl, css.input, css.lg)}
                        disabled
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Control
                        name={t("customerDetails.copyLinkButtonText")}
                        accepter={Button}
                        appearance="primary"
                        onClick={() => {
                            navigator.clipboard.writeText(
                                formValue.consentWizardAppLink
                            );
                        }}
                        className={cn(css.formControl, css.sm, css.button)}
                    >
                        {t("customerDetails.copyLinkButtonText")}
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Control
                        name={t("customerDetails.sendEmailButtonText")}
                        accepter={Button}
                        appearance="primary"
                        onClick={() => {
                            setIsSendByEmailClicked(true);
                        }}
                        className={cn(css.formControl, css.sm, css.button)}
                    >
                        {t("customerDetails.sendEmailButtonText")}
                    </Form.Control>
                </Form.Group>
            </div>
            <div className={css.formRow}>
                <Form.Group>
                    <Form.ControlLabel className={css.label}>
                        {t("customerDetails.partnersLabel")}
                    </Form.ControlLabel>
                    <Form.Control
                        accepter={CheckPicker}
                        placeholder={t("customerDetails.partnersPlaceholder")}
                        name={"partners"}
                        cleanable={false}
                        disabled={isPartnersDropdownDisabled}
                        data={partners}
                        renderMenuItem={renderPartnerMenuItem}
                        onChange={(value: any) =>
                            handleChange(value as ICustomerPartner[])
                        }
                        className={cn(css.formControl, css.lg, css.tagPicker)}
                    />
                </Form.Group>
            </div>
            <div className={css.formRow}>
                <Form.Group>
                    <Form.ControlLabel className={css.label}>
                        {t("customerDetails.regionInputLabel")}
                    </Form.ControlLabel>
                    <Form.Control
                        name={t("customerDetails.regionInputLabel")}
                        accepter={CheckboxGroup}
                        inline
                        value={regions}
                    >
                        {regions.map((region, index) => {
                            return (
                                <Checkbox
                                    key={String(index)}
                                    value={region}
                                    defaultChecked
                                    disabled
                                    className={css.checkbox}
                                >
                                    {regionNames[region] ?? region}
                                </Checkbox>
                            );
                        })}
                    </Form.Control>
                </Form.Group>
            </div>
            <div className={css.formRow}>
                <Form.Group>
                    <Form.ControlLabel className={css.label}>
                        {t("customerDetails.customerTypeInputLabel")}
                    </Form.ControlLabel>
                    <Form.Control
                        accepter={InputPicker}
                        data={[
                            {
                                value: formValue.type,
                                label: formValue.type,
                            },
                        ]}
                        name={"type"}
                        className={cn(
                            css.formControl,
                            css.lg,
                            css.customerTypeInput
                        )}
                        disabled
                    />
                </Form.Group>
            </div>
            <div className={css.formRow}>
                <Form.Group>
                    <Form.ControlLabel className={css.label}>
                        {t("consentWizardDetails.globalAdminNameInputLabel")}
                    </Form.ControlLabel>
                    <Form.Control
                        name={"adminUserName"}
                        className={cn(css.formControl, css.input, css.lg)}
                        disabled
                    />
                </Form.Group>
            </div>
            <div className={css.formRow}>
                <Form.Group>
                    <Form.ControlLabel className={css.label}>
                        {t("consentWizardDetails.globalAdminEmailInputLabel")}
                    </Form.ControlLabel>
                    <Form.Control
                        name={"adminUserEmail"}
                        className={cn(css.formControl, css.input, css.lg)}
                        disabled
                    />
                </Form.Group>
            </div>
            {customerDetails.regionConsents.map((regionConsent, index) => {
                return (
                    <div key={String(index)}>
                        <div className={css.consentsDescriptionContainer}>
                            {template(
                                t(
                                    "customerDetails.consentsTable.consentsDescriptionTemplate"
                                )
                            )({
                                region:
                                    regionNames[regionConsent.region] ??
                                    regionConsent.region,
                            })}
                        </div>
                        <Table
                            width={860}
                            data={regionConsent.consents}
                            className={cn(css.consentsTableContainer, {
                                [css.consentsTableSpace]:
                                    !isRegionConsentLastInList(
                                        regionConsent,
                                        customerDetails.regionConsents
                                    ),
                            })}
                            autoHeight
                        >
                            <Table.Column align="left" width={435}>
                                <Table.HeaderCell>
                                    {t(
                                        "customerDetails.consentsTable.consentNameColumnName"
                                    )}
                                </Table.HeaderCell>
                                <Table.Cell>
                                    {(consent: IConsent) => {
                                        return `${consent.name} ${consent.environmentName}`;
                                    }}
                                </Table.Cell>
                            </Table.Column>
                            <Table.Column align="left" width={195}>
                                <Table.HeaderCell>
                                    {t(
                                        "customerDetails.consentsTable.consentStatusColumnName"
                                    )}
                                </Table.HeaderCell>
                                <Table.Cell dataKey="type">
                                    {(rowData: IConsent) => {
                                        return getConsentStatusText(
                                            rowData.status
                                        );
                                    }}
                                </Table.Cell>
                            </Table.Column>
                            <Table.Column align="left" width={290}>
                                <Table.HeaderCell>
                                    {t(
                                        "customerDetails.consentsTable.actionColumnName"
                                    )}
                                </Table.HeaderCell>
                                <Table.Cell>
                                    {(consent: IConsent) => {
                                        const isStatusPending =
                                            consent.status ===
                                            ConsentStatusType.Pending;
                                        return (
                                            <RefreshButton
                                                onClick={() => {
                                                    setConsentStatus(
                                                        regionConsent.id,
                                                        consent.id,
                                                        ConsentStatusType.Pending
                                                    );
                                                    setIsUnsavedChangeAvailable(
                                                        true
                                                    );
                                                }}
                                                disabled={isStatusPending}
                                                tooltip={t(
                                                    "customerDetails.consentsTable.renewConsentButtonTooltipText"
                                                )}
                                            />
                                        );
                                    }}
                                </Table.Cell>
                            </Table.Column>
                        </Table>
                    </div>
                );
            })}
            <SettingsFooterContainer
                className={cn(css.footerSaveCustomer)}
                isFullWidth={true}
                saveButtonProps={{ type: "submit" }}
            >
                <div>
                    <sup className="Required__sup">*</sup>
                    {t("addNewCustomer.requiredFields")}
                </div>
            </SettingsFooterContainer>
        </Form>
    );
};

export default GeneralDetails;
