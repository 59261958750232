import { RootState } from "store/store";
import { connect } from "react-redux";
import Scheduler from "./Scheduler";
import {
    getScheduler,
    pushScheduler,
} from "store/environments/tenants/tenant-scheduler/tenant-scheduler-thunks";
import {
    setIsUnsavedChangeAvailable,
    setModalConfirmCallback,
} from "store/common/unsaved-data/unsaved-data-actions";
import { getActiveEnvironmentMoniker } from "store/environments/environment-selectors";
import { setRefreshActivated } from "store/common/page-refresh/page-refresh-actions";

const mapStateToProps = (state: RootState) => {
    return {
        envMoniker: getActiveEnvironmentMoniker(state),
        tenantMoniker: state.tenantsReducer.activeTenant.moniker,
        errorMessage:
            state.initializationReducer.errorResponseMessage ||
            state.initializationReducer.errorMessageInactiveEnvironment ||
            state.initializationReducer.errorMessageInactiveTenant,
        isUnsavedChangeAvailable:
            state.unsavedDataReducer.isUnsavedChangeAvailable,
    };
};
const SchedulerContainer = connect(mapStateToProps, {
    getScheduler,
    pushScheduler,
    setIsUnsavedChangeAvailable,
    setModalConfirmCallback,
    setRefreshActivated,
})(Scheduler);

export default SchedulerContainer;
