import React, { useEffect, useState } from "react";
import parse from "html-react-parser";

import {
    IEnrollStep,
    IEnrollStepData,
    StepStatus,
} from "store/environments/tenants/enroll-tenant/enroll-tenant-types";
import css from "./AutomatedStep.module.css";
import cssError from "./AutomatedStepErrorSection/AutomatedStepErrorSection.module.css";
import cn from "classnames";

interface ComponentProps {
    activeStep: IEnrollStep | null;
    stepList: IEnrollStep[] | null;
    setActiveStepData: (data: string) => void;
}

const AutomatedStep: React.FC<ComponentProps> = ({
    activeStep,
    stepList,
    setActiveStepData,
}: ComponentProps) => {
    const [messageList, setMessageList] = useState<IEnrollStepData[]>([]);

    const getMessages = (activeStep: IEnrollStep): [] => {
        try {
            return JSON.parse(activeStep.data || "") || [];
        } catch {
            return [];
        }
    };

    const getMessageElement = (
        activeStep: IEnrollStep | null,
        message: IEnrollStepData,
        messageList: IEnrollStepData[]
    ) => {
        const msgIndex = messageList.findIndex((m) => m === message);
        const currentMessage = message.actionMessage
            ? parse(message.actionMessage)
            : parse(message.actionDescription);
        const isActionRunning =
            message.actionMessage !== null &&
            (message === messageList[messageList.length - 1] ||
                messageList[msgIndex + 1].actionMessage === null);
        if (
            (activeStep?.status === StepStatus.Failed ||
                activeStep?.status === StepStatus.Skipped) &&
            isActionRunning
        ) {
            return (
                <div className={cn(css.failedElement)}>
                    {currentMessage}
                    {activeStep?.logs && (
                        <p className={cssError.errorSection}>
                            {activeStep.logs}
                        </p>
                    )}
                </div>
            );
        } else if (
            activeStep?.status === StepStatus.Running &&
            isActionRunning
        ) {
            return (
                <div className={cn(css.inProgressElement, css.listElement)}>
                    {currentMessage}
                </div>
            );
        } else if (message.actionMessage == null) {
            return (
                <div className={cn(css.pendingElement, css.listElement)}>
                    {currentMessage}
                </div>
            );
        } else {
            return <div className={css.listElement}>{currentMessage}</div>;
        }
    };

    useEffect(() => {
        if (activeStep !== null) {
            setMessageList(getMessages(activeStep));
        }
    }, [activeStep]);

    useEffect(() => {
        if (
            activeStep?.status === StepStatus.Running ||
            activeStep?.status === StepStatus.Pending
        ) {
            const runningStep = stepList?.find(
                (step) =>
                    step.status === StepStatus.Running ||
                    step.status === StepStatus.Pending
            );
            if (
                activeStep.logs &&
                activeStep.logs?.length &&
                runningStep &&
                activeStep.logs !== runningStep.data
            ) {
                setActiveStepData(activeStep?.logs || "");
            }
        }
    }, [activeStep, stepList, setActiveStepData]);

    return (
        <div>
            <ul className={css.list}>
                {messageList?.map((message: IEnrollStepData, index: number) => {
                    return (
                        <li key={String(index)}>
                            {getMessageElement(
                                activeStep,
                                message,
                                messageList
                            )}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default AutomatedStep;
