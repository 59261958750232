import ErrorSection from "components/Common/ErrorSection/ErrorSection";
import React, { useEffect, useRef, useState } from "react";
import { Loader, Message, Nav, Table } from "rsuite";
import Axios from "axios";
import cn from "classnames";

import { useTranslation } from "react-i18next";

import { getErrorMessage } from "api/defaults";
import { loadTenantErpVersionHistory } from "api/environments/tenants/tenant-erp-version-history-api";

import useRefreshPage from "hooks/useRefreshPage";
import useInactiveTenant from "hooks/useInactiveTenant";
import useInactiveEnvironment from "hooks/useInactiveEnvironment";
import useRequestCancellation from "hooks/useRequestCancellation";

import {
    IVersionUpdate,
    VersionType,
} from "store/environments/tenants/version-history/version-history-types";

import { compareDates, formatDateWithNameOfMonth } from "utils/date-helper";
import constants from "utils/constants";

import "./VersionHistory.less";

interface ComponentProps {
    envMoniker: string;
    tenantMoniker: string;
    errorMessage: string;
    setErrorResponseMessage: (msg: string) => void;
}

type SortType = "desc" | "asc";

const VersionHistory: React.FC<ComponentProps> = ({
    envMoniker,
    tenantMoniker,
    errorMessage,
    setErrorResponseMessage,
}: ComponentProps) => {
    const { t } = useTranslation();
    const cancelTokenSource = useRequestCancellation();
    const containerRef = useRef<HTMLDivElement>(null);
    const [activeTab, setActiveTab] = useState(VersionType.Dooap);
    const [historyRecords, setHistoryRecords] = useState<IVersionUpdate[]>([]);
    const [currentVersion, setCurrentVersion] = useState<string | null>(null);
    const [tableHeight, setTableHeight] = useState<number>();
    const [sortColumn, setSortColumn] = useState<string>("updateTime");
    const [sortType, setSortType] = useState<SortType>("desc");
    const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);
    const [isDataFetched, setIsDataFetched] = useState<boolean>(false);

    const updateTableHeight = () => {
        if (containerRef.current) {
            const tableHeight = containerRef.current.clientHeight * 0.7;
            setTableHeight(tableHeight);
        }
    };

    const fetchVersionHistory = () => {
        setIsLoaderVisible(true);
        setIsDataFetched(false);
        loadTenantErpVersionHistory(
            envMoniker,
            tenantMoniker,
            activeTab,
            cancelTokenSource
        )
            .then(([payload, status]) => {
                if (payload && status === constants.statusCode.OK) {
                    setHistoryRecords(
                        payload.versionUpdates.map((historyRecord) => {
                            return {
                                updateTime: new Date(historyRecord.updateTime),
                                versionNumber: historyRecord.versionNumber,
                            };
                        })
                    );
                    setCurrentVersion(payload.currentVersion);
                } else {
                    setErrorResponseMessage(
                        getErrorMessage(String(payload), status)
                    );
                }
            })
            .catch((error: Error) => {
                const axiosError = error;
                if (!Axios.isCancel(axiosError)) {
                    setErrorResponseMessage(error.message);
                }
            })
            .then(() => {
                setIsDataFetched(true);
                setIsLoaderVisible(false);
            });
    };

    useEffect(() => {
        fetchVersionHistory();
    }, [activeTab, envMoniker, tenantMoniker]);

    useEffect(() => {
        if (!isLoaderVisible) {
            updateTableHeight();
        }
    }, [isLoaderVisible]);

    useEffect(() => {
        window.addEventListener("resize", updateTableHeight);
    }, []);

    useRefreshPage(() => {
        if (envMoniker && tenantMoniker) {
            fetchVersionHistory();
        }
    });

    useInactiveTenant();
    useInactiveEnvironment();

    if (errorMessage) {
        return (
            <div className={"pageContainer"}>
                <ErrorSection errorMessage={errorMessage} />
            </div>
        );
    }

    const handleSortColumn = (sortColumn: string, sortType: SortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    };

    const getData = () => {
        if (sortColumn && sortType) {
            return historyRecords.sort((a, b) => {
                if (sortColumn === "updateTime") {
                    return sortType === "asc"
                        ? compareDates(a.updateTime, b.updateTime) < 0
                            ? 1
                            : -1
                        : compareDates(a.updateTime, b.updateTime) < 0
                          ? -1
                          : 1;
                }
                return sortType === "asc"
                    ? a[sortColumn as keyof IVersionUpdate] <
                      b[sortColumn as keyof IVersionUpdate]
                        ? -1
                        : 1
                    : a[sortColumn as keyof IVersionUpdate] <
                        b[sortColumn as keyof IVersionUpdate]
                      ? 1
                      : -1;
            });
        }

        return historyRecords;
    };

    const navItems = [
        {
            eventKey: VersionType.Dooap,
            label: "tenantConfig.versionHistory.navigationTab.dooapVersion",
        },
        {
            eventKey: VersionType.Application,
            label: "tenantConfig.versionHistory.navigationTab.applicationVersion",
        },
        {
            eventKey: VersionType.PlatForm,
            label: "tenantConfig.versionHistory.navigationTab.platformVersion",
        },
    ];

    return (
        <div ref={containerRef} className="version-history__container">
            <h1>{t("tenantConfig.versionHistory.header")}</h1>
            <Nav
                appearance="subtle"
                activeKey={activeTab}
                onSelect={(eventKey) => {
                    setActiveTab(eventKey);
                }}
                className={cn("navbar__container", "version-history__navbar")}
            >
                {navItems.map((navItem) => {
                    return (
                        <Nav.Item
                            key={navItem.eventKey}
                            eventKey={navItem.eventKey}
                            disabled={isLoaderVisible}
                        >
                            {t(navItem.label)}
                        </Nav.Item>
                    );
                })}
            </Nav>
            {!isLoaderVisible && isDataFetched && (
                <div className="version-history__current-version">
                    {currentVersion ? (
                        <p>
                            {t(
                                "tenantConfig.versionHistory.currentVersion.title"
                            )}
                            {currentVersion}
                        </p>
                    ) : (
                        <Message showIcon type="warning">
                            {t(
                                "tenantConfig.versionHistory.currentVersion.warningMessage"
                            )}
                        </Message>
                    )}
                </div>
            )}
            {!isLoaderVisible && isDataFetched ? (
                <>
                    <Table
                        shouldUpdateScroll={false}
                        virtualized={true}
                        data={getData()}
                        sortColumn={sortColumn}
                        sortType={sortType}
                        onSortColumn={(dataKey, sortType) =>
                            handleSortColumn(dataKey, sortType!)
                        }
                        height={tableHeight}
                    >
                        <Table.Column flexGrow={1} fixed sortable>
                            <Table.HeaderCell>
                                {t(
                                    `tenantConfig.versionHistory.table.versionHeaderVariation.${activeTab}`
                                )}
                            </Table.HeaderCell>
                            <Table.Cell dataKey="versionNumber">
                                {(rowData: IVersionUpdate) => {
                                    return <div>{rowData.versionNumber}</div>;
                                }}
                            </Table.Cell>
                        </Table.Column>
                        <Table.Column flexGrow={1} fixed sortable>
                            <Table.HeaderCell>
                                {t(
                                    "tenantConfig.versionHistory.table.dateHeader"
                                )}
                            </Table.HeaderCell>
                            <Table.Cell dataKey="updateTime">
                                {(rowData: IVersionUpdate) => {
                                    return (
                                        <div>
                                            {formatDateWithNameOfMonth(
                                                rowData.updateTime
                                            )}
                                        </div>
                                    );
                                }}
                            </Table.Cell>
                        </Table.Column>
                    </Table>
                </>
            ) : (
                <Loader
                    center
                    size="md"
                    speed="slow"
                    content={t("loader.defaultContent")}
                    className={"version-history__loader"}
                />
            )}
        </div>
    );
};

export default VersionHistory;
