import React, { useEffect, useRef, useState } from "react";
import { useImmer } from "use-immer";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import { v4 as uuid } from "uuid";
import {
    Button,
    ButtonToolbar,
    Checkbox,
    Form,
    FormInstance,
    IconButton,
    Message,
    Radio,
    RadioGroup,
    Schema,
    Table,
    toaster,
} from "rsuite";
import { TableInstance } from "rsuite/esm/Table";
import SaveIcon from "@rsuite/icons/Save";
import EditIcon from "@rsuite/icons/Edit";

import constants from "utils/constants";

import { setErrorResponseMessage } from "store/common/initialization/initialization-actions";
import {
    IMailbox,
    IMailboxDefault,
    MailboxCreationType,
    ValidationErrors,
    mailboxFields,
} from "store/environments/tenants/tenant-capture-mailboxes/tenant-capture-mailboxes-types";

import { getErrorMessage } from "api/defaults";
import {
    createTenantCaptureMailboxes,
    loadTenantDefaultCaptureMailboxes,
} from "api/environments/tenants/tenant-capture-mailboxes-api";

import useRequestCancellation from "hooks/useRequestCancellation";

import ModalContainer from "components/Common/Modal/ModalContainer";
import EditableCell from "../EditableCell";

import { getComparableMilboxValue } from "../mailbox-helpers";

import "./MailboxesCreation.less";

const emptyFormValue = {
    genericName: "",
    genericAddress: "",
};

interface ComponentProps {
    envMoniker: string;
    tenantMoniker: string;
    isOpened: boolean;
    setIsOpened: (isOpened: boolean) => void;
    setModalLoaderVisibility: (visible: boolean) => void;
}

const MailboxesCreation: React.FC<ComponentProps> = ({
    envMoniker,
    tenantMoniker,
    isOpened,
    setIsOpened,
    setModalLoaderVisibility,
}: ComponentProps) => {
    const { t } = useTranslation();
    const containerRef = useRef<HTMLDivElement>(null);
    const tableBodyRef = useRef<TableInstance<any, any> | null>(null);
    const formRef = useRef<FormInstance<Record<string, any>> | null>(null);
    const cancelTokenSource = useRequestCancellation();

    const [isDataFetched, setIsDataFetched] = useState<boolean>(false);
    const [companyMailboxes, setCompanyMailboxes] = useImmer<IMailboxDefault[]>(
        []
    );
    const [selectedType, setSelectedType] = useState<MailboxCreationType>(
        MailboxCreationType.Generic
    );
    const [sortColumn, setSortColumn] = useState<string>();
    const [sortType, setSortType] = useState();
    const [isAllChecked, setAllChecked] = useState<boolean>(true);
    const [formValue, setFormValue] = useState(emptyFormValue);
    const [genericMailbox, setGenericMailbox] = useState<IMailboxDefault>();
    const [resultMailboxes, setResultMailboxes] = useState<IMailbox[]>([]);

    const [validationErrors, setValidationErrors] = useImmer<ValidationErrors>(
        {}
    );
    const [saveBtnDisabled, setSaveBtnDisabled] = useState<boolean>(true);

    const fetchDefaultTenantMailboxes = () => {
        setModalLoaderVisibility(true);
        loadTenantDefaultCaptureMailboxes(
            envMoniker,
            tenantMoniker,
            cancelTokenSource
        )
            .then(([payload, status]) => {
                if (payload && status === constants.statusCode.OK) {
                    const allMailboxes = payload.map((mailbox) => ({
                        ...mailbox,
                        checked: true,
                    }));

                    setCompanyMailboxes(
                        allMailboxes.filter(
                            (mailbox) => mailbox.company !== null
                        )
                    );

                    const genericMailbox = allMailboxes.find(
                        (mailbox) => mailbox.company === null
                    );
                    if (genericMailbox) {
                        setFormValue({
                            genericName: genericMailbox.name,
                            genericAddress: genericMailbox.address,
                        });
                        setGenericMailbox(genericMailbox);
                    }
                } else {
                    setErrorResponseMessage(
                        getErrorMessage(String(payload), status)
                    );
                }
            })
            .catch((error: Error) => {
                const axiosError = error;
                if (!Axios.isCancel(axiosError)) {
                    setErrorResponseMessage(error.message);
                }
            })
            .then(() => {
                setModalLoaderVisibility(false);
            });
        setIsDataFetched(true);
    };

    const handleModalClose = () => {
        setAllChecked(false);
        setSelectedType(MailboxCreationType.Generic);
        setIsDataFetched(false);
        setIsOpened(false);
    };

    const handleTypeChange = (value: MailboxCreationType) => {
        if (
            [MailboxCreationType.Generic, MailboxCreationType.All].includes(
                value
            )
        ) {
            formRef?.current?.check();
        } else {
            formRef?.current?.cleanErrors();
        }

        setSelectedType(value);
    };

    const handleEdit = (rowData: IMailboxDefault) => {
        const updatedMailboxes = companyMailboxes.map((mailbox) =>
            mailbox.id === rowData.id
                ? {
                      ...mailbox,
                      editing: !mailbox.editing,
                      name: rowData.name.trim(),
                      address: rowData.address.trim(),
                  }
                : mailbox
        );
        setCompanyMailboxes(updatedMailboxes);
    };

    const isSaveIconDisabled = (rowData: IMailboxDefault) =>
        !!rowData.editing &&
        !!rowData.company &&
        (!!validationErrors[rowData.id]?.[mailboxFields.name] ||
            !!validationErrors[rowData.id]?.[mailboxFields.address]);

    const handleSortColumn = (sortColumn: string, sortType: any) => {
        setSortColumn(sortColumn);
        setSortType(sortType);

        setCompanyMailboxes((draft) => {
            if (sortColumn && sortType) {
                draft.sort((a, b) => {
                    const x = getComparableMilboxValue(a, sortColumn);
                    const y = getComparableMilboxValue(b, sortColumn);

                    return sortType === "asc"
                        ? x < y
                            ? -1
                            : 1
                        : x < y
                          ? 1
                          : -1;
                });
            }
        });
    };

    const handleSubmit = () => {
        if (!formRef.current?.check()) {
            return;
        }

        if (resultMailboxes && resultMailboxes.length > 0) {
            setModalLoaderVisibility(true);
            createTenantCaptureMailboxes(
                envMoniker,
                tenantMoniker,
                resultMailboxes
            ).then(([result, status]) => {
                if (status === constants.statusCode.OK) {
                    handleModalClose();
                } else {
                    toaster.push(
                        <Message type="error" showIcon>
                            {getErrorMessage(String(result), status)}
                        </Message>,
                        {
                            duration: constants.alertDurationMsec,
                        }
                    );
                }
                setModalLoaderVisibility(false);
            });
        }

        handleModalClose();
    };

    const handleCheckboxCheck = (checked: boolean, value: IMailboxDefault) => {
        if (value.exists) {
            return;
        }
        const updatedMailboxes = companyMailboxes.map((mailbox) =>
            mailbox.id === value.id ? { ...mailbox, checked: checked } : mailbox
        );
        setCompanyMailboxes(updatedMailboxes);
    };

    const handleChangeForm = (value: any) => {
        setFormValue(value);
    };

    useEffect(() => {
        if (!isDataFetched && isOpened) {
            fetchDefaultTenantMailboxes();
            setValidationErrors({});
        }
    }, [isDataFetched, isOpened]);

    useEffect(() => {
        if (isAllChecked) {
            setCompanyMailboxes((prevMailboxes) =>
                prevMailboxes.map((mailbox) => ({
                    ...mailbox,
                    checked: true,
                }))
            );
        } else {
            setCompanyMailboxes((prevMailboxes) =>
                prevMailboxes.map((mailbox) => ({
                    ...mailbox,
                    checked: mailbox.exists ? mailbox.checked : false,
                }))
            );
        }
    }, [isAllChecked]);

    useEffect(() => {
        if (
            companyMailboxes.length > 0 &&
            companyMailboxes.every((mailbox) => mailbox.checked)
        ) {
            setAllChecked(true);
        } else if (companyMailboxes.every((mailbox) => !mailbox.checked)) {
            setAllChecked(false);
        }
    }, [companyMailboxes]);

    useEffect(() => {
        let updatedMailboxes: IMailboxDefault[] = [];

        if (
            !genericMailbox?.exists &&
            (selectedType === MailboxCreationType.Generic ||
                selectedType === MailboxCreationType.All)
        ) {
            updatedMailboxes.push({
                id: uuid(),
                name: formValue.genericName,
                address: formValue.genericAddress,
                company: null,
                checked: false,
                editing: false,
                exists: true,
            });
        }

        if (
            selectedType === MailboxCreationType.Company ||
            selectedType === MailboxCreationType.All
        ) {
            updatedMailboxes = updatedMailboxes.concat(
                companyMailboxes
                    .filter((mailbox) => !mailbox.exists && mailbox.checked)
                    .map((mailbox) => ({ ...mailbox }))
            );

            setResultMailboxes(updatedMailboxes);
        }

        setResultMailboxes(updatedMailboxes);
    }, [formValue, companyMailboxes, selectedType, genericMailbox]);

    useEffect(() => {
        setSaveBtnDisabled(
            !isDataFetched ||
                !(resultMailboxes && resultMailboxes.length > 0) ||
                (selectedType !== MailboxCreationType.Generic &&
                    resultMailboxes.some(
                        (mailbox) =>
                            validationErrors[mailbox.id] &&
                            (validationErrors[mailbox.id].name ||
                                validationErrors[mailbox.id].address)
                    )) ||
                ([
                    MailboxCreationType.All,
                    MailboxCreationType.Generic,
                ].includes(selectedType) &&
                    !formRef.current?.check())
        );
    }, [isDataFetched, resultMailboxes, validationErrors]);

    const header = (
        <h1>{t("tenantConfig.captureMailboxes.mailboxCreation.title")}</h1>
    );
    const footer = (
        <ButtonToolbar className="custom-modal__button-toolbar">
            <Button
                type="submit"
                disabled={saveBtnDisabled}
                appearance="primary"
                onClick={handleSubmit}
            >
                {t("tenantConfig.captureMailboxes.mailboxCreation.createBtn")}
            </Button>
            <Button
                disabled={!isDataFetched}
                appearance="ghost"
                onClick={handleModalClose}
            >
                {t("confirmModal.cancel")}
            </Button>
        </ButtonToolbar>
    );

    const { StringType } = Schema.Types;
    const formModel = Schema.Model({
        genericName: StringType().isRequired(
            t("generalValidationMessages.isRequired")
        ),
        genericAddress: StringType()
            .isRequired(t("generalValidationMessages.isRequired"))
            .isEmail(t("generalValidationMessages.isEmail")),
    });

    return (
        <ModalContainer
            className="mailboxes-creation__container"
            open={isOpened}
            header={header}
            footer={footer}
            onClose={handleModalClose}
        >
            <RadioGroup
                defaultValue={MailboxCreationType.Generic}
                value={selectedType}
                onChange={(value) =>
                    handleTypeChange(value as MailboxCreationType)
                }
                className="mailboxes-creation__radio-group"
            >
                <Radio
                    value={MailboxCreationType.Generic}
                    className="mailboxes-creation__radio-item"
                >
                    {t(
                        "tenantConfig.captureMailboxes.mailboxCreation.formType.generic"
                    )}
                </Radio>
                <Radio
                    value={MailboxCreationType.Company}
                    className="mailboxes-creation__radio-item"
                >
                    {t(
                        "tenantConfig.captureMailboxes.mailboxCreation.formType.company"
                    )}
                </Radio>
                <Radio
                    value={MailboxCreationType.All}
                    className="mailboxes-creation__radio-item"
                >
                    {t(
                        "tenantConfig.captureMailboxes.mailboxCreation.formType.all"
                    )}
                </Radio>
            </RadioGroup>
            <div ref={containerRef}>
                <Form
                    model={formModel}
                    ref={formRef}
                    formValue={formValue}
                    onChange={handleChangeForm}
                    className={
                        selectedType === MailboxCreationType.Company ||
                        genericMailbox?.exists
                            ? "mailboxes-creation__form--disabled"
                            : ""
                    }
                >
                    <Form.Group className="add-modal__form-group">
                        <Form.ControlLabel>
                            {t(`tenantConfig.captureMailboxes.table.name`)}
                        </Form.ControlLabel>
                        <Form.Control name="genericName" />
                    </Form.Group>
                    <Form.Group className="add-modal__form-group">
                        <Form.ControlLabel>
                            {t(`tenantConfig.captureMailboxes.table.address`)}
                        </Form.ControlLabel>
                        <Form.Control name="genericAddress" />
                    </Form.Group>
                </Form>
                <Table
                    ref={tableBodyRef}
                    shouldUpdateScroll={false}
                    data={companyMailboxes}
                    sortColumn={sortColumn}
                    sortType={sortType}
                    onSortColumn={handleSortColumn}
                    height={350}
                    className={
                        selectedType === MailboxCreationType.Generic
                            ? "mailboxes-creation__form--disabled"
                            : ""
                    }
                >
                    <Table.Column flexGrow={1}>
                        <Table.HeaderCell>
                            <Checkbox
                                checked={isAllChecked}
                                onChange={() => {
                                    setAllChecked(!isAllChecked);
                                }}
                            />
                        </Table.HeaderCell>
                        <Table.Cell>
                            {(rowData: IMailboxDefault) => {
                                return (
                                    <Checkbox
                                        checked={rowData.checked}
                                        onChange={(_, checked) => {
                                            handleCheckboxCheck(
                                                checked,
                                                rowData
                                            );
                                        }}
                                        disabled={rowData.exists}
                                    />
                                );
                            }}
                        </Table.Cell>
                    </Table.Column>

                    <Table.Column flexGrow={2} sortable>
                        <Table.HeaderCell>
                            {t(
                                `tenantConfig.captureMailboxes.mailboxCreation.company`
                            )}
                        </Table.HeaderCell>
                        <Table.Cell dataKey="remoteId">
                            {(rowData: IMailbox) => {
                                return <div>{rowData.company?.remoteId}</div>;
                            }}
                        </Table.Cell>
                    </Table.Column>

                    <Table.Column flexGrow={7} sortable>
                        <Table.HeaderCell>
                            {t(`tenantConfig.captureMailboxes.table.name`)}
                        </Table.HeaderCell>
                        <Table.Cell dataKey={mailboxFields.name}>
                            {(rowData: IMailboxDefault) => (
                                <EditableCell
                                    mailboxes={companyMailboxes}
                                    dataKey={mailboxFields.name}
                                    data={rowData}
                                    tableBodyRef={tableBodyRef}
                                    validationErrors={validationErrors}
                                    setMailboxes={setCompanyMailboxes}
                                    setValidationErrors={setValidationErrors}
                                />
                            )}
                        </Table.Cell>
                    </Table.Column>

                    <Table.Column flexGrow={7} sortable>
                        <Table.HeaderCell>
                            {t(`tenantConfig.captureMailboxes.table.address`)}
                        </Table.HeaderCell>
                        <Table.Cell dataKey={mailboxFields.address}>
                            {(rowData: IMailboxDefault) => (
                                <EditableCell
                                    mailboxes={companyMailboxes}
                                    dataKey={mailboxFields.address}
                                    data={rowData}
                                    tableBodyRef={tableBodyRef}
                                    validationErrors={validationErrors}
                                    setMailboxes={setCompanyMailboxes}
                                    setValidationErrors={setValidationErrors}
                                />
                            )}
                        </Table.Cell>
                    </Table.Column>

                    <Table.Column flexGrow={1}>
                        <Table.HeaderCell>
                            {t(
                                `tenantConfig.captureMailboxes.mailboxCreation.action`
                            )}
                        </Table.HeaderCell>
                        <Table.Cell>
                            {(rowData: IMailboxDefault) =>
                                !rowData.exists && (
                                    <IconButton
                                        appearance="subtle"
                                        size="sm"
                                        onClick={() => {
                                            handleEdit(rowData);
                                        }}
                                        disabled={isSaveIconDisabled(rowData)}
                                        icon={
                                            rowData.editing ? (
                                                <SaveIcon />
                                            ) : (
                                                <EditIcon />
                                            )
                                        }
                                    />
                                )
                            }
                        </Table.Cell>
                    </Table.Column>
                </Table>
            </div>
        </ModalContainer>
    );
};

export default MailboxesCreation;
