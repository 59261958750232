import { RootState } from "store/store";
import { connect } from "react-redux";
import { fetchAppSettings } from "store/app-settings/app-settings-thunks";
import { fetchUserPermissions } from "store/app-global/app-global-thunks";
import { fetchSupportContact } from "store/contacts/contacts-thunks";
import MainLayout from "./MainLayout";
import { setIsUnsavedChangeAvailable } from "store/common/unsaved-data/unsaved-data-actions";

const mapStateToProps = (state: RootState) => {
    return {
        isAppSettingsFetched: state.appSettingsReducer.isAppSettingsFetched,
        isLoaderVisible: state.initializationReducer.loadersCount > 0,
        isUnsavedChangeAvailable:
            state.unsavedDataReducer.isUnsavedChangeAvailable,
    };
};

const MainLayoutContainer = connect(mapStateToProps, {
    fetchAppSettings,
    fetchUserPermissions,
    fetchSupportContact,
    setIsUnsavedChangeAvailable,
})(MainLayout);

export default MainLayoutContainer;
