import React from "react";
import { Route, Routes } from "react-router-dom";
import routes from "utils/routes";

const AppRouter: React.FC = () => {
    const mainLayoutRoutes = routes.mainLayout.map((route, index) => {
        return (
            <Route
                path={route.path}
                key={"AppRouter" + String(index)}
                Component={route.componentName}
            />
        );
    });

    return <Routes>{mainLayoutRoutes}</Routes>;
};

export default AppRouter;
