import React, { useEffect, useState } from "react";
import {
    CustomerType,
    ICustomerDetails,
} from "store/customers/customer-details/customer-details-types";
import {
    IAxImport,
    IAxImportEntity,
} from "store/environments/tenants/data-import/data-import-types";
import {
    IEnrollCache,
    IEnrollStep,
    StepStatus,
} from "store/environments/tenants/enroll-tenant/enroll-tenant-types";
import useRefreshPage from "hooks/useRefreshPage";
import AxImportEntitiesTable from "components/Environments/Tenants/AxImportEntitiesTable/AxImportEntitiesTable";
import { compareEntities } from "utils/tenant-workflows/tenant-workflows-helper";

interface IComponentProps {
    environmentMoniker: string;
    activeStep: IEnrollStep | null;
    enrollCache: IEnrollCache | null;
    customerDetails: ICustomerDetails;
    enrollId: string;
    isDeleteAllowed: boolean;
    fetchTenantAxImport: (
        environmentMoniker: string,
        tenantId: string
    ) => Promise<IAxImport | null>;
    setActiveStepData: (data: string | null) => void;
    fetchEnrollCache: (
        environmentMoniker: string,
        orchestratorInstanceId: string
    ) => Promise<void>;
    fetchCustomerDetails: (customerId: string) => Promise<void>;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
    showLoader: (isVisible: boolean) => void;
}

const DataImportControl: React.FC<IComponentProps> = ({
    environmentMoniker,
    activeStep,
    enrollCache,
    customerDetails,
    enrollId,
    isDeleteAllowed,
    fetchTenantAxImport,
    setActiveStepData,
    fetchEnrollCache,
    fetchCustomerDetails,
    setIsUnsavedChangeAvailable,
    showLoader,
}: IComponentProps) => {
    const [axImportEntities, setAxImportEntities] = useState<IAxImportEntity[]>(
        []
    );
    const [areAxEntitiesFetched, setAxEntitiesFetched] =
        useState<boolean>(false);
    const [isD365Customer, setIsD365Customer] = useState<boolean | null>(null);

    const fetchData = (envMoniker: string, tenantMoniker: string) => {
        if (envMoniker && tenantMoniker) {
            showLoader(true);
            fetchTenantAxImport(envMoniker, tenantMoniker)
                .then((value) => {
                    if (!value) {
                        return;
                    }
                    if (!isD365Customer) {
                        setAxImportEntities(
                            value.axImportEntities.sort(compareEntities)
                        );
                        return;
                    }
                    const modifiedEntities = value.axImportEntities.map(
                        (entity) => {
                            if (entity.isCefTurnedOn !== null) {
                                return {
                                    ...entity,
                                    isAxImportTurnedOn: false,
                                    isCefTurnedOn: true,
                                };
                            } else {
                                return entity;
                            }
                        }
                    );
                    setAxImportEntities(modifiedEntities.sort(compareEntities));
                })
                .then(() => {
                    showLoader(false);
                });
            setAxEntitiesFetched(true);
        }
    };

    useEffect(() => {
        if (!enrollCache && !isDeleteAllowed) {
            fetchEnrollCache(environmentMoniker, enrollId).then(() => {
                showLoader(false);
            });
        }
    }, [enrollCache]);

    useEffect(() => {
        if (
            enrollCache?.customerId &&
            (!customerDetails || customerDetails.id !== enrollCache.customerId)
        ) {
            fetchCustomerDetails(enrollCache.customerId).then(() => {
                showLoader(false);
            });
        }
    }, [
        fetchCustomerDetails,
        customerDetails,
        activeStep?.status,
        enrollCache?.customerId,
    ]);

    useEffect(() => {
        if (customerDetails) {
            setIsD365Customer(customerDetails.type === CustomerType.D365);
        }
    }, [customerDetails]);

    useEffect(() => {
        enrollCache && customerDetails && axImportEntities.length
            ? showLoader(false)
            : showLoader(true);
    }, [enrollCache, customerDetails, axImportEntities]);

    useEffect(() => {
        if (
            activeStep?.status !== StepStatus.Success &&
            enrollCache &&
            enrollCache.tenantMoniker &&
            environmentMoniker &&
            !areAxEntitiesFetched &&
            isD365Customer !== null
        ) {
            fetchData(environmentMoniker, enrollCache.tenantMoniker);
        }
    }, [
        activeStep,
        enrollCache,
        environmentMoniker,
        areAxEntitiesFetched,
        isD365Customer,
    ]);

    useEffect(() => {
        setActiveStepData(JSON.stringify(axImportEntities));
    }, [axImportEntities]);

    useEffect(() => {
        if (
            axImportEntities.some(
                (entity) => entity.isAxImportTurnedOn == null
            ) &&
            !isD365Customer
        ) {
            setAxImportEntities(
                axImportEntities.filter(
                    (entity) => entity.isAxImportTurnedOn !== null
                )
            );
        }
    }, [axImportEntities, isD365Customer]);

    useRefreshPage(() => {
        if (enrollId && environmentMoniker && enrollCache?.tenantMoniker) {
            if (activeStep?.status !== StepStatus.Success) {
                setAxEntitiesFetched(false);
            }
        }
    });

    return (
        <AxImportEntitiesTable
            axImportEntities={axImportEntities}
            isD365Customer={isD365Customer ?? false}
            isDataTransferAllowed={false}
            disabledSettingNames={[]}
            cefOriginalEntities={[]}
            settingWorkFlows={[]}
            setAxImportEntities={setAxImportEntities}
            handleTransferData={null}
            setIsUnsavedChangeAvailable={setIsUnsavedChangeAvailable}
        />
    );
};

export default DataImportControl;
