import { RootState } from "store/store";
import { connect } from "react-redux";
import { compose } from "redux";
import { getUserPermissions } from "store/app-global/app-global-selectors";
import { getAppSettings } from "store/app-settings/app-settings-selectors";
import TopMenu from "./TopMenu";

const mapStateToProps = (state: RootState) => {
    return {
        userPermissions: getUserPermissions(state),
        appSettings: getAppSettings(state),
    };
};

const TopMenuRouteMapContainer = compose(connect(mapStateToProps, {}))(TopMenu);

export default TopMenuRouteMapContainer;
