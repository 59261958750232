import React, { Dispatch, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    IScheduler,
    ISchedulerTime,
    ScheduleEvent,
    ScheduleEventSubtype,
    ScheduleEventType,
} from "store/environments/tenants/tenant-scheduler/tenant-scheduler-types";
import {
    Button,
    CheckPicker,
    DatePicker,
    Form,
    InputNumber,
    InputPicker,
    Message,
    Toggle,
    toaster,
} from "rsuite";
import constants from "utils/constants";
import "../Scheduler.less";
import { getErrorMessage } from "api/defaults";
import { template } from "lodash";
import useRefreshPage from "hooks/useRefreshPage";
import SchedulerPopover from "components/Environments/Tenants/LeftMenu/Scheduler/SchedulerPopover";
import cn from "classnames";
import ErrorSection from "components/Common/ErrorSection/ErrorSection";
import ErrorSectionContainer from "components/Common/ErrorSection/ErrorSectionContainer";
import useRequestCancellation from "hooks/useRequestCancellation";
import Axios, { CancelTokenSource } from "axios";
import SettingsFooterContainer from "components/Common/SettingsFooter/SettingsFooterContainer";
import TrashIcon from "@rsuite/icons/Trash";
import CheckIcon from "@rsuite/icons/Check";

const timeTypes = {
    time: 0,
    interval: 1,
};

const defaultSchedulerTime = {
    intervalInMinutes: null,
    intervalType: timeTypes.time,
    lastError: null,
    lastStarted: "",
    timeOfDay: "10:00:00",
    weekdays: 1,
};

const defaultRecurringSchedulerTime = {
    intervalInMinutes: 10,
    intervalType: timeTypes.interval,
    lastError: null,
    lastStarted: "",
    timeOfDay: null,
    weekdays: 127,
};

interface ComponentProps {
    envMoniker: string;
    tenantMoniker: string;
    scheduleEvent: ScheduleEvent;
    isTenantConfigurationsFetched: boolean;
    errorMessage: string;
    schedulerErrorMessage: string;
    isMlSettingChecked: boolean;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
    getScheduler: (
        envMoniker: string,
        tenantMoniker: string,
        eventType: ScheduleEventType,
        subtype: ScheduleEventSubtype,
        cancelTokenSource: CancelTokenSource
    ) => Promise<[IScheduler | null, number]>;
    pushScheduler: (
        envMoniker: string,
        tenantMoniker: string,
        scheduler: IScheduler
    ) => Promise<number>;
    showLoader: (isVisible: boolean) => void;
    setScheduleTimeErrorResponseMessage: (message: string) => void;
    getTenantConfigurations: (
        envMoniker: string,
        tenantMoniker: string
    ) => void;
    setIsDataLoading: Dispatch<React.SetStateAction<boolean>>;
}
const ScheduleTimes: React.FC<ComponentProps> = ({
    envMoniker,
    tenantMoniker,
    scheduleEvent,
    isTenantConfigurationsFetched,
    errorMessage,
    schedulerErrorMessage,
    isMlSettingChecked,
    setIsUnsavedChangeAvailable,
    getScheduler,
    pushScheduler,
    setScheduleTimeErrorResponseMessage,
    showLoader,
    getTenantConfigurations,
    setIsDataLoading,
}: ComponentProps) => {
    const { t } = useTranslation();
    const cancelTokenSource = useRequestCancellation();

    const [isSchedulerLoaded, setSchedulerLoaded] = useState<boolean>(false);
    const [scheduler, setScheduler] = useState<IScheduler>();
    const [isSaveBtnClicked, setSaveBtnClicked] = useState(false);

    const scheduleTimeTypeOptions = Object.entries(timeTypes).map(
        ([key, value]) => ({
            label: t(`tenantConfig.scheduler.intervalType.${key}`),
            value: value,
        })
    );
    const dayCheckPickerOptions = [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
    ].map((day, index) => ({
        label: t(`dayList.${day}`),
        value: Math.pow(2, index),
    }));
    const getNowFlag = new Date(1970, 1, 1, 1, 1, 1);
    const defaultDate = new Date("1970-01-01 10:00:00");

    const isEmailScheduler =
        scheduleEvent.type === ScheduleEventType.Email &&
        scheduleEvent.subtype === ScheduleEventSubtype.OpenInvoiceEmail;
    const isPoOnHoldMonthlyScheduler =
        scheduleEvent.type === ScheduleEventType.Email &&
        scheduleEvent.subtype ===
            ScheduleEventSubtype.OnHoldMonthlyInvoiceEmail;
    const isOnHoldDueDateScheduler =
        scheduleEvent.type === ScheduleEventType.Email &&
        scheduleEvent.subtype ===
            ScheduleEventSubtype.OnHoldDueDateInvoiceEmail;
    const isVendorScheduler =
        scheduleEvent.type === ScheduleEventType.VendorPrepaymentRegisterCheck;
    const isInvoiceScheduler =
        scheduleEvent.type === ScheduleEventType.InvoiceWaitingForPrCheck;
    const isMlScheduler =
        scheduleEvent.type === ScheduleEventType.MachineLearningModelReTraining;
    const isRecurringInvoicesScheduler =
        scheduleEvent.type === ScheduleEventType.RecurringInvoices;
    const isExtendedScheduler =
        isInvoiceScheduler || isVendorScheduler || isRecurringInvoicesScheduler;
    const isRowsLimitExceeded = scheduler
        ? scheduler.scheduleTimes.length >= constants.maxSchedulerRowsLimit
        : false;
    const cssCellClass = "Scheduler_cell " + (!scheduler?.enabled && "greyed");

    const fetchScheduler = (
        envMoniker: string,
        tenantMoniker: string,
        eventType: ScheduleEventType,
        eventSubtype: ScheduleEventSubtype
    ) => {
        setScheduleTimeErrorResponseMessage("");
        setIsDataLoading(true);
        getScheduler(
            envMoniker,
            tenantMoniker,
            eventType,
            eventSubtype,
            cancelTokenSource
        )
            .then(([payload, statusCode]) => {
                showLoader(false);
                setIsDataLoading(false);
                if (statusCode === constants.statusCode.OK && payload) {
                    setScheduler(payload);
                } else if (statusCode === constants.statusCode.NoContent) {
                    setScheduler({
                        enabled: false,
                        event: eventType,
                        scheduleTimes: [
                            eventType === ScheduleEventType.RecurringInvoices
                                ? defaultRecurringSchedulerTime
                                : defaultSchedulerTime,
                        ],
                        subtype: eventSubtype,
                    });
                } else {
                    const errorMessage = getErrorMessage(
                        String(payload),
                        statusCode
                    );
                    setScheduleTimeErrorResponseMessage(errorMessage);
                }
                setIsUnsavedChangeAvailable(false);
            })
            .catch((error: Error) => {
                const axiosError = error;
                if (!Axios.isCancel(axiosError)) {
                    setScheduleTimeErrorResponseMessage(error.message);
                }
            });
        setSaveBtnClicked(false);
        setSchedulerLoaded(true);
    };

    const updateScheduler = (update: (scheduler: IScheduler) => IScheduler) => {
        if (scheduler) {
            setScheduler(update(scheduler));
        }
    };

    const updateScheduleTimes = (
        position: number,
        update: (schedulerTime: ISchedulerTime) => ISchedulerTime
    ) => {
        updateScheduler((scheduler) => ({
            ...scheduler,
            scheduleTimes: scheduler.scheduleTimes.map((item, index) => {
                if (index !== position) {
                    return item;
                }
                return update(item);
            }),
        }));
    };

    const setSchedulerEnabled = (enabled: boolean) =>
        updateScheduler((scheduler) => ({
            ...scheduler,
            enabled: enabled,
        }));

    const updateSchedulerIntervalType = (
        intervalType: number,
        position: number
    ) => {
        const isIntervalType = intervalType === timeTypes.interval;
        updateScheduleTimes(position, (schedulerTime) => ({
            ...schedulerTime,
            intervalType: intervalType,
            intervalInMinutes: defaultSchedulerTime.intervalInMinutes,
            timeOfDay: isIntervalType ? null : defaultSchedulerTime.timeOfDay,
        }));
    };

    const getIntervalInMinutesFromInput = (intervalInMinutes: number) => {
        return intervalInMinutes === 0
            ? intervalInMinutes
            : intervalInMinutes || null;
    };

    const setIntervalInMinutes = (
        intervalInMinutes: string,
        position: number
    ) => {
        if (
            intervalInMinutes.toString().length >
            constants.maxSchedulerIntervalDigitsLimit
        ) {
            return;
        }
        updateScheduleTimes(position, (schedulerTime) => ({
            ...schedulerTime,
            intervalInMinutes: getIntervalInMinutesFromInput(
                parseInt(intervalInMinutes)
            ),
        }));
    };

    const updateTimeOfDay = (time: string, position: number) =>
        updateScheduleTimes(position, (schedulerTime) => ({
            ...schedulerTime,
            timeOfDay: time,
        }));

    const updateTime = (date: Date | null, position: number) => {
        const scheduleDate = date || defaultDate;
        scheduleDate.setSeconds(0);
        const formattedTime = scheduleDate.toLocaleTimeString(
            constants.localizations.us,
            {
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
            }
        );

        updateTimeOfDay(formattedTime, position);
    };

    const getCurrentDateInUTC = () => {
        const currentUTCDate: Date = new Date();
        currentUTCDate.setHours(currentUTCDate.getUTCHours());
        currentUTCDate.setMinutes(currentUTCDate.getUTCMinutes());

        return currentUTCDate;
    };

    const getDayNumbers = (weekdays: number) => {
        const numbers: number[] = [];
        for (let bit = 0; bit < dayCheckPickerOptions.length; bit++) {
            const mask = Math.pow(2, bit);
            if ((weekdays & mask) === mask) {
                numbers.push(mask);
            }
        }

        return numbers;
    };

    const renderMenuItem = (label: ReactNode) => {
        return <div className="daySelectorMenuItem">{label}</div>;
    };

    const removeScheduleTime = (position: number) =>
        updateScheduler((scheduler) => ({
            ...scheduler,
            scheduleTimes: [
                ...scheduler.scheduleTimes.slice(0, position),
                ...scheduler.scheduleTimes.slice(position + 1),
            ],
        }));

    const handleWeekdaySelect = (values: number[], position: number) => {
        const weekdays = values.reduce(
            (previousValue, currentValue) => previousValue + currentValue,
            0
        );
        updateScheduleTimes(position, (schedulerTime) => ({
            ...schedulerTime,
            weekdays: weekdays,
        }));

        setIsUnsavedChangeAvailable(true);
    };

    const handleToggleChange = (checked: boolean) => {
        setSchedulerEnabled(checked);
        setIsUnsavedChangeAvailable(true);
    };

    const handleRowAdd = () => {
        if (!scheduler?.enabled) {
            return;
        }
        updateScheduler((scheduler) => ({
            ...scheduler,
            scheduleTimes: [
                ...scheduler.scheduleTimes,
                scheduler.event === ScheduleEventType.RecurringInvoices
                    ? defaultRecurringSchedulerTime
                    : defaultSchedulerTime,
            ],
        }));

        setIsUnsavedChangeAvailable(true);
    };

    const getSchedulerToPush = (scheduler: IScheduler) => {
        if (!scheduler?.enabled) {
            scheduler.scheduleTimes = [];
        }
        return scheduler;
    };

    const isIntervalInMinutesValid = (
        intervalInMinutes: number | null,
        intervalType: number
    ) => {
        if (intervalType === timeTypes.interval) {
            if (intervalInMinutes === null) {
                return t("tenantConfig.scheduler.required");
            } else if (
                intervalInMinutes >= constants.minSchedulerIntervalLimit &&
                intervalInMinutes <= constants.maxSchedulerIntervalValueLimit
            ) {
                return null;
            } else {
                return t("tenantConfig.scheduler.intervalInRange");
            }
        }
        return null;
    };

    const isWeekdaysValid = (weekdays: number) => {
        if (weekdays === 0) {
            return t("tenantConfig.scheduler.required");
        } else {
            return null;
        }
    };

    const isSchedulerValid = () => {
        if (
            !scheduler ||
            !scheduler.scheduleTimes ||
            (scheduler.enabled && scheduler.scheduleTimes.length === 0)
        ) {
            return false;
        }

        return scheduler.scheduleTimes.every(
            (item) =>
                isWeekdaysValid(item.weekdays) === null &&
                isIntervalInMinutesValid(
                    item.intervalInMinutes,
                    item.intervalType
                ) === null
        );
    };

    const handleSaveClick = () => {
        setSaveBtnClicked(true);
        if (isSchedulerValid() || !scheduler?.enabled) {
            if (scheduler?.enabled && isMlScheduler && !isMlSettingChecked) {
                toaster.push(
                    <Message showIcon type="error">
                        {t("tenantConfig.scheduler.mlSchedulerErrorMessage")}
                    </Message>,
                    {
                        duration: constants.alertDurationMsec,
                    }
                );
                return;
            }
            showLoader(true);

            if (scheduler) {
                pushScheduler(
                    envMoniker,
                    tenantMoniker,
                    getSchedulerToPush({ ...scheduler })
                ).then((value) => {
                    if (value === constants.statusCode.OK) {
                        toaster.push(
                            <Message showIcon type="success">
                                {t(
                                    "tenantConfig.common.successfulUpdateAlertMessage"
                                )}
                            </Message>,
                            {
                                duration: constants.alertDurationMsec,
                            }
                        );
                        setSchedulerLoaded(false);
                    }
                    showLoader(false);
                });
                setIsUnsavedChangeAvailable(false);
            }
        }
    };

    useEffect(() => {
        if (
            envMoniker &&
            tenantMoniker &&
            !isSchedulerLoaded &&
            isTenantConfigurationsFetched
        ) {
            showLoader(true);
            fetchScheduler(
                envMoniker,
                tenantMoniker,
                scheduleEvent.type,
                scheduleEvent.subtype
            );
        }
    }, [
        envMoniker,
        tenantMoniker,
        isSchedulerLoaded,
        isTenantConfigurationsFetched,
        showLoader,
        fetchScheduler,
        setSchedulerLoaded,
        scheduleEvent,
    ]);

    useEffect(() => {
        if (envMoniker && tenantMoniker) {
            setSchedulerLoaded(false);
        }
    }, [envMoniker, tenantMoniker, scheduleEvent.type, scheduleEvent.subtype]);

    useEffect(() => {
        if (
            scheduler &&
            scheduler.enabled &&
            scheduler.scheduleTimes.length === 0
        ) {
            updateScheduler((scheduler) => ({
                ...scheduler,
                scheduleTimes: [
                    scheduler.event === ScheduleEventType.RecurringInvoices
                        ? defaultRecurringSchedulerTime
                        : defaultSchedulerTime,
                ],
            }));
        }
    }, [scheduler?.enabled]);

    useEffect(() => {
        return () => {
            setSchedulerLoaded(false);
        };
    }, [setSchedulerLoaded]);

    useRefreshPage(() => {
        showLoader(true);
        if (envMoniker && tenantMoniker) {
            fetchScheduler(
                envMoniker,
                tenantMoniker,
                ScheduleEventType.Email,
                ScheduleEventSubtype.OpenInvoiceEmail
            );
            getTenantConfigurations(envMoniker, tenantMoniker);
        }
    });

    if (schedulerErrorMessage.length > 0) {
        return <ErrorSection errorMessage={schedulerErrorMessage} />;
    }

    if (errorMessage.length > 0) {
        return <ErrorSectionContainer />;
    }

    return (
        <>
            <div className={"Scheduler_topContainer"}>
                <div className={"Scheduler_toggleContainer"}>
                    <Toggle
                        checked={scheduler?.enabled}
                        className={"Scheduler_toggleBtn"}
                        checkedChildren={t("toggler.onTitle")}
                        unCheckedChildren={t("toggler.offTitle")}
                        onChange={handleToggleChange}
                    />
                    <span className="Scheduler_toggleText">
                        {template(t("tenantConfig.scheduler.toggleControl"))({
                            eventType: isEmailScheduler
                                ? t("tenantConfig.scheduler.navBar.email")
                                : isVendorScheduler
                                  ? t(
                                        "tenantConfig.scheduler.navBar.vendorPrepaymentCheck"
                                    )
                                  : isInvoiceScheduler
                                    ? t(
                                          "tenantConfig.scheduler.navBar.invoiceWaitingForPr"
                                      )
                                    : isMlScheduler
                                      ? t(
                                            "tenantConfig.scheduler.navBar.mlModelReTraining"
                                        )
                                      : isPoOnHoldMonthlyScheduler
                                        ? t(
                                              "tenantConfig.scheduler.navBar.poOnHoldMonthly"
                                          )
                                        : isOnHoldDueDateScheduler
                                          ? t(
                                                "tenantConfig.scheduler.navBar.onHoldDueDate"
                                            )
                                          : isRecurringInvoicesScheduler
                                            ? t(
                                                  "tenantConfig.scheduler.navBar.recurringInvoices"
                                              )
                                            : "",
                        })}
                    </span>
                </div>
                <Button
                    disabled={isRowsLimitExceeded}
                    className={
                        "addRowBtn " +
                        (!scheduler?.enabled || isRowsLimitExceeded
                            ? " greyed"
                            : "")
                    }
                    onClick={handleRowAdd}
                >
                    {t("tenantConfig.addRowButton")}
                </Button>
            </div>
            <div
                className={cn(
                    "Scheduler_tableHeader",
                    isExtendedScheduler
                        ? "ExtendedScheduler_grid"
                        : "EmailScheduler_grid"
                )}
            >
                <div className={cssCellClass}>
                    {t("tenantConfig.scheduler.table.active")}{" "}
                </div>
                {isExtendedScheduler && (
                    <>
                        <div className={cssCellClass}>
                            {t("tenantConfig.scheduler.table.type")}
                            <Form.HelpText tooltip>
                                {t("tenantConfig.scheduler.table.typeTooltip")}
                            </Form.HelpText>
                        </div>
                        <div className={cssCellClass}>
                            {t("tenantConfig.scheduler.table.interval")}
                            <Form.HelpText tooltip>
                                {t(
                                    "tenantConfig.scheduler.table.intervalTooltip"
                                )}
                            </Form.HelpText>
                        </div>
                    </>
                )}
                <div className={cssCellClass}>
                    {t("tenantConfig.scheduler.table.time")}
                    <Form.HelpText tooltip>
                        {t("tenantConfig.scheduler.table.timeTooltip")}
                    </Form.HelpText>
                </div>
                <div className={cssCellClass}>
                    {t("tenantConfig.scheduler.table.day")}
                    <Form.HelpText tooltip>
                        {t("tenantConfig.scheduler.table.dayTooltip")}
                    </Form.HelpText>
                </div>
                <div className={cssCellClass}>
                    {t("tenantConfig.scheduler.table.action")}
                </div>
            </div>
            {scheduler && scheduler.enabled ? (
                scheduler.scheduleTimes.map((item, rowIndex) => {
                    return (
                        <div
                            className={cn(
                                "Scheduler_tableRow",
                                isExtendedScheduler
                                    ? "ExtendedScheduler_grid"
                                    : "EmailScheduler_grid"
                            )}
                            key={"scheduler_" + String(rowIndex)}
                        >
                            <div className={cssCellClass}>
                                {item.id && <CheckIcon />}{" "}
                            </div>
                            {isExtendedScheduler && (
                                <>
                                    <div className={cssCellClass}>
                                        <InputPicker
                                            className={"typeInputPicker"}
                                            data={scheduleTimeTypeOptions}
                                            value={item.intervalType}
                                            cleanable={false}
                                            searchable={false}
                                            onChange={(value) => {
                                                updateSchedulerIntervalType(
                                                    value,
                                                    rowIndex
                                                );
                                                setSaveBtnClicked(false);
                                                setIsUnsavedChangeAvailable(
                                                    true
                                                );
                                            }}
                                        />
                                    </div>
                                    <div
                                        className={cn(
                                            cssCellClass,
                                            item.intervalType === timeTypes.time
                                                ? "cellDisabledBlock"
                                                : ""
                                        )}
                                    >
                                        {item.intervalType ===
                                        timeTypes.time ? (
                                            <span className="cellDisabledText">
                                                {t(
                                                    "tenantConfig.scheduler.intervalDefault"
                                                )}
                                            </span>
                                        ) : (
                                            <>
                                                <InputNumber
                                                    postfix="min"
                                                    value={
                                                        item.intervalInMinutes !==
                                                        null
                                                            ? item.intervalInMinutes
                                                            : ""
                                                    }
                                                    className={"intervalInput"}
                                                    onChange={(value) => {
                                                        setIntervalInMinutes(
                                                            value === null
                                                                ? ""
                                                                : value.toString(),
                                                            rowIndex
                                                        );
                                                        setIsUnsavedChangeAvailable(
                                                            true
                                                        );
                                                    }}
                                                />
                                                <SchedulerPopover
                                                    isSaveBtnClicked={
                                                        isSaveBtnClicked
                                                    }
                                                    validationMessage={isIntervalInMinutesValid(
                                                        item.intervalInMinutes,
                                                        item.intervalType
                                                    )}
                                                />
                                            </>
                                        )}
                                    </div>
                                </>
                            )}
                            <div
                                className={cn(
                                    cssCellClass,
                                    item.intervalType === timeTypes.interval
                                        ? "cellDisabledBlock"
                                        : ""
                                )}
                            >
                                {item.intervalType === timeTypes.time ? (
                                    <DatePicker
                                        preventOverflow={true}
                                        ranges={[
                                            {
                                                label: "Now",
                                                value: getNowFlag,
                                            },
                                        ]}
                                        key={"picker" + String(item.id)}
                                        className={"timePicker"}
                                        format="HH:mm"
                                        value={
                                            new Date(
                                                "22 Nov 2000 " + item.timeOfDay
                                            )
                                        }
                                        onChange={(data) => {
                                            if (!data) {
                                                return;
                                            }

                                            data.getTime() ===
                                            getNowFlag.getTime()
                                                ? updateTime(
                                                      getCurrentDateInUTC(),
                                                      rowIndex
                                                  )
                                                : updateTime(data, rowIndex);
                                            setIsUnsavedChangeAvailable(true);
                                        }}
                                        onClean={() => {
                                            updateTime(null, rowIndex);
                                            setIsUnsavedChangeAvailable(true);
                                        }}
                                    />
                                ) : (
                                    <span className="cellDisabledText">
                                        {t(
                                            "tenantConfig.scheduler.timePickerPlaceholder"
                                        )}
                                    </span>
                                )}
                            </div>
                            <div className={cssCellClass}>
                                <>
                                    <CheckPicker
                                        placeholder={t(
                                            "tenantConfig.scheduler.daySelectorPlaceholder"
                                        )}
                                        placement="autoVerticalStart"
                                        className={"daySelector"}
                                        data={dayCheckPickerOptions}
                                        value={getDayNumbers(item.weekdays)}
                                        searchable={false}
                                        cleanable={false}
                                        onSelect={(value) =>
                                            handleWeekdaySelect(value, rowIndex)
                                        }
                                        renderMenuItem={renderMenuItem}
                                    />
                                    <SchedulerPopover
                                        isSaveBtnClicked={isSaveBtnClicked}
                                        validationMessage={isWeekdaysValid(
                                            item.weekdays
                                        )}
                                    />
                                </>
                            </div>
                            <div className={cssCellClass}>
                                <TrashIcon
                                    key={"delete" + String(rowIndex)}
                                    className={
                                        "delete-icon " +
                                        ((scheduler?.scheduleTimes.length ??
                                            0) <= 1
                                            ? " greyed"
                                            : "")
                                    }
                                    onClick={() => {
                                        if (
                                            (scheduler?.scheduleTimes.length ??
                                                0) > 1
                                        ) {
                                            removeScheduleTime(rowIndex);
                                            setIsUnsavedChangeAvailable(true);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    );
                })
            ) : (
                <div
                    className={cn(
                        "Scheduler_tableRow",
                        isExtendedScheduler
                            ? "ExtendedScheduler_grid"
                            : "EmailScheduler_grid"
                    )}
                    key={"scheduler_default_row"}
                >
                    <div className={cssCellClass} />
                    {isExtendedScheduler && (
                        <>
                            <div className={cssCellClass}></div>
                            <div className={cssCellClass}>
                                <span className="disaledSchedulerCell">
                                    {t(
                                        "tenantConfig.scheduler.intervalDefault"
                                    )}
                                </span>
                            </div>
                        </>
                    )}
                    <div className={cssCellClass}>
                        <DatePicker
                            preventOverflow={true}
                            className={"timePicker"}
                            format="HH:mm"
                            disabled={true}
                            value={defaultDate}
                        />
                    </div>
                    <div className={cssCellClass}>
                        <span className="disaledSchedulerCell">
                            {t("tenantConfig.scheduler.turnedOff")}
                        </span>
                    </div>
                    <div className={cssCellClass}>
                        <TrashIcon className={"delete-icon greyed"} />
                    </div>
                </div>
            )}
            <SettingsFooterContainer handleFormSubmit={handleSaveClick} />
        </>
    );
};

export default ScheduleTimes;
