import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import {
    Form,
    FormInstance,
    Message,
    Schema,
    Tooltip,
    toaster,
    Whisper,
} from "rsuite";
import useRefreshPage from "hooks/useRefreshPage";
import ErrorSectionContainer from "components/Common/ErrorSection/ErrorSectionContainer";
import useInactiveEnvironment from "hooks/useInactiveEnvironment";
import useInactiveTenant from "hooks/useInactiveTenant";
import constants from "utils/constants";
import { ITenantGeneralConfigurations } from "store/environments/tenants/tenant-configurations/tenant-configurations-types";
import { useLocation, useParams } from "react-router-dom";
import {
    ITenant,
    ITenantNameInfo,
} from "store/environments/tenants/tenants-types";
import { IRouteState } from "utils/types";
import { ITenantRouteState } from "utils/route-state-types";
import { IConfluenceDocumentLinks } from "store/information-links/information-links-types";
import {
    loadConfigurations,
    updateConfigurations,
} from "api/environments/tenants/tenant-general-configurations-api";
import { getErrorMessage } from "api/defaults";
import { loadSeqLogsLink } from "api/information-links-api";
import { formatString } from "utils/regex-helper";
import { CustomerType } from "store/customers/customer-details/customer-details-types";
import "./GeneralConfigurations.less";
import Axios, { CancelTokenSource } from "axios";
import useRequestCancellation from "hooks/useRequestCancellation";
import SettingsFooterContainer from "components/Common/SettingsFooter/SettingsFooterContainer";
import CopyIcon from "@rsuite/icons/Copy";
import Textarea from "components/Common/Textarea/Textarea";

interface ComponentProps {
    isConfluenceDocumentLinksFetched: boolean;
    confluenceDocumentLinks: IConfluenceDocumentLinks;
    envMoniker: string;
    tenantMoniker: string;
    errorMessage: string;
    isFetched: boolean;
    isGeneralConfigurationSaved: boolean;
    isUserPartner: boolean;
    tenantNames: ITenantNameInfo[];
    authenticationMethodDescription: string;
    showLoader: (isVisible: boolean) => void;
    updateActiveTenantDisplayName: (name: string) => void;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
    setActiveTenant: (tenant: ITenant) => void;
    fetchConfluenceDocumentLinks: (
        cancelTokenSource: CancelTokenSource
    ) => Promise<void>;
    setErrorResponseMessage: (msg: string) => void;
    setIsTenantFetched: (isFetched: boolean) => void;
}

const initialConfigurations = {
    customerName: "",
    customerType: CustomerType.D365,
    partners: [],
    displayName: "",
    moniker: "",
    id: "",
    dooapMainUrl: "",
    additionalInformation: "",
    azureFunctionsBaseUrl: "",
    azureSearchApiBaseUrl: "",
};

type IPageParameters = {
    tenantId: string;
};

const GeneralConfigurations: React.FC<ComponentProps> = ({
    isConfluenceDocumentLinksFetched,
    envMoniker,
    tenantMoniker,
    errorMessage,
    isFetched,
    isUserPartner,
    tenantNames,
    showLoader,
    updateActiveTenantDisplayName,
    setIsUnsavedChangeAvailable,
    setActiveTenant,
    fetchConfluenceDocumentLinks,
    setErrorResponseMessage,
    setIsTenantFetched,
}: ComponentProps) => {
    const { t } = useTranslation();
    const cancelTokenSource = useRequestCancellation();
    const location = useLocation();
    const params = useParams<IPageParameters>();
    const { tenantId } = params;
    const formRef = useRef<FormInstance>(null);
    const [configurations, setConfigurations] =
        useState<ITenantGeneralConfigurations>(initialConfigurations);
    const [seqLogsLink, setSeqLogsLink] = useState<string>("");
    const [copyFieldValueMessage, setCopyFiledValueMessage] = useState(
        t(
            "tenantConfig.generalConfigurationPage.copyFieldValue.copyToClipboard"
        )
    );
    const [isCopyTenantIdButtonShown, setIsCopyTenantIdButtonShown] =
        useState(false);
    const copyFieldValueTooltip = <Tooltip>{copyFieldValueMessage}</Tooltip>;

    const getTenantConfigurations = (
        envMoniker: string,
        tenantMoniker: string
    ) => {
        showLoader(true);
        loadConfigurations(envMoniker, tenantMoniker).then(
            ([payload, statusCode]) => {
                if (payload && statusCode === constants.statusCode.OK) {
                    setConfigurations(payload);
                } else {
                    const errorMessage = getErrorMessage(
                        String(payload),
                        statusCode
                    );
                    setErrorResponseMessage(errorMessage);
                }
                showLoader(false);
            }
        );
    };

    const fetchData = (envMoniker: string, tenantMoniker: string) => {
        if (envMoniker && tenantMoniker) {
            showLoader(true);
            Promise.all([
                loadConfigurations(envMoniker, tenantMoniker, cancelTokenSource)
                    .then(([payload, statusCode]) => {
                        if (payload && statusCode === constants.statusCode.OK) {
                            setConfigurations(payload);
                        } else {
                            const errorMessage = getErrorMessage(
                                String(payload),
                                statusCode
                            );
                            setErrorResponseMessage(errorMessage);
                        }
                    })
                    .catch((error: Error) => {
                        const axiosError = error;
                        if (!Axios.isCancel(axiosError)) {
                            setErrorResponseMessage(error.message);
                        }
                    }),
                loadSeqLogsLink(cancelTokenSource)
                    .then(([payload, statusCode]) => {
                        if (payload && statusCode === constants.statusCode.OK) {
                            setSeqLogsLink(payload);
                        } else {
                            const errorMessage = getErrorMessage(
                                String(payload),
                                statusCode
                            );
                            toaster.push(
                                <Message showIcon type="error">
                                    {errorMessage}
                                </Message>,
                                { duration: constants.alertDurationMsec }
                            );
                        }
                    })
                    .catch((error: Error) => {
                        const axiosError = error;
                        if (!Axios.isCancel(axiosError)) {
                            setErrorResponseMessage(error.message);
                        }
                    }),
            ]).then(() => {
                showLoader(false);
                setIsTenantFetched(true);
            });
        }
    };

    const formValue = {
        displayName: configurations.displayName,
        moniker: configurations.moniker,
        customerName: configurations.customerName,
        tenantId: configurations.id,
        azureFunctionsBaseUrl: configurations.azureFunctionsBaseUrl,
        azureSearchApiUrl: configurations.azureSearchApiBaseUrl,
        additionalInformation: configurations.additionalInformation,
        partners: configurations.partners.join(", "),
    };

    const { StringType } = Schema.Types;
    const formModel = Schema.Model({
        displayName: StringType()
            .isRequired(
                t("tenantConfig.generalConfigurationPage.tenantName.required")
            )
            .maxLength(
                constants.maxLengthLimit,
                t(
                    "tenantConfig.generalConfigurationPage.tenantName.maxLengthErrorMsg"
                )
            )
            .addRule((value) => {
                return !(value.trim().length < 3);
            }, t("tenantConfig.generalConfigurationPage.tenantName.minLengthErrorMsg"))
            .addRule((value) => {
                return !tenantNames
                    .filter((tenant) => tenant.id !== configurations.id)
                    .some((tenant) => tenant.name === value.trim());
            }, t("tenantConfig.generalConfigurationPage.tenantName.isUnique")),
        moniker: StringType(),
        customerName: StringType(),
        tenantId: StringType(),
        azureFunctionsBaseUrl: StringType(),
        azureSearchApiUrl: StringType(),
        additionalInformation: StringType().maxLength(
            constants.maxLengthLimit,
            t(
                "tenantConfig.generalConfigurationPage.tenantName.maxLengthErrorMsg"
            )
        ),
    });

    useEffect(() => {
        const historyData = (location.state as IRouteState<ITenantRouteState>)
            ?.data;

        if (historyData) {
            const tenantInHistory = historyData?.tenant;
            const envMonikerInHistory = historyData?.envMoniker;
            if (
                tenantInHistory &&
                envMonikerInHistory &&
                envMoniker &&
                tenantMoniker &&
                (envMonikerInHistory !== envMoniker ||
                    tenantInHistory.moniker !== tenantMoniker)
            ) {
                setActiveTenant(tenantInHistory);
                getTenantConfigurations(
                    envMonikerInHistory,
                    tenantInHistory.moniker
                );
                return;
            }
        }
    }, [envMoniker, tenantMoniker]);

    useEffect(() => {
        if (envMoniker && tenantMoniker && !isFetched) {
            fetchData(envMoniker, tenantMoniker);
        }
    }, [envMoniker, tenantMoniker, isFetched]);

    useEffect(() => {
        setIsTenantFetched(false);
    }, [setIsTenantFetched]);

    useEffect(() => {
        if (!isConfluenceDocumentLinksFetched) {
            fetchConfluenceDocumentLinks(cancelTokenSource).catch(
                (error: Error) => {
                    const axiosError = error;
                    if (!Axios.isCancel(axiosError)) {
                        toaster.push(
                            <Message showIcon type="error">
                                {error.message}
                            </Message>,
                            { duration: constants.alertDurationMsec }
                        );
                    }
                }
            );
        }
    }, [isConfluenceDocumentLinksFetched, fetchConfluenceDocumentLinks]);

    useRefreshPage(() => {
        if (envMoniker && tenantMoniker) {
            setIsTenantFetched(!isFetched);
        }
    });

    useInactiveTenant();
    useInactiveEnvironment();

    if (errorMessage.length > 0) {
        return <ErrorSectionContainer />;
    }

    const updateTenantConfigurations = async () => {
        const [payload, status] = await updateConfigurations(
            envMoniker,
            tenantMoniker,
            configurations
        );
        if (status === constants.statusCode.OK && configurations) {
            updateActiveTenantDisplayName(configurations.displayName);
            toaster.push(
                <Message type="success">
                    {t("tenantConfig.common.successfulUpdateAlertMessage")}
                </Message>,
                { duration: constants.alertDurationMsec }
            );
        } else {
            const errorMessage = getErrorMessage(String(payload), status);
            toaster.push(
                <Message showIcon type="error">
                    {errorMessage}
                </Message>,
                {
                    duration: constants.alertDurationMsec,
                }
            );
        }
    };

    const handleCopyButtonClick = (value: string) => {
        navigator.clipboard.writeText(value);
        setCopyFiledValueMessage(
            t("tenantConfig.generalConfigurationPage.copyFieldValue.copied")
        );
    };

    const handleFieldChange = (key: string, value: string) => {
        setConfigurations({
            ...configurations,
            [key]: value,
        });
        setIsUnsavedChangeAvailable(true);
    };

    const handleFormSubmit = async () => {
        if (!formRef.current?.check()) {
            toaster.push(
                <Message showIcon type="error">
                    {t("tenantConfig.generalConfigurationPage.warning")}
                </Message>,
                { duration: constants.alertDurationMsec }
            );
            return;
        }
        showLoader(true);
        updateTenantConfigurations().then(() => showLoader(false));
        setIsUnsavedChangeAvailable(false);
    };

    return (
        (isFetched && configurations && (
            <div className={"general-configurations__container"}>
                <div className="general-configurations__content">
                    <h1 className={"general-configurations__page-header"}>
                        {t("tenantConfig.generalConfigurationPage.header")}
                    </h1>
                    <h2 className={"general-configurations__page-subheader"}>
                        {t("tenantConfig.generalConfigurationPage.subHeader")}
                    </h2>
                    {configurations.dooapMainUrl && (
                        <h3
                            className={
                                "general-configurations__header-description"
                            }
                        >
                            {t(
                                "tenantConfig.generalConfigurationPage.dooapMain.description"
                            )}
                            <a
                                className={
                                    "general-configurations__header-link"
                                }
                                href={configurations.dooapMainUrl ?? undefined}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                {t(
                                    "tenantConfig.generalConfigurationPage.dooapMain.linkTitle"
                                )}
                            </a>
                        </h3>
                    )}
                    {!isUserPartner && (
                        <h3
                            className={
                                "general-configurations__header-description"
                            }
                        >
                            {t(
                                "tenantConfig.generalConfigurationPage.seqLogs.description"
                            )}
                            <a
                                className={
                                    "general-configurations__header-link"
                                }
                                href={formatString(
                                    constants.seqLogsFilter,
                                    seqLogsLink,
                                    tenantId ?? ""
                                )}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                {t(
                                    "tenantConfig.generalConfigurationPage.seqLogs.linkTitle"
                                )}
                            </a>
                        </h3>
                    )}
                    <div>
                        <Form
                            model={formModel}
                            ref={formRef}
                            formValue={formValue}
                        >
                            <div className={"general-configurations__form"}>
                                <Form.Group
                                    className={
                                        "general-configurations__grid-item"
                                    }
                                >
                                    <Form.ControlLabel
                                        className={
                                            "general-configurations__label"
                                        }
                                    >
                                        {t(
                                            "tenantConfig.generalConfigurationPage.customer.title"
                                        )}
                                        <Form.HelpText
                                            className={
                                                "general-configurations__help"
                                            }
                                            tooltip
                                        >
                                            {t(
                                                "tenantConfig.generalConfigurationPage.customer.description"
                                            )}
                                        </Form.HelpText>
                                    </Form.ControlLabel>
                                    <Form.Control
                                        readOnly
                                        className={cn(
                                            "general-configurations__form-control",
                                            "general-configurations__disabled"
                                        )}
                                        name="customerName"
                                    />
                                </Form.Group>
                                <Form.Group
                                    className={
                                        "general-configurations__grid-item"
                                    }
                                >
                                    <Form.ControlLabel
                                        className={
                                            "general-configurations__label"
                                        }
                                    >
                                        {t(
                                            "tenantConfig.generalConfigurationPage.azureFunctionsURL.title"
                                        )}
                                    </Form.ControlLabel>
                                    <Whisper
                                        trigger="hover"
                                        placement="right"
                                        speaker={
                                            <Tooltip>
                                                {
                                                    configurations.azureFunctionsBaseUrl
                                                }
                                            </Tooltip>
                                        }
                                    >
                                        <Form.Control
                                            readOnly
                                            className={cn(
                                                "general-configurations__form-control",
                                                "general-configurations__disabled"
                                            )}
                                            name="azureFunctionsBaseUrl"
                                        />
                                    </Whisper>
                                </Form.Group>
                                <Form.Group
                                    className={
                                        "general-configurations__grid-item"
                                    }
                                >
                                    <Form.ControlLabel
                                        className={
                                            "general-configurations__label"
                                        }
                                    >
                                        {t(
                                            "tenantConfig.generalConfigurationPage.tenantName.title"
                                        )}
                                        <Form.HelpText
                                            className={
                                                "general-configurations__help"
                                            }
                                            tooltip
                                        >
                                            {t(
                                                "tenantConfig.generalConfigurationPage.tenantName.description"
                                            )}
                                        </Form.HelpText>
                                    </Form.ControlLabel>
                                    <Form.Control
                                        className={
                                            "general-configurations__form-control"
                                        }
                                        name="displayName"
                                        onChange={(value: string) => {
                                            handleFieldChange(
                                                "displayName",
                                                value
                                            );
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group
                                    className={
                                        "general-configurations__grid-item"
                                    }
                                >
                                    <Form.ControlLabel
                                        className={
                                            "general-configurations__label"
                                        }
                                    >
                                        {t(
                                            "tenantConfig.generalConfigurationPage.AzureSearchAPIURL.title"
                                        )}
                                    </Form.ControlLabel>
                                    <Whisper
                                        trigger="hover"
                                        placement="right"
                                        speaker={
                                            <Tooltip>
                                                {
                                                    configurations.azureSearchApiBaseUrl
                                                }
                                            </Tooltip>
                                        }
                                    >
                                        <Form.Control
                                            className={cn(
                                                "general-configurations__form-control",
                                                "general-configurations__disabled"
                                            )}
                                            name="azureSearchApiUrl"
                                            readOnly
                                        />
                                    </Whisper>
                                </Form.Group>
                                <Form.Group
                                    className={
                                        "general-configurations__grid-item"
                                    }
                                >
                                    <Form.ControlLabel
                                        className={
                                            "general-configurations__label"
                                        }
                                    >
                                        {t(
                                            "tenantConfig.generalConfigurationPage.moniker.title"
                                        )}
                                    </Form.ControlLabel>
                                    <Form.Control
                                        className={cn(
                                            "general-configurations__form-control",
                                            "general-configurations__disabled"
                                        )}
                                        name="moniker"
                                        readOnly
                                    />
                                </Form.Group>
                                <Form.Group
                                    className={
                                        "general-configurations__grid-item"
                                    }
                                >
                                    <Form.ControlLabel
                                        className={
                                            "general-configurations__label"
                                        }
                                    >
                                        {t(
                                            "tenantConfig.generalConfigurationPage.tenantIdTitle"
                                        )}
                                    </Form.ControlLabel>
                                    <div
                                        onMouseEnter={() => {
                                            setIsCopyTenantIdButtonShown(true);
                                        }}
                                        onMouseLeave={() => {
                                            setIsCopyTenantIdButtonShown(false);
                                        }}
                                        className="general-configurations__field"
                                    >
                                        <Form.Control
                                            className={cn(
                                                "general-configurations__form-control",
                                                "general-configurations__disabled"
                                            )}
                                            name="tenantId"
                                            readOnly
                                        />
                                        {isCopyTenantIdButtonShown && (
                                            <Whisper
                                                placement="right"
                                                trigger="hover"
                                                speaker={copyFieldValueTooltip}
                                            >
                                                <CopyIcon
                                                    className={
                                                        "general-configurations__copy-icon"
                                                    }
                                                    onClick={() => {
                                                        handleCopyButtonClick(
                                                            configurations.id
                                                        );
                                                    }}
                                                    onMouseLeave={() =>
                                                        setCopyFiledValueMessage(
                                                            t(
                                                                "tenantConfig.generalConfigurationPage.copyFieldValue.copyToClipboard"
                                                            )
                                                        )
                                                    }
                                                />
                                            </Whisper>
                                        )}
                                    </div>
                                </Form.Group>
                                <Form.Group
                                    className={
                                        "general-configurations__grid-item"
                                    }
                                >
                                    <Form.ControlLabel
                                        className={
                                            "general-configurations__label"
                                        }
                                    >
                                        {t(
                                            "tenantConfig.generalConfigurationPage.partnersTitle"
                                        )}
                                    </Form.ControlLabel>
                                    <Whisper
                                        trigger="hover"
                                        placement="right"
                                        speaker={
                                            <Tooltip>
                                                {configurations.partners.join(
                                                    ", "
                                                )}
                                            </Tooltip>
                                        }
                                    >
                                        <Form.Control
                                            className={cn(
                                                "general-configurations__form-control",
                                                "general-configurations__disabled"
                                            )}
                                            name="partners"
                                            readOnly
                                        />
                                    </Whisper>
                                </Form.Group>
                            </div>
                            <div
                                className={
                                    "general-configurations__addition-info-content"
                                }
                            >
                                <div
                                    className={
                                        "general-configurations__addition-info-header"
                                    }
                                >
                                    <Form.ControlLabel>
                                        {t(
                                            "tenantConfig.generalConfigurationPage.additionalInformation.title"
                                        )}
                                    </Form.ControlLabel>
                                </div>
                                <Form.Control
                                    accepter={Textarea}
                                    className={
                                        "general-configurations__addition-info-text-field"
                                    }
                                    rows={3}
                                    placeholder={t(
                                        "tenantConfig.generalConfigurationPage.additionalInformation.placeholder"
                                    )}
                                    onChange={(value: string) => {
                                        handleFieldChange(
                                            "additionalInformation",
                                            value
                                        );
                                    }}
                                    name={"additionalInformation"}
                                />
                            </div>
                        </Form>
                    </div>
                </div>
                <SettingsFooterContainer
                    handleFormSubmit={handleFormSubmit}
                    saveButtonProps={{ type: "submit" }}
                />
            </div>
        )) ||
        null
    );
};

export default GeneralConfigurations;
