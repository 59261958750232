import { IEmailTemplateBase } from "store/deliverables/email-templates/email-templates-types";
import { IEmailRecipient } from "./customer-details/customer-details-types";

export const SET_CUSTOMERS = "customers/SET_CUSTOMERS";
export const SET_ACTIVE_CUSTOMERS_BY_ID =
    "customers/SET_ACTIVE_CUSTOMERS_BY_ID";
export const SORT_CUSTOMERS_BY_NAME =
    "customers/SORT_CUSTOMERS_BY_DISPLAY_NAME";
export const SET_ACTIVE_CUSTOMER_NAME = "customers/SET_ACTIVE_CUSTOMER_NAME";

export interface ICustomer {
    id: string;
    aadIssuerId: string | null;
    adminUserEmail: string | null;
    name: string;
    tenantsAmount: number;
    partners: string[];
    totalStepsNumber: number;
    completedSteps: number;
    consentWizardAppLink: string;
    guestTenantsAmount: number;
    emailTemplate: IEmailTemplateBase | null;
    dooapEnvironments: string[];
}

export interface ICustomerDeliverablesInfo {
    id: string;
    name: string;
    emailTemplate: IEmailTemplateBase | null;
    emailRecipients: IEmailRecipient[];
    dooapEnvironments: string[];
}

export interface ICustomerNameInfo {
    name: string;
    id: string;
}

export enum ConsentStatus {
    InProgress = 0,
    Completed = 1,
}
export interface ISortCustomersByName {
    type: typeof SORT_CUSTOMERS_BY_NAME;
}
export interface ISetCustomers {
    type: typeof SET_CUSTOMERS;
    customers: ICustomer[];
}
export interface ISetActiveCustomerById {
    type: typeof SET_ACTIVE_CUSTOMERS_BY_ID;
    customerId: string;
}

export interface ISetActiveCustomerName {
    type: typeof SET_ACTIVE_CUSTOMER_NAME;
    name: string;
}
export interface State {
    customers: ICustomer[];
    activeCustomer: ICustomer;
}
export type Actions =
    | ISetCustomers
    | ISetActiveCustomerById
    | ISortCustomersByName
    | ISetActiveCustomerName;
