import { IRouterLayouts } from "./types";
import constants from "./constants";
import HomePage from "pages-redirects/HomePage/HomePage";
import EnvironmentsContainer from "components/Environments/EnvironmentsContainer";
import CustomersContainer from "components/Customers/CustomersContainer";
import TenantsContainer from "components/Environments/Tenants/TenantsContainer";
import SchedulerContainer from "components/Environments/Tenants/LeftMenu/Scheduler/SchedulerContainer";
import DopeSubscriptionsContainer from "components/Environments/Tenants/LeftMenu/Subscriptions/SubscriptionsContainer";
import CultureSettingsContainer from "components/Environments/Tenants/LeftMenu/CultureSettings/CultureSettingsContainer";
import DataImportContainer from "components/Environments/Tenants/LeftMenu/DataImport/DataImportContainer";
import GeneralConfigurationsContainer from "components/Environments/Tenants/LeftMenu/GeneralConfigurations/GeneralConfigurationsContainer";
import CustomFieldsContainer from "components/Environments/Tenants/LeftMenu/CustomFields/CustomFieldsContainer";
import CustomerDetailsContainer from "components/Customers/CustomerDetails/CustomerDetailsContainer";
import SignalREnrollTenantContainer from "components/Environments/Tenants/EnrollTenant/SignalREnrollTenant/SignalREnrollTenantContainer";
import GuestTenantsConfigContainer from "components/Environments/Tenants/LeftMenu/GuestTenants/GuestTenantsConfigContainer";
import GuestTenantsEnrollContainer from "components/Environments/Tenants/EnrollTenant/StepFrame/GuestTenants/GuestTenantsEnroll/GuestTenantsEnrollContainer";
import ConfigurationLayoutContainer from "layouts/ConfigurationLayout/ConfigurationLayoutContainer";
import DeletedTenantMessageContainer from "components/Environments/Tenants/DeleteTenant/DeletedTenantMessage/DeletedTenantMessageContainer";
import PartnersContainer from "components/Partners/PartnersContainer";
import PartnerDetailsContainer from "components/Partners/PartnerDetails/PartnerDetailsContainer";
import DeliverablesLayout from "layouts/DeliverablesLayout/DeliverablesLayout";
import ErpPackagesContainer from "components/Deliverables/ErpPackages/ErpPackagesContainer";
import EmailTemplatesContainer from "components/Deliverables/EmailTemplates/EmailTemplatesContainer";
import FeatureConfigurationsContainer from "components/Environments/Tenants/LeftMenu/FeatureConfigurations/FeatureConfigurationsContainer";
import VersionHistoryContainer from "components/Environments/Tenants/LeftMenu/VersionHistory/VersionHistoryContainer";
import DataRefreshHistoryContainer from "components/Environments/Tenants/LeftMenu/DataRefreshHistory/DataRefreshHistoryContainer";
import MaintenanceModeContainer from "components/Environments/Tenants/LeftMenu/MaintenanceMode/MaintenanceModeContainer";
import CaptureMailboxesContainer from "components/Environments/Tenants/LeftMenu/CaptureMailboxes/CaptureMailboxesContainer";
import ErpConfigurationsContainer from "components/Environments/Tenants/LeftMenu/ErpConfigurations/ErpConfigurationsContainer";
import ActivityLogContainer from "components/Environments/Tenants/LeftMenu/ActivityLog/ActivityLogContainer";

const routes: IRouterLayouts = {
    mainLayout: [
        {
            name: "Main Page",
            path: constants.routePath.root,
            componentName: HomePage,
        },
        {
            name: "Customers Page",
            path: constants.routePath.customers,
            componentName: CustomersContainer,
        },
        {
            name: "Environments Page",
            path: constants.routePath.environments,
            componentName: EnvironmentsContainer,
        },
        {
            name: "Partners Page",
            path: constants.routePath.partners,
            componentName: PartnersContainer,
        },
        {
            name: "Partner Details",
            path: constants.routePath.partnerDetails,
            componentName: PartnerDetailsContainer,
        },
        {
            name: "Tenants Page",
            path: constants.routePath.tenants,
            componentName: TenantsContainer,
        },
        {
            name: "Tenant Configs Page",
            path: constants.tenantConfigsRoutes.tenantConfigs,
            componentName: ConfigurationLayoutContainer,
        },
        {
            name: "Tenant Customer Details",
            path: constants.routePath.customerDetails,
            componentName: CustomerDetailsContainer,
        },
        {
            name: "Enroll Tenant Page",
            path: constants.routePath.enrollTenant,
            componentName: SignalREnrollTenantContainer,
        },
        {
            name: "Enroll New Tenant Page",
            path: constants.routePath.enrollNewTenant,
            componentName: SignalREnrollTenantContainer,
        },
        {
            name: "Guest tenants enroll",
            path: constants.routePath.guestTenantsEnroll,
            componentName: GuestTenantsEnrollContainer,
        },
        {
            name: "Deleted tenant",
            path: constants.routePath.deletedTenant,
            componentName: DeletedTenantMessageContainer,
        },
        {
            name: "Deliverables",
            path: constants.deliverablesRoutes.deliverables,
            componentName: DeliverablesLayout,
        },
    ],
    deliverablesLayout: [
        {
            name: "Deliverables - ERP Packages",
            path: constants.deliverablesRoutes.packages,
            componentName: ErpPackagesContainer,
        },
        {
            name: "Deliverables - Email Templates",
            path: constants.deliverablesRoutes.templates,
            componentName: EmailTemplatesContainer,
        },
    ],
    configurationLayout: [
        {
            name: "Tenant Configs Page - General Configurations",
            path: constants.tenantConfigsRoutes.generalConfig,
            componentName: GeneralConfigurationsContainer,
        },
        {
            name: "Tenant Configs Page - Feature Configurations",
            path: constants.tenantConfigsRoutes.featureConfig,
            componentName: FeatureConfigurationsContainer,
        },
        {
            name: "Tenant Configs Page - ERP Configurations",
            path: constants.tenantConfigsRoutes.erpConfig,
            componentName: ErpConfigurationsContainer,
        },
        {
            name: "Tenant Configs Page - Custom Fields",
            path: constants.tenantConfigsRoutes.customFields,
            componentName: CustomFieldsContainer,
        },
        {
            name: "Tenant Configs Page - Scheduler",
            path: constants.tenantConfigsRoutes.scheduler,
            componentName: SchedulerContainer,
        },
        {
            name: "Tenant Configs Page - Culture Settings",
            path: constants.tenantConfigsRoutes.cultureSettings,
            componentName: CultureSettingsContainer,
        },
        {
            name: "Tenant Configs Page - Data Transfer",
            path: constants.tenantConfigsRoutes.dataImport,
            componentName: DataImportContainer,
        },
        {
            name: "Tenant Configs Page - Guest Tenants",
            path: constants.tenantConfigsRoutes.guestTenants,
            componentName: GuestTenantsConfigContainer,
        },
        {
            name: "Tenant Configs Page - DOPE Subscriptions",
            path: constants.tenantConfigsRoutes.dopeSubscriptions,
            componentName: DopeSubscriptionsContainer,
        },
        {
            name: "Tenant Configs Page - Version History",
            path: constants.tenantConfigsRoutes.versionHistory,
            componentName: VersionHistoryContainer,
        },
        {
            name: "Tenant Configs Page - Data Refresh History",
            path: constants.tenantConfigsRoutes.dataRefreshHistory,
            componentName: DataRefreshHistoryContainer,
        },
        {
            name: "Tenant Configs Page - Maintenance Mode",
            path: constants.tenantConfigsRoutes.maintenanceMode,
            componentName: MaintenanceModeContainer,
        },
        {
            name: "Tenant Configs Page - Mailbox Management",
            path: constants.tenantConfigsRoutes.captureMailboxes,
            componentName: CaptureMailboxesContainer,
        },
        {
            name: "Tenant Configs Page - Activity Logs",
            path: constants.tenantConfigsRoutes.activityLog,
            componentName: ActivityLogContainer,
        },
    ],
};

export default routes;
