import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import {
    Button,
    FlexboxGrid,
    Form,
    FormInstance,
    Message,
    Schema,
    Table,
    toaster,
} from "rsuite";
import cn from "classnames";
import useRefreshPage from "hooks/useRefreshPage";
import {
    IPartnerDetails,
    ICustomer,
    IPartnerCustomer,
    IActivePartner,
    IPartner,
} from "store/partners/partners-types";
import {
    loadPartnerDetails,
    updatePartnerDetails,
} from "../../../api/partners/partner-details-api";
import { getErrorMessage } from "api/defaults";
import constants from "../../../utils/constants";
import AddCustomerContainer from "./AddCustomer/AddCustomerContainer";
import ExpandableTable from "../../Common/ExpandableTable/ExpandableTable";
import ActionCell from "../../Common/ExpandableTable/ActionCell";
import Collapsible from "components/Common/Collapsible/Collapsible";
import css from "./PartnerDetails.module.css";
import SettingsFooterContainer from "components/Common/SettingsFooter/SettingsFooterContainer";
const { Column, HeaderCell, Cell } = Table;
import TrashIcon from "@rsuite/icons/Trash";

const defaultPartnerDetails = {
    id: "",
    name: "",
    aadGroupName: "",
    customers: [],
    aadGroupUsers: [],
};

interface ComponentProps {
    isRefreshActivated: boolean;
    fetchPartners: () => Promise<IPartner[] | null>;
    setLoaderVisibility: (isInitialized: boolean) => void;
    setActivePartner: (partner: IActivePartner) => void;
    showError: (errorMessage: string) => void;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
}

const PartnerDetails: React.FC<ComponentProps> = ({
    isRefreshActivated,
    fetchPartners,
    setLoaderVisibility,
    setActivePartner,
    showError,
    setIsUnsavedChangeAvailable,
}: ComponentProps) => {
    const { t } = useTranslation();
    const { partnerId } = useParams<{ partnerId: string }>();
    const formRef = useRef<FormInstance<Record<string, any>> | null>(null);

    const defaultRowHeight = 50;
    const emptyTableHeight = 150;

    const [partnerDetails, setPartnerDetails] = useState<IPartnerDetails>(
        defaultPartnerDetails
    );
    const [partnerNames, setPartnerNames] = useState<string[]>([]);
    const [isAddCustomerOpened, setIsAddCustomerOpened] =
        useState<boolean>(false);
    const [isPartnerDetailsLoaded, setIsPartnerDetailsLoaded] =
        useState<boolean>(false);
    const [isPartnersLoaded, setIsPartnersLoaded] = useState<boolean>(false);
    const [expandableAreaHeight, setExpandableAreaHeight] = useState<number>();

    const fetchPartnerDetails = (partnerId: string) => {
        setLoaderVisibility(true);
        loadPartnerDetails(partnerId).then(([payload, status]) => {
            setLoaderVisibility(false);
            if (payload && status === constants.statusCode.OK) {
                setActivePartner({
                    id: payload.id,
                    name: payload.name,
                });
                setPartnerDetails(payload);
            } else {
                const errorMessage = getErrorMessage(String(payload), status);
                showError(errorMessage);
            }
            setIsUnsavedChangeAvailable(false);
        });
        setIsPartnerDetailsLoaded(true);
    };

    const fetchPartnerNames = () => {
        fetchPartners().then((payload) => {
            if (payload) {
                setPartnerNames(
                    payload
                        .filter((partner) => partner.id !== partnerId)
                        .map((partner) => partner.name)
                );
            }
            setIsUnsavedChangeAvailable(false);
        });
        setIsPartnersLoaded(true);
    };

    useEffect(() => {
        if (
            partnerId &&
            (!isPartnerDetailsLoaded ||
                (partnerDetails.id && partnerDetails.id !== partnerId))
        ) {
            fetchPartnerDetails(partnerId);
        }
    }, [partnerDetails, isPartnerDetailsLoaded, partnerId]);

    useEffect(() => {
        if (!isPartnersLoaded) {
            fetchPartnerNames();
        }
    }, [
        isPartnersLoaded,
        setIsPartnersLoaded,
        setLoaderVisibility,
        fetchPartnerNames,
    ]);

    useRefreshPage(() => {
        fetchPartnerDetails(partnerId!);
        fetchPartnerNames();
        if (formRef && formRef.current) {
            formRef.current.cleanErrors();
        }
    });

    const updatePartner = (
        update: (partnerDetails: IPartnerDetails) => IPartnerDetails
    ) => {
        if (partnerDetails) {
            setPartnerDetails(update(partnerDetails));
        }
    };

    const updatePartnerName = (partnerName: string) => {
        updatePartner((partnerDetails) => ({
            ...partnerDetails,
            name: partnerName,
        }));
    };

    const addPartnerCustomers = (customers: ICustomer[]) => {
        if (customers.length) {
            const newPartnerCustomers = customers.map((customer: ICustomer) => {
                return {
                    ...customer,
                    tenantsAmount: customer.tenants.length,
                    partnerId: partnerDetails.id,
                };
            });
            updatePartner((partnerDetails) => ({
                ...partnerDetails,
                customers: [
                    ...partnerDetails.customers,
                    ...newPartnerCustomers,
                ],
            }));
            setIsUnsavedChangeAvailable(true);
        }
    };

    const handleAddCustomerClick = () => {
        setIsAddCustomerOpened(true);
    };

    const handleRemoveCustomerClick = (rowData: IPartnerCustomer) => {
        const removeItemIndex = partnerDetails.customers.findIndex(
            (item) => item.id === rowData.id
        );
        partnerDetails.customers.splice(removeItemIndex, 1);

        setPartnerDetails({
            ...partnerDetails,
            customers: [...partnerDetails.customers],
        });
        setIsUnsavedChangeAvailable(true);
    };

    const handleSavePartnerDetails = () => {
        if (!formRef.current?.check()) {
            return;
        }
        setLoaderVisibility(true);
        setActivePartner({
            id: partnerDetails.id,
            name: partnerDetails.name,
        });
        updatePartnerDetails(partnerDetails.id, partnerDetails).then(
            ([payload, status]) => {
                setLoaderVisibility(false);
                if (status === constants.statusCode.NoContent) {
                    toaster.push(
                        <Message showIcon type="success">
                            {t("partnerDetails.successfulUpdateAlertMessage")}
                        </Message>,
                        {
                            duration: constants.alertDurationMsec,
                        }
                    );
                } else {
                    const errorMessage = getErrorMessage(
                        String(payload),
                        status
                    );
                    toaster.push(
                        <Message showIcon type="success">
                            {errorMessage}
                        </Message>,
                        {
                            duration: constants.alertDurationMsec,
                        }
                    );
                }
            }
        );
        setIsUnsavedChangeAvailable(false);
    };

    const renderRowExpanded = (rowDate: IPartnerCustomer) => (
        <FlexboxGrid classPrefix={css.tenantsTableContainer}>
            <FlexboxGrid.Item>
                {/* Was decided to use HTML table instead of rsuite component because
                there is the problem with element height calculations */}
                <table>
                    <thead>
                        <tr
                            className={cn(
                                css.tenantsTableRow,
                                css.tenantsTableHeader
                            )}
                        >
                            <th className={css.tableHeaderCell}>
                                {t(
                                    "partnerDetails.tenantsTable.columnNames.tenantName"
                                )}
                            </th>
                            <th className={css.tableHeaderCell}>
                                {t(
                                    "partnerDetails.tenantsTable.columnNames.tenantMoniker"
                                )}
                            </th>
                            <th className={css.tableHeaderCell}>
                                {t(
                                    "partnerDetails.tenantsTable.columnNames.tenantId"
                                )}
                            </th>
                        </tr>
                    </thead>
                    <tbody
                        className={css.tenantsTableBody}
                        style={{
                            height: rowDate.tenants.length
                                ? rowDate.tenants.length * defaultRowHeight
                                : emptyTableHeight,
                        }}
                    >
                        {rowDate.tenants.length !== 0 ? (
                            rowDate.tenants.map((tenant, index) => (
                                <tr className={css.tenantsTableRow} key={index}>
                                    <td>{tenant.name}</td>
                                    <td>{tenant.moniker}</td>
                                    <td>{tenant.id}</td>
                                </tr>
                            ))
                        ) : (
                            <tr className={css.tenantsTableEmptyRow} key={0}>
                                <td>
                                    {t(
                                        "partnerDetails.tenantsTable.emptyTable"
                                    )}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </FlexboxGrid.Item>
        </FlexboxGrid>
    );

    const validationModel = Schema.Model({
        name: Schema.Types.StringType()
            .isRequired(t("generalValidationMessages.isRequired"))
            .minLength(
                constants.minNameLengthLimit,
                t("generalValidationMessages.minLength")
            )
            .maxLength(
                constants.maxPartnerNameLengthLimit,
                t("generalValidationMessages.maxLength500")
            )
            .addRule((value) => {
                return partnerNames.every((name) => name !== value);
            }, t("generalValidationMessages.isUnique")),
    });

    const formValue = {
        name: partnerDetails.name,
        aadGroupName: partnerDetails.aadGroupName,
    };

    const customersTable = (
        <div className={cn(css.tableContainer, css.formElementSpace)}>
            <FlexboxGrid align="bottom" justify="space-between">
                <FlexboxGrid.Item>
                    <div
                        className={cn(
                            css.tableDescriptionContainer,
                            css.customersTableDescriptionContainer
                        )}
                    >
                        {t("partnerDetails.customersTable.title")}
                    </div>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    <Button
                        className={cn(css.generalBtn, css.addCustomerBtn)}
                        onClick={handleAddCustomerClick}
                    >
                        {t("partnerDetails.addCustomerButton")}
                    </Button>
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <ExpandableTable
                rowKey={"id"}
                data={partnerDetails.customers}
                width={992}
                height={48}
                className={css.customersTableContainer}
                autoHeight
                rowHeight={48}
                headerHeight={48}
                renderRowExpanded={renderRowExpanded}
                rowExpandedHeight={expandableAreaHeight}
                isTableCollapsed={isRefreshActivated}
                setExpandableAreaHeight={setExpandableAreaHeight}
            >
                <Column width={480}>
                    <HeaderCell>
                        {t(
                            "partnerDetails.customersTable.columnNames.customer"
                        )}
                    </HeaderCell>
                    <Cell dataKey="name" className={css.rowCell} />
                </Column>
                <Table.Column width={360}>
                    <Table.HeaderCell>
                        {t(
                            "partnerDetails.customersTable.columnNames.numberOfTenants"
                        )}
                    </Table.HeaderCell>
                    <Table.Cell dataKey="tenants" className={css.rowCell}>
                        {(rowData: IPartnerCustomer) => {
                            return rowData.tenants.length;
                        }}
                    </Table.Cell>
                </Table.Column>
                <Table.Column width={100}>
                    <Table.HeaderCell>
                        {t("partnerDetails.customersTable.columnNames.action")}
                    </Table.HeaderCell>
                    <ActionCell
                        icon={<TrashIcon />}
                        onChange={handleRemoveCustomerClick}
                    />
                </Table.Column>
            </ExpandableTable>
        </div>
    );

    const usersTable = (
        <>
            <Table
                rowKey={"userPrincipalName"}
                data={partnerDetails.aadGroupUsers}
                width={992}
                height={48}
                rowHeight={48}
                headerHeight={48}
                autoHeight
                className={css.customersTableContainer}
            >
                <Table.Column width={372}>
                    <Table.HeaderCell>
                        {t("partnerDetails.usersTable.columnNames.userName")}
                    </Table.HeaderCell>
                    <Table.Cell dataKey="displayName" className={css.rowCell} />
                </Table.Column>
                <Table.Column width={620}>
                    <Table.HeaderCell>
                        {t("partnerDetails.usersTable.columnNames.email")}
                    </Table.HeaderCell>
                    <Table.Cell
                        dataKey="userPrincipalName"
                        className={css.rowCell}
                    />
                </Table.Column>
            </Table>
        </>
    );

    const userTableTitle = (
        <div className={css.tableDescriptionContainer}>
            {t("partnerDetails.usersTable.title")}
        </div>
    );

    return partnerDetails !== null ? (
        <div className={css.pageContainer}>
            <div className={css.pageContent}>
                <AddCustomerContainer
                    isOpened={isAddCustomerOpened}
                    setIsOpened={setIsAddCustomerOpened}
                    partnerCustomers={partnerDetails.customers}
                    addCustomers={addPartnerCustomers}
                />
                <h1 className={css.pageTitle}>{t("partners.detailsTitle")}</h1>
                <Form
                    ref={formRef}
                    formValue={formValue}
                    className={css.formContainer}
                    model={validationModel}
                >
                    <Form.Group>
                        <Form.ControlLabel className={css.label}>
                            {t("partners.partnerNameLabel")}
                        </Form.ControlLabel>
                        <Form.Control
                            name={"name"}
                            className={cn(css.formControl, css.input, css.lg)}
                            onChange={(value: string) => {
                                updatePartnerName(value);
                                setIsUnsavedChangeAvailable(true);
                            }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel className={css.label}>
                            {t("partners.partnerAadGroupLabel")}
                        </Form.ControlLabel>
                        <Form.Control
                            name={"aadGroupName"}
                            className={cn(css.formControl, css.input, css.lg)}
                            disabled
                        />
                    </Form.Group>
                    {customersTable}
                    <Collapsible
                        className={css.formElementSpace}
                        title={userTableTitle}
                    >
                        {usersTable}
                    </Collapsible>
                </Form>
            </div>
            <SettingsFooterContainer
                isFullWidth={true}
                handleFormSubmit={handleSavePartnerDetails}
                saveButtonProps={{ type: "submit" }}
            />
        </div>
    ) : null;
};

export default PartnerDetails;
