import Modal from "components/Common/Modal/Modal";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
    SendingStatus,
    IHistoryEntry,
} from "store/deliverables/erp-packages/erp-packages-types";
import { useTranslation } from "react-i18next";
import { Button, Table } from "rsuite";
import { loadPackageSendingHistory } from "api/deliverables/erp-packages-api";
import { getErrorMessage } from "api/defaults";
import constants from "utils/constants";
import PopoverCell from "components/Common/PopoverCell/PopoverCell";
import "./ErpPackageHistoryModal.less";

interface ComponentProps {
    erpPackageId: string;
    isOpened: boolean;
    handleClose: Dispatch<SetStateAction<boolean>>;
}

const ErpPackageHistoryModal: React.FC<ComponentProps> = ({
    erpPackageId,
    isOpened,
    handleClose,
}: ComponentProps) => {
    const { t } = useTranslation();
    const [history, setHistory] = useState<IHistoryEntry[]>([]);
    const [showLoader, setShowLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [sortColumn, setSortColumn] = useState<string>("");
    const [sortType, setSortType] = useState();
    const [tableHeight, setTableHeight] = useState<number>();
    const headerHeight = 40;
    const rowHeight = 46;

    useEffect(() => {
        if (isOpened) {
            setShowLoader(true);
            loadPackageSendingHistory(erpPackageId).then(
                ([data, statusCode]) => {
                    if (statusCode === constants.statusCode.OK && data) {
                        setHistory(
                            data.sort((a, b) =>
                                a.receiver.localeCompare(b.receiver)
                            )
                        );
                    } else {
                        setErrorMessage(
                            getErrorMessage(String(data), statusCode)
                        );
                    }
                    setShowLoader(false);
                }
            );
        }
    }, [isOpened, erpPackageId]);

    useEffect(() => {
        if (history) {
            setTableHeight(headerHeight + rowHeight * history.length);
        }
    }, [history]);

    const handleModalClose = () => {
        handleClose(false);
    };

    const formatDate = (date: string) => {
        const dateObject = new Date(Date.parse(date));

        return (
            ("0" + dateObject.getDate()).slice(-2) +
            "." +
            ("0" + (dateObject.getMonth() + 1)).slice(-2) +
            "." +
            dateObject.getFullYear() +
            ", " +
            ("0" + dateObject.getHours()).slice(-2) +
            ":" +
            ("0" + dateObject.getMinutes()).slice(-2)
        );
    };

    const getHistoryStatusText = (status: SendingStatus) => {
        switch (status) {
            case SendingStatus.NotSent:
                return t(
                    "deliverables.packages.history.table.statusDescription.notSent"
                );
            case SendingStatus.Sent:
                return t(
                    "deliverables.packages.history.table.statusDescription.sent"
                );
            case SendingStatus.Failed:
                return t(
                    "deliverables.packages.history.table.statusDescription.failed"
                );
            default:
                return "";
        }
    };

    const handleSortColumn = (sortColumn: string, sortType: any) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    };

    const getData = () => {
        if (sortColumn && sortType) {
            return history.sort((a, b) => {
                let x = a[sortColumn as keyof IHistoryEntry] || "-";
                let y = b[sortColumn as keyof IHistoryEntry] || "-";
                x = typeof x === "string" ? x.toLowerCase() : x;
                y = typeof y === "string" ? y.toLowerCase() : y;

                return sortType === "asc" ? (x < y ? -1 : 1) : x < y ? 1 : -1;
            });
        }

        return history;
    };

    const header = <h1>{t("deliverables.packages.history.header")}</h1>;
    const footer = (
        <>
            <Button
                className={"erp-package-history-modal__button"}
                type="submit"
                appearance="ghost"
                onClick={handleModalClose}
            >
                {t("deliverables.packages.history.close")}
            </Button>
        </>
    );

    return (
        <>
            <Modal
                className={"erp-package-history-modal__modal"}
                header={header}
                isModalLoaderVisible={showLoader}
                isModalOpen={isOpened}
                onClose={handleModalClose}
                closeButton={true}
                errorMessage={errorMessage}
                footer={footer}
            >
                {history.length ? (
                    <Table
                        shouldUpdateScroll={false}
                        virtualized={true}
                        data={getData()}
                        sortColumn={sortColumn}
                        sortType={sortType}
                        onSortColumn={handleSortColumn}
                        height={tableHeight}
                        headerHeight={headerHeight}
                    >
                        <Table.Column flexGrow={3} sortable>
                            <Table.HeaderCell>
                                {t(
                                    "deliverables.packages.history.table.customer"
                                )}
                            </Table.HeaderCell>
                            <Table.Cell dataKey="receiver">
                                {(rowData: IHistoryEntry) => {
                                    return <div>{rowData.receiver}</div>;
                                }}
                            </Table.Cell>
                        </Table.Column>
                        <Table.Column flexGrow={2} sortable>
                            <Table.HeaderCell>
                                {t(
                                    "deliverables.packages.history.table.sentOn"
                                )}
                            </Table.HeaderCell>
                            <Table.Cell dataKey="timeStamp">
                                {(rowData: IHistoryEntry) => {
                                    return (
                                        <div>
                                            {formatDate(rowData.timeStamp)}
                                        </div>
                                    );
                                }}
                            </Table.Cell>
                        </Table.Column>
                        <Table.Column flexGrow={5} sortable>
                            <Table.HeaderCell>
                                {t(
                                    "deliverables.packages.history.table.sentBy"
                                )}
                            </Table.HeaderCell>
                            <Table.Cell dataKey="sender">
                                {(rowData: IHistoryEntry) => {
                                    return <div>{rowData.sender}</div>;
                                }}
                            </Table.Cell>
                        </Table.Column>
                        <Table.Column flexGrow={1} sortable>
                            <Table.HeaderCell>
                                {t(
                                    "deliverables.packages.history.table.status"
                                )}
                            </Table.HeaderCell>
                            <Table.Cell dataKey="status">
                                {(rowData: IHistoryEntry) => {
                                    return (
                                        <div
                                            className={
                                                rowData.status ===
                                                SendingStatus.Failed
                                                    ? "erp-package-history-modal__failed"
                                                    : ""
                                            }
                                        >
                                            {getHistoryStatusText(
                                                rowData.status
                                            )}
                                        </div>
                                    );
                                }}
                            </Table.Cell>
                        </Table.Column>
                        <Table.Column flexGrow={2}>
                            <Table.HeaderCell>
                                {t(
                                    "deliverables.packages.history.table.reason"
                                )}
                            </Table.HeaderCell>
                            <Table.Cell dataKey="failureReason">
                                {(rowData: IHistoryEntry) => {
                                    return (
                                        <PopoverCell
                                            dataKey="failureReason"
                                            rowData={rowData}
                                        />
                                    );
                                }}
                            </Table.Cell>
                        </Table.Column>
                    </Table>
                ) : (
                    <div
                        className={"erp-package-history-modal__empty-container"}
                    >
                        {t("deliverables.packages.noDataFound")}
                    </div>
                )}
            </Modal>
        </>
    );
};

export default ErpPackageHistoryModal;
