import { connect } from "react-redux";
import { RootState } from "store/store";
import Consents from "./Consents";
import {
    getOrderedCustomerConsents,
    getRegions,
} from "store/customers/add-new-customer/add-new-customer-selector";

const mapStateToProps = (state: RootState) => {
    return {
        consents: getOrderedCustomerConsents(state),
        regions: getRegions(state),
    };
};
const ConsentsContainer = connect(mapStateToProps)(Consents);

export default ConsentsContainer;
