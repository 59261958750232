import { RootState } from "store/store";
import LogoCustomization from "./LogoCustomization";
import { connect } from "react-redux";
import { setIsUnsavedChangeAvailable } from "store/common/unsaved-data/unsaved-data-actions";
import { getActiveEnvironmentMoniker } from "store/environments/environment-selectors";

const mapStateToProps = (state: RootState) => ({
    envMoniker: getActiveEnvironmentMoniker(state),
});
const LogoCustomizationContainer = connect(mapStateToProps, {
    setIsUnsavedChangeAvailable,
})(LogoCustomization);

export default LogoCustomizationContainer;
