import React, { useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import { Button, Table } from "rsuite";

import constants from "utils/constants";
import { formatDateWithNameOfMonth } from "utils/date-helper";
import { getModalTableHeight } from "utils/measurement-helper";

import { getErrorMessage } from "api/defaults";
import { loadMailboxHistory } from "api/environments/tenants/tenant-capture-mailboxes-api";

import { IMailboxHistory } from "store/environments/tenants/tenant-capture-mailboxes/tenant-capture-mailboxes-types";

import ModalContainer from "components/Common/Modal/ModalContainer";
import PopoverCell from "components/Common/PopoverCell/PopoverCell";

import { getComparableHistoryValue } from "../mailbox-helpers";

import "./MailboxHistory.less";

interface ComponentProps {
    envMoniker: string;
    tenantId: string;
    mailboxId: string;
    isOpened: boolean;
    setIsOpened: (isOpened: boolean) => void;
    setModalLoaderVisibility: (visible: boolean) => void;
    setErrorResponseMessage: (message: string) => void;
}

const MailboxHistory: React.FC<ComponentProps> = ({
    envMoniker,
    tenantId,
    mailboxId,
    isOpened,
    setIsOpened,
    setModalLoaderVisibility,
    setErrorResponseMessage,
}: ComponentProps) => {
    const cancelTokenSource = Axios.CancelToken.source();

    const { t } = useTranslation();
    const [sortColumn, setSortColumn] = useState<string>();
    const [sortType, setSortType] = useState();

    const [history, setHistory] = useImmer<IMailboxHistory[]>([]);

    const handleModalClose = () => {
        setIsOpened(false);
        cancelTokenSource.cancel();
    };

    const header = (
        <h1>{t("tenantConfig.captureMailboxes.mailboxHistory.title")}</h1>
    );
    const footer = (
        <>
            <Button
                className={"mailbox-history-modal__button"}
                type="submit"
                appearance="ghost"
                onClick={handleModalClose}
            >
                {t("deliverables.packages.history.close")}
            </Button>
        </>
    );

    const tableHeight = getModalTableHeight(history.length);

    const getMailboxHistory = () => {
        setModalLoaderVisibility(true);
        loadMailboxHistory(envMoniker, tenantId, mailboxId, cancelTokenSource)
            .then(([payload, status]) => {
                if (payload && status === constants.statusCode.OK) {
                    setHistory(payload);
                } else {
                    setErrorResponseMessage(
                        getErrorMessage(String(payload), status)
                    );
                }
            })
            .catch((error: Error) => {
                const axiosError = error;
                if (!Axios.isCancel(error)) {
                    setErrorResponseMessage(axiosError.message);
                }
            })
            .then(() => {
                setModalLoaderVisibility(false);
            });
    };

    const handleSortColumn = (sortColumn: string, sortType: any) => {
        setSortColumn(sortColumn);
        setSortType(sortType);

        setHistory((draft) => {
            if (sortColumn && sortType) {
                draft.sort((a, b) => {
                    const x = getComparableHistoryValue(a, sortColumn);
                    const y = getComparableHistoryValue(b, sortColumn);

                    return sortType === "asc"
                        ? x < y
                            ? -1
                            : 1
                        : x < y
                          ? 1
                          : -1;
                });
            }
        });
    };

    useEffect(() => {
        if (isOpened && envMoniker && tenantId && mailboxId) {
            getMailboxHistory();
        }
    }, [envMoniker, tenantId, mailboxId, isOpened]);

    return (
        <>
            <ModalContainer
                className={"mailbox-history-modal__container"}
                open={isOpened}
                header={header}
                footer={footer}
                onClose={handleModalClose}
            >
                <Table
                    showHeader={true}
                    virtualized={true}
                    data={history}
                    height={tableHeight}
                    sortColumn={sortColumn}
                    sortType={sortType}
                    onSortColumn={handleSortColumn}
                >
                    <Table.Column flexGrow={2} sortable={true}>
                        <Table.HeaderCell>
                            {t(`tenantConfig.captureMailboxes.table.createdBy`)}
                        </Table.HeaderCell>
                        <Table.Cell dataKey="creator">
                            {(rowData: IMailboxHistory) => (
                                <PopoverCell
                                    dataKey="creator"
                                    rowData={rowData}
                                />
                            )}
                        </Table.Cell>
                    </Table.Column>

                    <Table.Column flexGrow={1.5} sortable={true}>
                        <Table.HeaderCell>
                            {t(`tenantConfig.captureMailboxes.table.createdOn`)}
                        </Table.HeaderCell>
                        <Table.Cell dataKey="creationDate">
                            {(rowData: IMailboxHistory) => (
                                <>
                                    {rowData.creationDate ? (
                                        <>
                                            {formatDateWithNameOfMonth(
                                                new Date(rowData.creationDate)
                                            )}
                                        </>
                                    ) : (
                                        <>{t(`unknown`)}</>
                                    )}
                                </>
                            )}
                        </Table.Cell>
                    </Table.Column>

                    <Table.Column flexGrow={2} sortable={true}>
                        <Table.HeaderCell>
                            {t(`tenantConfig.captureMailboxes.table.editedBy`)}
                        </Table.HeaderCell>
                        <Table.Cell dataKey="editor">
                            {(rowData: IMailboxHistory) => (
                                <PopoverCell
                                    dataKey="editor"
                                    rowData={rowData}
                                />
                            )}
                        </Table.Cell>
                    </Table.Column>

                    <Table.Column flexGrow={1.5} sortable={true}>
                        <Table.HeaderCell>
                            {t(`tenantConfig.captureMailboxes.table.editedOn`)}
                        </Table.HeaderCell>
                        <Table.Cell dataKey="editionDate">
                            {(rowData: IMailboxHistory) => (
                                <>
                                    {rowData.editionDate ? (
                                        <>
                                            {formatDateWithNameOfMonth(
                                                new Date(rowData.editionDate)
                                            )}
                                        </>
                                    ) : (
                                        <>{t(`unknown`)}</>
                                    )}
                                </>
                            )}
                        </Table.Cell>
                    </Table.Column>
                </Table>
            </ModalContainer>
        </>
    );
};

export default MailboxHistory;
