import { API } from "../../utils/api-config";
import { defaultErrorResponse } from "../defaults";
import {
    ICustomer,
    INewPartner,
    IPartner,
} from "store/partners/partners-types";

export const loadPartners = async (): Promise<[IPartner[] | null, number]> => {
    const response = await API.get("/partners");

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const loadPartnerGroups = async (): Promise<
    [string[] | null, number]
> => {
    const response = await API.get("/partners/aad-groups");

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const loadUserLinkedPartners = async (): Promise<
    [IPartner[] | null, number]
> => {
    const response = await API.get("/partners/user-linked");

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const addPartner = async (
    partner: INewPartner
): Promise<[null, number]> => {
    const response = await API.post("/partners", partner);

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const deletePartner = async (
    partnerId: string
): Promise<[null, number]> => {
    const response = await API.delete(`/partners/${partnerId}`);

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const loadCustomers = async (): Promise<
    [ICustomer[] | null, number]
> => {
    const response = await API.get("/partners/customers");

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};
