export const SET_MAILBOXES_CREATION_OPENED =
    "CaptureMailboxes/SET_CREATE_MAILBOXES_OPENED";

interface ITableItem {
    editing: boolean | null;
    checked: boolean;
}

export interface IMailbox extends ITableItem {
    id: string;
    name: string;
    address: string;
    company: ICompany | null;
}

export interface ICompany {
    id: string;
    remoteId: string;
}

export interface IMailboxDefault extends IMailbox {
    exists: boolean;
}

export interface IMailboxErrorLog {
    address: string;
    failureReason: string;
}

export interface IMailboxHistory {
    mailboxId: string;
    creator: string;
    creationDate: string;
    editor: string;
    editionDate: string;
}

export enum MailboxCreationType {
    Generic,
    Company,
    All,
}

export interface ValidationErrors {
    [key: string]: {
        name?: string;
        address?: string;
    };
}

export const mailboxFields = {
    name: "name",
    address: "address",
} as const;

export interface ISetMailboxesCreationOpened {
    type: typeof SET_MAILBOXES_CREATION_OPENED;
    isMailboxesCreationOpened: boolean;
}

export interface State {
    isMailboxesCreationOpened: boolean;
    isMailboxHistoryOpened: boolean;
}

export type Actions = ISetMailboxesCreationOpened;
