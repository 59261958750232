import {
    SET_MAILBOXES_CREATION_OPENED,
    Actions,
    State,
} from "./tenant-capture-mailboxes-types";

const initialState = {
    isMailboxesCreationOpened: false,
    isMailboxHistoryOpened: false,
};

const captureMailboxesReducer = (
    state: State = initialState,
    action: Actions
): State => {
    switch (action.type) {
        case SET_MAILBOXES_CREATION_OPENED:
            return {
                ...state,
                isMailboxesCreationOpened: action.isMailboxesCreationOpened,
            };

        default:
            return state;
    }
};
export default captureMailboxesReducer;
