import { RootState } from "store/store";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const useBeforeUnload = (): void => {
    const isUnsavedChangeAvailable = useSelector(
        (state: RootState) => state.unsavedDataReducer.isUnsavedChangeAvailable
    );
    const { t } = useTranslation();

    useEffect(() => {
        onbeforeunload = (e) => {
            if (isUnsavedChangeAvailable) {
                e.preventDefault();
                e.returnValue = t("unsavedChangesConfirmation.message");
                return t("unsavedChangesConfirmation.message");
            }
        };
        return () => {
            onbeforeunload = null;
        };
    }, [isUnsavedChangeAvailable]);
};

export default useBeforeUnload;
