import { RootState } from "store/store";
import { connect } from "react-redux";
import { compose } from "redux";
import { setActiveStepData } from "store/environments/tenants/enroll-tenant/enroll-tenant-actions";
import {
    getActiveStep,
    getEnrollId,
} from "store/environments/tenants/enroll-tenant/enroll-tenant-selectors";
import {
    getActiveEnvironmentMoniker,
    getAppRegistrations,
} from "store/environments/environment-selectors";
import { setIsUnsavedChangeAvailable } from "store/common/unsaved-data/unsaved-data-actions";
import { fetchDooapAppRegistrationsWithoutLoader } from "store/environments/environment-thunks";
import { getCustomerDetails } from "store/customers/customer-details/customer-details-selectors";
import { getCustomerDetailsWithoutLoader as fetchCustomerDetails } from "store/customers/customer-details/customer-details-thunk";
import IntegrationInformation from "./IntegrationInformation";
import withLoader from "hocs/withLoader/withLoader";
import { fetchEnrollCache } from "store/environments/tenants/enroll-tenant/enroll-tenant-thunks";

const mapStateToProps = (state: RootState) => ({
    activeStep: getActiveStep(state),
    environmentMoniker: getActiveEnvironmentMoniker(state),
    appRegistrations: getAppRegistrations(state),
    customerDetails: getCustomerDetails(state),
    enrollId: getEnrollId(state),
});

const IntegrationInformationContainer = compose(
    connect(mapStateToProps, {
        setActiveStepData,
        setIsUnsavedChangeAvailable,
        fetchEnrollCache,
        fetchDooapAppRegistrations: fetchDooapAppRegistrationsWithoutLoader,
        fetchCustomerDetails,
    }),
    withLoader
)(IntegrationInformation);

export default IntegrationInformationContainer;
