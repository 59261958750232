import { connect } from "react-redux";
import { RootState } from "store/store";

import { fetchEnvironments } from "store/environments/environment-thunks";
import { fetchTenants } from "store/environments/tenants/tenants-thunks";
import { fetchAppSettings } from "store/app-settings/app-settings-thunks";

import { getActiveEnvironmentMoniker } from "store/environments/environment-selectors";
import { getActiveTenantDeleteWorkflow } from "store/environments/tenants/tenant-delete/tenant-delete-selectors";
import { getAppSettings } from "store/app-settings/app-settings-selectors";
import { getTenantCustomerType } from "store/environments/tenants/tenants-selectors";

import LeftMenu from "./LeftMenu";

const mapStateToProps = (state: RootState) => {
    return {
        envMoniker: getActiveEnvironmentMoniker(state),
        tenantMoniker: state.tenantsReducer.activeTenant.moniker,
        activeTenant: state.tenantsReducer.activeTenant,
        customerType: getTenantCustomerType(state),
        activeTenantDeleteWorkflow: getActiveTenantDeleteWorkflow(state),
        appSettings: getAppSettings(state),
    };
};
const LeftMenuContainer = connect(mapStateToProps, {
    fetchEnvironments,
    fetchTenants,
    fetchAppSettings,
})(LeftMenu);

export default LeftMenuContainer;
