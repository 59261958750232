import { connect } from "react-redux";

import { RootState } from "store/store";
import { getActiveEnvironmentMoniker } from "store/environments/environment-selectors";
import { setModalLoaderVisibility } from "store/common/modal-window/modal-window-actions";
import { setErrorResponseMessage } from "store/common/initialization/initialization-actions";

import MailboxHistory from "./MailboxHistory";

const mapStateToProps = (state: RootState) => ({
    envMoniker: getActiveEnvironmentMoniker(state),
    tenantId: state.tenantsReducer.activeTenant.versionInfo.id,
});

const MailboxHistoryContainer = connect(mapStateToProps, {
    setModalLoaderVisibility,
    setErrorResponseMessage,
})(MailboxHistory);

export default MailboxHistoryContainer;
