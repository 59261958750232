import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import Axios from "axios";
import { useTranslation } from "react-i18next";
import { TableInstance } from "rsuite/Table";
import { IconButton, Loader, Message, Table, toaster } from "rsuite";

import SaveIcon from "@rsuite/icons/Save";
import EditIcon from "@rsuite/icons/Edit";
import HistoryIcon from "@rsuite/icons/History";
import GlobalIcon from "@rsuite/icons/Global";

import constants from "utils/constants";
import {
    ITenantFeatureSettingWorkFlow,
    TenantFeatureSettingWorkFlowType,
    WorkflowStatus,
} from "utils/tenant-workflows/tenant-workflows-types";

import {
    IMailbox,
    ValidationErrors,
    IMailboxDefault,
    mailboxFields,
} from "store/environments/tenants/tenant-capture-mailboxes/tenant-capture-mailboxes-types";

import { getErrorMessage } from "api/defaults";
import { getTenantFeatureSettingWorkFlow } from "api/environments/tenants/tenant-feature-settings-api";
import {
    loadTenantCaptureMailboxes,
    updateTenantCaptureMailboxes,
} from "api/environments/tenants/tenant-capture-mailboxes-api";

import useRequestCancellation from "hooks/useRequestCancellation";
import useRefreshPage from "hooks/useRefreshPage";
import usePreviousState from "hooks/usePreviousState";

import SignalRTenantFeatureSetting from "components/WorkflowLog/TenantFeature/SignalRTenantFeatureSetting";
import PopoverCell from "components/Common/PopoverCell/PopoverCell";
import SettingsFooterContainer from "components/Common//SettingsFooter/SettingsFooterContainer";

import MailboxesCreationContainer from "./MailboxesCreation/MailboxesCreationContainer";
import MailboxHistoryContainer from "./MailboxHistory/MailboxHistoryContainer";
import MailboxesErrorLogsContainer from "./MailboxesErrorLogs/MailboxesErrorLogsContainer";
import EditableCell from "./EditableCell";

import { getComparableMilboxValue } from "./mailbox-helpers";

import "./CaptureMailboxes.less";

interface ComponentProps {
    envMoniker: string;
    tenantMoniker: string;
    showLoader: (isVisible: boolean) => void;
    showError: (errorMessage: string) => void;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
}

const CaptureMailboxes: React.FC<ComponentProps> = ({
    envMoniker,
    tenantMoniker,
    showLoader,
    showError,
    setIsUnsavedChangeAvailable,
}: ComponentProps) => {
    const { t } = useTranslation();
    const containerRef = useRef<HTMLDivElement>(null);
    const tableBodyRef = useRef<TableInstance<any, any> | null>(null);
    const cancelTokenSource = useRequestCancellation();

    const params: Record<string, string> = useParams<{ tenantId: string }>();
    const { tenantId } = params;

    const [sortColumn, setSortColumn] = useState<string>();
    const [sortType, setSortType] = useState();

    const [mailboxes, setMailboxes] = useImmer<IMailboxDefault[]>([]);
    const [updatedMailboxes, setUpdatedMailboxes] = useImmer<IMailboxDefault[]>(
        []
    );
    const [selectedMailbox, setSelectedMailbox] =
        useState<IMailboxDefault | null>();

    const [isDataFetched, setIsDataFetched] = useState<boolean>(false);
    const [previousSettingWorkFlow, settingWorkFlow, setSettingWorkFlow] =
        usePreviousState<ITenantFeatureSettingWorkFlow | null>(null);
    const [mailboxesCreationInProgress, setMailboxesCreationInProgress] =
        useState(false);

    const [isErrorLogOpened, setErrorLogOpened] = useState<boolean>(false);
    const [isMailboxHistoryOpened, setIsMailboxHistoryOpened] =
        useState<boolean>(false);

    const [validationErrors, setValidationErrors] = useImmer<ValidationErrors>(
        {}
    );

    const fetchData = () => {
        showLoader(true);
        Promise.all([
            getTenantFeatureSettingWorkFlow(
                envMoniker,
                tenantMoniker,
                TenantFeatureSettingWorkFlowType.CaptureMailboxesCreation,
                cancelTokenSource
            )
                .then(([workFlows]) => {
                    setSettingWorkFlow(
                        workFlows?.find(
                            (workflow) =>
                                workflow.type ===
                                TenantFeatureSettingWorkFlowType.CaptureMailboxesCreation
                        ) ?? null
                    );
                })
                .catch((error: Error) => {
                    const axiosError = error;
                    if (!Axios.isCancel(axiosError)) {
                        showError(error.message);
                    }
                }),

            loadTenantCaptureMailboxes(
                envMoniker,
                tenantMoniker,
                cancelTokenSource
            )
                .then(([payload, status]) => {
                    if (payload && status === constants.statusCode.OK) {
                        setMailboxes(
                            payload.map(
                                (p) =>
                                    ({ ...p, exists: true }) as IMailboxDefault
                            )
                        );
                    } else {
                        showError(getErrorMessage(String(payload), status));
                    }
                })
                .catch((error: Error) => {
                    const axiosError = error;
                    if (!Axios.isCancel(axiosError)) {
                        showError(error.message);
                    }
                }),
        ]).then(() => {
            showLoader(false);
        });
        setIsDataFetched(true);
    };

    const isSaveIconDisabled = (rowData: IMailboxDefault) =>
        !!rowData.editing &&
        (!!validationErrors[rowData.id]?.[mailboxFields.name] ||
            !!validationErrors[rowData.id]?.[mailboxFields.address]);

    const handleEdit = (editingMailbox: IMailbox) => {
        setMailboxes((draft) => {
            const mailbox = draft.find((mb) => mb.id == editingMailbox.id);
            if (mailbox) {
                mailbox.editing = true;
            }
        });
    };

    const handleSave = (savedMailbox: IMailboxDefault) => {
        setUpdatedMailboxes((draft) => {
            draft.push({ ...savedMailbox });
        });

        setMailboxes((draft) => {
            const mailbox = draft.find((mb) => mb.id == savedMailbox.id);
            if (mailbox) {
                mailbox.editing = !mailbox.editing;
            }
        });
    };

    const handleAction = (mailbox: IMailboxDefault) => {
        if (mailbox.editing) {
            handleSave(mailbox);
        } else {
            handleEdit(mailbox);
        }
    };

    const handleOpenHistory = (mailbox: IMailboxDefault) => {
        setSelectedMailbox(mailbox);
        setIsMailboxHistoryOpened(true);
    };

    const handleSubmit = () => {
        if (mailboxes && envMoniker && tenantMoniker) {
            if (
                updatedMailboxes.some(
                    (mailbox) =>
                        validationErrors[mailbox.id] &&
                        (validationErrors[mailbox.id].name ||
                            validationErrors[mailbox.id].address)
                )
            ) {
                return;
            }

            showLoader(true);
            updateTenantCaptureMailboxes(
                envMoniker,
                tenantId,
                updatedMailboxes
            ).then(([payload, status]) => {
                showLoader(false);
                setIsUnsavedChangeAvailable(false);
                setUpdatedMailboxes([]);

                if (status === constants.statusCode.OK) {
                    toaster.push(
                        <Message showIcon type="success">
                            {t(
                                "tenantConfig.common.successfulUpdateAlertMessage"
                            )}
                        </Message>,
                        { duration: constants.alertDurationMsec }
                    );

                    showLoader(true);
                    loadTenantCaptureMailboxes(
                        envMoniker,
                        tenantMoniker,
                        cancelTokenSource
                    )
                        .then(([payload, status]) => {
                            if (payload && status === constants.statusCode.OK) {
                                setMailboxes(
                                    payload.map(
                                        (p) =>
                                            ({
                                                ...p,
                                                exists: true,
                                            }) as IMailboxDefault
                                    )
                                );

                                showLoader(false);
                            } else {
                                showError(
                                    getErrorMessage(String(payload), status)
                                );
                            }
                        })
                        .catch((error: Error) => {
                            const axiosError = error;
                            if (!Axios.isCancel(axiosError)) {
                                showError(error.message);
                            }
                        });
                } else {
                    showError(getErrorMessage(String(payload), status));
                }
            });
        }
    };

    const handleSortColumn = (sortColumn: string, sortType: any) => {
        setSortColumn(sortColumn);
        setSortType(sortType);

        setMailboxes((draft) => {
            if (sortColumn && sortType) {
                draft.sort((a, b) => {
                    const x = getComparableMilboxValue(a, sortColumn);
                    const y = getComparableMilboxValue(b, sortColumn);

                    return sortType === "asc"
                        ? x < y
                            ? -1
                            : 1
                        : x < y
                          ? 1
                          : -1;
                });
            }
        });
    };

    useEffect(() => {
        if (envMoniker && tenantMoniker && !isDataFetched) {
            fetchData();
        }
    }, [envMoniker, tenantMoniker, isDataFetched]);

    useEffect(() => {
        if (settingWorkFlow) {
            setMailboxesCreationInProgress(
                settingWorkFlow.status === WorkflowStatus.Running
            );
        }
    }, [settingWorkFlow]);

    useEffect(() => {
        if (
            previousSettingWorkFlow &&
            settingWorkFlow &&
            previousSettingWorkFlow.status === WorkflowStatus.Running &&
            settingWorkFlow.status === WorkflowStatus.Success
        ) {
            fetchData();
        }
    }, [previousSettingWorkFlow, settingWorkFlow]);

    useEffect(() => {
        if (updatedMailboxes.length > 0) {
            setIsUnsavedChangeAvailable(true);
        } else {
            setIsUnsavedChangeAvailable(false);
        }
    }, [updatedMailboxes]);

    useRefreshPage(() => {
        setIsDataFetched(false);
    });

    return (
        isDataFetched && (
            <div ref={containerRef} className="capture-mailboxes__container">
                <SignalRTenantFeatureSetting
                    tenantId={tenantId}
                    updateTenantFeatureSettingWorkFlows={(
                        settingWorkflow: ITenantFeatureSettingWorkFlow
                    ) => {
                        setSettingWorkFlow(settingWorkflow);
                    }}
                >
                    <h1>{t("tenantConfig.captureMailboxes.header")}</h1>

                    <Message
                        className="capture-mailboxes__message"
                        showIcon
                        type="warning"
                    >
                        {t("tenantConfig.captureMailboxes.message")}
                    </Message>
                    <div className="capture-mailboxes__top-section">
                        <Loader
                            content={t(
                                `tenantConfig.captureMailboxes.loadingTitle`
                            )}
                            speed="slow"
                            size="sm"
                            className={
                                !mailboxesCreationInProgress
                                    ? "capture-mailboxes__top-section--hidden"
                                    : ""
                            }
                        />
                        {settingWorkFlow && (
                            <a
                                onClick={() => {
                                    setErrorLogOpened(true);
                                }}
                                target="_blank"
                                rel="noreferrer noopener"
                                className="capture-mailboxes__error-log"
                            >
                                {t(
                                    `tenantConfig.captureMailboxes.errorLogsTitle`
                                )}
                            </a>
                        )}
                    </div>

                    <MailboxesCreationContainer />
                    <MailboxesErrorLogsContainer
                        isOpened={isErrorLogOpened}
                        setIsOpened={setErrorLogOpened}
                    />
                    <MailboxHistoryContainer
                        isOpened={isMailboxHistoryOpened}
                        setIsOpened={setIsMailboxHistoryOpened}
                        mailboxId={selectedMailbox?.id ?? ""}
                    />
                    <div className="capture-mailboxes__table__container">
                        <Table
                            ref={tableBodyRef}
                            data={mailboxes}
                            sortColumn={sortColumn}
                            sortType={sortType}
                            onSortColumn={handleSortColumn}
                            shouldUpdateScroll={false}
                            fillHeight={true}
                        >
                            <Table.Column
                                flexGrow={3}
                                minWidth={200}
                                fixed
                                sortable
                            >
                                <Table.HeaderCell>
                                    {t(
                                        `tenantConfig.captureMailboxes.table.name`
                                    )}
                                </Table.HeaderCell>
                                <Table.Cell dataKey={mailboxFields.name}>
                                    {(rowData: IMailboxDefault) => (
                                        <EditableCell
                                            mailboxes={mailboxes}
                                            dataKey={mailboxFields.name}
                                            data={rowData}
                                            tableBodyRef={tableBodyRef}
                                            validationErrors={validationErrors}
                                            setMailboxes={setMailboxes}
                                            setValidationErrors={
                                                setValidationErrors
                                            }
                                        />
                                    )}
                                </Table.Cell>
                            </Table.Column>

                            <Table.Column flexGrow={3} minWidth={200} sortable>
                                <Table.HeaderCell>
                                    {t(
                                        `tenantConfig.captureMailboxes.table.address`
                                    )}
                                </Table.HeaderCell>
                                <Table.Cell dataKey={mailboxFields.address}>
                                    {(rowData: IMailboxDefault) => (
                                        <EditableCell
                                            mailboxes={mailboxes}
                                            dataKey={mailboxFields.address}
                                            data={rowData}
                                            tableBodyRef={tableBodyRef}
                                            validationErrors={validationErrors}
                                            setMailboxes={setMailboxes}
                                            setValidationErrors={
                                                setValidationErrors
                                            }
                                        />
                                    )}
                                </Table.Cell>
                            </Table.Column>

                            <Table.Column flexGrow={2} minWidth={200} sortable>
                                <Table.HeaderCell>
                                    {t(
                                        `tenantConfig.captureMailboxes.table.companyAssignment`
                                    )}
                                </Table.HeaderCell>
                                <Table.Cell dataKey="remoteId">
                                    {(rowData: IMailboxDefault) => (
                                        <>
                                            {rowData.company?.remoteId ? (
                                                <PopoverCell
                                                    dataKey="remoteId"
                                                    rowData={rowData.company}
                                                />
                                            ) : (
                                                <>{t(`unknown`)}</>
                                            )}
                                        </>
                                    )}
                                </Table.Cell>
                            </Table.Column>

                            <Table.Column flexGrow={0.5} minWidth={80}>
                                <Table.HeaderCell>
                                    {t(
                                        `tenantConfig.captureMailboxes.table.hyperlink`
                                    )}
                                </Table.HeaderCell>
                                <Table.Cell dataKey="hyperlink">
                                    {(rowData: IMailbox) => (
                                        <a
                                            href={`${constants.mailboxUrl}${rowData.address}`}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            className="list__link"
                                        >
                                            <GlobalIcon />
                                        </a>
                                    )}
                                </Table.Cell>
                            </Table.Column>

                            <Table.Column flexGrow={0.5} minWidth={80}>
                                <Table.HeaderCell>
                                    {t(
                                        `tenantConfig.captureMailboxes.mailboxCreation.action`
                                    )}
                                </Table.HeaderCell>
                                <Table.Cell>
                                    {(rowData: IMailboxDefault) => (
                                        <IconButton
                                            appearance="subtle"
                                            size="sm"
                                            onClick={() => {
                                                handleAction(rowData);
                                            }}
                                            disabled={isSaveIconDisabled(
                                                rowData
                                            )}
                                            icon={
                                                rowData.editing ? (
                                                    <SaveIcon />
                                                ) : (
                                                    <EditIcon />
                                                )
                                            }
                                        />
                                    )}
                                </Table.Cell>
                            </Table.Column>

                            <Table.Column flexGrow={0.5} minWidth={80}>
                                <Table.HeaderCell>
                                    {t(
                                        `tenantConfig.captureMailboxes.table.history`
                                    )}
                                </Table.HeaderCell>
                                <Table.Cell>
                                    {(rowData: IMailboxDefault) => (
                                        <IconButton
                                            appearance="subtle"
                                            size="sm"
                                            onClick={() => {
                                                handleOpenHistory(rowData);
                                            }}
                                            icon={<HistoryIcon />}
                                        />
                                    )}
                                </Table.Cell>
                            </Table.Column>
                        </Table>
                    </div>
                    <SettingsFooterContainer handleFormSubmit={handleSubmit} />
                </SignalRTenantFeatureSetting>
            </div>
        )
    );
};
export default CaptureMailboxes;
