import React from "react";
import css from "./DeleteTenantPage.module.css";
import { useTranslation } from "react-i18next";
import { DeletionType } from "store/environments/tenants/tenants-types";

interface IComponentProps {
    deletionType: DeletionType;
}
const DeletingTenantPage: React.FC<IComponentProps> = ({
    deletionType,
}: IComponentProps) => {
    const { t } = useTranslation();
    return (
        <div
            className={
                deletionType === DeletionType.EnrollDeletion
                    ? css.deletingEnroll
                    : css.deletingTenant
            }
        >
            <span>{t("tenantDelete.deletingTenant")}</span>
        </div>
    );
};

export default DeletingTenantPage;
