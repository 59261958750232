import { AppThunk } from "../store";
import { setLoaderVisibility } from "../common/initialization/initialization-actions";
import constants from "utils/constants";
import { loadSupportContact } from "api/contacts";
import { setSupportContact } from "./contacts-actions";

export const fetchSupportContact = (): AppThunk => async (dispatch) => {
    dispatch(setLoaderVisibility(true));

    const [payload, status] = await loadSupportContact();

    if (payload && status === constants.statusCode.OK) {
        dispatch(setSupportContact(payload));
    }

    dispatch(setLoaderVisibility(false));
};
