import React from "react";
import { Message } from "rsuite";
import css from "./ErrorSection.module.css";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

interface ComponentProps {
    errorMessage: string;
    helpMessage?: string;
    isTitleVisible?: boolean;
}
const ErrorSection: React.FC<ComponentProps> = ({
    errorMessage,
    helpMessage,
    isTitleVisible = true,
}: ComponentProps) => {
    const { t } = useTranslation();
    return (
        <div className={css.pageContainer}>
            {isTitleVisible && (
                <h2 className={css.pageHeader}>{t("error.header")}</h2>
            )}
            <Message showIcon type="error" title={t("error.reason")}>
                {parse(errorMessage)}
            </Message>
            <Message
                className={css.marginTop}
                showIcon
                type="info"
                title={t("error.possibleFix")}
            >
                {helpMessage ?? t("error.refreshFix")}
            </Message>
        </div>
    );
};

export default ErrorSection;
