import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Form,
    FormInstance,
    InputPicker,
    Message,
    Schema,
    Table,
    toaster,
} from "rsuite";
import TrashIcon from "@rsuite/icons/Trash";
import "./Subscriptions.less";
import {
    ITenantDopeSubscription,
    ITenantDopeSubscriptionDisplay,
} from "./tenant-dope-subscriptions-types";
import useRefreshPage from "../../../../../hooks/useRefreshPage";
import { loadAllDopeSubscriptions } from "api/environments/environments-api";
import {
    checkFiles,
    loadTenantDopeSubscriptions,
    saveDopeSubscriptions,
} from "api/environments/tenants/tenant-subscriptions-api";
import constants from "utils/constants";
import { getErrorMessage } from "api/defaults";
import { cloneDeep, isEqual } from "lodash";
import useInactiveTenant from "hooks/useInactiveTenant";
import useInactiveEnvironment from "hooks/useInactiveEnvironment";
import ErrorSectionContainer from "components/Common/ErrorSection/ErrorSectionContainer";
import Axios from "axios";
import useRequestCancellation from "hooks/useRequestCancellation";
import SettingsFooterContainer from "components/Common/SettingsFooter/SettingsFooterContainer";

interface ComponentProps {
    envMoniker: string;
    tenantMoniker: string;
    errorMessage: string;
    showLoader: (isVisible: boolean) => void;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
    setErrorResponseMessage: (msg: string) => void;
}

const DopeSubscriptions: React.FC<ComponentProps> = ({
    envMoniker,
    tenantMoniker,
    errorMessage,
    showLoader,
    setIsUnsavedChangeAvailable,
    setErrorResponseMessage,
}: ComponentProps) => {
    const { t } = useTranslation();
    const cancelTokenSource = useRequestCancellation();
    const formRef = useRef<FormInstance<Record<string, any>> | null>(null);

    const [tenantSubscriptions, setTenantSubscriptions] = useState<
        ITenantDopeSubscription[]
    >([]);
    const [dropdownSubscriptions, setDropdownSubscriptions] = useState<
        ITenantDopeSubscription[]
    >([]);
    const [originalTenantSubscriptions, setOriginalTenantSubscriptions] =
        useState<ITenantDopeSubscription[]>([]);
    const [isFetched, setIsFetched] = useState<boolean>();
    const [isCheckInProgress, setIsCheckInProgress] = useState<boolean>();
    const [dropdownDopeSubscriptions, setDropdownDopeSubscriptions] = useState<
        Record<string, any>[]
    >([]);
    const [selectedSubscription, setSelectedSubscription] =
        useState<ITenantDopeSubscription | null>(null);
    const [isSubscriptionAlreadyAdded, setIsSubscriptionAlreadyAdded] =
        useState<boolean>();
    const [isSubscriptionTemplateReady, setIsSubscriptionTemplateReady] =
        useState<boolean>();

    const emptyTableData: ITenantDopeSubscriptionDisplay[] = [
        {
            id: null,
            rpcExtensionPath: "",
            pluginName: "",
            displayPath: t("tenantConfig.dopeSubscriptions.table.emptyData"),
        },
    ];

    useEffect(() => {
        if (envMoniker && tenantMoniker && !isFetched) {
            loadData();
        }
    }, [envMoniker, tenantMoniker]);

    useRefreshPage(() => {
        if (tenantMoniker && envMoniker) {
            loadData();
            setSelectedSubscription(null);
            formRef?.current?.cleanErrors();
        }
    });

    useEffect(() => {
        if (!dropdownSubscriptions) {
            return;
        }
        setDropdownDopeSubscriptions(
            dropdownSubscriptions.map((subscription) => ({
                label:
                    subscription.rpcExtensionPath +
                    "/" +
                    subscription.pluginName,
                value: subscription,
            }))
        );
    }, [dropdownSubscriptions]);

    useEffect(() => {
        setIsSubscriptionAlreadyAdded(
            !selectedSubscription ||
                tenantSubscriptions.some(
                    (sub) =>
                        sub.rpcExtensionPath ===
                            selectedSubscription.rpcExtensionPath &&
                        sub.pluginName === selectedSubscription.pluginName
                )
        );
    }, [selectedSubscription, tenantSubscriptions]);

    useEffect(() => {
        checkHasUnsavedChanges();
    }, [tenantSubscriptions, originalTenantSubscriptions]);

    function loadData() {
        setErrorResponseMessage("");
        showLoader(true);
        const promises: any[] = [];
        promises.push(getDropdownItems(), getTenantSubscriptions());
        Promise.all(promises).then(() => {
            showLoader(false);
            setIsFetched(true);
        });
    }

    useInactiveTenant();
    useInactiveEnvironment();

    const getDropdownItems = () => {
        loadAllDopeSubscriptions(envMoniker, cancelTokenSource)
            .then(([payload, status]) => {
                if (payload && status === constants.statusCode.OK) {
                    setDropdownSubscriptions(payload);
                } else {
                    const errorMessage = getErrorMessage(
                        String(payload),
                        status
                    );
                    setErrorResponseMessage(errorMessage);
                }
            })
            .catch((error: Error) => {
                const axiosError = error;
                if (!Axios.isCancel(axiosError)) {
                    setErrorResponseMessage(error.message);
                }
            });
    };

    const getTenantSubscriptions = () => {
        loadTenantDopeSubscriptions(
            envMoniker,
            tenantMoniker,
            cancelTokenSource
        )
            .then(([payload, status]) => {
                if (payload && status === constants.statusCode.OK) {
                    setTenantSubscriptions(payload);
                    setOriginalTenantSubscriptions(cloneDeep(payload));
                } else {
                    const errorMessage = getErrorMessage(
                        String(payload),
                        status
                    );
                    setErrorResponseMessage(errorMessage);
                }
            })
            .catch((error: Error) => {
                const axiosError = error;
                if (!Axios.isCancel(axiosError)) {
                    setErrorResponseMessage(error.message);
                }
            });
    };

    async function saveTenantDopeSubscription() {
        const status = await saveDopeSubscriptions(
            envMoniker,
            tenantMoniker,
            tenantSubscriptions
        );
        setSelectedSubscription(null);
        formRef?.current?.cleanErrors();
        return status;
    }

    function checkHasUnsavedChanges() {
        const hasChanges = !isEqual(
            tenantSubscriptions,
            originalTenantSubscriptions
        );
        setIsUnsavedChangeAvailable(hasChanges);
    }

    const handleAddLink = () => {
        if (selectedSubscription) {
            const sameSubscriptionFromMemory = originalTenantSubscriptions.find(
                (subscription) =>
                    subscription.pluginName ===
                        selectedSubscription.pluginName &&
                    subscription.rpcExtensionPath ===
                        selectedSubscription.rpcExtensionPath
            );
            setTenantSubscriptions([
                ...tenantSubscriptions,
                sameSubscriptionFromMemory ?? selectedSubscription,
            ]);
            setSelectedSubscription(null);
            formRef?.current?.cleanErrors();
        }
    };

    const handleSubscriptionSelection = (
        value: ITenantDopeSubscription | null
    ) => {
        formRef?.current?.cleanErrors();
        setSelectedSubscription(value);
        setIsSubscriptionTemplateReady(true);
    };

    const handleRemoveLink = (subscription: ITenantDopeSubscriptionDisplay) => {
        if (subscription) {
            setTenantSubscriptions(
                tenantSubscriptions.filter(
                    (sub) =>
                        sub.rpcExtensionPath !==
                            subscription.rpcExtensionPath ||
                        sub.pluginName !== subscription.pluginName
                )
            );
        }
    };

    const handleSubmit = async () => {
        showLoader(true);
        saveTenantDopeSubscription().then((status) => {
            if (
                status === constants.statusCode.OK ||
                status === constants.statusCode.NoContent
            ) {
                toaster.push(
                    <Message showIcon type="success" closable>
                        {t("tenantConfig.common.successfulUpdateAlertMessage")}
                    </Message>,
                    {
                        duration: constants.alertDurationMsec,
                    }
                );
            } else {
                toaster.push(
                    <Message showIcon type="error" closable>
                        {t("tenantConfig.dopeSubscriptions.errors.saveError")}
                    </Message>,
                    {
                        duration: constants.alertDurationMsec,
                    }
                );
            }
            loadData();
            setIsUnsavedChangeAvailable(false);
        });
    };

    function checkSubscriptionTemplateAsync(
        enteredValue: ITenantDopeSubscription
    ) {
        return new Promise<boolean>((resolve) => {
            if (enteredValue) {
                setIsCheckInProgress(true);
                checkFiles(envMoniker, tenantMoniker, enteredValue).then(
                    ([result, status]) => {
                        if (status === constants.statusCode.OK) {
                            setIsSubscriptionTemplateReady(result);
                            setIsCheckInProgress(false);
                            resolve(result);
                        } else {
                            setIsSubscriptionTemplateReady(false);
                            setIsCheckInProgress(false);
                            toaster.push(
                                <Message showIcon type="error" closable>
                                    {t(
                                        "tenantConfig.dopeSubscriptions.errors.fileCheckError"
                                    )}
                                </Message>,
                                {
                                    duration: constants.alertDurationMsec,
                                }
                            );
                            resolve(false);
                        }
                    }
                );
            }
        });
    }

    const model = Schema.Model({
        linkDropdown: Schema.Types.ObjectType()
            .addRule((enteredValue: any) => {
                return !tenantSubscriptions.some(
                    (sub) =>
                        sub?.rpcExtensionPath ===
                            enteredValue?.rpcExtensionPath &&
                        sub?.pluginName === enteredValue?.pluginName
                );
            }, t("tenantConfig.dopeSubscriptions.errors.alreadyActivated"))
            .addAsyncRule((enteredValue: any) => {
                return checkSubscriptionTemplateAsync(enteredValue);
            }, t("tenantConfig.dopeSubscriptions.errors.templatesMissing")),
    });

    const isTableDataPresent =
        tenantSubscriptions && tenantSubscriptions?.length > 0;
    const tableData = tenantSubscriptions.map((subscription) => {
        const row: ITenantDopeSubscriptionDisplay = {
            id: subscription.id,
            pluginName: subscription.pluginName,
            rpcExtensionPath: subscription.rpcExtensionPath,
            displayPath:
                subscription.rpcExtensionPath + "/" + subscription.pluginName,
        };
        return row;
    });

    if (errorMessage.length > 0) {
        return <ErrorSectionContainer />;
    }

    return (
        <div className="dopeSubscriptions_container">
            <h1 className="dopeSubscriptions_pageHeader">
                {t("tenantConfig.dopeSubscriptions.header")}
            </h1>

            <Form ref={formRef} className="default__form" model={model}>
                <Form.Group className="default__form-group">
                    <Form.ControlLabel className="dope_hook_selection_header">
                        {t("tenantConfig.dopeSubscriptions.hooks.title")}
                    </Form.ControlLabel>
                    <Form.Control
                        checkAsync={true}
                        accepter={InputPicker}
                        disabled={isCheckInProgress}
                        data={dropdownDopeSubscriptions}
                        name={"linkDropdown"}
                        value={selectedSubscription}
                        placeholder={t(
                            "tenantConfig.dopeSubscriptions.hooks.dropdownPlaceholder"
                        )}
                        cleanable={true}
                        className="formControl dope_dropdown_picker"
                        onChange={handleSubscriptionSelection}
                    />
                    <Button
                        type="submit"
                        appearance="ghost"
                        className="dope_add_button"
                        onClick={handleAddLink}
                        disabled={
                            !isSubscriptionTemplateReady ||
                            isSubscriptionAlreadyAdded ||
                            isCheckInProgress
                        }
                    >
                        {t("tenantConfig.dopeSubscriptions.addButton")}
                    </Button>
                </Form.Group>
            </Form>
            <Table
                autoHeight
                data={isTableDataPresent ? tableData : emptyTableData}
                cellBordered={false}
                width={900}
                className="dopeTable"
            >
                <Table.Column width={800}>
                    <Table.HeaderCell className="dope_table_header">
                        {t("tenantConfig.dopeSubscriptions.table.hookName")}
                    </Table.HeaderCell>
                    <Table.Cell
                        dataKey="displayPath"
                        className={
                            isTableDataPresent ? "" : "defaultDopeTableData"
                        }
                    />
                </Table.Column>

                <Table.Column width={100}>
                    <Table.HeaderCell>
                        {t("tenantConfig.dopeSubscriptions.table.action")}
                    </Table.HeaderCell>
                    <Table.Cell
                        className={
                            isTableDataPresent
                                ? ""
                                : "defaultDopeTableData_delete_button"
                        }
                    >
                        {(subscription: ITenantDopeSubscriptionDisplay) => (
                            <div className={"dope_table_cell"}>
                                <TrashIcon
                                    key={
                                        "delete" +
                                        String(subscription.rpcExtensionPath)
                                    }
                                    className="delete-icon"
                                    onClick={() =>
                                        handleRemoveLink(subscription)
                                    }
                                />
                            </div>
                        )}
                    </Table.Cell>
                </Table.Column>
            </Table>
            <SettingsFooterContainer handleFormSubmit={handleSubmit} />
        </div>
    );
};

export default DopeSubscriptions;
