import { API } from "../utils/api-config";
import { TAppSettings } from "store/app-settings/app-settings-types";
import { defaultErrorResponse } from "./defaults";

export const loadAppSettings = async (): Promise<TAppSettings> => {
    const response = await API.get(`/app-settings`);

    if (!response) {
        return defaultErrorResponse;
    }

    return [response?.data, response?.status];
};
