import {
    deleteEmailTemplate,
    loadEmailTemplates,
    updateEmailTemplate,
} from "api/deliverables/email-templates-api";
import React, { useEffect, useRef, useState } from "react";
import { getErrorMessage } from "api/defaults";
import {
    IEmailTemplate,
    IEmailTemplateDetails,
} from "store/deliverables/email-templates/email-templates-types";
import constants from "utils/constants";
import css from "./EmailTemplates.module.css";
import {
    ButtonToolbar,
    Form,
    IconButton,
    Message,
    Tooltip,
    toaster,
    Whisper,
} from "rsuite";
import EllipsisLabel from "components/Common/EllipsisLabel/EllipsisLabel";
import ErrorSectionContainer from "components/Common/ErrorSection/ErrorSectionContainer";
import useRefreshPage from "hooks/useRefreshPage";
import { useTranslation } from "react-i18next";
import ManageEmailTemplateContainer from "./ManageEmailTemplate/ManageEmailTemplateContainer";
import ConfirmModalContainer from "components/Common/ConfirmModal/ConfirmModalContainer";
import { template } from "lodash";
import EditIcon from "@rsuite/icons/Edit";
import TrashIcon from "@rsuite/icons/Trash";

interface ComponentProps {
    errorMessage: string;
    showLoader: (isVisible: boolean) => void;
    setErrorResponseMessage: (msg: string) => void;
    setManageTemplateOpened: (isOpen: boolean) => void;
    setManageTemplateDetails: (templateDetails: IEmailTemplateDetails) => void;
}

const EmailTemplates: React.FC<ComponentProps> = ({
    errorMessage,
    showLoader,
    setErrorResponseMessage,
    setManageTemplateOpened,
    setManageTemplateDetails,
}: ComponentProps) => {
    const [emailTemplates, setEmailTemplates] = useState<IEmailTemplate[]>([]);
    const [isEmailTemplatesLoaded, setIsEmailTemplatesLoaded] =
        useState<boolean>(false);
    const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
    const [currentTemplate, setCurrentTemplate] = useState<IEmailTemplate>();
    const containerRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    const fetchEmailTemplates = () => {
        setErrorResponseMessage("");
        showLoader(true);
        loadEmailTemplates().then(([payload, status]) => {
            showLoader(false);
            if (payload && status === constants.statusCode.OK) {
                setEmailTemplates(payload);
            } else {
                setErrorResponseMessage(
                    getErrorMessage(String(payload), status)
                );
            }
        });
        setIsEmailTemplatesLoaded(true);
    };

    const handleCancel = () => {
        setDeleteConfirmVisible(false);
    };

    const handleConfirm = async () => {
        if (currentTemplate) {
            showLoader(true);
            deleteEmailTemplate(currentTemplate.id).then(
                ([payload, status]) => {
                    showLoader(false);
                    setIsEmailTemplatesLoaded(false);
                    if (status === constants.statusCode.OK) {
                        toaster.push(
                            <Message showIcon type="success">
                                {template(
                                    t(
                                        "deliverables.emailTemplates.deleteSuccessMessage"
                                    )
                                )({
                                    templateName: currentTemplate.name,
                                })}
                            </Message>,
                            {
                                duration: constants.errorAlertDurationMsec,
                            }
                        );
                    } else {
                        const errorMessage = getErrorMessage(
                            String(payload),
                            status
                        );
                        toaster.push(
                            <Message type="success" showIcon>
                                {errorMessage}
                            </Message>,
                            {
                                duration: constants.errorAlertDurationMsec,
                            }
                        );
                    }
                }
            );
        }
        setDeleteConfirmVisible(false);
    };

    useRefreshPage(() => {
        containerRef.current?.scrollTo(0, 0);
        fetchEmailTemplates();
    });

    useEffect(() => {
        if (!isEmailTemplatesLoaded) {
            fetchEmailTemplates();
        }
    }, [isEmailTemplatesLoaded, fetchEmailTemplates]);

    const tableRows =
        emailTemplates &&
        emailTemplates.length &&
        emailTemplates.map((item, index) => {
            return (
                <tr
                    className={css.tableRowTemplates}
                    key={String("rowIndex_" + index)}
                >
                    <td className={css.cellTemplates}>
                        <EllipsisLabel>{item.name}</EllipsisLabel>
                    </td>
                    <td className={css.cellTemplates}>{item.locale}</td>
                    <td className={css.cellTemplates}>
                        <ButtonToolbar block>
                            <Whisper
                                trigger={"hover"}
                                placement={"top"}
                                delay={constants.defaultDelay}
                                speaker={
                                    <Tooltip>
                                        {t(
                                            "deliverables.emailTemplates.table.tooltip.edit"
                                        )}
                                    </Tooltip>
                                }
                            >
                                <IconButton
                                    icon={
                                        <EditIcon
                                            onClick={() => {
                                                setManageTemplateOpened(true);
                                                setManageTemplateDetails({
                                                    title: t(
                                                        "deliverables.emailTemplates.modal.editTitle"
                                                    ),
                                                    confirmButtonName: t(
                                                        "deliverables.emailTemplates.modal.save"
                                                    ),
                                                    actionName: t(
                                                        "deliverables.emailTemplates.updatedAction"
                                                    ),
                                                    emailTemplate: item,
                                                    apiMethod:
                                                        updateEmailTemplate,
                                                });
                                            }}
                                        ></EditIcon>
                                    }
                                />
                            </Whisper>
                            <Whisper
                                trigger={"hover"}
                                placement={"top"}
                                delay={constants.defaultDelay}
                                speaker={
                                    <Tooltip>
                                        {t(
                                            "deliverables.emailTemplates.table.tooltip.delete"
                                        )}
                                    </Tooltip>
                                }
                            >
                                <IconButton
                                    icon={
                                        <TrashIcon
                                            onClick={() => {
                                                setDeleteConfirmVisible(true);
                                                setCurrentTemplate(item);
                                            }}
                                        ></TrashIcon>
                                    }
                                />
                            </Whisper>
                        </ButtonToolbar>
                    </td>
                </tr>
            );
        });

    if (errorMessage.length > 0) {
        return (
            <div className={css.error}>
                <ErrorSectionContainer />
            </div>
        );
    }
    return (
        <>
            <ConfirmModalContainer
                handleCancelModal={handleCancel}
                confirmCallbacks={[handleConfirm]}
                isVisible={deleteConfirmVisible}
                message={template(
                    t("deliverables.emailTemplates.confirmationMessage")
                )({ templateName: currentTemplate?.name })}
            />
            <ManageEmailTemplateContainer
                refreshTemplates={fetchEmailTemplates}
                existingTemplates={emailTemplates}
            />
            {isEmailTemplatesLoaded && (
                <div className={css.container}>
                    <div className={css.emailTemplatesContent}>
                        <h3>{t("deliverables.emailTemplates.title")}</h3>
                        <table className={css.templatesTable}>
                            <thead>
                                <tr className={css.tableHeaderTemplates}>
                                    <th className={css.cellTemplates}>
                                        {t(
                                            "deliverables.emailTemplates.table.header.name"
                                        )}
                                        <Form.HelpText
                                            tooltip
                                            className={css.help}
                                        >
                                            {t(
                                                "deliverables.emailTemplates.table.tooltip.name"
                                            )}
                                        </Form.HelpText>
                                    </th>
                                    <th className={css.cellTemplates}>
                                        {t(
                                            "deliverables.emailTemplates.table.header.locale"
                                        )}
                                        <Form.HelpText
                                            tooltip
                                            className={css.help}
                                        >
                                            {t(
                                                "deliverables.emailTemplates.table.tooltip.locale"
                                            )}
                                        </Form.HelpText>
                                    </th>
                                    <th className={css.cellTemplates}>
                                        {t(
                                            "deliverables.emailTemplates.table.header.action"
                                        )}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{tableRows || []}</tbody>
                        </table>
                    </div>
                </div>
            )}
        </>
    );
};

export default EmailTemplates;
