import { RootState } from "store/store";
import { connect } from "react-redux";
import { flowRight } from "lodash";
import {
    fetchEnrollWorkflow,
    startEnroll,
    approveEnrollStep,
    retryEnrollStep,
    continueEnrollStep,
    terminateEnroll,
} from "store/environments/tenants/enroll-tenant/enroll-tenant-thunks";
import {
    clearSecretActiveStepData,
    clearWorkflowData,
    setActiveStep,
} from "store/environments/tenants/enroll-tenant/enroll-tenant-actions";
import {
    setErrorEnrollManualStepResponseMessage,
    setErrorMessageEnrollStep,
    setErrorMessageInactiveTenant,
} from "store/common/initialization/initialization-actions";

import {
    getActiveStep,
    getEnrollSteps,
    getRunningStepIndex,
    getEnrollWorkflow,
    getEnrollCache,
} from "store/environments/tenants/enroll-tenant/enroll-tenant-selectors";
import { setOrchestratorInstanceId } from "store/environments/tenants/enroll-tenant/enroll-tenant-actions";
import { fetchEnvironments } from "store/environments/environment-thunks";
import EnrollTenant from "./EnrollTenant";
import {
    setIsUnsavedChangeAvailable,
    setModalConfirmCallback,
} from "store/common/unsaved-data/unsaved-data-actions";
import {
    getErrorResponseMessage,
    getErrorEnrollManualStepResponseMessage,
} from "store/common/initialization/initialization-selectors";
import withScroll from "hocs/withScroll/withScroll";
import { setTenantDeleteWorkflow } from "store/environments/tenants/tenant-delete/tenant-delete-actions";
import { updateTenantChangeEntityFrameworkSettings } from "store/environments/tenants/tenant-feature-settings/tenant-feature-settings-thunks";
import { setRefreshActivated } from "store/common/page-refresh/page-refresh-actions";
import { updateTenantAxImportEntities } from "store/environments/tenants/data-import/data-import-thunks";

const mapStateToProps = (state: RootState) => ({
    enrollSteps: getEnrollSteps(state),
    runningStepIndex: getRunningStepIndex(state),
    activeStep: getActiveStep(state),
    enrollCache: getEnrollCache(state),
    environmentMoniker: state.environmentsReducer.activeEnvironment.moniker,
    orchestratorInstanceId: state.enrollTenantReducer.orchestratorInstanceId,
    enrollWorkflow: getEnrollWorkflow(state),
    errorMessage: getErrorResponseMessage(state),
    errorEnrollManualStepResponseMessage:
        getErrorEnrollManualStepResponseMessage(state),
    isUnsavedChangeAvailable: state.unsavedDataReducer.isUnsavedChangeAvailable,
});

const Container = connect(mapStateToProps, {
    fetchEnrollWorkflow,
    setOrchestratorInstanceId,
    startEnroll,
    setActiveStep,
    approveEnrollStep,
    fetchEnvironments,
    setIsUnsavedChangeAvailable,
    clearWorkflowData,
    retryEnrollStep,
    continueEnrollStep,
    setErrorMessageIEnrollStep: setErrorMessageEnrollStep,
    setErrorEnrollManualStepResponseMessage,
    clearSecretActiveStepData,
    terminateEnroll,
    setTenantDeleteWorkflow,
    updateTenantAxImportEntities,
    updateTenantChangeEntityFrameworkSettings,
    setModalConfirmCallback,
    setRefreshActivated,
    setErrorMessageInactiveTenant,
})(EnrollTenant);

const EnrollTenantContainer = flowRight(withScroll)(Container);

export default EnrollTenantContainer;
