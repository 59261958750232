import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IEnvironment } from "store/environments/environment-types";
import classNames from "classnames";
import { Panel } from "rsuite";
import "./EnvironmentCard.less";

interface ComponentProps {
    environment: IEnvironment;
}
const EnvironmentCard: React.FC<ComponentProps> = ({
    environment,
}: ComponentProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const navigateTo = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        navigate(`/environments/${environment.name}/tenants`);
    };

    const tenants =
        (environment.tenantsStatusInfo &&
            environment.tenantsStatusInfo.length) ||
        0;

    const errors =
        (tenants &&
            environment.tenantsStatusInfo.filter((t) => t.status === 2)
                .length) ||
        0;

    const getReadableStatus = () => {
        let status = "Error";

        switch (environment.status) {
            case 0:
                status = "Normal";
                break;
            case 1:
                status = "Warning";
                break;
            default:
                break;
        }

        return status;
    };

    const getCSSClass = () => {
        let elementClass = "card__status-rectangle--error";

        switch (environment.status) {
            case 0:
                elementClass = "card__status-rectangle--normal";
                break;
            case 1:
                elementClass = "card__status-rectangle--warning";
                break;
            default:
                break;
        }

        return elementClass;
    };

    return (
        <Panel
            onClick={navigateTo}
            bordered={true}
            shaded={true}
            bodyFill={true}
            className={classNames("card", "environment-card")}
        >
            <div className="card_body">
                <div className={getCSSClass()} />
                <div className="environment-card__layout">
                    <h2 className="environment-card__header-title">
                        {environment.name}
                    </h2>

                    <span>{t("environment.environmentCard.status") + ":"}</span>
                    <span>{getReadableStatus()}</span>

                    <span>
                        {t("environment.environmentCard.enrollPerm") + ":"}
                    </span>

                    <span className="environment-card__note">
                        {String(environment.isEnrollAllowed)}
                    </span>

                    <span>
                        {t("environment.environmentCard.deletePerm") + ":"}
                    </span>

                    <span className="environment-card__note">
                        {String(environment.isDeleteAllowed)}
                    </span>

                    <span className="environment-card__tenants-section">
                        {t("environment.environmentCard.tenants") + ":"}
                    </span>
                    <span className="environment-card__tenants-section">
                        {tenants}
                    </span>

                    <span>{t("status.error") + ":"}</span>
                    <span>{errors}</span>
                </div>
            </div>
        </Panel>
    );
};

export default EnvironmentCard;
