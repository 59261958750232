import React from "react";
import { Progress } from "rsuite";
import { IEnrollStep } from "store/environments/tenants/enroll-tenant/enroll-tenant-types";
import styles from "./ProgressBar.module.css";
import { useTranslation } from "react-i18next";
import { template } from "lodash";

interface IComponentProps {
    enrollSteps: IEnrollStep[] | null;
    runningStepIndex: number;
}

const ProgressBar: React.FC<IComponentProps> = ({
    runningStepIndex,
    enrollSteps,
}: IComponentProps) => {
    const { t } = useTranslation();

    const calculatePercentOfCompletedSteps = (
        currentStep: number,
        numberOfSteps: number
    ) => {
        return (currentStep / numberOfSteps) * 100;
    };

    return (
        enrollSteps && (
            <div className={styles.progressContainer}>
                <div className={styles.progressTitle}>
                    {template(t("enrollTenant.progress.progressString"))({
                        numberOfSteps: runningStepIndex + 1,
                        totalNumberofSteps: enrollSteps.length,
                    })}
                </div>
                <Progress.Line
                    percent={calculatePercentOfCompletedSteps(
                        runningStepIndex + 1,
                        enrollSteps.length
                    )}
                    showInfo={false}
                    strokeColor="#424242"
                />
            </div>
        )
    );
};

export default ProgressBar;
