import { RootState } from "store/store";
import { connect } from "react-redux";
import { getActiveEnvironmentMoniker } from "store/environments/environment-selectors";
import TenantDeleteProgressModalFooter from "./TenantDeleteProgressModalFooter";

const mapStateToProps = (state: RootState) => ({
    environmentMoniker: getActiveEnvironmentMoniker(state),
    tenantMoniker:
        state.tenantsReducer.activeTenant.moniker ||
        state.enrollTenantReducer.enrollCache?.tenantMoniker ||
        "",
});
const TenantDeleteProgressModalFooterContainer = connect(mapStateToProps)(
    TenantDeleteProgressModalFooter
);

export default TenantDeleteProgressModalFooterContainer;
