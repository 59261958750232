import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useRef,
    useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Form, FormInstance, InputPicker, Schema } from "rsuite";
import cn from "classnames";
import { v4 as uuid, validate } from "uuid";
import {
    ICheckRightsStepApproveData,
    IEnrollStep,
    StepStatus,
} from "store/environments/tenants/enroll-tenant/enroll-tenant-types";
import { ICustomer } from "store/customers/customers-types";
import useRefreshPage from "hooks/useRefreshPage";
import constants from "utils/constants";
import styles from "./SelectCustomer.module.css";

type Customer = {
    id: string;
    name: string;
    isAadIssuerNull: boolean;
    isAllConsentsAccepted: boolean;
};

type SelectCustomerFormValue = {
    tenantName: string;
    tenantId: string;
    customer: Customer;
} & typeof defaultSelectCustomerFormValue;

const defaultSelectCustomerFormValue = {
    tenantName: "",
    tenantId: "",
    customer: {
        id: "",
        name: "",
        isAadIssuerNull: false,
        isAllConsentsAccepted: false,
    },
};

interface IComponentProps {
    activeStep: IEnrollStep | null;
    customers: ICustomer[];
    orchestratorInstanceId: string;
    reservedTenantNames: string[];
    activeEnvMoniker: string;
    setIsStepFormValid: Dispatch<SetStateAction<() => boolean>>;
    setActiveStepData: (data: string | null) => void;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
    fetchCustomers: () => Promise<void>;
    fetchTenants: (activeEnvMoniker: string) => Promise<void>;
    showLoader: (isVisible: boolean) => void;
}
const SelectCustomer: React.FC<IComponentProps> = ({
    activeStep,
    customers,
    orchestratorInstanceId,
    reservedTenantNames,
    activeEnvMoniker,
    setIsStepFormValid,
    setActiveStepData,
    setIsUnsavedChangeAvailable,
    fetchCustomers,
    fetchTenants,
    showLoader,
}: IComponentProps) => {
    const { t } = useTranslation();
    const formRef = useRef<FormInstance<Record<string, any>> | null>(null);
    const [customerPickerData, setCustomerPickerData] = useState<
        Record<string, any>[]
    >([]);

    // generate new default Id value each time
    defaultSelectCustomerFormValue.tenantId = uuid();

    const [selectCustomerFormValue, setSelectCustomerFormValue] =
        useState<SelectCustomerFormValue>(defaultSelectCustomerFormValue);
    const [isFormDisabled, setIsFormDisabled] = useState(false);
    const [
        lastManuallyChangedPropertyNames,
        setLastManuallyChangedPropertyNames,
    ] = useState<string[]>([]);

    const fetchData = (activeEnvMoniker: string) => {
        showLoader(true);
        const promises: any[] = [];

        if (activeEnvMoniker) {
            promises.push(fetchTenants(activeEnvMoniker));
        }
        promises.push(fetchCustomers());

        Promise.all(promises).then(() => {
            showLoader(false);
        });
    };

    const handleCustomerNameChange = (value: Customer) => {
        if (value) {
            const tenantNameResult = value.name;
            setSelectCustomerFormValue({
                ...selectCustomerFormValue,
                tenantName: tenantNameResult,
                customer: {
                    id: value.id,
                    name: value.name,
                    isAadIssuerNull: value.isAadIssuerNull,
                    isAllConsentsAccepted: value.isAllConsentsAccepted,
                },
            });
            setActiveStepData(
                JSON.stringify({
                    id: selectCustomerFormValue.tenantId,
                    name: tenantNameResult,
                    customer: {
                        id: value.id,
                        name: value.name,
                    },
                })
            );

            setLastManuallyChangedPropertyNames([
                ...lastManuallyChangedPropertyNames,
                "tenantName",
            ]);
        }
    };

    const handleCleanCustomer = () => {
        setLastManuallyChangedPropertyNames([
            ...lastManuallyChangedPropertyNames,
            "customer",
        ]);
        handleFormChange({
            ...selectCustomerFormValue,
            customer: defaultSelectCustomerFormValue.customer,
        });
    };

    const handleFormChange = (value: any) => {
        const newValue = value as SelectCustomerFormValue;
        if (newValue) {
            setSelectCustomerFormValue({
                ...newValue,
            });
            setActiveStepData(
                JSON.stringify({
                    id: newValue.tenantId,
                    name: newValue.tenantName,
                    customer: {
                        id: newValue.customer?.id,
                        name: newValue.customer?.name,
                    },
                })
            );
            setIsUnsavedChangeAvailable(true);
        }
    };

    useRefreshPage(() => {
        if (orchestratorInstanceId) {
            if (activeStep?.status !== StepStatus.Success) {
                setSelectCustomerFormValue(defaultSelectCustomerFormValue);
            }
            if (formRef && formRef.current) {
                formRef.current.cleanErrors();
            }

            fetchCustomers().then(() => {
                showLoader(false);
            });
        }
    });

    useEffect(() => {
        setIsStepFormValid(() => () => formRef?.current?.check() || false);
    }, [setIsStepFormValid, formRef]);

    useEffect(() => {
        if (activeStep?.data && activeStep.status !== StepStatus.Success) {
            lastManuallyChangedPropertyNames.forEach((propertyName) => {
                formRef?.current?.checkForField(propertyName);
            });
            setLastManuallyChangedPropertyNames([]);
        }
    }, [formRef, activeStep?.data]);

    useEffect(() => {
        if (activeStep) {
            try {
                const stepData = JSON.parse(
                    activeStep.data ?? ""
                ) as ICheckRightsStepApproveData;

                if (activeStep?.status === StepStatus.Success) {
                    setIsFormDisabled(true);
                }

                if (stepData) {
                    setSelectCustomerFormValue({
                        tenantName: stepData.name,
                        tenantId: stepData.id,
                        customer: {
                            name: stepData.customer.name,
                            id: stepData.customer.id,
                            isAadIssuerNull: true,
                            isAllConsentsAccepted: true,
                        },
                    });
                    setCustomerPickerData([
                        {
                            label: stepData.customer.name,
                            value: {
                                id: stepData.customer.id,
                                name: stepData.customer.name,
                                isAadIssuerNull: true,
                                isAllConsentsAccepted: true,
                            },
                        },
                    ]);
                }
            } catch {
                return;
            }
        }
    }, []);

    useEffect(() => {
        if (activeStep?.status === StepStatus.Running) {
            fetchData(activeEnvMoniker);
        }
    }, [activeStep?.status, activeEnvMoniker]);

    useEffect(() => {
        if (activeStep?.status === StepStatus.Running) {
            setCustomerPickerData(
                customers.map((customer) => ({
                    label: customer.name,
                    value: {
                        id: customer.id,
                        name: customer.name,
                        isAadIssuerNull: Boolean(customer.aadIssuerId),
                        isAllConsentsAccepted:
                            customer.totalStepsNumber ===
                            customer.completedSteps,
                    },
                }))
            );
        }
    }, [customers]);

    const model = Schema.Model({
        tenantName: Schema.Types.StringType()
            .isRequired(
                t("enrollTenant.stepFormCommon.validationMessages.isRequired")
            )
            .addRule((enteredValue: string) => {
                const tenantIndex = reservedTenantNames.findIndex(
                    (tenantName) => tenantName === enteredValue
                );

                return tenantIndex === constants.defaultIndex;
            }, t("enrollTenant.stepFormCommon.validationMessages.nameAlreadyExist")),
        tenantId: Schema.Types.StringType()
            .isRequired(
                t("enrollTenant.stepFormCommon.validationMessages.isRequired")
            )
            .addRule(
                (enteredValue: string) => validate(enteredValue),
                t(
                    "enrollTenant.stepFormCommon.validationMessages.isCorrectGuid"
                )
            ),
        customer: Schema.Types.ObjectType()
            .addRule((value) => {
                /**
                 * Compares selected object with default value. If same so consider that object is empty and not selected.
                 * As, reason 'isRequired' does not work with ObjectType, because Schema.model has the empty instance.
                 */
                return value !== defaultSelectCustomerFormValue.customer;
            }, t("enrollTenant.stepFormCommon.validationMessages.isRequired"))
            .addRule((customer) => {
                return customer?.isAllConsentsAccepted;
            }, t("enrollTenant.stepFormCommon.validationMessages.isConsentWizardCompleted"))
            .addRule((customer) => {
                return customer?.isAadIssuerNull;
            }, t("enrollTenant.stepFormCommon.validationMessages.isAadIssuerNull")),
    });

    return (
        activeStep && (
            <Form
                ref={formRef}
                formValue={selectCustomerFormValue}
                model={model}
                onChange={(value) => handleFormChange(value)}
            >
                <Form.Group>
                    <Form.ControlLabel className={styles.label}>
                        {t("enrollTenant.checkRights.customerTitle")}
                    </Form.ControlLabel>
                    <Form.Control
                        disabled={isFormDisabled}
                        accepter={InputPicker}
                        data={customerPickerData}
                        name={"customer"}
                        placeholder={t(
                            "enrollTenant.checkRights.customerPlaceholder"
                        )}
                        className={cn(
                            styles.formControl,
                            styles.enrollInputPicker
                        )}
                        onChange={(value: any) => {
                            handleCustomerNameChange(value);
                        }}
                        onClean={() => {
                            handleCleanCustomer();
                        }}
                    />
                </Form.Group>
                <Form.Group className={styles.tenantNameSection}>
                    <Form.ControlLabel className={styles.label}>
                        {t("enrollTenant.checkRights.tenantNameTitle")}
                    </Form.ControlLabel>
                    <Form.Control
                        disabled={isFormDisabled}
                        name={"tenantName"}
                        placeholder={t(
                            "enrollTenant.checkRights.tenantNamePlaceholder"
                        )}
                        className={styles.formControl}
                    />
                </Form.Group>
                <Form.Group className={styles.tenantNameSection}>
                    <Form.ControlLabel className={styles.label}>
                        {t("enrollTenant.checkRights.tenantIdTitle")}
                    </Form.ControlLabel>
                    <Form.Control
                        disabled={isFormDisabled}
                        name={"tenantId"}
                        placeholder={t(
                            "enrollTenant.checkRights.tenantIdPlaceholder"
                        )}
                        className={styles.formControl}
                    />
                </Form.Group>
            </Form>
        )
    );
};

export default SelectCustomer;
