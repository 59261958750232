import React, { useEffect, useRef, useState } from "react";
import css from "./CultureSettings.module.css";
import { InputPicker, Form, toaster, Message, FormInstance } from "rsuite";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { Schema } from "rsuite";
import {
    ICultureSettings,
    ICurrency,
} from "store/environments/tenants/tenant-culture-settings/tenant-culture-settings-types";
import useRefreshPage from "hooks/useRefreshPage";
import constants from "utils/constants";
import ErrorSectionContainer from "components/Common/ErrorSection/ErrorSectionContainer";
import useRequestCancellation from "hooks/useRequestCancellation";
import Axios, { CancelTokenSource } from "axios";
import SettingsFooterContainer from "components/Common/SettingsFooter/SettingsFooterContainer";
const { StringType, NumberType } = Schema.Types;

interface ComponentProps {
    errorMessage: string;
    culture: string | null;
    numberAndDateCulture: string | null;
    preciseScale: number;
    defaultCurrency: string | null;
    environmentMoniker: string | null;
    tenantMoniker: string | null;
    currencies: ICurrency[];
    supportedCultures: string[];
    cultures: string[];
    showLoader: (isVisible: boolean) => void;
    setCulture: (culture: string) => void;
    setNumberAndDateCulture: (numberAndDateCulture: string) => void;
    setPreciseScale: (preciseScale: number) => void;
    setDefaultCurrency: (defaultCurrency: string) => void;
    getCultureSettings: (
        environmentMoniker: string,
        tenantMoniker: string,
        cancelTokenSource: CancelTokenSource
    ) => Promise<void>;
    updateCultureSettings: (
        environmentMoniker: string,
        tenantMoniker: string,
        cultureSettings: ICultureSettings
    ) => Promise<void>;
    getCurrencies: (
        environmentMoniker: string,
        tenantMoniker: string,
        cancelTokenSource: CancelTokenSource
    ) => Promise<void>;
    getSupportedCultures: (
        environmentMoniker: string,
        tenantMoniker: string,
        cancelTokenSource: CancelTokenSource
    ) => Promise<void>;
    getCultures: (
        environmentMoniker: string,
        tenantMoniker: string,
        cancelTokenSource: CancelTokenSource
    ) => Promise<void>;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
    isCultureSettingsSaved: boolean;
    setCultureSettingsSaved: (isSaved: boolean) => void;
    setErrorResponseMessage: (msg: string) => void;
}
const CultureSettings: React.FC<ComponentProps> = ({
    errorMessage,
    culture,
    numberAndDateCulture,
    preciseScale,
    defaultCurrency,
    currencies,
    supportedCultures,
    cultures,
    showLoader,
    environmentMoniker,
    tenantMoniker,
    isCultureSettingsSaved,
    setCulture,
    setNumberAndDateCulture,
    setPreciseScale,
    setDefaultCurrency,
    getCultureSettings,
    updateCultureSettings,
    getCurrencies,
    getSupportedCultures,
    getCultures,
    setIsUnsavedChangeAvailable,
    setCultureSettingsSaved,
    setErrorResponseMessage,
}: ComponentProps) => {
    const { t } = useTranslation();
    const cancelTokenSource = useRequestCancellation();
    const formRef = useRef<FormInstance<Record<string, any>> | null>(null);
    const [isSaveBtnClicked, setSaveBtnClicked] = useState(false);
    const [isCultureSettingsFetched, setIsCultureSettingsFetched] =
        useState(false);
    const cultureInputPickerData = supportedCultures.map((culture) => ({
        value: culture,
        label: culture,
    }));
    const numberAndDateInputPickerData = cultures.map(
        (numberAndDateCulture) => ({
            value: numberAndDateCulture,
            label: numberAndDateCulture,
        })
    );
    const preciseScaleInputPickerData = Array.from(Array(9).keys()).map(
        (number) => {
            // Dropdown values should contain numbers 2 - 10
            const item = number + 2;
            return { value: item, label: item };
        }
    );
    const model = Schema.Model({
        culture: StringType().isRequired(
            t("tenantConfig.cultureSettingsPage.common.isRequired")
        ),
        numberAndDateCulture: StringType().isRequired(
            t("tenantConfig.cultureSettingsPage.common.isRequired")
        ),
        preciseScale: NumberType().isRequired(
            t("tenantConfig.cultureSettingsPage.common.isRequired")
        ),
        defaultCurrency: StringType().isRequired(
            t("tenantConfig.cultureSettingsPage.common.isRequired")
        ),
    });

    useEffect(() => {
        if (tenantMoniker !== null && environmentMoniker !== null) {
            showLoader(true);
            Promise.all([
                getCultureSettings(
                    environmentMoniker,
                    tenantMoniker,
                    cancelTokenSource
                )
                    .catch((error: Error) => {
                        const axiosError = error;
                        if (!Axios.isCancel(axiosError)) {
                            setErrorResponseMessage(error.message);
                        }
                    })
                    .then(() => setIsCultureSettingsFetched(true)),
                getCurrencies(
                    environmentMoniker,
                    tenantMoniker,
                    cancelTokenSource
                ).catch((error: Error) => {
                    const axiosError = error;
                    if (!Axios.isCancel(axiosError)) {
                        setErrorResponseMessage(error.message);
                    }
                }),
            ]).then(() => showLoader(false));
        }
    }, [tenantMoniker, environmentMoniker, getCultureSettings]);

    useEffect(() => {
        if (
            tenantMoniker &&
            environmentMoniker &&
            supportedCultures.length === 0
        ) {
            getSupportedCultures(
                environmentMoniker,
                tenantMoniker,
                cancelTokenSource
            ).catch((error: Error) => {
                const axiosError = error;
                if (!Axios.isCancel(axiosError)) {
                    setErrorResponseMessage(error.message);
                }
            });
        }
    }, [
        tenantMoniker,
        environmentMoniker,
        supportedCultures,
        getSupportedCultures,
    ]);

    useEffect(() => {
        if (tenantMoniker && environmentMoniker && cultures.length === 0) {
            getCultures(
                environmentMoniker,
                tenantMoniker,
                cancelTokenSource
            ).catch((error: Error) => {
                const axiosError = error;
                if (!Axios.isCancel(axiosError)) {
                    setErrorResponseMessage(error.message);
                }
            });
        }
    }, [tenantMoniker, environmentMoniker, cultures, getCultures]);

    useEffect(() => {
        if (isCultureSettingsSaved) {
            toaster.push(
                <Message type="success" showIcon>
                    {t("tenantConfig.common.successfulUpdateAlertMessage")}
                </Message>,
                {
                    duration: constants.alertDurationMsec,
                }
            );
            setCultureSettingsSaved(false);
        }
    });

    useRefreshPage(() => {
        if (tenantMoniker !== null && environmentMoniker !== null) {
            showLoader(true);
            getCultureSettings(
                environmentMoniker,
                tenantMoniker,
                cancelTokenSource
            )
                .then(() => showLoader(false))
                .catch((error: Error) => {
                    const axiosError = error;
                    if (!Axios.isCancel(axiosError)) {
                        setErrorResponseMessage(error.message);
                    }
                });
        }
        if (formRef && formRef.current) {
            formRef.current.cleanErrors();
        }
    });

    const formValue = {
        culture,
        numberAndDateCulture,
        preciseScale,
        defaultCurrency,
    };
    const handleSubmit = () => {
        if (!formRef.current?.check()) {
            toaster.push(
                <Message type="error" showIcon>
                    {t("tenantConfig.generalConfigurationPage.warning")}
                </Message>,
                {
                    duration: constants.alertDurationMsec,
                }
            );
            return;
        }
        setSaveBtnClicked(true);
        if (tenantMoniker !== null && environmentMoniker !== null) {
            showLoader(true);
            updateCultureSettings(
                environmentMoniker,
                tenantMoniker,
                formValue
            ).then(() => showLoader(false));
            setIsUnsavedChangeAvailable(false);
        }
    };

    const formFieldsNames = {
        cultureName: "culture",
        numberAndDateName: "numberAndDateCulture",
        preciseScaleName: "preciseScale",
        defaultCurrencyName: "defaultCurrency",
    };

    type formValueType = keyof typeof formValue;

    const handleCleanField = (fieldName: formValueType) => {
        if (
            formRef &&
            formRef.current &&
            (formValue[fieldName] ||
                (!formValue[fieldName] && !isSaveBtnClicked))
        ) {
            return formRef.current.cleanErrorForField(fieldName);
        }
    };

    const getFormattedDateTime = (
        numberAndDateCulture: string | null,
        options: any
    ): string => {
        return numberAndDateCulture
            ? new Intl.DateTimeFormat(numberAndDateCulture, options).format(
                  Date.now()
              )
            : "";
    };

    if (errorMessage.length > 0) {
        return <ErrorSectionContainer />;
    }

    return (
        isCultureSettingsFetched && (
            <div className={css.pageContainer}>
                <div className={css.pageContent}>
                    <h1 className={css.pageHeader}>
                        {t("tenantConfig.cultureSettingsPage.header")}
                    </h1>
                    <Form
                        ref={formRef}
                        className={css.form}
                        model={model}
                        onSubmit={handleSubmit}
                        formValue={formValue}
                    >
                        <div className={css.row}>
                            <div className={css.col}>
                                <Form.Group>
                                    <Form.ControlLabel className={css.label}>
                                        {t(
                                            "tenantConfig.cultureSettingsPage.culture.title"
                                        )}
                                        <Form.HelpText
                                            className={css.help}
                                            tooltip
                                        >
                                            {t(
                                                "tenantConfig.cultureSettingsPage.culture.tooltip"
                                            )}
                                        </Form.HelpText>
                                    </Form.ControlLabel>
                                    <Form.Control
                                        accepter={InputPicker}
                                        preventOverflow={true}
                                        data={cultureInputPickerData}
                                        cleanable={false}
                                        onSelect={(value: any) => {
                                            setIsUnsavedChangeAvailable(true);
                                            setCulture(value);
                                        }}
                                        onClean={() =>
                                            handleCleanField(
                                                formFieldsNames.cultureName as formValueType
                                            )
                                        }
                                        name={formFieldsNames.cultureName}
                                        className={cn(
                                            css.formControl,
                                            css.formControlShort
                                        )}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <div className={css.row}>
                            <div className={css.numberAndDateInputContainer}>
                                <Form.Group>
                                    <Form.ControlLabel className={css.label}>
                                        {t(
                                            "tenantConfig.cultureSettingsPage.numberAndDateCulture.title"
                                        )}
                                        <Form.HelpText
                                            className={css.help}
                                            tooltip
                                        >
                                            {t(
                                                "tenantConfig.cultureSettingsPage.numberAndDateCulture.tooltip"
                                            )}
                                        </Form.HelpText>
                                    </Form.ControlLabel>
                                    <Form.Control
                                        accepter={InputPicker}
                                        preventOverflow={true}
                                        data={numberAndDateInputPickerData}
                                        cleanable={false}
                                        onSelect={(value: any) => {
                                            setIsUnsavedChangeAvailable(true);
                                            setNumberAndDateCulture(value);
                                        }}
                                        onClean={() =>
                                            handleCleanField(
                                                formFieldsNames.numberAndDateName as formValueType
                                            )
                                        }
                                        name={formFieldsNames.numberAndDateName}
                                        className={css.formControl}
                                    />
                                </Form.Group>
                            </div>
                            <div className={css.col}>
                                <Form.Group>
                                    <Form.ControlLabel className={css.label}>
                                        {t(
                                            "tenantConfig.cultureSettingsPage.numberAndDateCulture.shortFormatTitle"
                                        )}
                                    </Form.ControlLabel>
                                    <Form.Control
                                        name={"shortFormat"}
                                        readOnly
                                        value={getFormattedDateTime(
                                            numberAndDateCulture,
                                            {
                                                day: "2-digit",
                                                month: "2-digit",
                                                year: "numeric",
                                            }
                                        )}
                                        className={cn(
                                            css.formControl,
                                            css.dateTimeExampleInput
                                        )}
                                    />
                                </Form.Group>
                            </div>
                            <div className={css.col}>
                                <Form.Group>
                                    <Form.ControlLabel className={css.label}>
                                        {t(
                                            "tenantConfig.cultureSettingsPage.numberAndDateCulture.longFormatTitle"
                                        )}
                                    </Form.ControlLabel>
                                    <Form.Control
                                        name={"longFormatTitle"}
                                        readOnly
                                        value={getFormattedDateTime(
                                            numberAndDateCulture,
                                            {
                                                day: "2-digit",
                                                month: "short",
                                                year: "numeric",
                                                hour: "numeric",
                                                minute: "numeric",
                                            }
                                        )}
                                        className={cn(
                                            css.formControl,
                                            css.dateTimeExampleInput
                                        )}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <div className={css.row}>
                            <div className={css.col}>
                                <Form.Group>
                                    <Form.ControlLabel className={css.label}>
                                        {t(
                                            "tenantConfig.cultureSettingsPage.preciseScale.title"
                                        )}
                                        <Form.HelpText
                                            className={css.help}
                                            tooltip
                                        >
                                            {t(
                                                "tenantConfig.cultureSettingsPage.preciseScale.tooltip"
                                            )}
                                        </Form.HelpText>
                                    </Form.ControlLabel>
                                    <Form.Control
                                        accepter={InputPicker}
                                        preventOverflow={true}
                                        data={preciseScaleInputPickerData}
                                        cleanable={false}
                                        onSelect={(value: any) => {
                                            setIsUnsavedChangeAvailable(true);
                                            setPreciseScale(value);
                                        }}
                                        onClean={() =>
                                            handleCleanField(
                                                formFieldsNames.preciseScaleName as formValueType
                                            )
                                        }
                                        name={formFieldsNames.preciseScaleName}
                                        className={cn(
                                            css.formControl,
                                            css.smallInput,
                                            css.formControlShort
                                        )}
                                    />
                                </Form.Group>
                            </div>
                            <div className={css.col}>
                                <Form.Group>
                                    <Form.Control
                                        name={"preciseScale"}
                                        readOnly
                                        value={(0).toFixed(preciseScale)}
                                        className={cn(
                                            css.formControl,
                                            css.preciseScaleExampleInput
                                        )}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <div className={css.row}>
                            <div className={css.col}>
                                <Form.Group>
                                    <Form.ControlLabel className={css.label}>
                                        {t(
                                            "tenantConfig.cultureSettingsPage.currency.title"
                                        )}
                                    </Form.ControlLabel>
                                    <Form.Control
                                        accepter={InputPicker}
                                        preventOverflow={true}
                                        data={currencies.map((currency) => ({
                                            value: currency.code,
                                            label: currency.code,
                                        }))}
                                        cleanable={false}
                                        onSelect={(value: any) => {
                                            setIsUnsavedChangeAvailable(true);
                                            setDefaultCurrency(value);
                                        }}
                                        onClean={() =>
                                            handleCleanField(
                                                formFieldsNames.defaultCurrencyName as formValueType
                                            )
                                        }
                                        name={
                                            formFieldsNames.defaultCurrencyName
                                        }
                                        className={cn(
                                            css.formControl,
                                            css.smallInput
                                        )}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    </Form>
                </div>
                <SettingsFooterContainer handleFormSubmit={handleSubmit} />
            </div>
        )
    );
};

export default CultureSettings;
