import { IHubConnection } from "utils/types";
import {
    IFailedStepHandle,
    ITenantDeleteWorkflow,
} from "utils/tenant-workflows/tenant-workflows-types";
import { API } from "../../../utils/api-config";
import { defaultErrorResponse } from "../../defaults";
import { IDeleteWorkflowOptions } from "store/environments/tenants/tenant-delete/tenant-delete-types";

export const startTenantDelete = async (
    environmentMoniker: string,
    tenantMoniker: string,
    data: IDeleteWorkflowOptions
): Promise<[string | null, number]> => {
    if (!environmentMoniker) {
        return defaultErrorResponse;
    }
    const response = await API.post(
        `environments/${environmentMoniker}/tenants/${tenantMoniker}/delete`,
        data
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const getTenantDelete = async (
    environmentMoniker: string,
    tenantMoniker: string
): Promise<[ITenantDeleteWorkflow | null, number]> => {
    if (!environmentMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.get(
        `environments/${environmentMoniker}/tenants/${tenantMoniker}/delete`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const getDeleteTenantHubConnection = async (
    tenantId: string
): Promise<[IHubConnection | null, number]> => {
    if (!tenantId) {
        return defaultErrorResponse;
    }

    const response = await API.get("hub/connection", {
        headers: {
            "x-ms-signalr-userid": tenantId,
            "x-ms-signalr-hub-name": "Delete",
        },
    });

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const handleFailedTenantDelete = async (
    environmentMoniker: string,
    tenantMoniker: string,
    data: IFailedStepHandle
): Promise<[null, number]> => {
    if (!environmentMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.post(
        `environments/${environmentMoniker}/tenants/${tenantMoniker}/delete/handle-fail`,
        data
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};
