import { RootState } from "store/store";
import { connect } from "react-redux";
import FieldForm from "./FieldForm";
import { getActiveEnvironmentMoniker } from "store/environments/environment-selectors";
import { getActiveTenantMoniker } from "store/environments/tenants/tenants-selectors";

const mapStateToProps = (state: RootState) => ({
    envMoniker: getActiveEnvironmentMoniker(state),
    tenantMoniker: getActiveTenantMoniker(state),
});
const FieldFormContainer = connect(mapStateToProps, {}, null, {
    forwardRef: true,
})(FieldForm);

export default FieldFormContainer;
