import { RootState } from "store/store";
import { connect } from "react-redux";
import Modal from "./Modal";

const mapStateToProps = (state: RootState) => {
    return {
        isModalOpen: state.modalWindowReducer.isModalOpen,
        isModalLoaderVisible: state.modalWindowReducer.isModalLoaderVisible,
        errorMessage: state.initializationReducer.errorResponseMessageForModal,
    };
};

const ModalContainer = connect(mapStateToProps)(Modal);

export default ModalContainer;
