import { API } from "../../../utils/api-config";
import {
    IEnrollWorkflow,
    StepApproveDataTypes,
    IEnrollCache,
    InformationActions,
} from "store/environments/tenants/enroll-tenant/enroll-tenant-types";
import { IHubConnection } from "../../../utils/types";
import { defaultErrorResponse } from "../../defaults";
import { IFailedStepHandle } from "utils/tenant-workflows/tenant-workflows-types";

export const startTenantEnroll = async (
    environmentMoniker: string
): Promise<[string | null, number]> => {
    if (!environmentMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.post(
        `environments/${environmentMoniker}/tenants/enroll`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const loadEnrollWorkflow = async (
    environmentMoniker: string,
    orchestratorInstanceId: string
): Promise<[IEnrollWorkflow | null, number]> => {
    if (!orchestratorInstanceId) {
        return defaultErrorResponse;
    }

    const response = await API.get(
        `environments/${environmentMoniker}/tenants/enrollments/${orchestratorInstanceId}`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const getEnrollCache = async (
    environmentMoniker: string,
    orchestratorInstanceId: string
): Promise<[IEnrollCache | null, number]> => {
    if (!environmentMoniker || !orchestratorInstanceId) {
        return defaultErrorResponse;
    }

    const response = await API.get(
        `environments/${environmentMoniker}/tenants/enrollments/${orchestratorInstanceId}/cache`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const loadSubstepActions = async (
    orchestratorInstanceId: string,
    environmentMoniker: string,
    stepName: string
): Promise<[InformationActions | null, number]> => {
    if (!orchestratorInstanceId || !environmentMoniker || !stepName) {
        return defaultErrorResponse;
    }
    const response = await API.get(
        `/enroll/${environmentMoniker}/${orchestratorInstanceId}/instructions/${stepName}`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const approveEnrollStep = async (
    environmentMoniker: string,
    orchestratorInstanceId: string,
    eventName: string,
    data: StepApproveDataTypes
): Promise<[void | null, number]> => {
    if (!environmentMoniker || !orchestratorInstanceId || !eventName || !data) {
        return defaultErrorResponse;
    }

    const response = await API.post(
        `environments/${environmentMoniker}/tenants/enrollments/${orchestratorInstanceId.toLowerCase()}/approve/${eventName}`,
        data
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const terminateTenantEnroll = async (
    environmentMoniker: string,
    orchestratorInstanceId: string
): Promise<[void | null, number]> => {
    if (!environmentMoniker || !orchestratorInstanceId) {
        return defaultErrorResponse;
    }
    const response = await API.post(
        `environments/${environmentMoniker}/tenants/enrollments//${orchestratorInstanceId.toLowerCase()}/terminate`
    );
    if (!response) {
        return defaultErrorResponse;
    }
    return [response.data, response.status];
};

export const handleFailedEnrollStep = async (
    environmentMoniker: string,
    enrollId: string,
    data: IFailedStepHandle
): Promise<[null, number]> => {
    if (!environmentMoniker || !enrollId) {
        return defaultErrorResponse;
    }

    const response = await API.post(
        `environments/${environmentMoniker}/tenants/enrollments/${enrollId}/handle-fail`,
        data
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const getEnrollHubConnection = async (
    orchestratorInstanceId: string
): Promise<[IHubConnection | null, number]> => {
    if (!orchestratorInstanceId) {
        return defaultErrorResponse;
    }

    const response = await API.get("hub/connection", {
        headers: {
            "x-ms-signalr-userid": orchestratorInstanceId,
            "x-ms-signalr-hub-name": "Enroll",
        },
    });

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const getExampleImage = async (
    environmentMoniker: string,
    enrollId: string,
    fileName: string
): Promise<[string | null, number]> => {
    const response = await API.get(
        `environments/${environmentMoniker}/tenants/enrollments/${enrollId}/enroll-images/${fileName}`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};
