import {
    loadEnvironmentAppRegistrations,
    loadEnvironments,
} from "api/environments/environments-api";
import {
    setErrorMessageInactiveEnvironment,
    setErrorMessageInactiveTenant,
    setErrorResponseMessage,
    setLoaderVisibility,
} from "../common/initialization/initialization-actions";
import { AppThunk } from "../store";
import {
    setActiveEnvironmentByName,
    setActiveEnvironmentTenantsMonikers,
    setAppRegistrations,
    setEnvironments,
} from "./environment-actions";
import constants from "utils/constants";
import { getErrorMessage } from "api/defaults";
import { updateEnvironmentFilters } from "../filters/filters-actions";
import { updateEnvironmentPingInfo } from "./tenants/tenant-ping/tenant-ping-actions";

export const fetchEnvironments =
    (envName?: string): AppThunk =>
    async (dispatch) => {
        dispatch(setLoaderVisibility(true));
        dispatch(setErrorResponseMessage(""));
        dispatch(setErrorMessageInactiveEnvironment(""));
        dispatch(setErrorMessageInactiveTenant(""));

        const [payload, status] = await loadEnvironments();

        if (payload && status === constants.statusCode.OK) {
            dispatch(setEnvironments(payload));
            dispatch(updateEnvironmentFilters(payload));
            dispatch(updateEnvironmentPingInfo(payload));

            if (envName) {
                dispatch(
                    setActiveEnvironmentByName(decodeURIComponent(envName))
                );
                dispatch(setActiveEnvironmentTenantsMonikers());
            }
        } else {
            const errorMessage = getErrorMessage(String(payload), status);

            dispatch(setErrorResponseMessage(errorMessage));
        }

        dispatch(setLoaderVisibility(false));
    };

export const fetchDooapAppRegistrations =
    (envMoniker: string): AppThunk =>
    async (dispatch) => {
        dispatch(setLoaderVisibility(true));
        dispatch(setErrorResponseMessage(""));
        dispatch(setErrorMessageInactiveEnvironment(""));
        dispatch(setErrorMessageInactiveTenant(""));

        const [payload, status] =
            await loadEnvironmentAppRegistrations(envMoniker);

        if (payload && status === constants.statusCode.OK) {
            dispatch(setAppRegistrations([...payload]));
        } else {
            const errorMessage = getErrorMessage(String(payload), status);

            dispatch(setErrorResponseMessage(errorMessage));
        }
        dispatch(setLoaderVisibility(false));
    };

export const fetchDooapAppRegistrationsWithoutLoader =
    (envMoniker: string): AppThunk =>
    async (dispatch) => {
        dispatch(setErrorResponseMessage(""));
        dispatch(setErrorMessageInactiveEnvironment(""));
        dispatch(setErrorMessageInactiveTenant(""));

        const [payload, status] =
            await loadEnvironmentAppRegistrations(envMoniker);

        if (payload && status === constants.statusCode.OK) {
            dispatch(setAppRegistrations([...payload]));
        } else {
            const errorMessage = getErrorMessage(String(payload), status);

            dispatch(setErrorResponseMessage(errorMessage));
        }
    };
