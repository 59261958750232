import { RootState } from "store/store";
import { connect } from "react-redux";
import { compose } from "redux";
import GeneralConfigurations from "./GeneralConfigurations";
import {
    updateActiveTenantDisplayName,
    setActiveTenant,
    setIsTenantFetched,
} from "store/environments/tenants/tenants-actions";
import { setIsUnsavedChangeAvailable } from "store/common/unsaved-data/unsaved-data-actions";
import { getActiveEnvironmentMoniker } from "store/environments/environment-selectors";
import {
    getActiveTenantMoniker,
    getAuthenticationMethod,
    getIsTenantFetched,
    getTenantNames,
} from "store/environments/tenants/tenants-selectors";
import { fetchConfluenceDocumentLinks } from "store/information-links/information-links-thunks";
import withLoader from "hocs/withLoader/withLoader";
import { getConfluenceDocumentLinks } from "store/information-links/information-links-selectors";
import { getIsUserPartner } from "store/app-global/app-global-selectors";
import { setErrorResponseMessage } from "store/common/initialization/initialization-actions";

const mapStateToProps = (state: RootState) => {
    return {
        isConfluenceDocumentLinksFetched:
            state.informationLinksReducer.isConfluenceDocumentLinksFetched,
        confluenceDocumentLinks: getConfluenceDocumentLinks(state),
        envMoniker: getActiveEnvironmentMoniker(state),
        tenantMoniker: getActiveTenantMoniker(state),
        errorMessage:
            state.initializationReducer.errorResponseMessage ||
            state.initializationReducer.errorMessageInactiveEnvironment ||
            state.initializationReducer.errorMessageInactiveTenant ||
            state.initializationReducer.errorTenantDeleteMessage,
        isFetched: getIsTenantFetched(state),
        isUserPartner: getIsUserPartner(state),
        tenantNames: getTenantNames(state),
        authenticationMethodDescription: getAuthenticationMethod(state),
    };
};
const GeneralConfigurationsContainer = compose(
    connect(mapStateToProps, {
        updateActiveTenantDisplayName,
        setIsUnsavedChangeAvailable,
        setActiveTenant,
        setIsTenantFetched,
        fetchConfluenceDocumentLinks,
        setErrorResponseMessage,
    }),
    withLoader
)(GeneralConfigurations);

export default GeneralConfigurationsContainer;
