import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { template } from "lodash";
import { Message } from "rsuite";
import {
    ITenantFeatureSettingWorkFlow,
    TenantFeatureSettingWorkFlowType,
    WorkflowStatus,
    WorkflowStepStatus,
} from "utils/tenant-workflows/tenant-workflows-types";
import {
    isEveryWorkflowStepCompleted,
    isWorkflowTerminated,
} from "utils/tenant-workflows/tenant-workflows-helper";
import { getSupportEmail } from "store/contacts/contacts-selectors";
import { RootState } from "store/store";
import parse from "html-react-parser";
import { TypeAttributes } from "rsuite/esm/internals/types";

interface IComponentProps {
    workflow: ITenantFeatureSettingWorkFlow;
}

const TenantFeatureProgressMessage: React.FC<IComponentProps> = ({
    workflow,
}: IComponentProps) => {
    const { t } = useTranslation();

    const supportEmail = useSelector((state: RootState) =>
        getSupportEmail(state)
    );

    const getCompletionMessageProps = (
        workflow: ITenantFeatureSettingWorkFlow
    ): {
        type: TypeAttributes.Status;
        description: string | JSX.Element | JSX.Element[];
    } => {
        if (
            workflow.steps.some(
                (step) => step.status === WorkflowStepStatus.CompletedWithError
            ) &&
            workflow.status === WorkflowStatus.Success
        ) {
            return {
                type: "warning",
                description: t(
                    "tenantFeatureSetting.progressModal.warningMessage"
                ),
            };
        } else if (workflow.status === WorkflowStatus.Terminated) {
            return {
                type: "warning",
                description: t(
                    "tenantFeatureSetting.progressModal.terminationMessage"
                ),
            };
        } else if (
            workflow.type ===
                TenantFeatureSettingWorkFlowType.SearchServiceSelection &&
            workflow.status === WorkflowStatus.Success
        ) {
            return {
                type: "warning",
                description: parse(
                    template(
                        t(
                            "tenantFeatureSetting.progressModal.selectSearchServiceWarningMessage"
                        )
                    )({
                        email: supportEmail,
                    })
                ),
            };
        } else {
            switch (workflow.status) {
                case WorkflowStatus.Failed:
                    return {
                        type: "error",
                        description: t(
                            "tenantFeatureSetting.progressModal.errorMessage"
                        ),
                    };
                default:
                    return {
                        type: "info",
                        description: t(
                            "tenantFeatureSetting.progressModal.successMessage"
                        ),
                    };
            }
        }
    };

    const completionMessageProps = getCompletionMessageProps(workflow);

    return (
        <>
            {(isEveryWorkflowStepCompleted(workflow.steps) ||
                isWorkflowTerminated(workflow)) && (
                <Message showIcon type={completionMessageProps.type}>
                    {completionMessageProps.description}
                </Message>
            )}
        </>
    );
};
export default TenantFeatureProgressMessage;
