import { AppDispatch, RootState } from "store/store";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setErrorMessageInactiveEnvironment } from "store/common/initialization/initialization-actions";
import { useTranslation } from "react-i18next";
import { getIsActiveEnvironmentSet } from "store/environments/environment-selectors";

const useInactiveEnvironment = (): void => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const isActiveEnvironment = useSelector((state: RootState) =>
        getIsActiveEnvironmentSet(state)
    );

    useEffect(() => {
        dispatch(
            setErrorMessageInactiveEnvironment(
                !isActiveEnvironment ? t("error.noActiveEnvironment") : ""
            )
        );
    }, [isActiveEnvironment]);
};

export default useInactiveEnvironment;
