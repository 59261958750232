import { RootState } from "store/store";
import Preloader from "./Preloader";
import { connect } from "react-redux";

const mapStateToProps = (state: RootState) => {
    return {
        isLoaderVisible: state.initializationReducer.loadersCount > 0,
    };
};

const PreloaderContainer = connect(mapStateToProps)(Preloader);

export default PreloaderContainer;
