import { defaultErrorResponse } from "../../defaults";
import { API } from "../../../utils/api-config";
import { ITenantCompany } from "store/environments/tenants/tenant-companies/tenant-companies-types";

export const loadCompanies = async (
    environmentMoniker: string,
    tenantMoniker: string
): Promise<[ITenantCompany[] | null, number]> => {
    if (!environmentMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.get(
        `environments/${environmentMoniker}/tenants/${tenantMoniker}/companies`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const loadIntegrationUserCompanies = async (
    environmentMoniker: string,
    tenantMoniker: string
): Promise<[ITenantCompany[] | null, number]> => {
    if (!environmentMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.get(
        `environments/${environmentMoniker}/tenants/${tenantMoniker}/companies/integration-user-companies`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};
