import { connect } from "react-redux";
import { RootState } from "store/store";
import { getEnrollId } from "store/environments/tenants/enroll-tenant/enroll-tenant-selectors";
import { getErrorEnrollManualStepResponseMessage } from "store/common/initialization/initialization-selectors";
import { fetchEnrollWorkflow } from "store/environments/tenants/enroll-tenant/enroll-tenant-thunks";
import StepFrame from "./StepFrame";

const mapStateToProps = (state: RootState) => ({
    errorMessage: state.initializationReducer.errorMessageEnrollStep,
    errorEnrollManualStepResponseMessage:
        getErrorEnrollManualStepResponseMessage(state),
    environmentMoniker: state.environmentsReducer.activeEnvironment.moniker,
    enrollId: getEnrollId(state),
});

const StepFrameContainer = connect(mapStateToProps, {
    fetchEnrollWorkflow,
})(StepFrame);

export default StepFrameContainer;
