import { compose } from "redux";
import { connect } from "react-redux";
import { setLoaderVisibility } from "store/common/initialization/initialization-actions";
import { setIsUnsavedChangeAvailable } from "store/common/unsaved-data/unsaved-data-actions";
import { getCustomerTenantAmount } from "store/customers/customers-selectors";
import { RootState } from "store/store";
import DeleteCustomerButton from "./DeleteCustomerButton";
import { getCustomerDetails } from "store/customers/customer-details/customer-details-selectors";

const mapStateToProps = (state: RootState) => {
    return {
        customerDetails: getCustomerDetails(state),
        tenantsAmount: getCustomerTenantAmount(state),
    };
};

const DeleteCustomerButtonContainer = compose(
    connect(mapStateToProps, {
        setLoaderVisibility,
        setIsUnsavedChangeAvailable,
    })
)(DeleteCustomerButton);

export default DeleteCustomerButtonContainer;
