import React, { ForwardRefExoticComponent, RefAttributes } from "react";
import { ViewMode } from "store/environments/tenants/tenants-types";
import "./ToggleButton.less";
import MenuIcon from "@rsuite/icons/Menu";
import GridIcon from "@rsuite/icons/Grid";
import { IconButton } from "rsuite";
import { IconProps } from "@rsuite/icons/esm/Icon";

interface ComponentProps {
    viewMode: ViewMode;
    setViewMode: (viewMode: ViewMode) => void;
}
const ToggleButton: React.FC<ComponentProps> = ({
    viewMode,
    setViewMode,
}: ComponentProps) => {
    const onToggleClick = (viewMode: ViewMode) => {
        setViewMode(viewMode);
    };

    const togglerButtons: Array<{
        icon: ForwardRefExoticComponent<IconProps & RefAttributes<SVGElement>>;
        mode: ViewMode;
    }> = [
        { icon: MenuIcon, mode: ViewMode.List },
        { icon: GridIcon, mode: ViewMode.Card },
    ];

    return (
        <div className="toggler__container">
            {togglerButtons.map((button, index) => (
                <IconButton
                    icon={<button.icon />}
                    onClick={() => onToggleClick(button.mode)}
                    className={
                        viewMode === button.mode ? "toggler--active" : ""
                    }
                    key={index}
                />
            ))}
        </div>
    );
};
export default ToggleButton;
