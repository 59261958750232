import React from "react";
import StatusBlock from "components/Common/StatusBlock/StatusBlock";
import { useTranslation } from "react-i18next";
import {
    ITenant,
    ITenantPingInfo,
    TenantStatus,
} from "store/environments/tenants/tenants-types";
import "./TenantFilters.less";
import constants from "utils/constants";
import { getComplexStatus, getPingStatus } from "utils/status-helper";

interface ComponentProps {
    activeStatus: number;
    activeEnvironmentId: string;
    tenants: ITenant[];
    tenantsPingInfo: ITenantPingInfo[];
    setEnvironmentFilterStatus: (id: string, status: number) => void;
}
const TenantFilters: React.FC<ComponentProps> = ({
    activeStatus,
    activeEnvironmentId,
    tenants,
    tenantsPingInfo,
    setEnvironmentFilterStatus,
}: ComponentProps) => {
    const { t } = useTranslation();

    const getStatusCounts = () => {
        const counts: Record<TenantStatus, number> = {
            [TenantStatus.Normal]: 0,
            [TenantStatus.Warning]: 0,
            [TenantStatus.Error]: 0,
            [TenantStatus.Enroll]: 0,
            [TenantStatus.Deleting]: 0,
        };

        tenants.forEach((item) => {
            const complexStatus = getComplexTenantStatus(item);
            counts[complexStatus]++;
        });

        return counts;
    };

    const getComplexTenantStatus = (tenant: ITenant) => {
        const pingInfo = tenantsPingInfo.find(
            (item) => item.tenantId === tenant.versionInfo.id
        );
        return getComplexStatus(
            tenant.versionInfo.fetchStatus,
            getPingStatus(pingInfo)
        );
    };

    const filterStatusOptions = [
        {
            status: constants.defaultIndex,
            label: t("status.all"),
        },
        {
            status: TenantStatus.Error,
            label: t("status.error"),
        },
        {
            status: TenantStatus.Normal,
            label: t("status.normal"),
        },
        {
            status: TenantStatus.Enroll,
            label: t("status.enroll"),
        },
        {
            status: TenantStatus.Deleting,
            label: t("status.deleting"),
        },
    ];

    return (
        <div className="filters__container">
            {filterStatusOptions.map((option) => (
                <StatusBlock
                    key={option.status}
                    isActive={activeStatus === option.status}
                    subTitle={
                        option.status === constants.defaultIndex
                            ? String(tenants.length)
                            : String(
                                  getStatusCounts()[
                                      option.status as TenantStatus
                                  ]
                              )
                    }
                    title={option.label}
                    onClick={() =>
                        setEnvironmentFilterStatus(
                            activeEnvironmentId,
                            option.status
                        )
                    }
                />
            ))}
        </div>
    );
};
export default TenantFilters;
