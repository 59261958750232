import * as React from "react";
import { useTranslation } from "react-i18next";
import css from "./ErpPackageCard.module.css";
import {
    IEmailsSendingWorkflow,
    IErpPackage,
    IErpPackageDetails,
    IManageErpPackage,
    WorkflowStatus,
} from "store/deliverables/erp-packages/erp-packages-types";
import { Dropdown, IconButton, Loader, Panel, Tooltip, Whisper } from "rsuite";
import { formatDate, isPreviousDate } from "utils/date-helper";
import { useEffect, useState } from "react";
import ConfirmModalContainer from "components/Common/ConfirmModal/ConfirmModalContainer";
import {
    updateErpPackage,
    getErpPackageEmailsSendingWorkflow,
    loadErpPackageSendingStatus,
} from "api/deliverables/erp-packages-api";
import { template } from "lodash";
import ErpPackageHistoryModal from "../ErpPackageHistoryModal/ErpPackageHistoryModal";
import SignalREmailsSending from "../EmailsSending/SignalREmailsSending";
import constants from "utils/constants";
import { SendingStatus } from "store/deliverables/erp-packages/erp-packages-types";
import usePreviousState from "hooks/usePreviousState";
import CopyIcon from "@rsuite/icons/Copy";
import SendIcon from "@rsuite/icons/Send";
import EditIcon from "@rsuite/icons/Edit";
import TrashIcon from "@rsuite/icons/Trash";
import MoreIcon from "@rsuite/icons/More";

interface ComponentProps {
    erpPackage: IErpPackage;
    isPackagesModificationAllowed: boolean;
    deletePackage: (erpPackage: IErpPackage) => void;
    setManagePackageOpened: (isOpened: boolean) => void;
    setEmailsSendingOpened: (isOpened: boolean) => void;
    setErpPackage: (erpPackage: IErpPackage) => void;
    setManagePackageDetails: (packageDetails: IErpPackageDetails) => void;
}

const ErpPackageCard: React.FC<ComponentProps> = ({
    erpPackage,
    isPackagesModificationAllowed,
    deletePackage,
    setManagePackageOpened,
    setEmailsSendingOpened,
    setErpPackage,
    setManagePackageDetails,
}: ComponentProps) => {
    const { t } = useTranslation();
    const [copyLinkMessage, setCopyLinkMessage] = useState(
        t("deliverables.packages.card.copyLink.copyToClipboard")
    );
    const isActionDisabled = isPreviousDate(
        new Date(erpPackage.expirationDate)
    );
    const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
    const [isHistoryOpened, setIsHistoryOpened] = useState(false);
    const [
        previousEmailsSendingWorkflow,
        emailsSendingWorkflow,
        setEmailsSendingWorkflow,
    ] = usePreviousState<IEmailsSendingWorkflow | null>(null);
    const [isWorkflowFetched, setIsWorkflowFetched] = useState(false);
    const [areEmailsSending, setAreEmailsSending] = useState(false);
    const [isSendingStatusFailed, setIsSendingStatusFailed] = useState<boolean>(
        erpPackage.status === SendingStatus.Failed
    );

    const getDateField = (date: string | null) => {
        return date ? formatDate(new Date(date)) : "-";
    };

    const handleCancel = () => {
        setDeleteConfirmVisible(false);
    };

    const handleConfirm = async () => {
        deletePackage(erpPackage);
        setDeleteConfirmVisible(false);
    };

    const handleCopyLink = () => {
        navigator.clipboard.writeText(erpPackage.blobLink);
        setCopyLinkMessage(t("deliverables.packages.card.copyLink.copied"));
    };

    const handleHistoryClick = () => {
        setIsHistoryOpened(true);
    };

    const handleEdit = () => {
        setManagePackageOpened(true);

        const mappedErpPackage: IManageErpPackage = {
            ...erpPackage,
            creationDate: new Date(erpPackage.creationDate),
            uatStartDate: erpPackage.uatStartDate
                ? new Date(erpPackage.uatStartDate)
                : null,
            goLiveDate: erpPackage.goLiveDate
                ? new Date(erpPackage.goLiveDate)
                : null,
            expirationDate: erpPackage.expirationDate
                ? new Date(erpPackage.expirationDate)
                : null,
        };

        setManagePackageDetails({
            title: t("deliverables.packages.edit.title"),
            confirmButtonName: t("deliverables.packages.edit.save"),
            erpPackage: mappedErpPackage,
            apiMethod: updateErpPackage,
        });
    };

    const handleSend = () => {
        setEmailsSendingOpened(true);
        setErpPackage(erpPackage);
    };

    const fetchWorkflow = () => {
        getErpPackageEmailsSendingWorkflow(erpPackage.id).then(
            ([payload, status]) => {
                if (payload && status === constants.statusCode.OK) {
                    setEmailsSendingWorkflow(payload);
                }
                setIsWorkflowFetched(true);
            }
        );
    };

    const fetchSendingStatus = () => {
        loadErpPackageSendingStatus(erpPackage.id).then(
            ([data, statusCode]) => {
                if (statusCode === constants.statusCode.OK && data) {
                    setIsSendingStatusFailed(data === SendingStatus.Failed);
                }
            }
        );
    };

    useEffect(() => {
        if (
            previousEmailsSendingWorkflow &&
            emailsSendingWorkflow &&
            previousEmailsSendingWorkflow.status === WorkflowStatus.Running &&
            emailsSendingWorkflow.status === WorkflowStatus.Completed
        ) {
            fetchSendingStatus();
        }
    }, [previousEmailsSendingWorkflow, emailsSendingWorkflow]);

    useEffect(() => {
        if (!isWorkflowFetched) {
            fetchWorkflow();
        }
    }, [isWorkflowFetched]);

    useEffect(() => {
        if (emailsSendingWorkflow) {
            setAreEmailsSending(
                emailsSendingWorkflow.status === WorkflowStatus.Running
            );
        }
    }, [emailsSendingWorkflow]);

    return (
        <>
            <ConfirmModalContainer
                handleCancelModal={handleCancel}
                confirmCallbacks={[handleConfirm]}
                isVisible={deleteConfirmVisible}
                message={template(
                    t("deliverables.packages.delete.confirmationMessage")
                )({
                    packageVersion: erpPackage.version,
                })}
            />
            <SignalREmailsSending
                workflowId={emailsSendingWorkflow?.id ?? null}
                updateWorkflow={(workflow: IEmailsSendingWorkflow) => {
                    setEmailsSendingWorkflow(workflow);
                }}
            >
                <ErpPackageHistoryModal
                    erpPackageId={erpPackage.id}
                    isOpened={isHistoryOpened}
                    handleClose={setIsHistoryOpened}
                />
                <Panel bordered shaded bodyFill className={css.panel}>
                    <div className={css.card}>
                        <div className={css.cardTitle}>
                            <div className={css.cardName}>
                                {erpPackage.packageType.type}
                            </div>
                            <div className={css.cardDescription}>
                                {erpPackage.description}
                            </div>
                            <div className={css.cardActions}>
                                <Whisper
                                    placement="left"
                                    trigger={"hover"}
                                    speaker={
                                        <Tooltip>{copyLinkMessage}</Tooltip>
                                    }
                                >
                                    <IconButton
                                        icon={<CopyIcon />}
                                        onClick={handleCopyLink}
                                        onMouseLeave={() =>
                                            setCopyLinkMessage(
                                                t(
                                                    "deliverables.packages.card.copyLink.copyToClipboard"
                                                )
                                            )
                                        }
                                        disabled={isActionDisabled}
                                    />
                                </Whisper>
                                <Whisper
                                    placement="left"
                                    trigger={"hover"}
                                    speaker={
                                        <Tooltip>
                                            {t(
                                                "deliverables.packages.card.sendBtnTooltip"
                                            )}
                                        </Tooltip>
                                    }
                                >
                                    <IconButton
                                        icon={<SendIcon />}
                                        onClick={handleSend}
                                        disabled={
                                            isActionDisabled || areEmailsSending
                                        }
                                    />
                                </Whisper>

                                {isPackagesModificationAllowed && (
                                    <Dropdown
                                        placement="bottomEnd"
                                        className={css.actionsDropdown}
                                        icon={<MoreIcon />}
                                        noCaret
                                    >
                                        <Dropdown.Item
                                            icon={<EditIcon />}
                                            onClick={handleEdit}
                                        >
                                            {t(
                                                "deliverables.packages.card.editTitle"
                                            )}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            icon={<TrashIcon />}
                                            onClick={() => {
                                                setDeleteConfirmVisible(true);
                                            }}
                                        >
                                            {t(
                                                "deliverables.packages.card.deleteTitle"
                                            )}
                                        </Dropdown.Item>
                                    </Dropdown>
                                )}
                            </div>
                        </div>
                        <div className={css.layout}>
                            <span>
                                {t("deliverables.packages.versionTitle") +
                                    ": " +
                                    erpPackage.version}
                            </span>
                            <span>
                                {t("deliverables.packages.createdTitle") +
                                    ": " +
                                    getDateField(erpPackage.creationDate)}
                            </span>
                            <span>
                                {t("deliverables.packages.goLiveTitle") +
                                    ": " +
                                    getDateField(erpPackage.goLiveDate)}
                            </span>
                            <span>
                                {t("deliverables.packages.targetTitle") +
                                    ": " +
                                    erpPackage.packageTarget.target}
                            </span>
                            <span>
                                {t("deliverables.packages.uatStartTitle") +
                                    ": " +
                                    getDateField(erpPackage.uatStartDate)}
                            </span>
                            <span>
                                {t("deliverables.packages.expirationTitle") +
                                    ": " +
                                    getDateField(erpPackage.expirationDate)}
                            </span>
                        </div>
                        <div className={css.footer}>
                            <div className={css.cardLinks}>
                                {erpPackage.statusPageLink && (
                                    <a
                                        href={erpPackage.statusPageLink}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        {t(
                                            "deliverables.packages.statusPageTitle"
                                        )}
                                    </a>
                                )}
                                {erpPackage.releaseNotesLink && (
                                    <a
                                        href={erpPackage.releaseNotesLink}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        {t(
                                            "deliverables.packages.releaseNotesTitle"
                                        )}
                                    </a>
                                )}
                                <a
                                    onClick={handleHistoryClick}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className={
                                        isSendingStatusFailed
                                            ? css.historyFailed
                                            : ""
                                    }
                                >
                                    {t("deliverables.packages.historyTitle")}
                                </a>
                            </div>
                            {areEmailsSending && (
                                <Loader
                                    content={t(
                                        "deliverables.packages.sendingTitle"
                                    )}
                                    speed="slow"
                                    size="sm"
                                    className={css.loader}
                                />
                            )}
                        </div>
                    </div>
                </Panel>
            </SignalREmailsSending>
        </>
    );
};

export default ErpPackageCard;
