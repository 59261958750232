import i18n from "utils/i18n";

import {
    ICompany,
    IMailboxDefault,
    IMailboxHistory,
} from "store/environments/tenants/tenant-capture-mailboxes/tenant-capture-mailboxes-types";

export const getComparableMilboxValue = (
    obj: IMailboxDefault,
    sortingProperty: string
) => {
    const unknown = i18n.t("unknown");
    const comparableString =
        obj[sortingProperty as keyof IMailboxDefault] ||
        (obj.company && obj.company[sortingProperty as keyof ICompany]) ||
        unknown;

    return typeof comparableString === "string"
        ? comparableString.toLowerCase()
        : comparableString;
};

export const getComparableHistoryValue = (
    obj: IMailboxHistory,
    sortingProperty: string
) => {
    const unknown = i18n.t("unknown");
    const comparableString =
        obj[sortingProperty as keyof IMailboxHistory] || unknown;

    return comparableString.toLowerCase();
};
