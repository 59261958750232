import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import React, { useEffect, useState } from "react";
import constants from "utils/constants";
import { IHubConnection } from "utils/types";
import { ContainerProps } from "rsuite";
import { IEmailsSendingWorkflow } from "store/deliverables/erp-packages/erp-packages-types";
import { getEmailsSendingHubConnection } from "api/deliverables/erp-packages-api";

interface IComponentProps extends ContainerProps {
    workflowId: string | null;
    updateWorkflow: (workFlow: IEmailsSendingWorkflow) => void;
}

const SignalREmailsSending: React.FC<IComponentProps> = ({
    workflowId,
    children,
    updateWorkflow,
}: IComponentProps) => {
    const [hubInfo, setHubInfo] = useState<IHubConnection | null>(null);
    const [hubConnectionBuilder, setHubConnectionBuilder] =
        useState<HubConnectionBuilder | null>(null);

    const [hubConnection, setHubConnection] = useState<HubConnection | null>(
        null
    );
    useEffect(() => {
        if (workflowId !== null && hubInfo === null) {
            getEmailsSendingHubConnection(workflowId).then(([payload]) => {
                setHubInfo(payload);
            });
        }
    }, [workflowId, hubInfo]);

    useEffect(() => {
        if (hubInfo !== null) {
            if (hubConnectionBuilder === null) {
                setHubConnectionBuilder(
                    new HubConnectionBuilder()
                        .withAutomaticReconnect()
                        .withUrl(hubInfo.url, {
                            accessTokenFactory: () => hubInfo.accessToken,
                        })
                );
            }
        }
    }, [
        hubInfo,
        hubConnectionBuilder,
        updateWorkflow,
        setHubConnectionBuilder,
    ]);
    useEffect(() => {
        if (hubConnectionBuilder !== null) {
            setHubConnection(hubConnectionBuilder.build());
        }
    }, [hubConnectionBuilder]);
    useEffect(() => {
        if (hubConnection !== null) {
            hubConnection.on(
                constants.enrollNewSignalRMessageMethodName,
                (workFlow: string) => {
                    updateWorkflow(
                        JSON.parse(workFlow) as IEmailsSendingWorkflow
                    );
                }
            );
            hubConnection.start();
        }
        return () => {
            if (hubConnection !== null) {
                hubConnection.stop();
            }
        };
    }, [hubConnection]);
    return <>{children}</>;
};

export default SignalREmailsSending;
