import { connect } from "react-redux";
import { setErrorResponseMessageForModal } from "store/common/initialization/initialization-actions";
import { setModalLoaderVisibility } from "store/common/modal-window/modal-window-actions";
import AddCustomer from "./AddCustomer";

const mapStateToProps = () => {
    return {};
};

const AddCustomerContainer = connect(mapStateToProps, {
    setErrorResponseMessageForModal,
    setModalLoaderVisibility,
})(AddCustomer);

export default AddCustomerContainer;
