import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import { Button, Table } from "rsuite";

import constants from "utils/constants";
import { getModalTableHeight } from "utils/measurement-helper";

import { IMailboxErrorLog } from "store/environments/tenants/tenant-capture-mailboxes/tenant-capture-mailboxes-types";

import { loadMailboxesErrorLogs } from "api/environments/tenants/tenant-capture-mailboxes-api";
import { getErrorMessage } from "api/defaults";

import ModalContainer from "components/Common/Modal/ModalContainer";
import PopoverCell from "components/Common/PopoverCell/PopoverCell";

import "./MailboxesErrorLogs.less";

interface ComponentProps {
    envMoniker: string;
    tenantMoniker: string;
    isOpened: boolean;
    setIsOpened: (isOpened: boolean) => void;
    setModalLoaderVisibility: (visible: boolean) => void;
    setErrorResponseMessage: (message: string) => void;
}

const MailboxesErrorLogs: React.FC<ComponentProps> = ({
    envMoniker,
    tenantMoniker,
    isOpened,
    setIsOpened,
    setModalLoaderVisibility,
    setErrorResponseMessage,
}: ComponentProps) => {
    const cancelTokenSource = Axios.CancelToken.source();
    const { t } = useTranslation();
    const [errorLogs, setErrorLogs] = useImmer<IMailboxErrorLog[]>([]);
    const handleModalClose = () => {
        setIsOpened(false);
    };

    const header = <h1>{t("tenantConfig.captureMailboxes.errorLogsTitle")}</h1>;
    const footer = (
        <>
            <Button
                className={"mailboxes-error-log-modal__button"}
                type="submit"
                appearance="ghost"
                onClick={handleModalClose}
            >
                {t("deliverables.packages.history.close")}
            </Button>
        </>
    );

    const tableHeight = getModalTableHeight(errorLogs.length);

    const getMailboxesErrorLogs = () => {
        setModalLoaderVisibility(true);
        loadMailboxesErrorLogs(envMoniker, tenantMoniker, cancelTokenSource)
            .then(([payload, status]) => {
                if (payload && status === constants.statusCode.OK) {
                    setErrorLogs(payload);
                } else {
                    setErrorResponseMessage(
                        getErrorMessage(String(payload), status)
                    );
                }
            })
            .catch((error: Error) => {
                const axiosError = error;
                if (!Axios.isCancel(error)) {
                    setErrorResponseMessage(axiosError.message);
                }
            })
            .then(() => {
                setModalLoaderVisibility(false);
            });
    };

    useEffect(() => {
        if (isOpened && envMoniker && tenantMoniker) {
            getMailboxesErrorLogs();
        }
    }, [envMoniker, tenantMoniker, isOpened]);

    return (
        <ModalContainer
            className={"mailboxes-error-log-modal__modal"}
            open={isOpened}
            header={header}
            footer={footer}
            onClose={handleModalClose}
        >
            <Table
                shouldUpdateScroll={false}
                virtualized={true}
                data={errorLogs}
                height={tableHeight}
            >
                <Table.Column flexGrow={2}>
                    <Table.HeaderCell>
                        {t(`tenantConfig.captureMailboxes.table.address`)}
                    </Table.HeaderCell>
                    <Table.Cell dataKey="mailboxAddress">
                        {(rowData: IMailboxErrorLog) => (
                            <PopoverCell
                                dataKey="mailboxAddress"
                                rowData={rowData}
                            />
                        )}
                    </Table.Cell>
                </Table.Column>

                <Table.Column flexGrow={3}>
                    <Table.HeaderCell>
                        {t(`tenantConfig.captureMailboxes.table.failureReason`)}
                    </Table.HeaderCell>
                    <Table.Cell dataKey="failureReason">
                        {(rowData: IMailboxErrorLog) => (
                            <PopoverCell
                                className="error-text"
                                dataKey="failureReason"
                                rowData={rowData}
                            />
                        )}
                    </Table.Cell>
                </Table.Column>
            </Table>
        </ModalContainer>
    );
};

export default MailboxesErrorLogs;
