import { defaultErrorResponse } from "../../defaults";
import { API } from "../../../utils/api-config";
import Axios, { CancelTokenSource } from "axios";
import { ActivityLogPagination } from "store/environments/tenants/tenant-activity-log/tenant-activity-log-types";

export const loadActivityLog = async (
    environmentMoniker: string,
    tenantMoniker: string,
    pageIndex?: number,
    pageSize?: number,
    stateDate?: string | null,
    endDate?: string | null,
    selectedActionTypes?: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[ActivityLogPagination | null, number]> => {
    if (!environmentMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.get(
        `/environments/${environmentMoniker}/tenants/${tenantMoniker}/activity-logs?pageIndex=${pageIndex}&pageSize=${pageSize}&startDate=${
            stateDate ?? ""
        }&endDate=${endDate ?? ""}&actionTypes=${selectedActionTypes}`,
        { cancelToken: cancelTokenSource?.token }
    );

    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};
