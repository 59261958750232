import { AppThunk } from "../store";
import { setUserPermissions } from "./app-global-actions";
import { loadUserPermissions } from "../../api/app-global-api";
import {
    setErrorResponseMessage,
    setLoaderVisibility,
} from "store/common/initialization/initialization-actions";
import constants from "utils/constants";
import { getErrorMessage } from "../../api/defaults";

export const fetchUserPermissions = (): AppThunk => async (dispatch) => {
    dispatch(setLoaderVisibility(true));
    dispatch(setErrorResponseMessage(""));

    const [payload, status] = await loadUserPermissions();

    if (payload !== null && status === constants.statusCode.OK) {
        dispatch(setUserPermissions(payload));
    } else {
        const errorMessage = getErrorMessage(String(payload), status);

        dispatch(setErrorResponseMessage(errorMessage));
    }

    dispatch(setLoaderVisibility(false));
};
