import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Message, toaster } from "rsuite";
import css from "components/BreadCrumb/BreadCrumb.module.css";
import ProcessModalButton from "components/WorkflowLog/ProcessModalButton/ProcessModalButton";
import {
    ITenantDeleteWorkflow,
    WorkflowModalType,
} from "utils/tenant-workflows/tenant-workflows-types";
import {
    getTenantDelete,
    startTenantDelete,
} from "api/environments/tenants/tenant-delete-api";
import constants from "utils/constants";
import { getErrorMessage } from "api/defaults";
import ConfirmModalContainer from "components/Common/ConfirmModal/ConfirmModalContainer";
import SignalRTenantDelete from "components/WorkflowLog/TenantDelete/SignalRTenantDelete";
import {
    EnrollStatus,
    IEnrollCache,
    IEnrollStep,
    IEnrollWorkflow,
} from "store/environments/tenants/enroll-tenant/enroll-tenant-types";
import { template } from "lodash";
import { isEveryWorkflowStepCompleted } from "utils/tenant-workflows/tenant-workflows-helper";
import { useNavigate } from "react-router";
import cn from "classnames";

interface ComponentProps {
    envMoniker: string;
    activeEnvName: string;
    enrollSteps: IEnrollStep[] | null;
    activeTenantDeleteWorkflow: ITenantDeleteWorkflow | null;
    orchestratorInstanceId: string | null;
    enrollCache: IEnrollCache | null;
    enrollWorkflow: IEnrollWorkflow | null;
    fetchEnrollCache: (
        environmentMoniker: string,
        orchestratorInstanceId: string
    ) => Promise<[IEnrollCache | null, number]>;
    fetchEnrollWorkflow: (
        environmentMoniker: string,
        orchestratorInstanceId: string
    ) => void;
    setLoaderVisibility: (visible: boolean) => void;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
    setTenantDeleteWorkflow: (workflow: ITenantDeleteWorkflow | null) => void;
}

const DeleteTenantEnrollBar: React.FC<ComponentProps> = ({
    envMoniker,
    activeEnvName,
    enrollSteps,
    activeTenantDeleteWorkflow,
    orchestratorInstanceId,
    enrollCache,
    enrollWorkflow,
    fetchEnrollCache,
    fetchEnrollWorkflow,
    setLoaderVisibility,
    setIsUnsavedChangeAvailable,
    setTenantDeleteWorkflow,
}: ComponentProps) => {
    const { t } = useTranslation();
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [deleteWorkflowId, setDeleteWorkflowId] = useState<string>("");
    const [confirmationDialogMessage, setConfirmationDialogMessage] =
        useState("");
    const [deleteCompleted, setDeleteCompleted] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isDeleteAllowed, setIsDeleteAllowed] = useState(false);
    const [moniker, setMoniker] = useState("");
    const [tenantId, setTenantId] = useState("");
    const [tenantName, setTenantName] = useState("");
    const [enrollCacheLoading, setEnrollCacheLoading] = useState(false);
    const [deleteWorkflowIsLoading, setDeleteWorkflowIsLoading] =
        useState(false);
    const [deleteWorkFlowFetchedFor, setDeleteWorkFlowFetchedFor] =
        useState("");
    const navigate = useNavigate();

    const handleCancel = () => {
        setConfirmVisible(false);
    };

    const handleConfirm = async () => {
        setLoaderVisibility(true);
        setIsUnsavedChangeAvailable(false);

        startTenantDelete(envMoniker, moniker, {
            storeInvoiceDailyVolumes: false,
        }).then(([payload, status]) => {
            if (payload && status === constants.statusCode.OK) {
                setDeleteWorkflowId(payload);
                getTenantDelete(envMoniker, moniker)
                    .then(([payload, status]) => {
                        setLoaderVisibility(false);
                        if (payload && status === constants.statusCode.OK) {
                            setTenantDeleteWorkflow(payload);
                        } else {
                            const errorMessage = getErrorMessage(
                                String(payload),
                                status
                            );
                            toaster.push(
                                <Message type="error" showIcon>
                                    {errorMessage}
                                </Message>,
                                {
                                    duration: constants.errorAlertDurationMsec,
                                }
                            );
                        }
                    })
                    .then(() => {
                        if (orchestratorInstanceId) {
                            fetchEnrollWorkflow(
                                envMoniker,
                                orchestratorInstanceId
                            );
                        }
                    });
            } else {
                const errorMessage = getErrorMessage(String(payload), status);
                toaster.push(
                    <Message type="error" showIcon>
                        {errorMessage}
                    </Message>,
                    {
                        duration: constants.errorAlertDurationMsec,
                    }
                );
                setLoaderVisibility(false);
            }
        });
        setConfirmVisible(false);
    };

    const handleDelete = () => {
        setConfirmVisible(true);
    };

    function updateWorkflow(workflow: ITenantDeleteWorkflow) {
        setTenantDeleteWorkflow(workflow);
    }

    useEffect(() => {
        setConfirmationDialogMessage(
            template(t("tenantDelete.areYouSure"))({
                tenantName: tenantName,
            })
        );
    }, [tenantName]);

    useEffect(() => {
        if (enrollCache && enrollCache.tenantMoniker) {
            setTenantId(enrollCache.tenantId);
            setMoniker(enrollCache.tenantMoniker);
            setTenantName(enrollCache.tenantName);
        }

        if (
            !moniker &&
            (!enrollCache || !enrollCache.tenantMoniker) &&
            orchestratorInstanceId &&
            enrollSteps &&
            enrollSteps[0].status > 1 &&
            !enrollCacheLoading
        ) {
            setEnrollCacheLoading(true);
            fetchEnrollCache(envMoniker, orchestratorInstanceId).then(
                ([payload, statusCode]) => {
                    setEnrollCacheLoading(false);
                    if (!payload || statusCode !== constants.statusCode.OK) {
                        const errorMessage = getErrorMessage(
                            String(payload),
                            statusCode
                        );
                        toaster.push(
                            <Message type="error" showIcon>
                                {errorMessage}
                            </Message>,
                            {
                                duration: constants.errorAlertDurationMsec,
                            }
                        );
                    }
                }
            );
        }
    }, [enrollWorkflow, enrollCache, orchestratorInstanceId, enrollSteps]);

    useEffect(() => {
        if (activeTenantDeleteWorkflow) {
            setDeleteCompleted(
                isEveryWorkflowStepCompleted(activeTenantDeleteWorkflow.steps)
            );
        }
    }, [activeTenantDeleteWorkflow]);

    useEffect(() => {
        if (deleteCompleted && activeTenantDeleteWorkflow) {
            navigate(
                `/environments/${activeEnvName}/deleted-tenant/${moniker}`,
                { replace: true }
            );
        }
    }, [deleteCompleted]);

    useEffect(() => {
        if (
            !deleteWorkflowIsLoading &&
            tenantId &&
            moniker &&
            deleteWorkFlowFetchedFor !== tenantId
        ) {
            setDeleteWorkflowIsLoading(true);
            getTenantDelete(envMoniker, moniker).then(([payload, status]) => {
                setDeleteWorkflowIsLoading(false);
                setDeleteWorkFlowFetchedFor(tenantId);
                if (payload && status === constants.statusCode.OK) {
                    setTenantDeleteWorkflow(payload);
                }
            });
        }
    }, [moniker, activeTenantDeleteWorkflow, tenantId]);

    useEffect(() => {
        if (
            activeTenantDeleteWorkflow &&
            activeTenantDeleteWorkflow.tenantId === tenantId
        ) {
            setDeleteWorkflowId(activeTenantDeleteWorkflow.id);
        }
    }, [activeTenantDeleteWorkflow, enrollCache]);

    useEffect(() => {
        setIsDeleting(
            !!(
                enrollWorkflow &&
                enrollWorkflow.status === EnrollStatus.Terminating
            )
        );
        setIsDeleteAllowed(
            !!(tenantId && !enrollWorkflow) ||
                !!(enrollWorkflow && enrollSteps && enrollSteps[0].status > 1)
        );
    }, [activeTenantDeleteWorkflow, enrollWorkflow, enrollCache]);

    return (
        <>
            <ConfirmModalContainer
                handleCancelModal={handleCancel}
                confirmCallbacks={[handleConfirm]}
                isVisible={confirmVisible}
                message={confirmationDialogMessage}
            />
            <SignalRTenantDelete
                deleteWorkflowId={deleteWorkflowId}
                updateWorkflow={updateWorkflow}
            >
                {!deleteCompleted && (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {activeTenantDeleteWorkflow && (
                            <ProcessModalButton
                                workflow={activeTenantDeleteWorkflow}
                                workflowType={WorkflowModalType.TenantDeletion}
                            />
                        )}
                        <Button
                            onClick={handleDelete}
                            disabled={
                                isDeleting ||
                                !isDeleteAllowed ||
                                deleteWorkFlowFetchedFor.length === 0
                            }
                            appearance="primary"
                            className={cn(css.addNewCustomerBtn, css.deleteBtn)}
                        >
                            {t("tenantDelete.deleteButton")}
                        </Button>
                    </div>
                )}
            </SignalRTenantDelete>
        </>
    );
};

export default DeleteTenantEnrollBar;
