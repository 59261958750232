import { RootState } from "store/store";
import { flowRight } from "lodash";
import { connect } from "react-redux";
import {
    getActiveStep,
    getEnrollCache,
    getEnrollId,
    getEnrollWorkflow,
} from "store/environments/tenants/enroll-tenant/enroll-tenant-selectors";
import SetAdmin from "./SetAdmin";
import { setIsUnsavedChangeAvailable } from "store/common/unsaved-data/unsaved-data-actions";
import { setActiveStepData } from "store/environments/tenants/enroll-tenant/enroll-tenant-actions";
import { getActiveEnvironmentMoniker } from "store/environments/environment-selectors";
import { fetchEnrollCache } from "store/environments/tenants/enroll-tenant/enroll-tenant-thunks";
import { getCustomerDetailsWithoutLoader as fetchCustomerDetails } from "store/customers/customer-details/customer-details-thunk";
import { getCustomerDetails } from "store/customers/customer-details/customer-details-selectors";
import withLoader from "hocs/withLoader/withLoader";

const mapStateToProps = (state: RootState) => ({
    activeStep: getActiveStep(state),
    enrollTenant: getEnrollWorkflow(state),
    enrollCache: getEnrollCache(state),
    enrollId: getEnrollId(state),
    customerDetails: getCustomerDetails(state),
    environmentMoniker: getActiveEnvironmentMoniker(state),
});

const Container = connect(mapStateToProps, {
    setActiveStepData,
    setIsUnsavedChangeAvailable,
    fetchEnrollCache,
    fetchCustomerDetails,
})(SetAdmin);

const SetAdminContainer = flowRight(withLoader)(Container);

export default SetAdminContainer;
