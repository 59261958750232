import {
    IContact,
    SET_SUPPORT_CONTACT,
    SetSupportContact,
} from "./contacts-types";

export const setSupportContact = (contact: IContact): SetSupportContact => ({
    type: SET_SUPPORT_CONTACT,
    contact,
});
