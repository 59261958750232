import { connect } from "react-redux";
import { flowRight } from "lodash";

import { RootState } from "store/store";
import { setIsUnsavedChangeAvailable } from "store/common/unsaved-data/unsaved-data-actions";
import { getActiveEnvironmentMoniker } from "store/environments/environment-selectors";
import { setEmailSent } from "store/customers/customer-details/customer-details-actions";

import withLoader from "hocs/withLoader/withLoader";
import withError from "hocs/withError/withError";

import CaptureMailboxes from "./CaptureMailboxes";

const mapStateToProps = (state: RootState) => ({
    envMoniker: getActiveEnvironmentMoniker(state),
    tenantMoniker: state.tenantsReducer.activeTenant.moniker,
});
const Container = connect(mapStateToProps, {
    setIsUnsavedChangeAvailable,
    setEmailSent,
})(CaptureMailboxes);

const CaptureMailboxesContainer = flowRight(withLoader, withError)(Container);

export default CaptureMailboxesContainer;
