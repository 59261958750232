import { RootState } from "store/store";
import { connect } from "react-redux";
import withLoader from "hocs/withLoader/withLoader";
import { getActiveEnvironmentMoniker } from "store/environments/environment-selectors";
import { setIsUnsavedChangeAvailable } from "store/common/unsaved-data/unsaved-data-actions";
import withError from "hocs/withError/withError";
import MaintenanceMode from "./MaintenanceMode";
import { flowRight } from "lodash";

const mapStateToProps = (state: RootState) => {
    return {
        envMoniker: getActiveEnvironmentMoniker(state),
        tenantMoniker: state.tenantsReducer.activeTenant.moniker,
    };
};
const Container = connect(mapStateToProps, {
    setIsUnsavedChangeAvailable,
})(MaintenanceMode);

const MaintenanceModeContainer = flowRight(withLoader, withError)(Container);

export default MaintenanceModeContainer;
