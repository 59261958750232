import {
    ICustomerDetails,
    IEmailDetails,
} from "store/customers/customer-details/customer-details-types";
import { API } from "../../utils/api-config";
import { defaultErrorResponse } from "../defaults";

export const getCustomerDetails = async (
    customerId: string
): Promise<[ICustomerDetails | null, number]> => {
    const response = await API.get(`/customers/${customerId}/details`);

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const updateCustomerDetails = async (
    customerId: string,
    customerDetails: ICustomerDetails
): Promise<[void | null, number]> => {
    const response = await API.put(
        `/customers/${customerId}/details`,
        customerDetails
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const sendEmail = async (
    customerId: string,
    emailDetails: IEmailDetails
): Promise<[void | null, number]> => {
    const response = await API.post(
        `/customers/${customerId}/details/send-email`,
        emailDetails
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};
