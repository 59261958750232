import { connect } from "react-redux";
import RefreshButton from "./RefreshButton";
import { setActiveEnvironmentByName } from "store/environments/environment-actions";
import { setRefreshActivated } from "store/common/page-refresh/page-refresh-actions";

const RefreshButtonContainer = connect(null, {
    setActiveEnvironmentByName,
    setRefreshActivated,
})(RefreshButton);

export default RefreshButtonContainer;
