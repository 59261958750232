import React from "react";
import { Container, Content, Sidebar } from "rsuite";
import css from "./DeliverablesLayout.module.css";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "utils/routes";
import constants from "utils/constants";
import DeliverablesMenu from "components/Deliverables/DeliverablesMenu/DeliverablesMenu";

const DeliverablesLayout: React.FC = () => {
    const leftMenuRouters = routes.deliverablesLayout.map((route, index) => {
        return (
            <Route
                key={String(index)}
                path={route.path}
                element={<route.componentName />}
            />
        );
    });
    return (
        <>
            <Container className={css.container}>
                <Sidebar className={`${css.leftMenu} ${css.scrollableArea}`}>
                    <DeliverablesMenu />
                </Sidebar>
                <Content
                    className={`${css.contentSection} ${css.scrollableArea}`}
                >
                    <Routes>
                        <Route
                            key={String(leftMenuRouters.length)}
                            path={constants.routePath.root}
                            element={
                                <Navigate
                                    to={
                                        constants.routePath.deliverablesPackages
                                    }
                                />
                            }
                        />
                        {leftMenuRouters}
                    </Routes>
                </Content>
            </Container>
        </>
    );
};

export default DeliverablesLayout;
