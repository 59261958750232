import { RootState } from "store/store";
import { connect } from "react-redux";
import CustomerNavigation from "./CustomerNavigation";
import {
    setIsUnsavedChangeAvailable,
    setModalConfirmCallback,
} from "store/common/unsaved-data/unsaved-data-actions";
import { setRefreshActivated } from "store/common/page-refresh/page-refresh-actions";
import { getAppSettings } from "store/app-settings/app-settings-selectors";

const mapStateToProps = (state: RootState) => {
    return {
        appSettings: getAppSettings(state),
        isUnsavedChangeAvailable:
            state.unsavedDataReducer.isUnsavedChangeAvailable,
    };
};
const CustomerNavigationContainer = connect(mapStateToProps, {
    setIsUnsavedChangeAvailable,
    setModalConfirmCallback,
    setRefreshActivated,
})(CustomerNavigation);

export default CustomerNavigationContainer;
