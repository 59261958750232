import { RootState } from "store/store";
import { connect } from "react-redux";
import { setErrorResponseMessage } from "store/common/initialization/initialization-actions";
import EmailTemplates from "./EmailTemplates";
import withLoader from "hocs/withLoader/withLoader";
import { compose } from "redux";
import {
    setManageTemplateDetails,
    setManageTemplateOpened,
} from "store/deliverables/email-templates/email-templates-actions";

const mapStateToProps = (state: RootState) => {
    return {
        errorMessage: state.initializationReducer.errorResponseMessage,
    };
};
const EmailTemplatesContainer = compose(
    connect(mapStateToProps, {
        setErrorResponseMessage,
        setManageTemplateOpened,
        setManageTemplateDetails,
    }),
    withLoader
)(EmailTemplates);

export default EmailTemplatesContainer;
