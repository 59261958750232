import { AppThunk } from "../store";
import { setAppSettings } from "./app-settings-actions";
import { loadAppSettings } from "../../api/app-settings-api";
import {
    setErrorResponseMessage,
    setLoaderVisibility,
} from "store/common/initialization/initialization-actions";
import constants from "utils/constants";
import { getErrorMessage } from "../../api/defaults";

export const fetchAppSettings = (): AppThunk => async (dispatch) => {
    dispatch(setLoaderVisibility(true));
    dispatch(setErrorResponseMessage(""));

    const [payload, status] = await loadAppSettings();

    if (payload && status === constants.statusCode.OK) {
        dispatch(setAppSettings(payload));
    } else {
        const errorMessage = getErrorMessage(String(payload), status);

        dispatch(setErrorResponseMessage(errorMessage));
    }

    dispatch(setLoaderVisibility(false));
};
