import { SET_SUPPORT_CONTACT, Actions, State } from "./contacts-types";

const initialState: State = {
    supportContact: {
        email: "",
    },
};

const contactsReducer = (
    state: State = initialState,
    action: Actions
): State => {
    switch (action.type) {
        case SET_SUPPORT_CONTACT:
            return {
                ...state,
                supportContact: action.contact,
            };

        default:
            return state;
    }
};

export default contactsReducer;
