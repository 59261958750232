import {
    SET_MAILBOXES_CREATION_OPENED,
    ISetMailboxesCreationOpened,
} from "./tenant-capture-mailboxes-types";

export const setCreateMailboxesOpened = (
    isMailboxesCreationOpened: boolean
): ISetMailboxesCreationOpened => ({
    type: SET_MAILBOXES_CREATION_OPENED,
    isMailboxesCreationOpened,
});
