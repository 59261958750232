import React from "react";
import { useTranslation } from "react-i18next";
import {
    ICustomerConsentsMaster,
    regionNames,
} from "store/customers/add-new-customer/add-new-customer-types";
import "./Consents.less";

interface ComponentProps {
    consents: ICustomerConsentsMaster[];
    regions: string[];
}
const Consents: React.FC<ComponentProps> = ({
    consents,
    regions,
}: ComponentProps) => {
    const { t } = useTranslation();

    const consentList = regions.map((region, index) => {
        const consentNames = consents
            .filter(
                (consent) =>
                    consent.dooapEnvironmentAppRegistration?.dooapEnvironment
                        .region === region
            )
            .map((consent) => {
                return `${consent.dooapEnvironmentAppRegistration?.dooapAadApplicationType.description} ${consent.dooapEnvironmentAppRegistration?.dooapEnvironment.name}`;
            });
        if (consentNames.length) {
            return (
                <div key={String(index)} className="consents__container">
                    <h4>
                        {`${t("addNewCustomer.consentList.header")} ${
                            regionNames[region] ?? region
                        }`}
                    </h4>
                    <ul className="consents__list">
                        {consentNames.map((consentName, index) => (
                            <li key={String(index)}>{consentName}</li>
                        ))}
                    </ul>
                </div>
            );
        }

        return null;
    });
    const isConsentListEmpty = consentList.filter((list) => list).length === 0;

    if (isConsentListEmpty) {
        return <></>;
    }

    return <div className="consents">{consentList}</div>;
};

export default Consents;
