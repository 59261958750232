import { RootState } from "store/store";
import { connect } from "react-redux";
import { flowRight } from "lodash";
import PartnerDetails from "./PartnerDetails";
import { setActivePartner } from "store/partners/partners-actions";
import { setLoaderVisibility } from "store/common/initialization/initialization-actions";
import { setIsUnsavedChangeAvailable } from "store/common/unsaved-data/unsaved-data-actions";
import withError from "hocs/withError/withError";
import { fetchPartners } from "store/partners/partners-thunks";

const mapStateToProps = (state: RootState) => {
    return {
        isRefreshActivated: state.pageRefreshReducer.isRefreshActivated,
    };
};
const Container = connect(mapStateToProps, {
    fetchPartners,
    setLoaderVisibility,
    setActivePartner,
    setIsUnsavedChangeAvailable,
})(PartnerDetails);

const PartnerDetailsContainer = flowRight(withError)(Container);

export default PartnerDetailsContainer;
