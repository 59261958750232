import { connect } from "react-redux";
import SendEmail from "./SendEmail";
import { RootState } from "store/store";
import { getActiveEnvironmentMoniker } from "store/environments/environment-selectors";

const mapStateToProps = (state: RootState) => {
    return {
        envMoniker: getActiveEnvironmentMoniker(state),
        isEmailSent: state.customerDetailsReducer.isEmailSent,
    };
};

const SendEmailContainer = connect(mapStateToProps, {})(SendEmail);

export default SendEmailContainer;
