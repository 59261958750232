import Environments from "./Environments";
import { connect } from "react-redux";
import { RootState } from "store/store";
import {
    setActiveEnvironmentById,
    setActiveEnvironmentTenantsMonikers,
} from "store/environments/environment-actions";
import { compose } from "redux";
import { fetchEnvironments } from "store/environments/environment-thunks";
import { getEnvironmentList } from "store/environments/environment-selectors";

const mapStateToProps = (state: RootState) => {
    return {
        environmentList: getEnvironmentList(state),
        errorMessage: state.initializationReducer.errorResponseMessage,
    };
};

const EnvironmentsContainer = compose(
    connect(mapStateToProps, {
        setActiveEnvironmentById,
        setActiveEnvironmentTenantsMonikers,
        fetchEnvironments,
    })
)(Environments);

export default EnvironmentsContainer;
