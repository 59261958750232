import { defaultErrorResponse } from "api/defaults";
import { API } from "utils/api-config";
import { IVersionHistory } from "store/environments/tenants/version-history/version-history-types";
import Axios, { CancelTokenSource } from "axios";

export const loadTenantErpVersionHistory = async (
    environmentMoniker: string,
    tenantMoniker: string,
    versionType: number,
    cancelTokenSource?: CancelTokenSource
): Promise<[IVersionHistory | null, number]> => {
    if (!environmentMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }
    const response = await API.get(
        `/environments/${environmentMoniker}/tenants/${tenantMoniker}/erp/version/${versionType}/history`,
        { cancelToken: cancelTokenSource?.token }
    );

    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};
