import { RootState } from "store/store";
import { connect } from "react-redux";
import BreadCrumb from "./BreadCrumb";
import { compose } from "redux";
import { setIsModalOpen } from "store/common/modal-window/modal-window-actions";
import {
    getIsActiveEnvironmentSet,
    getIsDeleteAllowed,
    getIsEnrollAllowed,
} from "store/environments/environment-selectors";
import {
    getIsActiveTenantSet,
    getTenantDisplayName,
    getTenantMoniker,
} from "store/environments/tenants/tenants-selectors";
import { getCustomerName } from "store/customers/customers-selectors";
import { getPartnerName } from "store/partners/partners-selectors";
import {
    getIsEmailTemplatesAllowed,
    getIsUserPartner,
    getIsPackagesModificationAllowed,
} from "store/app-global/app-global-selectors";
import { setAddNewGuestTenant } from "store/environments/tenants/tenant-guest-tenants/tenant-guest-tenants-actions";
import { setEnvironmentHistoryOpened } from "store/environments/environment-history/environment-history-actions";
import { setAddNewPartner } from "store/partners/partners-actions";
import {
    setManagePackageDetails,
    setManagePackageOpened,
} from "store/deliverables/erp-packages/erp-packages-actions";
import {
    setManageTemplateOpened,
    setManageTemplateDetails,
} from "store/deliverables/email-templates/email-templates-actions";
import { setAppRegistrationInfoOpened } from "store/environments/environment-actions";
import { setCreateMailboxesOpened } from "store/environments/tenants/tenant-capture-mailboxes/tenant-capture-mailboxes-actions";

const mapStateToProps = (state: RootState) => {
    return {
        tenantDisplayName: getTenantDisplayName(state),
        tenantMoniker: getTenantMoniker(state),
        customerName: getCustomerName(state),
        partnerName: getPartnerName(state),
        isEnrollAllowed: getIsEnrollAllowed(state),
        isDeleteAllowed: getIsDeleteAllowed(state),
        isUserPartner: getIsUserPartner(state),
        isActiveEnvironment: getIsActiveEnvironmentSet(state),
        isActiveTenant: getIsActiveTenantSet(state),
        isPackagesModificationAllowed: getIsPackagesModificationAllowed(state),
        isTemplatesAccessAllowed: getIsEmailTemplatesAllowed(state),
    };
};

const BreadCrumbContainer = compose(
    connect(mapStateToProps, {
        setIsModalOpen,
        setAddNewGuestTenant,
        setEnvironmentHistoryOpened,
        setAppRegistrationInfoOpened,
        setAddNewPartner,
        setManagePackageOpened,
        setManagePackageDetails,
        setManageTemplateOpened,
        setManageTemplateDetails,
        setCreateMailboxesOpened,
    })
)(BreadCrumb);

export default BreadCrumbContainer;
