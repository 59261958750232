import { API } from "../../../utils/api-config";
import {
    ICultureSettings,
    TCurrency,
    TCultureSettings,
} from "store/environments/tenants/tenant-culture-settings/tenant-culture-settings-types";
import { defaultErrorResponse } from "../../defaults";
import Axios, { CancelTokenSource } from "axios";

export const getCultureSettings = async (
    environmentMoniker: string,
    tenantMoniker: string,
    cancelTokenSource?: CancelTokenSource
): Promise<TCultureSettings> => {
    if (!environmentMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.get(
        `environments/${environmentMoniker}/tenants/${tenantMoniker}/culture-settings`,
        { cancelToken: cancelTokenSource?.token }
    );

    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};

export const putCultureSettings = async (
    envMoniker: string,
    tenantMoniker: string,
    cultureSettings: ICultureSettings
): Promise<[ICultureSettings[] | null, number]> => {
    if (!envMoniker || !tenantMoniker || !cultureSettings) {
        return defaultErrorResponse;
    }

    const response = await API.put(
        `environments/${envMoniker}/tenants/${tenantMoniker}/culture-settings`,
        cultureSettings
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const getCurrencies = async (
    environmentMoniker: string,
    tenantMoniker: string,
    cancelTokenSource?: CancelTokenSource
): Promise<TCurrency> => {
    if (!environmentMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.get(
        `environments/${environmentMoniker}/tenants/${tenantMoniker}/culture-settings/currencies`,
        { cancelToken: cancelTokenSource?.token }
    );

    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};

export const getSupportedCultures = async (
    environmentMoniker: string,
    tenantMoniker: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[string[] | null, number]> => {
    const response = await API.get(
        `environments/${environmentMoniker}/tenants/${tenantMoniker}/culture-settings/cultures/supported`,
        { cancelToken: cancelTokenSource?.token }
    );

    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};

export const getCultures = async (
    environmentMoniker: string,
    tenantMoniker: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[string[] | null, number]> => {
    const response = await API.get(
        `environments/${environmentMoniker}/tenants/${tenantMoniker}/culture-settings/cultures`,
        { cancelToken: cancelTokenSource?.token }
    );

    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};
