import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    ButtonToolbar,
    Form,
    FormInstance,
    Message,
    Schema,
    toaster,
} from "rsuite";
import ModalContainer from "components/Common/Modal/ModalContainer";
import {
    IGuestTenant,
    IGuestTenantDooapAppRegistration,
} from "store/environments/tenants/tenant-guest-tenants/tenant-guest-tenants-types";
import constants from "utils/constants";
import { regionNames } from "store/customers/add-new-customer/add-new-customer-types";
import {
    createGuestTenant,
    loadGuestTenantConsents,
} from "api/environments/tenants/guest-tenants-api";

interface ComponentProps {
    envMoniker: string;
    tenantMoniker: string;
    isOpened: boolean;
    allTenants: IGuestTenant[] | null | undefined;
    setIsOpened: (isOpened: boolean) => void;
    refreshGuestTenants: () => void;
    setModalLoaderVisibility: (visible: boolean) => void;
}

const NewGuestTenant: React.FC<ComponentProps> = ({
    envMoniker,
    tenantMoniker,
    isOpened,
    allTenants,
    setIsOpened,
    refreshGuestTenants,
    setModalLoaderVisibility,
}: ComponentProps) => {
    const { t } = useTranslation();
    const formRef = useRef<FormInstance>(null);
    const defaultFormValue = {
        tenantName: "",
    };
    const [guestTenantName, setGuestTenantName] = useState<string>("");
    const [isConsentsDownloaded, setIsConsentsDownloaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tenantNames, setTenantNames] = useState<string[]>([]);
    const [consentsList, setConsents] = useState<
        IGuestTenantDooapAppRegistration[] | null
    >();
    const [formValue, setFormValue] = useState(defaultFormValue);
    const onModalCloseHandler = () => {
        setFormValue(defaultFormValue);
        setIsOpened(false);
    };
    const handleSubmit = async () => {
        if (!formRef.current?.check()) {
            return;
        }
        setIsLoading(true);
        setModalLoaderVisibility(true);
        const guestTenant = { name: guestTenantName.trim() };
        createGuestTenant(envMoniker, tenantMoniker, guestTenant).then(
            ([result, status]) => {
                if (
                    status === constants.statusCode.OK ||
                    status === constants.statusCode.NoContent
                ) {
                    onModalCloseHandler();
                    refreshGuestTenants();
                } else {
                    const reason = result ? result : "";
                    toaster.push(
                        <Message showIcon type="error">
                            {`${t(
                                "tenantConfig.guestTenants.newGuestTenant.failedToCreate"
                            )} ${reason}`}
                        </Message>,
                        { duration: constants.alertDurationMsec }
                    );
                }
                setIsLoading(false);
                setModalLoaderVisibility(false);
            }
        );
    };
    const handleFormChange = (v: any) => {
        setGuestTenantName(v.tenantName);
        setFormValue(v);
    };

    useEffect(() => {
        if (envMoniker && tenantMoniker && !isConsentsDownloaded && isOpened) {
            setIsLoading(true);
            setModalLoaderVisibility(true);
            loadGuestTenantConsents(envMoniker, tenantMoniker).then(
                ([loadedConsentsList, success]) => {
                    setIsLoading(false);
                    setModalLoaderVisibility(false);
                    if (success) {
                        setConsents(loadedConsentsList);
                        setIsConsentsDownloaded(true);
                    } else {
                        toaster.push(
                            <Message showIcon type="error">
                                {t(
                                    "tenantConfig.guestTenants.newGuestTenant.consentsLoadFailed"
                                )}
                            </Message>,
                            { duration: constants.alertDurationMsec }
                        );
                    }
                }
            );
        }
    }, [isOpened, envMoniker, tenantMoniker]);

    useEffect(() => {
        if (allTenants) {
            const names = allTenants.map((value) => value.name);
            setTenantNames(names);
        }
    }, [allTenants]);

    const formKey = {
        tenantName: "tenantName",
    };
    const model = Schema.Model({
        tenantName: Schema.Types.StringType()
            .isRequired(
                t("tenantConfig.guestTenants.newGuestTenant.isRequired")
            )
            .maxLength(
                constants.maxGuestTenantNameLengthLimit,
                t(
                    "tenantConfig.guestTenants.newGuestTenant.maxGuestTenantNameLengthError"
                )
            )
            .minLength(
                constants.minNameLengthLimit,
                t("tenantConfig.guestTenants.newGuestTenant.minLengthError")
            )
            .addRule((enteredValue: string) => {
                return tenantNames.indexOf(enteredValue.trim()) < 0;
            }, t("tenantConfig.guestTenants.newGuestTenant.nameAlreadyExist"))
            .addRule((enteredValue: string) => {
                const final = enteredValue.trim();
                return final.length >= constants.minNameLengthLimit;
            }, t("tenantConfig.guestTenants.newGuestTenant.minLengthError")),
    });

    const header = (
        <h1>{t("tenantConfig.guestTenants.newGuestTenant.title")}</h1>
    );

    const footer = (
        <ButtonToolbar
            className="custom-modal__button-toolbar"
            style={{ marginTop: "40px" }}
        >
            <Button
                type="submit"
                appearance="primary"
                disabled={isLoading || consentsList?.length === 0}
                onClick={handleSubmit}
            >
                {t("tenantConfig.guestTenants.newGuestTenant.create")}
            </Button>
            <Button
                appearance="ghost"
                disabled={isLoading}
                onClick={onModalCloseHandler}
            >
                {t("tenantConfig.guestTenants.newGuestTenant.cancel")}
            </Button>
        </ButtonToolbar>
    );

    const region = consentsList?.length ? consentsList[0].region : "";
    const consentsView =
        consentsList && consentsList.length > 0 ? (
            <div className="consents__container" style={{ marginTop: "1rem" }}>
                <h4>
                    {`${t(
                        "tenantConfig.guestTenants.consentDialog.description"
                    )} ${regionNames[region] ?? region}`}
                </h4>
                <ul className="consents__list">
                    {consentsList.map((item, index) => {
                        return (
                            <li key={String("rowIndex_" + index)}>
                                {item.description} {item.environmentName}
                            </li>
                        );
                    })}
                </ul>
            </div>
        ) : (
            <Message showIcon type="info" title={t("error.possibleFix")}>
                {t("tenantConfig.guestTenants.consentDialog.noConsentError")}
            </Message>
        );

    return (
        <ModalContainer
            open={isOpened}
            onClose={onModalCloseHandler}
            header={header}
            footer={footer}
        >
            <>
                <Form
                    className="Default__form"
                    ref={formRef}
                    model={model}
                    formValue={formValue}
                    onChange={handleFormChange}
                >
                    <Form.Group className="Default__form-group">
                        <Form.ControlLabel>
                            {t(
                                "tenantConfig.guestTenants.newGuestTenant.nameTitle"
                            )}
                        </Form.ControlLabel>
                        <Form.Control
                            placeholder={t(
                                "tenantConfig.guestTenants.newGuestTenant.namePlaceholder"
                            )}
                            name={formKey.tenantName}
                        />
                    </Form.Group>
                </Form>
                {consentsList && consentsView}
            </>
        </ModalContainer>
    );
};

export default NewGuestTenant;
