import { RootState } from "store/store";
import { connect } from "react-redux";
import SettingsFooter from "./SettingsFooter";

const mapStateToProps = (state: RootState) => {
    return {
        isUnsavedChangeAvailable:
            state.unsavedDataReducer.isUnsavedChangeAvailable,
    };
};
const SettingsFooterContainer = connect(mapStateToProps, {})(SettingsFooter);

export default SettingsFooterContainer;
