import React from "react";
import { FormControlProps } from "rsuite";
import { Input } from "rsuite";
interface TextareaProps
    extends Omit<
        FormControlProps,
        keyof React.HTMLAttributes<HTMLInputElement>
    > {
    rows: number;
    placeholder: string;
}

// eslint-disable-next-line react/display-name
const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
    (props, ref) => <Input {...props} as="textarea" ref={ref} />
);

export default Textarea;
