import constants from "./constants";

const tableMinModalHeight = constants.dimensions.table.minModalHeight;
const tableMaxModalHeight = constants.dimensions.table.maxModalHeight;
const tableModalRowHeight = constants.dimensions.table.defaultModalRowHeight;

export const getModalTableHeight = (row: number): number => {
    const defaultHeight = tableModalRowHeight + tableModalRowHeight * row;

    return tableMinModalHeight > defaultHeight
        ? tableMinModalHeight
        : tableMaxModalHeight < defaultHeight
          ? tableMaxModalHeight
          : defaultHeight;
};
