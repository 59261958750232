import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Nav } from "rsuite";
import { IModalCallback } from "store/common/unsaved-data/unsaved-data-types";
import { v4 as uuid } from "uuid";
import cn from "classnames";
import "./SchedulerNavigation.less";

interface ComponentProps {
    active: string;
    isUnsavedChangeAvailable: boolean;
    isDataLoading: boolean;
    isRecurringInvoicesEnabled: boolean;
    setActiveSchedulerTab: Dispatch<SetStateAction<string>>;
    setModalConfirmCallback: (modalConfirmCallback: IModalCallback) => void;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
    setRefreshActivated: (isRefreshActivated: boolean) => void;
}
const SchedulerNavigation: React.FC<ComponentProps> = ({
    active,
    isUnsavedChangeAvailable,
    isDataLoading,
    isRecurringInvoicesEnabled,
    setActiveSchedulerTab,
    setModalConfirmCallback,
    setIsUnsavedChangeAvailable,
    setRefreshActivated,
}: ComponentProps) => {
    const { t } = useTranslation();

    const onSelectTabHandler = (eventKey: string) => {
        if (isUnsavedChangeAvailable && eventKey !== active) {
            setModalConfirmCallback({
                id: uuid(),
                isAutoDeleteResistant: false,
                isPageRedirect: true,
                callback: () => {
                    setIsUnsavedChangeAvailable(false);
                    setActiveSchedulerTab(eventKey);
                },
            });
            setRefreshActivated(true);
            return;
        }
        setActiveSchedulerTab(eventKey);
    };

    return (
        <Nav
            appearance="subtle"
            activeKey={active}
            onSelect={onSelectTabHandler}
            className={cn("navbar__container", "scheduler__navbar")}
        >
            <Nav.Item eventKey="email" disabled={isDataLoading}>
                {t("tenantConfig.scheduler.navBar.email")}
            </Nav.Item>
            <Nav.Item eventKey="vendorPrepaymentCheck" disabled={isDataLoading}>
                {t("tenantConfig.scheduler.navBar.vendorPrepaymentCheck")}
            </Nav.Item>
            <Nav.Item eventKey="invoiceWaitingForPr" disabled={isDataLoading}>
                {t("tenantConfig.scheduler.navBar.invoiceWaitingForPr")}
            </Nav.Item>
            <Nav.Item eventKey="mlModelReTraining" disabled={isDataLoading}>
                {t("tenantConfig.scheduler.navBar.mlModelReTraining")}
            </Nav.Item>
            <Nav.Item eventKey="poOnHoldMonthly" disabled={isDataLoading}>
                {t("tenantConfig.scheduler.navBar.poOnHoldMonthly")}
            </Nav.Item>
            <Nav.Item eventKey="onHoldDueDate" disabled={isDataLoading}>
                {t("tenantConfig.scheduler.navBar.onHoldDueDate")}
            </Nav.Item>
            {isRecurringInvoicesEnabled && (
                <Nav.Item eventKey="recurringInvoices" disabled={isDataLoading}>
                    {t("tenantConfig.scheduler.navBar.recurringInvoices")}
                </Nav.Item>
            )}
        </Nav>
    );
};

export default SchedulerNavigation;
