import { connect } from "react-redux";
import ErpPackageCard from "./ErpPackageCard";
import {
    setEmailsSendingOpened,
    setManagePackageDetails,
    setManagePackageOpened,
} from "store/deliverables/erp-packages/erp-packages-actions";
import { getIsPackagesModificationAllowed } from "store/app-global/app-global-selectors";
import { RootState } from "store/store";

const mapStateToProps = (state: RootState) => {
    return {
        isPackagesModificationAllowed: getIsPackagesModificationAllowed(state),
    };
};
const ErpPackageCardContainer = connect(mapStateToProps, {
    setManagePackageOpened,
    setEmailsSendingOpened,
    setManagePackageDetails,
})(ErpPackageCard);

export default ErpPackageCardContainer;
