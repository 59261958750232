import { IPartnerDetails } from "store/partners/partners-types";
import { API } from "../../utils/api-config";
import { defaultErrorResponse } from "../defaults";

export const loadPartnerDetails = async (
    partnerId: string
): Promise<[IPartnerDetails | null, number]> => {
    const response = await API.get(`/partners/${partnerId}/details`);

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const updatePartnerDetails = async (
    partnerId: string,
    partnerDetails: IPartnerDetails
): Promise<[null, number]> => {
    const response = await API.put(
        `/partners/${partnerId}/details`,
        partnerDetails
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};
