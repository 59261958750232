import { RootState } from "store/store";
import { connect } from "react-redux";
import { compose } from "redux";
import DataImport from "./DataImport";

import withLoader from "hocs/withLoader/withLoader";
import {
    fetchTenantAxImport,
    updateTenantAxImportEntities,
    transferCefData,
} from "store/environments/tenants/data-import/data-import-thunks";
import {
    fetchTenantChangeEntityFrameworkSettingWorkflows,
    updateTenantChangeEntityFrameworkSettings,
} from "store/environments/tenants/tenant-feature-settings/tenant-feature-settings-thunks";
import { setIsUnsavedChangeAvailable } from "store/common/unsaved-data/unsaved-data-actions";
import { getActiveEnvironmentMoniker } from "store/environments/environment-selectors";
import { getIsUserPartner } from "store/app-global/app-global-selectors";
import { getAppSettings } from "store/app-settings/app-settings-selectors";
import { getTenantCustomerType } from "store/environments/tenants/tenants-selectors";

const mapStateToProps = (state: RootState) => {
    return {
        envMoniker: getActiveEnvironmentMoniker(state),
        tenantMoniker: state.tenantsReducer.activeTenant.moniker,
        customerType: getTenantCustomerType(state),
        isPartner: getIsUserPartner(state),
        errorMessage:
            state.initializationReducer.errorResponseMessage ||
            state.initializationReducer.errorMessageInactiveEnvironment ||
            state.initializationReducer.errorMessageInactiveTenant,
        isRunAllEnabled: getAppSettings(state).isRunAllEnabled,
    };
};
const DataImportContainer = compose(
    connect(mapStateToProps, {
        setIsUnsavedChangeAvailable,
        updateTenantAxImportEntities,
        fetchTenantAxImport,
        fetchTenantChangeEntityFrameworkSettingWorkflows,
        updateTenantChangeEntityFrameworkSettings,
        transferCefData,
    }),
    withLoader
)(DataImport);

export default DataImportContainer;
