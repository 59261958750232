import React from "react";
import { createRoot } from "react-dom/client";
import AppContainer from "./components/App/AppContainer";
import "./utils/i18n";
import { Provider } from "react-redux";
import store from "./store/store";
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
} from "react-router";
import { MsalProvider } from "@azure/msal-react";
import { authProvider } from "./utils/auth-provider";

document.title = `${process.env.REACT_APP_ENV_NAME} Control App`;

const Index: React.FC = () => {
    const router = createBrowserRouter(
        createRoutesFromElements(<Route path="*" element={<AppContainer />} />)
    );
    return <RouterProvider router={router} />;
};

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
    <Provider store={store}>
        <MsalProvider instance={authProvider}>
            <Index />
        </MsalProvider>
    </Provider>
);
