import { connect } from "react-redux";

import { RootState } from "store/store";
import { getActiveEnvironmentMoniker } from "store/environments/environment-selectors";
import { setModalLoaderVisibility } from "store/common/modal-window/modal-window-actions";
import { setErrorResponseMessage } from "store/common/initialization/initialization-actions";

import MailboxesErrorLogs from "./MailboxesErrorLogs";

const mapStateToProps = (state: RootState) => ({
    envMoniker: getActiveEnvironmentMoniker(state),
    tenantMoniker: state.tenantsReducer.activeTenant.moniker,
});

const MailboxesErrorLogsContainer = connect(mapStateToProps, {
    setModalLoaderVisibility,
    setErrorResponseMessage,
})(MailboxesErrorLogs);

export default MailboxesErrorLogsContainer;
