import ConfirmModalContainer from "components/Common/ConfirmModal/ConfirmModalContainer";
import React, { useEffect, useState } from "react";
import { Button, Message, Tooltip, toaster, Whisper } from "rsuite";
import css from "components/BreadCrumb/BreadCrumb.module.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { deleteCustomer } from "api/customers/customers-api";
import constants from "utils/constants";
import { getErrorMessage } from "api/defaults";
import { ICustomerDetails } from "store/customers/customer-details/customer-details-types";
import { template } from "lodash";
import cn from "classnames";

interface ComponentProps {
    customerDetails: ICustomerDetails | null;
    tenantsAmount: number;
    setLoaderVisibility: (visible: boolean) => void;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
}

const DeleteCustomerButton: React.FC<ComponentProps> = ({
    customerDetails,
    tenantsAmount,
    setLoaderVisibility,
    setIsUnsavedChangeAvailable,
}: ComponentProps) => {
    const isButtonDisabled = tenantsAmount !== 0;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [confirmationDialogMessage, setConfirmationDialogMessage] =
        useState("");

    const handleConfirm = async () => {
        setLoaderVisibility(true);
        if (customerDetails) {
            deleteCustomer(customerDetails.id).then(([payload, status]) => {
                if (status === constants.statusCode.OK) {
                    toaster.push(
                        <Message type="success" showIcon closable>
                            {template(t("customers.delete.successMessage"))({
                                customerName: customerDetails.name,
                            })}
                        </Message>,
                        {
                            duration: constants.alertDurationMsec,
                        }
                    );
                    navigate(`/customers`);
                } else {
                    const errorMessage = getErrorMessage(
                        String(payload),
                        status
                    );
                    toaster.push(
                        <Message type="error" showIcon closable>
                            {errorMessage}
                        </Message>,
                        {
                            duration: constants.alertDurationMsec,
                        }
                    );
                }
                setLoaderVisibility(false);
            });
        }
        setConfirmVisible(false);
        setIsUnsavedChangeAvailable(false);
    };

    const handleDelete = () => {
        setConfirmVisible(true);
    };

    const handleCancel = () => {
        setConfirmVisible(false);
    };

    useEffect(() => {
        if (customerDetails) {
            setConfirmationDialogMessage(
                template(t("customers.delete.confirmationMessage"))({
                    customerName: customerDetails.name,
                })
            );
        }
    }, [customerDetails]);

    const DeleteButton = () => {
        return isButtonDisabled ? (
            <Whisper
                trigger="hover"
                placement="bottom"
                speaker={
                    <Tooltip>{t("customers.delete.buttonHelpText")}</Tooltip>
                }
            >
                <Button
                    disabled={isButtonDisabled}
                    onClick={handleDelete}
                    appearance="primary"
                    className={css.addNewCustomerBtn}
                >
                    {t("customers.delete.buttonTitle")}
                </Button>
            </Whisper>
        ) : (
            <Button
                disabled={isButtonDisabled}
                onClick={handleDelete}
                appearance="primary"
                className={cn(css.addNewCustomerBtn, css.deleteBtn)}
            >
                {t("customers.delete.buttonTitle")}
            </Button>
        );
    };

    return (
        <>
            <ConfirmModalContainer
                handleCancelModal={handleCancel}
                confirmCallbacks={[handleConfirm]}
                isVisible={confirmVisible}
                message={confirmationDialogMessage}
            />
            <DeleteButton />
        </>
    );
};

export default DeleteCustomerButton;
