import React, { useEffect, useRef, useState } from "react";
import Axios from "axios";
import { FormInstance, Message, Table, toaster } from "rsuite";

import { useTranslation } from "react-i18next";

import useRequestCancellation from "hooks/useRequestCancellation";
import useRefreshPage from "hooks/useRefreshPage";
import useInactiveTenant from "hooks/useInactiveTenant";
import useInactiveEnvironment from "hooks/useInactiveEnvironment";

import { IDataRefreshUpdate } from "store/environments/tenants/tenant-data-refresh/tenant-data-refresh-types";
import { EmailRecipientType } from "store/customers/customer-details/customer-details-types";
import {
    IMessage,
    MessageType,
} from "store/environments/tenants/data-import/data-import-types";

import {
    loadTenantErpDataRefreshHistory,
    updateTenantErpDataRefreshEmailRecipients,
} from "api/environments/tenants/tenant-erp-data-refresh-api";
import { getErrorMessage } from "api/defaults";

import constants from "utils/constants";
import { compareDates, formatDateWithNameOfMonth } from "utils/date-helper";

import "./DataRefreshHistory.less";
import EmailAddressesBox from "../../../../Common/EmailAddressesBox/EmailAddressesBox";
import SettingsFooterContainer from "components/Common/SettingsFooter/SettingsFooterContainer";
import { TypeAttributes } from "rsuite/esm/internals/types";

interface IComponentProps {
    envMoniker: string;
    tenantId: string;
    showLoader: (isVisible: boolean) => void;
    showError: (errorMessage: string) => void;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
}

type SortType = "desc" | "asc";

const defaultSortType: SortType = "desc";

const negativeMessageTypes = [MessageType.Error, MessageType.Warning];

const DataRefreshHistory: React.FC<IComponentProps> = ({
    envMoniker,
    tenantId,
    showLoader,
    showError,
    setIsUnsavedChangeAvailable,
}: IComponentProps) => {
    const { t } = useTranslation();
    const cancelTokenSource = useRequestCancellation();

    const containerRef = useRef<HTMLDivElement>(null);
    const mainFormRef = useRef<FormInstance<Record<string, any>> | null>(null);

    const [mainEmailAddress, setMainEmailAddress] = useState("");
    const [resultMainEmailAddresses, setResultMainEmailAddresses] = useState<
        string[]
    >([]);
    const isMainResultArray = resultMainEmailAddresses.length > 0;

    const [historyRecords, setHistoryRecords] = useState<IDataRefreshUpdate[]>(
        []
    );

    const [dataRefreshMessage, setDataRefreshMessage] =
        useState<IMessage | null>(null);
    const [sortColumn, setSortColumn] = useState<string>("updateTime");
    const [sortType, setSortType] = useState<SortType>(defaultSortType);

    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [isDataFetched, setIsDataFetched] = useState<boolean>(false);

    const handleSortColumn = (sortColumn: string, sortType: SortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    };

    const handleFormSubmit = () => {
        if (!mainFormRef.current?.check()) {
            return;
        }

        if (envMoniker && tenantId) {
            showError("");
            showLoader(true);
            if (mainEmailAddress) {
                resultMainEmailAddresses.push(mainEmailAddress);
                setMainEmailAddress("");
            }

            const emailRecipients = resultMainEmailAddresses.map((address) => ({
                address,
                type: EmailRecipientType.Main,
            }));

            updateTenantErpDataRefreshEmailRecipients(
                envMoniker,
                tenantId,
                emailRecipients
            ).then(([payload, status]) => {
                showLoader(false);
                setIsUnsavedChangeAvailable(false);
                if (status === constants.statusCode.NoContent) {
                    toaster.push(
                        <Message type="success" showIcon>
                            {t(
                                "tenantConfig.common.successfulUpdateAlertMessage"
                            )}
                        </Message>,
                        {
                            duration: constants.alertDurationMsec,
                        }
                    );
                } else {
                    showError(getErrorMessage(String(payload), status));
                }
            });
        }
    };

    const fetchData = () => {
        showError("");
        showLoader(true);

        loadTenantErpDataRefreshHistory(envMoniker, tenantId, cancelTokenSource)
            .then(([payload, status]) => {
                if (payload && status === constants.statusCode.OK) {
                    setHistoryRecords(
                        payload.dataRefreshUpdates.map((historyRecord) => {
                            return {
                                updateTime: new Date(historyRecord.updateTime),
                            };
                        })
                    );

                    setResultMainEmailAddresses(
                        payload.emailRecipients.map((emailRecipient) => {
                            return emailRecipient.address;
                        })
                    );

                    setDataRefreshMessage(payload.message);
                } else {
                    showError(getErrorMessage(String(payload), status));
                }
            })
            .catch((error: Error) => {
                const axiosError = error;
                if (!Axios.isCancel(axiosError)) {
                    showError(error.message);
                }
            })
            .then(() => {
                setIsDataFetched(true);
                showLoader(false);
            });
    };

    useEffect(() => {
        fetchData();
    }, [envMoniker, tenantId]);

    useRefreshPage(() => {
        if (envMoniker && tenantId) {
            fetchData();
        }
    });

    useEffect(() => {
        setIsDisabled(
            negativeMessageTypes.includes(
                dataRefreshMessage?.type ?? MessageType.Information
            )
        );
    }, [dataRefreshMessage]);

    useInactiveTenant();
    useInactiveEnvironment();

    const handleResultMainEmailChange = (
        value: React.SetStateAction<string[]>
    ) => {
        setIsUnsavedChangeAvailable(true);
        setResultMainEmailAddresses(value);
    };

    const handleMainEmailChange = (value: React.SetStateAction<string>) => {
        setIsUnsavedChangeAvailable(true);
        setMainEmailAddress(value);
    };

    const getData = () => {
        if (sortColumn && sortType) {
            return historyRecords.sort((a, b) => {
                if (sortColumn === "updateTime") {
                    return sortType === "asc"
                        ? compareDates(a.updateTime, b.updateTime) < 0
                            ? 1
                            : -1
                        : compareDates(a.updateTime, b.updateTime) < 0
                          ? -1
                          : 1;
                }
                return sortType === "asc"
                    ? a[sortColumn as keyof IDataRefreshUpdate] <
                      b[sortColumn as keyof IDataRefreshUpdate]
                        ? -1
                        : 1
                    : a[sortColumn as keyof IDataRefreshUpdate] <
                        b[sortColumn as keyof IDataRefreshUpdate]
                      ? 1
                      : -1;
            });
        }

        return historyRecords;
    };

    const getMessageProps = (
        axImportMessage: IMessage | null
    ): {
        type: TypeAttributes.Status;
        title: string;
    } => {
        switch (axImportMessage?.type) {
            case MessageType.Information:
                return {
                    type: "info",
                    title: t("tenantConfig.emptyMessage.title.info"),
                };
            case MessageType.Warning:
            case MessageType.Error:
                return {
                    type: "warning",
                    title: t("tenantConfig.emptyMessage.title.warning"),
                };
            default:
                return {
                    type: "info",
                    title: "",
                };
        }
    };

    const messageProps = getMessageProps(dataRefreshMessage);

    return (
        isDataFetched && (
            <div ref={containerRef} className="data-refresh-history__container">
                <div className="data-refresh-history__content">
                    <h1>{t("tenantConfig.dataRefreshHistory.header")}</h1>
                    {dataRefreshMessage && (
                        <Message
                            className="data-refresh-history__message"
                            showIcon
                            {...messageProps}
                        >
                            {dataRefreshMessage.message}
                        </Message>
                    )}
                    <EmailAddressesBox
                        formRef={mainFormRef}
                        emailAddress={mainEmailAddress}
                        resultEmailAddresses={resultMainEmailAddresses}
                        isResultArray={isMainResultArray}
                        readOnly={isDisabled}
                        boxLabel={t(
                            "tenantConfig.dataRefreshHistory.mainRecipientTitle"
                        )}
                        tooltipText={t(
                            "tenantConfig.dataRefreshHistory.emailBoxTooltip"
                        )}
                        setEmailAddress={handleMainEmailChange}
                        setResultEmailAddresses={handleResultMainEmailChange}
                    />
                    <div className="data-refresh-history__form-helper">
                        {t("tenantConfig.dataRefreshHistory.helpText")}
                    </div>
                    <Table
                        shouldUpdateScroll={false}
                        data={getData()}
                        sortColumn={sortColumn}
                        sortType={sortType}
                        onSortColumn={(dataKey, sortType) =>
                            handleSortColumn(dataKey, sortType!)
                        }
                        autoHeight={true}
                    >
                        <Table.Column flexGrow={1} fixed sortable>
                            <Table.HeaderCell>
                                {t(
                                    "tenantConfig.dataRefreshHistory.table.dateHeader"
                                )}
                            </Table.HeaderCell>
                            <Table.Cell dataKey="updateTime">
                                {(rowData: IDataRefreshUpdate) => {
                                    return (
                                        <div>
                                            {formatDateWithNameOfMonth(
                                                rowData.updateTime
                                            )}
                                        </div>
                                    );
                                }}
                            </Table.Cell>
                        </Table.Column>
                    </Table>
                </div>
                <SettingsFooterContainer handleFormSubmit={handleFormSubmit} />
            </div>
        )
    );
};

export default DataRefreshHistory;
