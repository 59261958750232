import { RootState } from "store/store";
import { compose } from "redux";
import DeleteTenantBar from "./DeleteTenantBar";
import { connect } from "react-redux";
import {
    getActiveEnvironmentMoniker,
    getActiveEnvironmentName,
} from "store/environments/environment-selectors";
import { getActiveTenantMoniker } from "store/environments/tenants/tenants-selectors";
import {
    getActiveTenantDeleteWorkflow,
    getIsDeleteFetched,
} from "store/environments/tenants/tenant-delete/tenant-delete-selectors";
import { setLoaderVisibility } from "store/common/initialization/initialization-actions";
import { setIsUnsavedChangeAvailable } from "store/common/unsaved-data/unsaved-data-actions";
import { setTenantDeleteWorkflow } from "store/environments/tenants/tenant-delete/tenant-delete-actions";

const mapStateToProps = (state: RootState) => {
    return {
        envMoniker: getActiveEnvironmentMoniker(state),
        activeEnvName: getActiveEnvironmentName(state),
        tenantMoniker: getActiveTenantMoniker(state),
        activeTenant: state.tenantsReducer.activeTenant,
        activeTenantDeleteWorkflow: getActiveTenantDeleteWorkflow(state),
        isDeleteFetched: getIsDeleteFetched(state),
        storeInvoiceVolumeEnabled:
            state.appSettingsReducer.appSettings.isInvoiceVolumeStoringEnabled,
    };
};

const DeleteTenantBarContainer = compose(
    connect(mapStateToProps, {
        setLoaderVisibility,
        setIsUnsavedChangeAvailable,
        setTenantDeleteWorkflow,
    })
)(DeleteTenantBar);

export default DeleteTenantBarContainer;
