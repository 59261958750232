import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    ITenantDeleteWorkflow,
    WorkflowModalType,
} from "utils/tenant-workflows/tenant-workflows-types";
import ProcessModalButton from "components/WorkflowLog/ProcessModalButton/ProcessModalButton";
import styles from "./DeletedTenantMessage.module.css";
import { Button } from "rsuite";
import cn from "classnames";
import { useNavigate, useParams } from "react-router";

interface IComponentProps {
    activeTenantDeleteWorkflow: ITenantDeleteWorkflow | null;
    setTenantDeleteWorkflow: (workflow: ITenantDeleteWorkflow | null) => void;
}

const DeletedTenantMessage: React.FC<IComponentProps> = ({
    activeTenantDeleteWorkflow,
    setTenantDeleteWorkflow,
}: IComponentProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { environmentName } = useParams<{ environmentName: string }>();

    const handleDashboard = () => {
        if (environmentName) {
            navigate(`/environments/${environmentName}/tenants`);
        }
    };

    useEffect(() => {
        return () => {
            setTenantDeleteWorkflow(null);
        };
    }, [setTenantDeleteWorkflow]);

    return (
        <div className={styles.messageContainer}>
            <div className={styles.mainMessage}>
                {t("tenantDelete.deletedTenantMessage")}
            </div>
            <div className={styles.historyLog}>
                {activeTenantDeleteWorkflow !== null && (
                    <ProcessModalButton
                        workflow={activeTenantDeleteWorkflow}
                        workflowType={WorkflowModalType.TenantDeletion}
                    />
                )}
            </div>

            <div>
                <Button
                    type="submit"
                    onClick={handleDashboard}
                    className={cn(styles.generalBtn, styles.saveBtn)}
                >
                    {t("tenantDelete.gotoDashboard")}
                </Button>
            </div>
        </div>
    );
};

export default DeletedTenantMessage;
