import { RootState } from "store/store";
import { connect } from "react-redux";
import { getActiveTenantMoniker } from "store/environments/tenants/tenants-selectors";
import GuestTenantsConfig from "./GuestTenantsConfig";

const mapStateToProps = (state: RootState) => ({
    tenantMoniker: getActiveTenantMoniker(state),
});
const GuestTenantsConfigContainer = connect(
    mapStateToProps,
    {}
)(GuestTenantsConfig);

export default GuestTenantsConfigContainer;
