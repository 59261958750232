import Axios, { CancelTokenSource } from "axios";
import { defaultErrorResponse } from "./defaults";
import { IContact } from "store/contacts/contacts-types";
import { API } from "utils/api-config";

export const loadSupportContact = async (
    cancelTokenSource?: CancelTokenSource
): Promise<[IContact | null, number]> => {
    const response = await API.get(`/contacts/support`, {
        cancelToken: cancelTokenSource?.token,
    });

    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};
