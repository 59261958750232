import React, { useEffect, useState } from "react";
import { Container, Content, Loader, Sidebar } from "rsuite";
import css from "./ConfigurationLayout.module.css";
import LeftMenuContainer from "components/Environments/Tenants/LeftMenu/LeftMenuContainer";
import { Route, Routes, useLocation } from "react-router-dom";
import routes from "utils/routes";
import {
    ITenant,
    DeletionType,
} from "store/environments/tenants/tenants-types";
import cn from "classnames";
import { ITenantDeleteWorkflow } from "utils/tenant-workflows/tenant-workflows-types";
import { getTenantDelete } from "api/environments/tenants/tenant-delete-api";
import constants from "utils/constants";
import { getErrorMessage } from "api/defaults";
import DeletingTenantPage from "components/Environments/Tenants/DeleteTenant/DeleteTenantPage/DeletingTenantPage";
import { useTranslation } from "react-i18next";
import ErrorSectionContainer from "components/Common/ErrorSection/ErrorSectionContainer";
import useInactiveTenant from "hooks/useInactiveTenant";
import useInactiveEnvironment from "hooks/useInactiveEnvironment";

interface IComponentProps {
    envMoniker: string;
    tenantMoniker: string;
    activeTenant: ITenant;
    activeTenantDeleteWorkflow: ITenantDeleteWorkflow | null;
    isDeleteFetched: boolean;
    errorMessage: string;
    setErrorTenantDeleteMessage: (msg: string) => void;
    setTenantDeleteWorkflow: (workflow: ITenantDeleteWorkflow | null) => void;
    setIsDeleteFetched: (isDeleteFetched: boolean) => void;
}

const ConfigurationLayout: React.FC<IComponentProps> = ({
    envMoniker,
    tenantMoniker,
    activeTenant,
    activeTenantDeleteWorkflow,
    isDeleteFetched,
    errorMessage,
    setErrorTenantDeleteMessage,
    setTenantDeleteWorkflow,
    setIsDeleteFetched,
}: IComponentProps) => {
    const { t } = useTranslation();
    const location = useLocation();
    const [isTenantDeleteWorkflowLoading, setIsTenantDeleteWorkflowLoading] =
        useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const deletedTenantPathSegment = "deleted-tenant";

    useEffect(() => {
        setIsDeleting(
            !!(
                activeTenant &&
                activeTenantDeleteWorkflow &&
                activeTenant.versionInfo.id ===
                    activeTenantDeleteWorkflow.tenantId
            )
        );
    }, [activeTenant, activeTenantDeleteWorkflow]);

    useEffect(() => {
        return () => {
            setIsDeleteFetched(false);
        };
    }, [setIsDeleteFetched]);

    useEffect(() => {
        if (
            !isDeleteFetched &&
            !isTenantDeleteWorkflowLoading &&
            tenantMoniker &&
            envMoniker
        ) {
            setIsTenantDeleteWorkflowLoading(true);
            setErrorTenantDeleteMessage("");

            getTenantDelete(envMoniker, tenantMoniker).then(
                ([payload, status]) => {
                    if (payload && status === constants.statusCode.OK) {
                        setTenantDeleteWorkflow(payload);
                    } else if (status !== constants.statusCode.NotFound) {
                        setErrorTenantDeleteMessage(
                            getErrorMessage(String(payload), status)
                        );
                    }
                    setIsDeleteFetched(true);
                    setIsTenantDeleteWorkflowLoading(false);
                }
            );
        }
    }, [isDeleteFetched, envMoniker, tenantMoniker]);

    useEffect(() => {
        return () => {
            if (!location.pathname.includes(deletedTenantPathSegment)) {
                setTenantDeleteWorkflow(null);
            }
        };
    }, [setTenantDeleteWorkflow, history]);

    useInactiveTenant();
    useInactiveEnvironment();

    const leftMenuRouters = routes.configurationLayout.map((route, index) => {
        return (
            <Route
                key={String(index)}
                path={route.path}
                element={<route.componentName />}
            />
        );
    });

    return (
        <Container className={css.container}>
            <Sidebar className={`${css.leftMenu} ${css.scrollableArea}`}>
                <LeftMenuContainer />
            </Sidebar>
            <Content className={`${css.contentSection}`}>
                {isDeleteFetched ? (
                    <>
                        {!isTenantDeleteWorkflowLoading &&
                            activeTenant &&
                            isDeleting && (
                                <DeletingTenantPage
                                    deletionType={DeletionType.TenantDeletion}
                                />
                            )}
                        {!isTenantDeleteWorkflowLoading &&
                            activeTenant &&
                            !isDeleting &&
                            !errorMessage && <Routes>{leftMenuRouters}</Routes>}
                    </>
                ) : (
                    isTenantDeleteWorkflowLoading && (
                        <Loader
                            center
                            size="md"
                            speed="slow"
                            content={t("loader.defaultContent")}
                            className={cn(css.loader)}
                        />
                    )
                )}
                {errorMessage && <ErrorSectionContainer />}
            </Content>
        </Container>
    );
};

export default ConfigurationLayout;
