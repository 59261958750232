import {
    IErpPackage,
    IManageErpPackage,
    IErpPackageTarget,
    IErpPackageType,
    IHistoryEntry,
    IEmailsSendingWorkflow,
    IErpPackageEmailsSending,
    SendingStatus,
} from "store/deliverables/erp-packages/erp-packages-types";
import { API } from "utils/api-config";
import { IHubConnection } from "utils/types";
import { defaultErrorResponse } from "../defaults";

export const loadErpPackages = async (): Promise<
    [IErpPackage[] | null, number]
> => {
    const response = await API.get("/deliverables/packages");

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const loadErpPackageTypes = async (): Promise<
    [IErpPackageType[] | null, number]
> => {
    const response = await API.get("/deliverables/packages/types");

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const loadErpPackageTargets = async (): Promise<
    [IErpPackageTarget[] | null, number]
> => {
    const response = await API.get("/deliverables/packages/targets");

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const addErpPackage = async (
    erpPackage: IManageErpPackage
): Promise<[null, number]> => {
    const response = await API.post("/deliverables/packages", erpPackage);

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const updateErpPackage = async (
    packageDetails: IManageErpPackage
): Promise<[null, number]> => {
    const response = await API.put(
        `/deliverables/packages/${packageDetails.id}`,
        packageDetails
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const deleteErpPackage = async (
    packageId: string
): Promise<[null, number]> => {
    const response = await API.delete(`/deliverables/packages/${packageId}`);

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const loadPackageSendingHistory = async (
    packageId: string
): Promise<[IHistoryEntry[] | null, number]> => {
    const response = await API.get(
        `/deliverables/packages/${packageId}/history`
    );
    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const loadErpPackageSendingStatus = async (
    packageId: string
): Promise<[SendingStatus | null, number]> => {
    const response = await API.get(
        `/deliverables/packages/${packageId}/history/status`
    );
    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const getErpPackageEmailsSendingWorkflow = async (
    packageId: string
): Promise<[IEmailsSendingWorkflow | null, number]> => {
    const response = await API.get(`deliverables/packages/${packageId}/send`);

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const sendErpPackageEmails = async (
    packageId: string,
    emailSendingData: IErpPackageEmailsSending
): Promise<[string[] | null, number]> => {
    if (!packageId || !emailSendingData) {
        return defaultErrorResponse;
    }

    const response = await API.post(
        `deliverables/packages/${packageId}/send`,
        emailSendingData
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const getEmailsSendingHubConnection = async (
    workflowId: string
): Promise<[IHubConnection | null, number]> => {
    if (!workflowId) {
        return defaultErrorResponse;
    }

    const response = await API.get("hub/connection", {
        headers: {
            "x-ms-signalr-userid": workflowId,
            "x-ms-signalr-hub-name": "ErpPackageEmailSending",
        },
    });

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};
