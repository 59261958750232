import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonToolbar, Form, IconButton, Tooltip, Whisper } from "rsuite";
import useRefreshPage from "hooks/useRefreshPage";
import useInactiveTenant from "hooks/useInactiveTenant";
import useInactiveEnvironment from "hooks/useInactiveEnvironment";

import { IGuestTenant } from "store/environments/tenants/tenant-guest-tenants/tenant-guest-tenants-types";
import {
    ConsentStatusType,
    IConsent,
    IEmailDetails,
} from "store/customers/customer-details/customer-details-types";
import SendEmailContainer from "components/SendEmail/SendEmailContainer";
import GuestTenantConsentsContainer from "components/Environments/Tenants/LeftMenu/GuestTenants/GuestTenantConsents/GuestTenantConsentsContainer";
import NewGuestTenantContainer from "components/Environments/Tenants/LeftMenu/GuestTenants/NewGuestTenant/NewGuestTenantContainer";
import EllipsisLabel from "components/Common/EllipsisLabel/EllipsisLabel";
import {
    loadGuestTenants,
    activateGuestTenant,
    sendEmailForGuestTenant,
} from "api/environments/tenants/guest-tenants-api";
import constants from "utils/constants";
import { getErrorMessage } from "api/defaults";
import "./GuestTenants.less";
import useRequestCancellation from "hooks/useRequestCancellation";
import Axios from "axios";
import EmailFillIcon from "@rsuite/icons/EmailFill";
import CopyIcon from "@rsuite/icons/Copy";

interface ComponentProps {
    envMoniker: string;
    tenantMoniker: string;
    isAddNewGuestTenantOpened: boolean;
    hideActivateColumn?: boolean | undefined;
    setEmailSent: (isEmailSent: boolean) => void;
    setAddNewGuestTenant: (isOpened: boolean) => void;
    showLoader: (isVisible: boolean) => void;
    showError: (errorMessage: string) => void;
}

const GuestTenants: React.FC<ComponentProps> = ({
    envMoniker,
    tenantMoniker,
    isAddNewGuestTenantOpened,
    hideActivateColumn,
    setEmailSent,
    setAddNewGuestTenant,
    showLoader,
    showError,
}: ComponentProps) => {
    const { t } = useTranslation();

    const [isGuestTenantsFetched, setIsGuestTenantsFetched] = useState(false);
    const [guestTenants, setGuestTenants] = useState<IGuestTenant[] | null>();
    const [isSendByEmailClicked, setIsSendByEmailClicked] = useState(false);
    const [isConsentClicked, setConsentClicked] = useState(false);
    const [currentGuestTenant, setCurrentGuestTenant] =
        useState<IGuestTenant | null>(null);
    const [guestTenantId, setGuestTenantId] = useState("");
    const [copyTooltipMessage, setCopyTooltipMessage] = useState(
        t("customerDetails.copyFieldValue.copyToClipboard")
    );

    const cancelTokenSource = useRequestCancellation();

    const fetchGuestTenants = (envMoniker: string, tenantMoniker: string) => {
        showError("");
        showLoader(true);
        loadGuestTenants(envMoniker, tenantMoniker, cancelTokenSource)
            .then(([payload, status]) => {
                showLoader(false);
                if (payload && status === constants.statusCode.OK) {
                    setGuestTenants(payload);
                    if (currentGuestTenant) {
                        const t = payload.find(
                            (value) => value.id === currentGuestTenant.id
                        );
                        setCurrentGuestTenant(t || null);
                    }
                } else {
                    showError(getErrorMessage(String(payload), status));
                }
            })
            .catch((error: Error) => {
                const axiosError = error;
                if (!Axios.isCancel(axiosError)) {
                    showError(error.message);
                }
            });
        setIsGuestTenantsFetched(true);
    };

    const activateGuestTenantinDooap = (
        guestTenantId: string,
        envMoniker: string,
        tenantMoniker: string
    ) => {
        activateGuestTenant(guestTenantId, envMoniker, tenantMoniker)
            .then(([payload, status]) => {
                if (
                    status === constants.statusCode.OK ||
                    status === constants.statusCode.NoContent
                ) {
                    refreshGuestTenants();
                } else if (payload) {
                    showError(getErrorMessage(String(payload), status));
                }
            })
            .then(() => showLoader(false));
    };

    const refreshGuestTenants = () => {
        setIsGuestTenantsFetched(false);
    };

    const getConsentStatus = (consents: IConsent[]): string => {
        if (
            consents.some((value) => value.status === ConsentStatusType.Pending)
        ) {
            return t("tenantConfig.guestTenants.consentPending");
        }
        if (
            consents.some((value) => value.status === ConsentStatusType.Failed)
        ) {
            return t("tenantConfig.guestTenants.consentFailed");
        }
        return t("tenantConfig.guestTenants.consentAccepted");
    };

    const getTenantStatusLink = (tenant: IGuestTenant) => {
        if (
            (!tenant.regionConsents.consents ||
                !tenant.regionConsents.consents.every(
                    (value) => value.status === ConsentStatusType.Success
                )) &&
            !tenant.activeInDooapMain
        ) {
            return "";
        }

        if (tenant.activeInDooapMain) {
            return t("tenantConfig.guestTenants.tenantStatusActivated");
        }

        return (
            <a
                className={"statusLink"}
                href={undefined}
                target={"_blank"}
                rel={"noopener noreferrer"}
                onClick={(event) => {
                    event.stopPropagation();
                    showLoader(true);
                    activateGuestTenantinDooap(
                        tenant.id,
                        envMoniker,
                        tenantMoniker
                    );
                }}
            >
                {t("tenantConfig.guestTenants.tenantStatusInactive")}
            </a>
        );
    };

    const sendEmail = (envMoniker: string, emailDetails: IEmailDetails) => {
        showLoader(true);
        sendEmailForGuestTenant(envMoniker, emailDetails).then(
            ([payload, status]) => {
                showLoader(false);
                if (
                    status === constants.statusCode.OK ||
                    status === constants.statusCode.NoContent
                ) {
                    setEmailSent(true);
                } else {
                    showError(getErrorMessage(String(payload), status));
                }
            }
        );
    };

    useEffect(() => {
        if (envMoniker && tenantMoniker && !isGuestTenantsFetched) {
            fetchGuestTenants(envMoniker, tenantMoniker);
        }
    }, [
        envMoniker,
        tenantMoniker,
        isGuestTenantsFetched,
        fetchGuestTenants,
        setIsGuestTenantsFetched,
    ]);

    useEffect(() => {
        return () => {
            setIsGuestTenantsFetched(false);
        };
    }, [setIsGuestTenantsFetched]);

    useRefreshPage(() => {
        if (envMoniker && tenantMoniker) {
            fetchGuestTenants(envMoniker, tenantMoniker);
        }
    });

    useInactiveTenant();
    useInactiveEnvironment();

    const tableRows =
        guestTenants &&
        guestTenants.length &&
        guestTenants.map((item, index) => {
            return (
                <tr
                    className={"tableRowTenants"}
                    key={String("rowIndex_" + index)}
                >
                    <td className="cellTenants">
                        <EllipsisLabel>{item.name}</EllipsisLabel>
                    </td>
                    <td className="cellTenants">
                        {item.aadIssuerId ? item.aadIssuerId : "-"}
                    </td>
                    <td className="cellTenants">
                        <a
                            className={"statusLink"}
                            href={undefined}
                            target={"_blank"}
                            rel={"noopener noreferrer"}
                            onClick={(event) => {
                                event.stopPropagation();
                                setCurrentGuestTenant(item);
                                setConsentClicked(true);
                            }}
                        >
                            {getConsentStatus(item.regionConsents.consents)}
                        </a>
                    </td>
                    {!hideActivateColumn && (
                        <td className="cellTenants">
                            {getTenantStatusLink(item)}
                        </td>
                    )}
                    <td className="cellTenants">
                        <ButtonToolbar className="table__button-toolbar">
                            <Whisper
                                trigger={"hover"}
                                placement={"top"}
                                speaker={
                                    <Tooltip>{copyTooltipMessage}</Tooltip>
                                }
                            >
                                <IconButton
                                    icon={<CopyIcon />}
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            item.consentWizardAppLink
                                        );
                                        setCopyTooltipMessage(
                                            t(
                                                "customerDetails.copyFieldValue.copied"
                                            )
                                        );
                                    }}
                                    onMouseLeave={() =>
                                        setCopyTooltipMessage(
                                            t(
                                                "customerDetails.copyFieldValue.copyToClipboard"
                                            )
                                        )
                                    }
                                />
                            </Whisper>
                            <Whisper
                                trigger={"hover"}
                                placement={"top"}
                                speaker={
                                    <Tooltip>
                                        {t(
                                            "customerDetails.sendEmailButtonText"
                                        )}
                                    </Tooltip>
                                }
                            >
                                <IconButton
                                    icon={<EmailFillIcon />}
                                    onClick={() => {
                                        setGuestTenantId(item.id);
                                        setIsSendByEmailClicked(true);
                                    }}
                                />
                            </Whisper>
                        </ButtonToolbar>
                    </td>
                </tr>
            );
        });

    const tenantConsents =
        currentGuestTenant !== null ? (
            <GuestTenantConsentsContainer
                isOpened={isConsentClicked}
                setIsOpened={setConsentClicked}
                tenant={currentGuestTenant}
                tenantMoniker={tenantMoniker}
                refreshGuestTenants={refreshGuestTenants}
                allTenants={guestTenants}
            />
        ) : (
            <></>
        );

    return (
        <>
            {isGuestTenantsFetched && (
                <div className={"guest-tenants__container"}>
                    <SendEmailContainer
                        isOpened={isSendByEmailClicked}
                        setIsOpened={setIsSendByEmailClicked}
                        userId={guestTenantId}
                        sendEmailWithLink={sendEmail}
                        setEmailSent={setEmailSent}
                    />
                    {tenantConsents}
                    <NewGuestTenantContainer
                        isOpened={isAddNewGuestTenantOpened}
                        setIsOpened={setAddNewGuestTenant}
                        allTenants={guestTenants}
                        tenantMoniker={tenantMoniker}
                        refreshGuestTenants={refreshGuestTenants}
                    />
                    <div className="guestTenantsContent">
                        <h1 className={"general-configurations__page-header"}>
                            {t("tenantConfig.guestTenants.header")}
                        </h1>
                        <table className={"tenantsTable"}>
                            <thead>
                                <tr className="tableHeaderTenants">
                                    <th className={"cellTenants"}>
                                        {t(
                                            "tenantConfig.guestTenants.tenantNameHeader"
                                        )}
                                    </th>
                                    <th className={"cellTenants"}>
                                        {t(
                                            "tenantConfig.guestTenants.aadIssuerHeader"
                                        )}
                                    </th>
                                    <th className={"cellTenants"}>
                                        {t(
                                            "tenantConfig.guestTenants.consentStatusHeader"
                                        )}
                                    </th>
                                    {!hideActivateColumn && (
                                        <th className={"cellTenants"}>
                                            {t(
                                                "tenantConfig.guestTenants.activateHeader"
                                            )}
                                            <Form.HelpText
                                                className="tooltipPos"
                                                tooltip
                                            >
                                                {t(
                                                    "tenantConfig.guestTenants.activateHeaderHelp"
                                                )}
                                            </Form.HelpText>
                                        </th>
                                    )}
                                    <th className={"cellTenants"}>
                                        {t(
                                            "tenantConfig.guestTenants.shareHeader"
                                        )}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{tableRows || []}</tbody>
                        </table>
                    </div>
                </div>
            )}
        </>
    );
};

export default GuestTenants;
