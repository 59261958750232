import React, { useEffect, useState } from "react";
import { Button, Container, Content, Header } from "rsuite";
import AppHeaderContainer from "components/AppHeader/AppHeaderContainer";
import PreloaderContainer from "components/Common/Preloader/PreloaderContainer";
import BreadCrumbContainer from "components/BreadCrumb/BreadCrumbContainer";
import AppRouterContainer from "components/App/AppRouter/AppRouterContainer";
import css from "./MainLayout.module.css";
import UnsavedDataModalContainer from "components/UnsavedDataModal/UnsavedDataModalContainer";
import useBeforeUnload from "hooks/useBeforeUnload";
import EnvironmentHistoryContainer from "components/Environments/EnvironmentHistory/EnvironmentHistoryContainer";
import TenantStatusContainer from "components/Environments/Tenants/TenantStatus/TenantStatusContainer";
import AppRegistrationInformationContainer from "components/Environments/AppRegistrationInformation/AppRegistrationInformationContainer";
import { BlockerFunction, useBlocker } from "react-router-dom";
import ModalContainer from "components/Common/Modal/ModalContainer";
import { useTranslation } from "react-i18next";

interface ComponentProps {
    isAppSettingsFetched: boolean;
    isLoaderVisible: boolean;
    isUnsavedChangeAvailable: boolean;
    fetchAppSettings: () => void;
    fetchUserPermissions: () => void;
    fetchSupportContact: () => void;
    setIsUnsavedChangeAvailable: (isAvailible: boolean) => void;
}

const MainLayout: React.FC<ComponentProps> = ({
    isAppSettingsFetched,
    isLoaderVisible,
    isUnsavedChangeAvailable,
    fetchAppSettings,
    fetchUserPermissions,
    fetchSupportContact,
    setIsUnsavedChangeAvailable,
}: ComponentProps) => {
    const { t } = useTranslation();
    useBeforeUnload();

    const shouldBlock = React.useCallback<BlockerFunction>(
        ({ currentLocation, nextLocation }) =>
            isUnsavedChangeAvailable &&
            currentLocation.pathname !== nextLocation.pathname,
        [isUnsavedChangeAvailable]
    );
    const blocker = useBlocker(shouldBlock);

    const [isUserPermissionsLoaded, setUserPermissionsLoaded] = useState(false);
    const [isSupportContactLoaded, setIsSupportContactLoaded] = useState(false);

    useEffect(() => {
        if (!isAppSettingsFetched) {
            fetchAppSettings();
        }
    }, [isAppSettingsFetched, fetchAppSettings]);

    useEffect(() => {
        if (!isUserPermissionsLoaded) {
            fetchUserPermissions();
            setUserPermissionsLoaded(true);
        }
    }, [isUserPermissionsLoaded, fetchUserPermissions]);

    useEffect(() => {
        if (!isSupportContactLoaded) {
            fetchSupportContact();
            setIsSupportContactLoaded(true);
        }
    }, [isSupportContactLoaded, fetchSupportContact]);

    const proceedNavigation = () => {
        if (blocker.proceed) {
            setIsUnsavedChangeAvailable(false);
            blocker.proceed();
        }
    };

    const cancelNavigation = () => {
        if (blocker.reset) {
            blocker.reset();
        }
    };

    return (
        <Container>
            <ModalContainer
                title={""}
                enforceFocus={true}
                className="confirm-modal"
                onClose={cancelNavigation}
                open={blocker.state === "blocked"}
                footer={
                    <>
                        <Button onClick={proceedNavigation}>
                            {t("unsavedDataModal.button.leave")}
                        </Button>
                        <Button onClick={cancelNavigation}>
                            {t("unsavedDataModal.button.stay")}
                        </Button>
                    </>
                }
            >
                {t("unsavedDataModal.mainMessage")}
            </ModalContainer>
            <Header>
                <AppHeaderContainer />
            </Header>
            <Content className={css.content}>
                <PreloaderContainer />
                <div
                    className={
                        isLoaderVisible || !isAppSettingsFetched
                            ? css.hiddenContent
                            : css.mainContent
                    }
                >
                    <BreadCrumbContainer />
                    <AppRouterContainer />
                    <EnvironmentHistoryContainer />
                    <AppRegistrationInformationContainer />
                    <TenantStatusContainer />
                </div>
            </Content>
            <UnsavedDataModalContainer />
        </Container>
    );
};

export default MainLayout;
