import { API } from "../../../utils/api-config";
import { TPromise } from "store/environments/tenants/tenants-types";
import { defaultErrorResponse } from "../../defaults";

export const loadTenants = async (envMoniker: string): Promise<TPromise> => {
    if (!envMoniker) {
        return defaultErrorResponse;
    }

    const parameters = `/environments/${envMoniker}/tenants`;
    const response = await API.get(`${parameters}`);

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const loadD365RefreshParametersConfig = async (
    envMoniker: string
): Promise<[boolean | null, number]> => {
    if (!envMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.get(
        `/environments/${envMoniker}/tenants/d365-refresh-parameters-config`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};
