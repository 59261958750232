import React, { useEffect, useState } from "react";
import { Button, ButtonToolbar, Form, Message, toaster } from "rsuite";
import { useTranslation } from "react-i18next";
import {
    FailoverType,
    ITenantFeatureSettingWorkFlow,
    TenantFeatureSettingWorkFlowType,
    WorkflowStatus,
    WorkflowStepStatus,
    mlTenantFeatureSettingTypes,
} from "utils/tenant-workflows/tenant-workflows-types";
import {
    retryTenantFeatureSetting,
    handleFailedTenantFeatureSetting,
    retryCefTenantFeatureSetting,
    approveTenantFeatureSetting,
    terminateSettingWorkflow,
} from "api/environments/tenants/tenant-feature-settings-api";
import { tenantFeatureSettingDefinitions } from "utils/tenant-workflows/tenant-workflows-definitions";
import cefTenantFeatureSettingTypes from "utils/tenant-workflows/cef-tenant-workflows-types";
import constants from "utils/constants";
import { getErrorMessage } from "api/defaults";
import cn from "classnames";

interface IComponentProps {
    isContinueButtonDisabled?: boolean;
    environmentMoniker: string;
    tenantMoniker: string;
    tenantFeatureSettingWorkFlow: ITenantFeatureSettingWorkFlow;
    onClose?: () => void;
    triggerRefresh?: () => void;
}

const TenantFeatureProgressModalFooter: React.FC<IComponentProps> = ({
    isContinueButtonDisabled,
    environmentMoniker,
    tenantMoniker,
    tenantFeatureSettingWorkFlow,
    onClose,
    triggerRefresh,
}: IComponentProps) => {
    const { t } = useTranslation();
    const [isRefreshInvoked, setIsRefreshInvoked] = useState(false);
    const [isContinueInvoked, setIsContinueInvoked] = useState(false);
    const [isTermiateInvoked, setIsTerminateInvoked] = useState(false);

    const isAnyStepFailed = tenantFeatureSettingWorkFlow.steps.some(
        (step) => step.status === WorkflowStepStatus.Failed
    );
    const isWorkflowWaitingForApprove =
        tenantFeatureSettingWorkFlow.status === WorkflowStatus.WaitForApprove;

    const settingDefinition =
        tenantFeatureSettingDefinitions[tenantFeatureSettingWorkFlow.type] ??
        null;

    const isMlTrainingFailed =
        mlTenantFeatureSettingTypes.includes(
            tenantFeatureSettingWorkFlow.type
        ) &&
        tenantFeatureSettingWorkFlow.steps.some((step) => {
            return (
                (step.order === 3 || step.order === 4) &&
                step.status === WorkflowStepStatus.Failed
            );
        });

    const isSearchServiceSelectionFailedStepToSkip =
        tenantFeatureSettingWorkFlow.type ===
            TenantFeatureSettingWorkFlowType.SearchServiceSelection &&
        tenantFeatureSettingWorkFlow.steps.some((step) => {
            return (
                step.order === 0 && step.status === WorkflowStepStatus.Failed
            );
        });

    const isTerminateAvailable =
        !cefTenantFeatureSettingTypes.includes(
            tenantFeatureSettingWorkFlow.type
        ) &&
        ![WorkflowStatus.Success, WorkflowStatus.Terminated].includes(
            tenantFeatureSettingWorkFlow.status
        );

    const isWorkflowTerminated =
        tenantFeatureSettingWorkFlow.status === WorkflowStatus.Terminated;

    useEffect(() => {
        if (isRefreshInvoked && !isAnyStepFailed) {
            setIsRefreshInvoked(false);
        }
        if (isContinueInvoked && !isAnyStepFailed) {
            setIsContinueInvoked(false);
        }
    }, [isAnyStepFailed]);

    const handleRetry = () => {
        setIsRefreshInvoked(true);

        const retryFunction = cefTenantFeatureSettingTypes.includes(
            tenantFeatureSettingWorkFlow.type
        )
            ? retryCefTenantFeatureSetting
            : isMlTrainingFailed || isSearchServiceSelectionFailedStepToSkip
              ? () =>
                    handleFailedTenantFeatureSetting(
                        environmentMoniker,
                        tenantMoniker,
                        settingDefinition.urlParameterValue,
                        tenantFeatureSettingWorkFlow.id,
                        { failoverType: FailoverType.Retry }
                    )
              : retryTenantFeatureSetting;

        retryFunction(
            environmentMoniker,
            tenantMoniker,
            settingDefinition.urlParameterValue,
            tenantFeatureSettingWorkFlow.id
        ).then(([payload, status]) => {
            if (status !== constants.statusCode.OK) {
                toaster.push(
                    <Message showIcon type="error">
                        {getErrorMessage(String(payload), status)}
                    </Message>,
                    {
                        duration: constants.alertDurationMsec,
                    }
                );
                setIsRefreshInvoked(false);
            }
        });
    };

    const handleTerminate = () => {
        setIsTerminateInvoked(true);
        terminateSettingWorkflow(
            environmentMoniker,
            tenantMoniker,
            settingDefinition.urlParameterValue,
            tenantFeatureSettingWorkFlow.id
        ).then(([payload, status]) => {
            if (status === constants.statusCode.OK) {
                if (onClose) {
                    onClose();
                }
                if (triggerRefresh) {
                    triggerRefresh();
                }
            } else {
                toaster.push(
                    <Message showIcon type="error">
                        {getErrorMessage(String(payload), status)}
                    </Message>,
                    {
                        duration: constants.alertDurationMsec,
                    }
                );
            }
            setIsTerminateInvoked(false);
        });
    };

    const handleContinue = () => {
        setIsContinueInvoked(true);

        const continueFunction =
            isMlTrainingFailed || isSearchServiceSelectionFailedStepToSkip
                ? () =>
                      handleFailedTenantFeatureSetting(
                          environmentMoniker,
                          tenantMoniker,
                          settingDefinition.urlParameterValue,
                          tenantFeatureSettingWorkFlow.id,
                          { failoverType: FailoverType.Skip }
                      )
                : approveTenantFeatureSetting;

        continueFunction(
            environmentMoniker,
            tenantMoniker,
            settingDefinition.urlParameterValue,
            tenantFeatureSettingWorkFlow.id
        );
    };

    return settingDefinition !== null ? (
        <ButtonToolbar
            className={cn(
                "custom-modal__button-toolbar",
                "progress-modal__footer-toolbar"
            )}
        >
            {!isWorkflowTerminated && isAnyStepFailed && (
                <div className="progress-modal__button-tooltip">
                    <Button
                        type="submit"
                        appearance="primary"
                        onClick={handleRetry}
                        loading={isRefreshInvoked}
                    >
                        {t("tenantFeatureSetting.progressModal.button.retry")}
                    </Button>
                    <Form.HelpText
                        className={"general-configurations__help"}
                        tooltip
                    >
                        {t(
                            "tenantFeatureSetting.progressModal.button.helpText.retry"
                        )}
                    </Form.HelpText>
                </div>
            )}
            {!isWorkflowTerminated &&
                (isWorkflowWaitingForApprove ||
                    (isAnyStepFailed &&
                        (isMlTrainingFailed ||
                            isSearchServiceSelectionFailedStepToSkip))) && (
                    <div className="progress-modal__button-tooltip">
                        <Button
                            disabled={
                                isWorkflowWaitingForApprove &&
                                isContinueButtonDisabled
                            }
                            type="submit"
                            appearance="primary"
                            onClick={handleContinue}
                            loading={isContinueInvoked}
                        >
                            {t(
                                "tenantFeatureSetting.progressModal.button.continue"
                            )}
                        </Button>
                        <Form.HelpText
                            className={"general-configurations__help"}
                            tooltip
                        >
                            {t(
                                "tenantFeatureSetting.progressModal.button.helpText.continue"
                            )}
                        </Form.HelpText>
                    </div>
                )}
            <Button
                appearance={isAnyStepFailed ? "ghost" : "primary"}
                onClick={onClose}
            >
                {t("tenantFeatureSetting.progressModal.button.close")}
            </Button>
            {isTerminateAvailable && (
                <div className="progress-modal__button-tooltip">
                    <Button
                        className="progress-modal__footer-terminate"
                        appearance={"ghost"}
                        onClick={handleTerminate}
                        loading={isTermiateInvoked}
                    >
                        {t(
                            "tenantFeatureSetting.progressModal.button.terminate"
                        )}
                    </Button>
                    <Form.HelpText
                        className={"general-configurations__help"}
                        tooltip
                    >
                        {t(
                            "tenantFeatureSetting.progressModal.button.helpText.terminate"
                        )}
                    </Form.HelpText>
                </div>
            )}
        </ButtonToolbar>
    ) : null;
};

export default TenantFeatureProgressModalFooter;
