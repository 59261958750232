import { API } from "utils/api-config";
import {
    IEnvironment,
    IDooapAppRegistration,
} from "store/environments/environment-types";
import { defaultErrorResponse } from "../defaults";
import { IEnvironmentHistoryItem } from "store/environments/environment-history/environment-history-types";
import { ITenantDopeSubscription } from "components/Environments/Tenants/LeftMenu/Subscriptions/tenant-dope-subscriptions-types";
import Axios, { CancelTokenSource } from "axios";

export const loadEnvironments = async (): Promise<
    [IEnvironment[] | null, number]
> => {
    const response = await API.get("/environments");

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const loadActionHistory = async (
    environmentMoniker: string
): Promise<[IEnvironmentHistoryItem[] | null, number]> => {
    const response = await API.get(
        `/environments/${environmentMoniker}/history`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const loadEnvironmentAppRegistrations = async (
    environmentMoniker: string
): Promise<[IDooapAppRegistration[] | null, number]> => {
    const response = await API.get(
        `/environments/${environmentMoniker}/app-registrations`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const loadAllDopeSubscriptions = async (
    envMoniker: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[ITenantDopeSubscription[] | null, number]> => {
    const response = await API.get(`environments/${envMoniker}/subscriptions`, {
        cancelToken: cancelTokenSource?.token,
    });

    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};
