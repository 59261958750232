import { connect } from "react-redux";
import { setIsUnsavedChangeAvailable } from "store/common/unsaved-data/unsaved-data-actions";
import SftpIpAddresses from "./SftpIpAddresses";

const mapStateToProps = () => ({});
const SftpIpAddressesContainer = connect(mapStateToProps, {
    setIsUnsavedChangeAvailable,
})(SftpIpAddresses);

export default SftpIpAddressesContainer;
